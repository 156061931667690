import { motion } from "framer-motion";
import React, { useMemo } from "react";

import { useLeaderboard } from "../../hooks";
import {
  calculateScore,
  contentTransition,
  contentVariants,
  shortenAddress,
} from "../../utility";
import { Modal } from "../modal";
import {
  LeaderBoardRowProps,
  ModalLeaderBoardProps,
} from "./modalLeaderBoardProps";
import { truncateName } from "./truncateName";
import { Button } from "..";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { PLACEHOLDER_CHAIN_ID } from "../../chains.ts";

export const ModalLeaderBoard: React.FC<ModalLeaderBoardProps> = ({
  player,
  isVisible,
  onClose,
}) => {
  const { wallet } = usePrivyWagmi();
  const { leaderboard } = useLeaderboard(
    wallet?.chainId || PLACEHOLDER_CHAIN_ID
  );

  const players = useMemo(() => {
    if (!leaderboard) {
      return [];
    }

    const uniquePlayers = new Map();

    leaderboard.forEach((player) => {
      if (!uniquePlayers.has(player.player)) {
        uniquePlayers.set(player.player, {
          ...player,
          score: calculateScore(player.wins, player.losses, player.escapes),
        });
      }
    });

    return Array.from(uniquePlayers.values())
      .sort((a, b) => b.score - a.score)
      .map((player, index) => ({
        ...player,
        key: player.player,
        rank: index + 1,
        username: player.username || shortenAddress(player.player),
      }));
  }, [leaderboard]);

  const playerData = useMemo(() => {
    // Get the player's data from the leaderboard
    const data = players?.find((entry) => entry.key === player);

    if (data === undefined) return;
    const { username, wins, losses, escapes, score, rank } = data;
    return {
      key: player,
      username,
      wins,
      losses,
      escapes,
      score,
      rank,
    };
  }, [players, player]);

  return (
    <Modal isVisible={isVisible} className="px-2 py-2 space-y-4">
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={contentVariants}
        transition={contentTransition}
        className="relative h-full flex p-2 rounded-3xl w-full min-w-0 bg-background-primary/50 backdrop-blur-xl border border-background-primary/20 shadow-lg"
      >
        <div className="flex h-full overflow-y-scroll flex-1 flex-col min-w-0 pt-4 pb-2 px-2 rounded-2xl bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
          <h2 className="text-lg text-center font-display font-bold">
            Leader Board
          </h2>
          <div className="flex flex-col space-y-2 pt-4">
            {/* LeaderBoardHeader */}
            <div className="flex flex-row space-x-4 font-display font-black text-xs text-text-primary py-2 px-4">
              <span className="w-4 flex flex-col items-center">#</span>
              <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
                <span className="flex flex-col flex-1">Player</span>
                <div className="flex flex-row flex-1 space-x-2 text-right">
                  <span className="flex flex-col flex-1">W</span>
                  <span className="flex flex-col flex-1">L</span>
                  <span className="flex flex-col flex-1">E</span>
                  <span className="flex flex-col flex-1">S</span>
                </div>
              </div>
            </div>

            {playerData !== undefined && (
              <div className="flex flex-col space-y-2 pb-4">
                <span className="font-display font-black text-xs text-text-primary text-center">
                  You
                </span>
                <LeaderBoardRow {...playerData} key={playerData.key} />
              </div>
            )}

            <div className="flex flex-col pb-4 w-full px-8">
              <div className="w-full h-[0.5px] bg-base-300" />
            </div>

            {players?.map((player) => (
              <LeaderBoardRow {...player} key={player.key} />
            ))}
          </div>
        </div>
        <Button
          variant="light"
          size="2xs"
          onClick={onClose}
          className="absolute -top-2 -right-2"
          sound="close"
        >
          <span className="material-symbols-rounded text-lg">close</span>
        </Button>
      </motion.div>
    </Modal>
  );
};

const LeaderBoardRow: React.FC<LeaderBoardRowProps> = ({
  username,
  wins,
  losses,
  escapes,
  score,
  rank,
}) => {
  return (
    <div
      className="flex flex-row p-4 space-x-4 font-bold text-xs text-text-primary rounded-lg backdrop-blur-sm bg-background-primary/60 border border-background-primary/20 shadow-sm"
    >
      <span className="w-4 flex flex-col items-center">{rank}</span>
      <div className="flex flex-row flex-1 space-x-2 justify-between text-left">
        <div className="flex flex-1 min-w-0">
          <span className="truncate">{truncateName(username)}</span>
        </div>
        <div className="flex flex-row flex-1 space-x-2 text-right">
          <span className="flex flex-col flex-1">{wins}</span>
          <span className="flex flex-col flex-1">{losses}</span>
          <span className="flex flex-col flex-1">{escapes}</span>
          <span className="flex flex-col flex-1">{score}</span>
        </div>
      </div>
    </div>
  );
};
