import { motion } from "framer-motion";
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { zeroAddress } from "viem";

import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { useToggle } from "@uidotdev/usehooks";

import SOUNDS from "../../assets/sounds";
import { PLACEHOLDER_CHAIN_ID } from "../../chains.ts";
import {
  Button,
  ModalLeaderBoard,
  ModalProfile,
  ModalQueue,
  MonsterCard,
  ProfilePicture,
  SelectionSlot,
} from "../../components";
import { ModalMonsterSelection } from "../../components/modalMonsterSelection";
import {
  LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
  LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY,
  Monsters,
  NOTIFICATIONS_DISABLED,
  NOTIFICATIONS_ENABLED,
  NOTIFICATIONS_REQUESTED,
  getMonsterIdByName,
} from "../../config";
import { AppContext, UserContext } from "../../context";
import {
  MatchPhase,
  useEmbeddedWallet,
  useMatch,
  useMatchMaking,
} from "../../hooks";
import { usePlayerStats } from "../../hooks/chain/battle/leaderboard";
import { useFetchTokensByOwner } from "../../hooks/graph/tokens";
import useSound from "../../sound/useSound.ts";
import { Monster, Statistic } from "../../types";
import { contentTransition, contentVariants } from "../../utility";
import { calculateScore, generateTokenId } from "../../utility";
import { arbitrum } from "viem/chains";

export const BattlePreparation: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, userName, profilePictureURI } = useContext(UserContext)!;
  const { match } = useMatch(user);
  const { wallet } = usePrivyWagmi();

  const { enoughFunds, minimum } = useEmbeddedWallet(user);
  const [leaderBoardVisibility, toggleLeaderBoardVisibility] = useToggle(false);
  const { stats } = usePlayerStats(
    wallet?.chainId || PLACEHOLDER_CHAIN_ID,
    user
  );

  // Notifications
  const [notificationsRequested, setNotificationsRequested] = useState(
    localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY) ===
      NOTIFICATIONS_REQUESTED
  );
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY) ===
      NOTIFICATIONS_ENABLED
  );

  const requestPermissions = useCallback(() => {
    try {
      localStorage.setItem(
        LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY,
        NOTIFICATIONS_REQUESTED
      );
      setNotificationsRequested(true);

      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          setNotificationsEnabled(true);
          localStorage.setItem(
            LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
            NOTIFICATIONS_ENABLED
          );
        } else {
          setNotificationsEnabled(false);
          localStorage.setItem(
            LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
            NOTIFICATIONS_DISABLED
          );
        }
      });
    } catch (err) {
      setNotificationsEnabled(false);
      localStorage.setItem(
        LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
        NOTIFICATIONS_DISABLED
      );
    }
  }, []);

  // State for monsters and related UI elements
  const [monsters, setMonsters] = useState<{
    monster1?: Monster;
    monster2?: Monster;
  }>({});

  const [selectedSlot, setSelectedSlot] = useState<"monster1" | "monster2">();
  const [modalSelectionVisibility, setModalSelectionVisibility] =
    useState(false);
  const [modalProfileVisibility, setModalProfileVisibility] = useState(
    location?.state?.deposit
  );

  // Read from local storage
  useEffect(() => {
    const monster1Id = localStorage.getItem("ocb:monster-monster1:v2");
    const monster2Id = localStorage.getItem("ocb:monster-monster2:v2");

    const monsters = Object.values(Monsters).map((config) => {
      return {
        config,
        tokenId: generateTokenId(),
        isWaitingForCommit: false,
        statusEffects: [],
      };
    });

    if (monster1Id) {
      const monster1 = monsters.find(
        (monster) => monster.config.id === monster1Id
      );

      if (!monster1) {
        console.info("Monster 1 not found, removing from local storage");
        localStorage.removeItem("ocb:monster-monster1");
      } else {
        setMonsters((prev) => ({ ...prev, monster1 }));
      }
    }

    if (monster2Id) {
      const monster2 = monsters.find(
        (monster) => monster.config.id === monster2Id
      );

      if (!monster2) {
        console.info("Monster 2 not found, removing from local storage");
        localStorage.removeItem("ocb:monster-monster2");
      } else {
        setMonsters((prev) => ({ ...prev, monster2 }));
      }
    }
  }, []);

  const { tokens, refetch } = useFetchTokensByOwner(wallet?.address, true);

  const availableMonsterIds = useMemo(() => {
    return tokens
      .filter((token) => !!token.epoch?.randomness)
      .map((token) => getMonsterIdByName(token.decodedUri.name));
  }, [tokens]);

  // Join Match Logic
  const [isLoading, setIsLoading] = useState(false);
  const [inQueue, setInQueue] = useState(false);

  const { join, enabled, withdraw } = useMatchMaking(
    wallet?.chainId,
    monsters.monster1,
    monsters.monster2
  );

  const { chainId, setNoPromptOnSignature } = useContext(AppContext);
  useEffect(() => {
    if (chainId === arbitrum.id && !modalProfileVisibility) {
      setModalProfileVisibility(true);
    }
  }, [chainId, modalProfileVisibility]);

  const [playQueueWaiting, { stop }] = useSound(SOUNDS.queueWaiting, {
    interrupt: true,
    volume: 0.5,
  });

  const [playQueueSuccess] = useSound(SOUNDS.queueSuccess, {
    interrupt: true,
    volume: 1.0,
  });

  const onJoin = useCallback(() => {
    setInQueue(true);
    setIsLoading(true);
    playQueueWaiting();

    if (join) {
      stop();
      playQueueSuccess();
      join();
    }
  }, [join, playQueueSuccess, playQueueWaiting, stop]);

  const onWithdraw = useCallback(async () => {
    setInQueue(false);
    setIsLoading(true);
    stop();

    await withdraw?.();
    setIsLoading(false);
  }, [stop, withdraw]);

  useEffect(() => {
    if (
      typeof match !== "undefined" &&
      match?.id !== BigInt(0) &&
      match?.phase !== MatchPhase.GameOver &&
      match?.escaped === zeroAddress
    ) {
      setIsLoading(false);
      setInQueue(false);

      navigate("/battle");
    }
  }, [match, navigate]);

  // Monster Selection
  const handleSlotClick = useCallback((slot: "monster1" | "monster2") => {
    setSelectedSlot(slot);
    setModalSelectionVisibility(true);
  }, []);

  const handleMonsterSelect = useCallback(
    (monster: Monster) => {
      if (selectedSlot) {
        const oppositeSlot =
          selectedSlot === "monster1" ? "monster2" : "monster1";

        if (monsters[oppositeSlot]?.config.id === monster.config.id) {
          setMonsters((prev) => ({ ...prev, [oppositeSlot]: undefined }));
          localStorage.removeItem(`ocb:monster-${oppositeSlot}`);
        }

        setMonsters((prev) => ({ ...prev, [selectedSlot]: monster }));
        localStorage.setItem(`ocb:monster-${selectedSlot}`, monster.config.id);
        setModalSelectionVisibility(false);
      }
    },
    [selectedSlot, monsters]
  );

  // Profile Modal
  const profileStatistics: Statistic[] = useMemo(() => {
    return [
      { name: "Wins", value: stats?.wins || 0 },
      { name: "Losses", value: stats?.wins || 0 },
      { name: "Escapes", value: stats?.wins || 0 },
      {
        name: "Score",
        value: calculateScore(
          stats?.wins || 0,
          stats?.losses || 0,
          stats?.escapes || 0
        ),
      },
    ];
  }, [stats]);

  const team = useMemo(() => {
    return monsters.monster1 && monsters.monster2
      ? [monsters.monster1, monsters.monster2]
      : [];
  }, [monsters]);

  useEffect(() => {
    setNoPromptOnSignature(true);

    return () => {
      setNoPromptOnSignature(false);
    };
  }, [setNoPromptOnSignature]);

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro">
        <div className="flex flex-1 flex-col px-4 py-safe-or-4 bg-black/50 backdrop-blur-sm">
          <div className="flex flex-row justify-between">
            <h1 className="font-display font-black text-text-light text-3xl">
              Battle
            </h1>

            <div className="flex flex-row space-x-2">
              <Button
                size="xs"
                variant="dark"
                className="w-10 h-10 shadow-lg"
                onClick={() =>
                  window.open(
                    "https://battle-docs.polychainmonsters.com",
                    "_blank"
                  )
                }
              >
                <i className="material-symbols-rounded text-lg">
                  developer_guide
                </i>
              </Button>
              <Button
                size="xs"
                variant="dark"
                className="w-10 h-10 shadow-lg"
                onClick={
                  toggleLeaderBoardVisibility as unknown as MouseEventHandler<HTMLButtonElement>
                }
              >
                <i className="material-symbols-rounded text-lg">leaderboard</i>
              </Button>
              <ProfilePicture
                size={40}
                imageURI={profilePictureURI}
                onClick={() => setModalProfileVisibility(true)}
              />
            </div>
          </div>

          <motion.div
            className="flex flex-1 justify-center items-center space-x-4 flex-row overflow-visible"
            transition={contentTransition}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <MonsterSlot
              monster={monsters.monster1}
              slot="monster1"
              onClick={handleSlotClick}
            />
            <MonsterSlot
              monster={monsters.monster2}
              slot="monster2"
              onClick={handleSlotClick}
            />
          </motion.div>
          {!enoughFunds?.eth && enabled && (
            <span className="text-xs text-center text-text-light mb-2">
              Oops! Looks like your ETH treasure chest is a bit light. We will
              airdrop you gas shortly!
              {/* {`Oops! Looks like your ETH treasure chest is a bit light. You'll need at least ${minimum.eth} ETH to start your next epic battle!`} */}
            </span>
          )}
          {!notificationsRequested ? (
            <Button
              variant="light"
              size="lg"
              onClick={requestPermissions}
              sound="select"
            >
              Enable Notifications
            </Button>
          ) : (
            <Button
              variant="light"
              size="lg"
              onClick={onJoin}
              sound="select"
              disabled={!enabled || isLoading || !enoughFunds?.eth}
            >
              {isLoading
                ? "Loading..."
                : enabled
                ? "Join Queue"
                : "Choose Your Team"}
            </Button>
          )}
        </div>
      </div>
      <ModalMonsterSelection
        availableMonsterIds={availableMonsterIds}
        isVisible={modalSelectionVisibility}
        onSelect={handleMonsterSelect}
        onClose={() => setModalSelectionVisibility(false)}
      />
      <ModalQueue
        monsters={monsters}
        isVisible={inQueue}
        onClose={onWithdraw}
      />
      <ModalLeaderBoard
        player={user}
        userName={userName}
        isVisible={leaderBoardVisibility}
        onClose={toggleLeaderBoardVisibility}
      />
      <ModalProfile
        isVisible={modalProfileVisibility}
        walletAddress={user}
        userName={userName}
        imageURI={profilePictureURI}
        onClose={() => setModalProfileVisibility(false)}
        statistics={profileStatistics}
        team={team}
      />
    </>
  );
};

/**
 * Components to clean up the above code
 */

interface MonsterSlotProps {
  monster?: Monster;
  slot: "monster1" | "monster2";
  onClick: (slot: "monster1" | "monster2") => void;
}

const MonsterSlot: React.FC<MonsterSlotProps> = ({
  monster,
  slot,
  onClick,
}) => {
  const [play] = useSound(SOUNDS.click);

  const handleClick = useCallback(
    (monsterSlot: "monster1" | "monster2") => {
      if (onClick === undefined) return;
      onClick(monsterSlot);
      play();
    },
    [onClick, play]
  );

  const onClickInternal = useCallback(() => {
    handleClick(slot);
  }, [handleClick, slot]);

  return monster ? (
    <MonsterCard monster={monster} onClick={onClickInternal} />
  ) : (
    <SelectionSlot className="flex-1" onClick={onClickInternal} />
  );
};
