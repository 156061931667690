import React from "react";

import IMAGES from "../../assets/images";
import { RetinaImageProps } from ".";

export const RetinaImage = ({
  imageKey,
  alt = "",
  ...rest
}: RetinaImageProps) => {
  const src = IMAGES[imageKey];

  // Error handling: Ensure src is provided
  if (!src) {
    console.error(
      `Error: No image found for key "${imageKey}" in RetinaImage.`
    );
    return null;
  }

  // Error handling: Check for file extension
  const match = src.match(/\.(jpeg|jpg|png|gif|webp)$/);
  if (!match) {
    console.error("Error: Invalid image format or `src` format.");
    return null;
  }

  const ext = match[0]; // capture the file extension

  const srcSet = `
        ${src} 1x,
        ${src.replace(ext, `@2x${ext}`)} 2x,
        ${src.replace(ext, `@3x${ext}`)} 3x
      `;

  return <img src={src} srcSet={srcSet} alt={alt} {...rest} />;
};
