export const info = [
  {
    title: "What does a pack cost?",
    text: "Packs are not sold in the regular way. To mint a pack, you need to stake the displayed amount of PMON per pack. The staked PMON is attached to your pack. When you transfer your pack, you also transfer the staked PMON.",
  },
  {
    title: "What happens when I open a pack?",
    text: "When you open a pack, it will reveal its content on-chain. You get one monster per pack. The staked PMON will remain attached to the monster.",
  },
  {
    title: "Is there a gas fee?",
    text: "Yes, there is a gas fee for minting and opening packs. The gas fee is paid in PMON.",
  },
  {
    title: "How can I get my stake back?",
    text: "You can reverse swap your pack or monster at any time. However, there is a 10% fee for swapping back. So, if you staked 1 PMON to mint a pack, you will get 0.9 PMON back when you reverse swap the pack or the revealed monster. All of the fees go to the community reward pools (more information coming soon).",
  },
];
