import {
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  Address,
} from "wagmi";

interface DepositArgs {
  address: Address;
  isEnabled?: boolean;
}

interface DepositResult {
  depositAsync: (() => Promise<void>) | undefined;
  pmonBalance: bigint | undefined;
}

export function useBridgePmon({
  address,
  isEnabled,
}: DepositArgs): DepositResult {
  const { data: pmonBalance }: { data: bigint | undefined } = useContractRead({
    address: "0xBC9B77acA82f6BE43927076D71cd453b625165B8", // PMON token address
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "balanceOf",
    args: [address], // Replace with user's address
    watch: isEnabled,
    enabled: isEnabled,
  });

  const { config } = usePrepareContractWrite({
    address: "0x1285D6cE3604D341b29ccfF300d043af1CDb57e3", // Replace with Sequencer Inbox address
    abi: [
      {
        constant: false,
        inputs: [
          {
            name: "_amount",
            type: "uint256",
          },
        ],
        name: "depositERC20",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "depositERC20",
    args: [pmonBalance?.toString() || "0"],
    enabled: !!pmonBalance && pmonBalance > 0n,
  });

  const { writeAsync: depositAsync } = useContractWrite(config);

  return {
    depositAsync: depositAsync
      ? async () => {
          await depositAsync();
        }
      : undefined,
    pmonBalance,
  };
}
