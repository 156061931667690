import { PlayerProps } from "../../../types";
import { shortenAddress } from "../../../utility";

export const getPlayerData = ({
  address,
  team,
  userName,
  imageURI,
}: Omit<PlayerProps, "addressShort">): PlayerProps => {
  return {
    address,
    addressShort: shortenAddress(address),
    userName,
    imageURI,
    team,
  };
};
