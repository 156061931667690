import React, { createContext, useContext, useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";
import { Address, zeroAddress } from "viem";

import { usePrivy } from "@privy-io/react-auth";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";

import IMAGES from "../assets/images/index.ts";
import { getChain, PLACEHOLDER_CHAIN_ID } from "../chains.ts";
import { useUserName } from "../hooks";
import { AppContext } from "./app.tsx";

type ProviderProps = {
  children: React.ReactNode;
};

type ContextProps = {
  nonce: number;
  userName: string;
  user: Address;
  walletAddress: Address;
  isLoading: boolean;
  profilePictureURI: string;
  statistics: {
    wins: number;
    winRate: number;
    losses: number;
    forfeits: number;
  };
};

export const UserContext = createContext<ContextProps>({
  nonce: 0,
  userName: "",
  user: zeroAddress,
  walletAddress: zeroAddress,
  isLoading: true,
  profilePictureURI: "",
  statistics: {
    wins: 0,
    winRate: 0,
    losses: 0,
    forfeits: 0,
  },
});

export const UserProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nonce, setNonce] = useState(0);

  const { chainId } = useContext(AppContext);
  const { ready, user: privyUser } = usePrivy();
  const { wallet } = usePrivyWagmi();

  useEffect(() => {
    const activeChain = getChain(`${chainId}`);
    wallet?.switchChain(activeChain.id);
  }, [chainId, wallet]);

  const { userName } = useUserName(wallet?.chainId || PLACEHOLDER_CHAIN_ID, {
    address: wallet?.address as Address,
  });

  useEffect(() => {
    if (privyUser) {
      localStorage.setItem("ocb:user:did", privyUser.id);
    }
  }, [privyUser]);

  const isInitialFetchDone = true;

  // TODO: Sebastian – Add logic to fetch statistics
  const wins = 0;
  const losses = 0;
  const forfeits = 0;
  const winRate = Math.round(wins / (wins + losses + forfeits));
  const statistics = { wins, losses, forfeits, winRate };
  const profilePictureURI = IMAGES.trainer;

  useEffect(() => {
    if (ready && !privyUser) {
      setIsLoading(false);
    }

    if (privyUser && wallet && isInitialFetchDone) {
      setIsLoading(false);
    }
  }, [isInitialFetchDone, privyUser, ready, wallet]);

  useInterval(() => {
    const fetchNonce = async () => {
      if (wallet?.address) {
        // const nonce = await getPublicClient().getTransactionCount({
        //   address: wallet?.address as Address,
        // });
        //
        // setNonce(nonce);
      }
    };

    fetchNonce();
  }, 2_000);

  return (
    <UserContext.Provider
      value={{
        nonce,
        userName: userName || "",
        user: wallet?.address as Address,
        walletAddress: wallet?.address as Address,
        statistics,
        isLoading,
        profilePictureURI,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
