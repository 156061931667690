import { motion } from "framer-motion";
import React, { useMemo, useState } from "react";

import { useToggle } from "@uidotdev/usehooks";

import {
  ActionSheet,
  Button,
  ModalComingSoon,
  MonsterCard,
} from "../../components";
import { Monsters } from "../../config";
import { Monster, MonsterElement } from "../../types";
import {
  contentTransition,
  contentVariants,
  getStringForElement,
} from "../../utility";
import {
  capitalizeFirstChar,
  generateTokenId,
  mapSortKeyToConfigKey,
} from "./helper";
import {
  SortBy,
  SortByKey,
  allowedElements,
  allowedTypes,
  sortByKeys,
} from "./types";

export const Collection: React.FC = () => {
  const [sortBy, setSortBy] = useState<SortBy>();
  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [filterElements, setFilterElements] = useState<MonsterElement[]>([]);
  const [isSheetVisible, toggleSheetVisible] = useToggle(false);

  // Actions

  const handleClickMonster = (monster: Monster) => {};

  const handleClickFilter = () => {
    toggleSheetVisible();
  };

  const handleSortBy = (key: SortByKey) => {
    setSortBy((prevSortBy) => {
      if (prevSortBy?.key === key) {
        return undefined;
      }
      return { key, order: "desc" };
    });
  };

  const handleFilterElement = (btnElement: MonsterElement) => {
    setFilterElements((prevElements) =>
      prevElements.includes(btnElement)
        ? prevElements.filter((e) => e !== btnElement)
        : [...prevElements, btnElement]
    );
  };

  const handleFilterType = (btnType: string) => {
    setFilterTypes((prevTypes) =>
      prevTypes.includes(btnType)
        ? prevTypes.filter((e) => e !== btnType)
        : [...prevTypes, btnType]
    );
  };

  const handleClear = () => {
    setFilterElements([]);
    setFilterTypes([]);
    setSortBy(undefined);
    toggleSheetVisible();
  };

  // Openings

  const numberFilters = filterElements.length + filterTypes.length;

  const displayedMonsters = useMemo(() => {
    let monsters = Object.values(Monsters).filter((monster) => {
      const isElementMatch =
        filterElements?.length === 0 ||
        filterElements.includes(monster.element);
      const isTypeMatch =
        filterTypes?.length === 0 || filterTypes.includes(monster.type);
      return isElementMatch && isTypeMatch;
    });

    // Sorting
    if (sortBy) {
      monsters.sort((a, b) => {
        const valueA = a.attributes[sortBy.key];
        const valueB = b.attributes[sortBy.key];
        if (sortBy.order === "asc") {
          return valueA - valueB; // Ascending order
        } else {
          return valueB - valueA; // Descending order
        }
      });
    }

    return monsters.map((monsterConfig) => ({
      config: monsterConfig,
      tokenId: generateTokenId(),
      isWaitingForCommit: false,
      statusEffects: [],
    }));
  }, [filterTypes, filterElements, sortBy]);

  return (
    <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro">
      <div className="flex flex-col flex-1 pt-safe-offset-4 pb-4 px-4 bg-black/50 backdrop-blur-sm space-y-4 h-screen overflow-y-auto">
        <h1 className="font-display font-black text-text-light text-3xl">
          Collection
        </h1>

        <div className="grid grid-cols-2 gap-4">
          {displayedMonsters.map((monster) => (
            <motion.div
              key={monster.tokenId.toString()}
              variants={contentVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={contentTransition}
            >
              <MonsterCard
                monster={monster}
                onClick={() => handleClickMonster(monster)}
              />
            </motion.div>
          ))}
        </div>
      </div>

      <div className="absolute bottom-16 right-2 z-10 pb-safe">
        <Button
          variant="light"
          size="xs"
          className="w-12 h-12 shadow-lg"
          onClick={handleClickFilter}
        >
          <i className="material-symbols-rounded text-xl text-text-primary">
            filter_list
          </i>
        </Button>
        {numberFilters > 0 && (
          <div className="h-5 w-5 rounded-full bg-background-highlight absolute bottom-0 right-0 flex flex-col justify-center items-center">
            <span className="font-display text-xs text-text-light">
              {numberFilters}
            </span>
          </div>
        )}
      </div>

      <ActionSheet
        title="Sort & Filter"
        isVisible={isSheetVisible}
        onClose={toggleSheetVisible}
        className="px-4 pb-safe-offset-4"
      >
        <div className="flex flex-col space-y-4">
          {/** Sort By */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Sort By
            </h2>

            <div className="grid grid-cols-4 gap-2">
              {sortByKeys.map((sortKey) => {
                const displayKey = mapSortKeyToConfigKey(sortKey); // Mapping to display value
                const isSelected = sortBy?.key === sortKey;
                const variant = isSelected ? "light" : "unselected";
                const onClick = () => handleSortBy(sortKey);
                return (
                  <Button
                    key={sortKey}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {displayKey} {/* Display the mapped key */}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Monster Type */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Type
            </h2>

            <div className="grid grid-cols-4 gap-2">
              {allowedTypes.map((btnType) => {
                const variant = filterTypes.includes(btnType)
                  ? "light"
                  : "unselected";
                const onClick = () => handleFilterType(btnType);

                return (
                  <Button
                    key={btnType}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {capitalizeFirstChar(btnType)}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Monster Element */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Elements
            </h2>

            <div className="grid grid-cols-3 gap-2">
              {allowedElements.map((btnElement) => {
                const variant = filterElements.includes(btnElement)
                  ? "light"
                  : "unselected";
                const onClick = () => handleFilterElement(btnElement);

                return (
                  <Button
                    key={btnElement}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {getStringForElement(btnElement)}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Clear */}
          <Button onClick={handleClear}>Clear</Button>
        </div>
      </ActionSheet>
    </div>
  );
};
