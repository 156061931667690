import { useContext } from "react";
import { getAddress, ContractNames, getAbi } from "../contracts.ts";
import { Contract } from "../../../types";
import { AppContext } from "../../../context";

export const useContract = (id: ContractNames): Contract => {
  const { chainId } = useContext(AppContext);
  const address = getAddress(id, `${chainId}`);
  const abi = getAbi(id);
  return {
    address,
    abi,
    chainId,
  };
};
