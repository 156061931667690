import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { BottomTabNavigation } from "../../components";

// Format pathname to remove trailing slash and get root path
function removeAfterSecondSlash(path: string): string {
  const parts = path.split("/");
  return parts[1] ? `/${parts[1]}` : "/";
}

export const LayoutApp: React.FC = () => {
  const location = useLocation();
  const formattedPathname = removeAfterSecondSlash(location.pathname);

  // Show tab navigation on these routes
  const showTabNavigation = [
    "/battle-preparation",
    "/booster-packs",
    "/openings",
    "/binders",
  ].includes(formattedPathname);

  return (
    <div className="flex flex-col h-screen w-screen">
      {/* Outlet and content should scroll within this container */}
      <div className="flex flex-1 overflow-y-auto">
        <Outlet />
      </div>

      {/* Tab Navigation (conditionally rendered) */}
      {showTabNavigation && <BottomTabNavigation />}
    </div>
  );
};
