import { Monsters } from "../../config";
import { MonsterElement } from "../../types";

export const allowedElements: MonsterElement[] = [
  MonsterElement.Fire,
  MonsterElement.Nature,
  MonsterElement.Water,
  MonsterElement.Electric,
  MonsterElement.Mental,
  MonsterElement.Toxic,
];

export const allowedTypes: string[] = Array.from(
  new Set(Object.values(Monsters).map((monster) => monster.type)),
).sort();

export const sortByKeys = ["attack", "defense", "speed", "hp"] as const;

export type SortByKey = (typeof sortByKeys)[number];

export type SortBy =
  | {
      key: SortByKey;
      order: "asc" | "desc";
    }
  | undefined;
