import { MonsterElement } from "../../types";

const getRandomElement = (): MonsterElement => {
  const elements = Object.values(MonsterElement).filter(
    (value) => typeof value === "number",
  ) as MonsterElement[];
  const randomIndex = Math.floor(Math.random() * elements.length);
  return elements[randomIndex];
};

export async function battleLoader() {
  return getRandomElement();
}
