import Bugsnag from "@bugsnag/js";

export const logError = (error: Error, metadata: any) => {
  Bugsnag.notify(error, function (event) {
    let user = localStorage.getItem("ocb:user:did");
    if (user) {
      event.setUser(user);
    }
    if (metadata) {
      event.addMetadata("meta", metadata);
    }
  });
};
