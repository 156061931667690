import * as Tone from "tone";

import React, { useEffect } from "react";

import { HookOptions, PlayOptions, PlayFunction, ReturnedValue } from "./types";
import { Player } from "tone";

export default function useSound<T = any>(
  src?: string | string[],
  {
    id,
    volume = 1,
    playbackRate = 1,
    soundEnabled = true,
    interrupt = false,
    onload,
    ...delegated
  }: HookOptions<T> = {} as HookOptions
) {
  const [duration, setDuration] = React.useState<number | null>(null);
  const [sound, setSound] = React.useState<Player | null>(null);

  useEffect(() => {
    if (src) {
      setSound(new Tone.Player(src as string).toDestination());
    }
  }, [src]);

  const play: PlayFunction = React.useCallback(
    (options?: PlayOptions) => {
      try {
        if (typeof options === "undefined") {
          options = {};
        }

        if (!sound || (!soundEnabled && !options.forceSoundEnabled)) {
          return;
        }

        sound.start();
      } catch (err) {
        // todo implement fix
      }
    },
    [sound, soundEnabled]
  );

  const stop = React.useCallback(() => {
    try {
      if (!sound) {
        return;
      }
      sound.stop();
    } catch (err) {
      // todo implement fix
    }
  }, [sound]);

  const pause = React.useCallback(() => {
    try {
      if (!sound) {
        return;
      }
      sound.stop();
    } catch (err) {
      // todo implement fix
    }
  }, [sound]);

  const returnedValue: ReturnedValue = [
    play,
    {
      sound,
      stop,
      pause,
      duration,
    },
  ];

  return returnedValue;
}

export { useSound };
