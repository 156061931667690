import { useRouteError } from "react-router-dom";

import IMAGES from "../../assets/images";
import { useEffect } from "react";

interface RouteError {
  statusText?: string;
  message?: string;
}

export const PageError = () => {
  const error = useRouteError() as RouteError;

  useEffect(() => {
    // if (error) {
    //   throw error;
    // }
  }, [error]);

  return (
    <div className="flex flex-1 flex-col py-safe-or-4">
      <div className="flex flex-1 flex-col justify-center items-center">
        <img
          alt="Error"
          src={IMAGES.error}
          srcSet={IMAGES.error2x}
          className="w-3/4 aspect-square"
        />
        <div className="flex flex-col text-center space-y-2 p-4">
          <h1 className="font-display font-black text-2xl text-text-primary">
            Whoa, a Wild Glitch!
          </h1>
          <p className="text-sm text-text-secondary">
            Seems you've stumbled upon a rare anomaly in the Polyverse. Our
            Polymon trainers are on it! Hang tight, and let's get you back to
            your adventure with Polychain Monsters soon.
          </p>
          <i className="text-xs text-text-error">
            {error.statusText || error.message}
          </i>
        </div>
      </div>
    </div>
  );
};
