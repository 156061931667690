import {
  Abi,
  Account,
  Chain,
  EIP1193Provider,
  encodeFunctionData,
  toBytes,
  WriteContractParameters,
} from "viem";
import {
  getWalletClient,
  WriteContractPreparedArgs,
  WriteContractResult,
} from "@wagmi/core";
import { ConnectorNotFoundError } from "wagmi";
import { getChain, sapphire } from "../chains.ts";
import {
  fetchRuntimePublicKeyByChainId,
  lazy as lazyCipher,
  X25519DeoxysII,
} from "./cipher.ts";
import { getCleanChainId } from "../utility";
import { assertActiveChain } from "./assertActiveChain.ts";
import { Contract } from "../types";

async function fetchRuntimePublicKey(
  {
    request,
  }: {
    request: EIP1193Provider["request"];
  },
  chainId?: number
): Promise<Uint8Array> {
  try {
    const resp: any = await request({
      method: "oasis_callDataPublicKey" as any,
      args: [],
    });
    if (resp && "key" in resp) {
      return toBytes(resp.key);
    }
  } catch (e: any) {
    console.error(
      "failed to fetch runtime public key using upstream transport:",
      e
    );
  }
  if (!chainId)
    throw new Error("unable to fetch runtime public key. chain not provided");
  return fetchRuntimePublicKeyByChainId(chainId);
}

/**
 * @description Function to call a contract write method in a confidential way on Oasis Sapphire
 */
export async function writeContractConfidential<
  TAbi extends Abi | readonly unknown[],
  TFunctionName extends string
>(
  config: WriteContractPreparedArgs<TAbi, TFunctionName>
): Promise<WriteContractResult> {
  const chainId = getCleanChainId(config.chainId);
  const chain = getChain(`${chainId}`);

  const walletClient = await getWalletClient({ chainId });

  if (!walletClient) throw new ConnectorNotFoundError();
  if (config.chainId) assertActiveChain({ chainId });

  let request: WriteContractParameters<TAbi, TFunctionName, Chain, Account> =
    config.request;

  if (chainId === sapphire.id) {
    const cipher = lazyCipher(async () => {
      const rtPubKey = await fetchRuntimePublicKey(
        walletClient.transport,
        chainId
      );
      return X25519DeoxysII.ephemeral(rtPubKey);
    });

    const data = encodeFunctionData({
      abi: request.abi,
      args: request.args,
      functionName: request.functionName,
    } as any);

    const encryptedData = await cipher.encryptEncode(data);

    const hash = await walletClient.sendTransaction({
      data: encryptedData,
      to: request.address,
      chain,
      ...request,
    } as any); // todo debug the exact typescript issues here

    return { hash };
  } else {
    const hash = await walletClient.writeContract({
      ...request,
      chain,
    } as WriteContractParameters<TAbi, TFunctionName, Chain, Account>);

    return { hash };
  }
}

/**
 * Directly call a contract method in a confidential manner without specifying chainId and mode every time.
 */
export async function writeContractConfidentialDirect<
  TFunctionName extends string
>({
  contract,
  functionName,
  args,
  value,
  gas,
}: {
  contract: Contract;
  functionName: TFunctionName;
  args: any[]; // Replace with a more specific type if possible, depending on your function arguments.
  value?: bigint;
  gas?: bigint;
}): Promise<WriteContractResult> {
  // Utilizing the contract's chainId directly from the contract object
  const fullConfig: WriteContractPreparedArgs<
    typeof contract.abi,
    TFunctionName
  > = {
    mode: "prepared",
    chainId: contract.chainId,
    request: {
      address: contract.address,
      abi: contract.abi,
      functionName,
      args,
      value,
      gas,
    } as unknown as WriteContractParameters<typeof contract.abi, TFunctionName>,
  };

  return writeContractConfidential<typeof contract.abi, TFunctionName>(
    fullConfig
  );
}
