import { useState, useEffect } from "react";

export function useLongPress(
  callback: () => void = () => {},
  ms: number = 300,
) {
  const [startLongPress, setStartLongPress] = useState<boolean>(false);

  useEffect(() => {
    let timerId: number | null = null;
    if (startLongPress) {
      timerId = window.setTimeout(callback, ms);
    } else if (timerId !== null) {
      window.clearTimeout(timerId);
    }

    return () => {
      if (timerId !== null) {
        window.clearTimeout(timerId);
      }
    };
  }, [startLongPress, callback, ms]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  };
}
