import { motion } from "framer-motion";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { MonsterBoardHealthProps } from "./monsterBoardProps";

export const MonsterBoardHealth: React.FC<MonsterBoardHealthProps> = ({
  hp,
  hpInitial,
}) => {
  const healthPercentage = useMemo(() => {
    const percentage = Math.round((hp / hpInitial) * 100);
    return Math.min(100, Math.max(0, percentage));
  }, [hp, hpInitial]);

  const { healthColorBackground, healthColor } = useMemo(
    () => getHealthClasses(healthPercentage),
    [healthPercentage],
  );

  return (
    <div
      className={twMerge(
        "h-2 mt-1 rounded-full shadow-inner",
        healthColorBackground,
      )}
    >
      <motion.div
        transition={{ duration: 0.5 }}
        animate={{ width: `${healthPercentage}%` }}
        className={`h-full ${healthColor} rounded-full shadow-sm`}
      />
    </div>
  );
};

const getHealthClasses = (percentage: number) => {
  if (percentage > 66) {
    return {
      healthColorBackground: "bg-healthBackground-high",
      healthColor: "bg-health-high",
    };
  } else if (percentage > 33) {
    return {
      healthColorBackground: "bg-healthBackground-medium",
      healthColor: "bg-health-medium",
    };
  } else {
    return {
      healthColorBackground: "bg-healthBackground-low",
      healthColor: "bg-health-low",
    };
  }
};
