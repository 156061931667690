import { useCallback, useContext, useEffect, useState } from "react";
import {
  GetSupplyDataPoints,
  GraphContext,
} from "../../../context/graphContext";

export const useFetchSupplyData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataPoints, setDataPoints] = useState<GetSupplyDataPoints[]>([]);

  const { getSupplyDataPoints } = useContext(GraphContext);

  const fetchDataPoints = useCallback(async () => {
    setIsLoading(true);
    const res = await getSupplyDataPoints();
    setDataPoints(res?.supplyDataPoints);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchDataPoints();
  }, [fetchDataPoints]);

  const refetch = useCallback(() => {
    fetchDataPoints();
  }, [fetchDataPoints]);

  return { isLoading, refetch, dataPoints };
};
