import React from "react";

interface TouchlinesProps {
  children: React.ReactElement | React.ReactElement[];
}

interface CenterCircleProps {
  children: React.ReactElement;
}

const Touchlines: React.FC<TouchlinesProps> = ({ children }) => (
  <div className="absolute flex items-center justify-center top-2 right-2 bottom-2 left-2 border-8 border-background-primary opacity-90 rounded-2xl">
    {children}
  </div>
);

const CenterLine: React.FC = () => (
  <div className="absolute flex w-full h-full justify-center items-center">
    <div className="h-2 w-full bg-background-primary" />
  </div>
);

const CenterCircle: React.FC<CenterCircleProps> = ({ children }) => (
  <div className="flex items-center justify-center w-3/4 aspect-square border-8 border-background-primary rounded-full">
    {children}
  </div>
);

const CenterSpot: React.FC = () => (
  <div className="w-8 h-8 rounded-full bg-background-primary" />
);

export const BattleFieldMarkings: React.FC = () => (
  <Touchlines>
    <CenterLine />
    <CenterCircle>
      <CenterSpot />
    </CenterCircle>
  </Touchlines>
);
