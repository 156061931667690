import React from "react";
import {
  Route,
  RouterProvider,
  createHashRouter,
  createRoutesFromElements,
} from "react-router-dom";

import {
  AlphaCountdown,
  Battle,
  BattlePreparation,
  Binders,
  BoosterPacks,
  Collection,
  Intro,
  LayoutApp,
  LayoutAuth,
  PageError,
  PageNotFound,
  Reveal,
  battleLoader,
  Openings,
} from "./routes";
import { BattleDebug } from "./routes/BattleDebug/BattleDebug.tsx";

// const ALPHA_LAUNCH_DATE = import.meta.env.VITE_ALPHA_LAUNCH_DATE
//   ? new Date(parseInt(import.meta.env.VITE_ALPHA_LAUNCH_DATE))
//   : undefined;

const ALPHA_LAUNCH_DATE = new Date();

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<LayoutApp />} errorElement={<PageError />}>
      <Route index element={<Intro />} />
      <Route element={<LayoutAuth />}>
        <Route
          path="alpha-countdown"
          element={<AlphaCountdown launchDate={ALPHA_LAUNCH_DATE} />}
        />
        <Route
          path="battle-preparation"
          element={<BattlePreparation />}
          loader={battleLoader}
        />
        <Route path="battle" element={<Battle />} loader={battleLoader} />
        <Route path={"battle-debug/:id"} element={<BattleDebug />} />
        <Route path={"booster-packs"} element={<BoosterPacks />} />
        <Route path={"reveal/:id"} element={<Reveal />} />
        <Route path={"collection"} element={<Collection />} />
        <Route path={"openings"} element={<Openings />} />
        <Route path={"binders"} element={<Binders />} />
        <Route path={"battle-debug/:player/:id"} element={<BattleDebug />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
