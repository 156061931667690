import { Address, decodeAbiParameters, parseAbiParameters } from "viem";
import { EventLogType, eventLogClasses } from "./types";

const decodeEventData = (data: `0x${string}`, iface: string) => {
  return decodeAbiParameters(parseAbiParameters(iface), data);
};

export const decodeEvent = (
  id: bigint,
  action: bigint,
  timestamp: bigint,
  data: `0x${string}`,
  player: Address,
  opponent: Address,
  monster: bigint,
  opponentMonster: bigint,
  round: bigint,
): EventLogType | undefined => {
  let decodedEvent: EventLogType | undefined;

  for (const eventLogClass of eventLogClasses) {
    if (eventLogClass.ON_CHAIN_NAME === action) {
      decodedEvent = new (eventLogClass as any)(
        id,
        action,
        player,
        opponent,
        monster,
        opponentMonster,
        timestamp,
        round,
        decodeEventData(data, eventLogClass.INTERFACE),
      );
      break;
    }
  }

  return decodedEvent;
};
