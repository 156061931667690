import { useCallback, useState } from "react";
import { Address } from "viem";

import { Monster } from "../../../types";

export type ModalProfileState = {
  isVisible: boolean;
  detail: {
    userName: string;
    imageURI?: string;
    walletAddress: Address;
    team: Monster[] | [];
  } | null;
};

type ModalProfileDetail = {
  userName: string;
  imageURI?: string;
  walletAddress: Address;
  team: Monster[] | [];
};

export function useModalProfile() {
  const [profileState, setProfileState] = useState<ModalProfileState>({
    isVisible: false,
    detail: null,
  });

  // Handler to update the profile details and set visibility to true
  const handleProfileDetail = useCallback((detail: ModalProfileDetail) => {
    setProfileState({
      isVisible: true,
      detail,
    });
  }, []);

  // Handler to close the modal and clear profile details
  const closeModalProfile = useCallback(() => {
    setProfileState({
      isVisible: false,
      detail: null,
    });
  }, []);

  return {
    profileState,
    handleProfileDetail,
    closeModalProfile,
  };
}
