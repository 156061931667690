import React from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { ModalActionCompleteProps } from "./modalActionCompleteProps.ts";
import { ActionBar, Button } from "..";
import { noop } from "lodash";
import { motion } from "framer-motion";
import cn from "classnames";

export const ModalActionComplete = ({
  text,
  isVisible,
  isDone,
  onClose,
  buttonText,
  title,
  isError,
}: ModalActionCompleteProps) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  return (
    <Modal title={title} isVisible={isVisible} className="py-4">
      <div className="flex flex-1 flex-col px-2">
        <div className="flex flex-1 flex-col justify-center">
          <div className="font-display text-text-light text-center">
            <>
              <p className="text-3xl font-black">
                {isDone ? (
                  <>{isError ? "Oops 🚨" : "Woohoo! 💫"}</>
                ) : (
                  <div className="flex flex-col items-center w-full">
                    <motion.div
                      className="flex flex-col justify-center items-center w-10 h-10 bg-background-highlight/40 border border-background-highlight/20"
                      animate={{
                        scale: [1, 1.5, 1.5, 1, 1],
                        rotate: [0, 0, 180, 180, 0],
                        borderRadius: ["5%", "5%", "50%", "50%", "5%"],
                      }}
                      transition={{
                        duration: 2,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.5, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 1,
                      }}
                    >
                      <motion.div
                        className="w-5 h-5 bg-background-highlight/80"
                        animate={{
                          scale: [1, 1.25, 1.25, 1, 1],
                          rotate: [0, 0, 180, 180, 0],
                          borderRadius: ["5%", "5%", "50%", "50%", "5%"],
                        }}
                        transition={{
                          duration: 2,
                          ease: "easeInOut",
                          times: [0, 0.2, 0.5, 0.8, 1],
                          repeat: Infinity,
                          repeatDelay: 1,
                        }}
                      />
                    </motion.div>
                  </div>
                )}
              </p>
              <p
                className={cn("text-lg pt-4", {
                  "text-xs": isError,
                })}
                onClick={noop}
              >
                {text}
              </p>
            </>
          </div>
        </div>
        <ActionBar variant="floating">
          <Button
            disabled={!isDone}
            variant="light"
            size="xs"
            className="flex-1"
            onClick={isDone ? onClose : noop}
            sound="click2"
          >
            {buttonText}
          </Button>
        </ActionBar>
      </div>
    </Modal>
  );
};
