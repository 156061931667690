import { motion } from "framer-motion";
import { debounce } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useLockedBody } from "usehooks-ts";

import SOUNDS from "../../assets/sounds";
import { Monsters } from "../../config";
import useSound from "../../sound/useSound.ts";
import { Monster, MonsterConfig, MonsterElement } from "../../types";
import { generateTokenId, getStringForElement } from "../../utility";
import { Button } from "../button";
import { Modal } from "../modal";
import { MonsterCard } from "../monsterCard";
import {
  contentTransition,
  contentVariants,
} from "./modalMonsterSelectionMotion";
import { ModalMonsterSelectionProps } from "./modalMonsterSelectionProps";
import { ButtonOpenPacks } from "../buttonOpenPacks";

const allowedElements: MonsterElement[] = [
  MonsterElement.Fire,
  MonsterElement.Nature,
  MonsterElement.Water,
  MonsterElement.Electric,
  MonsterElement.Mental,
  MonsterElement.Toxic,
];

export const ModalMonsterSelection: React.FC<ModalMonsterSelectionProps> = ({
  availableMonsterIds,
  isVisible,
  onClose,
  onSelect,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const [element, setElement] = useState<MonsterElement>(MonsterElement.Fire);

  const displayedMonsters = useMemo(() => {
    const filteredMonsterConfigs = Object.values(Monsters).filter(
      (monster: MonsterConfig) => monster.element === element
    );

    const monsterConfigsInCollection = filteredMonsterConfigs.filter(
      (monsterConfig: MonsterConfig) =>
        availableMonsterIds.includes(monsterConfig.id)
    );

    return monsterConfigsInCollection.map(
      (monsterConfig: MonsterConfig): Monster => ({
        config: monsterConfig,
        tokenId: generateTokenId(),
        isWaitingForCommit: false,
        statusEffects: [],
      })
    );
  }, [element, availableMonsterIds]);

  const displayedText = useMemo(() => {
    if (element === MonsterElement.Fire) {
      return "Fire is strong against Nature & Toxic and weak against Water & Mental.";
    } else if (element === MonsterElement.Nature) {
      return "Nature is strong against Water & Electric and weak against Fire & Toxic.";
    } else if (element === MonsterElement.Water) {
      return "Water is strong against Fire & Mental and weak against Nature & Electric.";
    } else if (element === MonsterElement.Electric) {
      return "Electric is strong against Water & Mental and weak against Nature & Toxic.";
    } else if (element === MonsterElement.Mental) {
      return "Mental is strong against Fire & Toxic and weak against Water & Electric.";
    } else if (element === MonsterElement.Toxic) {
      return "Toxic is strong against Nature & Electric and weak against Fire & Mental.";
    }
  }, [element]);

  const [play] = useSound(SOUNDS.success);

  // Define the debounced function outside of useCallback
  const debouncedSelect = debounce(
    (monster) => {
      onSelect(monster);
      onClose();
      play();
    },
    500,
    {
      leading: true,
      trailing: false,
    }
  );

  // Memoize the debounced function using useCallback
  const handleSelect = useCallback(
    (monster: Monster) => {
      debouncedSelect(monster);
    },
    [debouncedSelect]
  );

  const getButtonVariant = (btnElement: MonsterElement) =>
    element === btnElement ? "light" : "unselected";

  return (
    <Modal isVisible={isVisible} title="Select Your Team" onClose={onClose}>
      <span className="text-sm text-center text-background-primary pb-4">
        {displayedText}
      </span>

      {displayedMonsters.length === 0 ? (
        <div className="flex flex-col my-auto">
          <ButtonOpenPacks element={element} />
        </div>
      ) : (
      <div className="grid grid-cols-2 gap-4 pb-40">
        {displayedMonsters.map((monster) => (
          <motion.div
            key={monster.tokenId?.toString()}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={contentTransition}
          >
            <MonsterCard
              isDisabled={!availableMonsterIds.includes(monster.config.id)}
              monster={monster}
              onClick={() => handleSelect(monster)}
            />
          </motion.div>
        ))}
      </div>
      )}

      <motion.div className="fixed grid grid-cols-3 gap-2 bottom-0 left-0 right-0 px-2 pb-safe-or-4 pt-4 bg-background-primary/40 backdrop-blur-xl border border-t border-background-primary/20">
        {allowedElements.map((btnElement) => (
          <Button
            key={btnElement}
            size="sm"
            variant={getButtonVariant(btnElement)}
            className="w-full"
            onClick={() => setElement(btnElement)}
            sound="click"
          >
            {getStringForElement(btnElement)}
          </Button>
        ))}
      </motion.div>
    </Modal>
  );
};
