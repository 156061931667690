import { useCallback, useState } from "react";

import { Monster, MonsterStatusEffect } from "../../../types";

export type HandleMonsterDetailProps = {
  monster?: Monster;
  statusEffects?: MonsterStatusEffect[];
};

export function useModalMonsterDetail() {
  const [monsterDetail, setMonsterDetail] =
    useState<HandleMonsterDetailProps | null>(null);
  const [detailVisibility, setDetailVisibility] = useState<boolean>(false);

  const handleMonsterDetail = useCallback((props: HandleMonsterDetailProps) => {
    setMonsterDetail(props);
    setDetailVisibility(true);
  }, []);

  const closeModal = useCallback(() => {
    setDetailVisibility(false);
    setMonsterDetail(null);
  }, []);

  return {
    monsterDetail,
    detailVisibility,
    handleMonsterDetail,
    closeModal,
  };
}
