import {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useInterval } from "usehooks-ts";
import {
  Address,
  encodePacked,
  Hex,
  keccak256,
  toHex,
  zeroAddress,
} from "viem";
import { useContractRead } from "wagmi";
import { noop } from "lodash";
import { writeContractConfidential as writeContractConfidential } from "../../../sapphire";
import { writeContract } from "@wagmi/core";
import { getConfigById } from "../../../config";
import {
  Monster,
  MonsterId,
  MonsterStatusEffect,
  StatusEffectGroup,
} from "../../../types";
import {
  MatchMakerV3Abi,
  MatchMakerV3AbiConfidential,
  UsernamesV1Abi,
} from "../abis";
import { contracts as contractAddresses } from "../contracts.ts";
import { useMatchEvents } from "./useMatchEvents";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { getCurrentTimestamp, shortenAddress } from "../../../utility";
import { useRunWithCorrectNonce } from "../helpers";
import { isSapphire } from "../../../sapphire/isSapphire.ts";
import { AppContext } from "../../../context";
import { arbitrum } from "viem/chains";
import { sapphire } from "../../../chains.ts";

// Predefined constants
const GAS = BigInt(5_000_000);
const EMPTY_BYTES =
  "0x0000000000000000000000000000000000000000000000000000000000000000";

// Enum for match phases
export enum MatchPhase {
  Commit = 0,
  Reveal = 1,
  GameOver = 2,
}

type Player = {
  address: Address;
  userName: string;
};

// Type definitions for a match and its properties
export type Match = {
  id: bigint;
  phase: MatchPhase;
  activeMonster: Monster;
  inactiveMonster: Monster;
  activeMonsterOpponent: Monster;
  inactiveMonsterOpponent: Monster;
  round: bigint;
  self: Player;
  opponent: Player;
  escaped: Address;
  timeout: bigint;
};

type MonsterFromChain = {
  tokenId: bigint;
  hp: number;
  hpInitial: number;
  attack: number;
  defense: number;
  speed: number;
  element: number;
  monsterType: number;
};

type StatusEffectFromChain = {
  statusEffect: Address;
  remainingTurns: bigint;
  group: number;
};

type MatchFromChain = {
  id: bigint;
  challengerMonster1: MonsterFromChain;
  challengerMonster2: MonsterFromChain;
  opponentMonster1: MonsterFromChain;
  opponentMonster2: MonsterFromChain;
  challengerStatusEffects1: StatusEffectFromChain[];
  challengerStatusEffects2: StatusEffectFromChain[];
  opponentStatusEffects1: StatusEffectFromChain[];
  opponentStatusEffects2: StatusEffectFromChain[];
  _match: {
    challengerTeam: {
      owner: Address;
    };
    currentChallengerMove: {
      commit: string;
      move: Address;
      monsterId: bigint;
    };
    opponentTeam: {
      owner: Address;
    };
    currentOpponentMove: {
      commit: string;
      move: Address;
      monsterId: bigint;
    };
    phase: MatchPhase;
    timeout: bigint;
    round: bigint;
    escaped: Address;
  };
  eventLogger: Address;
};

// Utility function to generate a commit hash for a given move and secret
const getCommitHash = (move: Address, secret: string = "secret") =>
  keccak256(
    encodePacked(
      ["address", "bytes32"],
      [
        move,
        toHex(secret, {
          size: 32,
        }),
      ]
    )
  );

const convertChainStatusEffectToStatusEffect = (
  contracts: Map<Address, string>,
  chainStatusEffect: StatusEffectFromChain
): MonsterStatusEffect => {
  return {
    name: contracts.get(chainStatusEffect.statusEffect) || "Unknown",
    remainingTurns: chainStatusEffect.remainingTurns,
    key: "",
    description: "",
    imageURI: "",
    group:
      chainStatusEffect.group === 0
        ? StatusEffectGroup.BUFF
        : chainStatusEffect.group === 1
        ? StatusEffectGroup.DEBUFF
        : StatusEffectGroup.WALL,
  };
};

const convertChainStatusEffectsToStatusEffects = (
  contracts: Map<Address, string>,
  chainStatusEffects: StatusEffectFromChain[]
): MonsterStatusEffect[] => {
  return chainStatusEffects.map((chainStatusEffect) =>
    convertChainStatusEffectToStatusEffect(contracts, chainStatusEffect)
  );
};

export const getOtherMonster = (
  chainMatch: MatchFromChain,
  monster: MonsterFromChain,
  isChallengerOnChain: boolean
): MonsterFromChain => {
  if (isChallengerOnChain) {
    return chainMatch.challengerMonster1.tokenId === monster.tokenId
      ? chainMatch.challengerMonster2
      : chainMatch.challengerMonster1;
  } else {
    return chainMatch.opponentMonster1.tokenId === monster.tokenId
      ? chainMatch.opponentMonster2
      : chainMatch.opponentMonster1;
  }
};

export const getOtherMonsterStatusEffects = (
  chainMatch: MatchFromChain,
  monster: MonsterFromChain,
  isChallengerOnChain: boolean
): StatusEffectFromChain[] => {
  if (isChallengerOnChain) {
    return chainMatch.challengerMonster1.tokenId === monster.tokenId
      ? chainMatch.challengerStatusEffects2
      : chainMatch.challengerStatusEffects1;
  } else {
    return chainMatch.opponentMonster1.tokenId === monster.tokenId
      ? chainMatch.opponentStatusEffects2
      : chainMatch.opponentStatusEffects1;
  }
};

// Utility function to convert chain data to a more structured Match type
const convertChainMatchToMatch = (
  user: Address,
  contracts: Map<Address, string>,
  usernamesMapping: Map<Address, string | undefined>,
  chainMatch?: MatchFromChain
): Match | undefined => {
  if (!chainMatch) return undefined;

  const isChallengerOnChain = chainMatch._match.challengerTeam.owner === user;

  const activeMonsterFromChain = isChallengerOnChain
    ? chainMatch.challengerMonster1.hp === 0
      ? chainMatch.challengerMonster2
      : chainMatch.challengerMonster1
    : chainMatch.opponentMonster1.hp === 0
    ? chainMatch.opponentMonster2
    : chainMatch.opponentMonster1;

  const activeMonsterOpponentFromChain = isChallengerOnChain
    ? chainMatch.opponentMonster1.hp === 0
      ? chainMatch.opponentMonster2
      : chainMatch.opponentMonster1
    : chainMatch.challengerMonster1.hp === 0
    ? chainMatch.challengerMonster2
    : chainMatch.challengerMonster1;

  const inactiveMonsterFromChain = getOtherMonster(
    chainMatch,
    activeMonsterFromChain,
    isChallengerOnChain
  );

  const inactiveMonsterOpponentFromChain = getOtherMonster(
    chainMatch,
    activeMonsterOpponentFromChain,
    !isChallengerOnChain
  );

  const activeMonsterStatusEffectsFromChain = isChallengerOnChain
    ? chainMatch.challengerMonster1.hp === 0
      ? chainMatch.challengerStatusEffects2
      : chainMatch.challengerStatusEffects1
    : chainMatch.opponentMonster1.hp === 0
    ? chainMatch.opponentStatusEffects2
    : chainMatch.opponentStatusEffects1;

  const activeMonsterOpponentStatusEffectsFromChain = isChallengerOnChain
    ? chainMatch.opponentMonster1.hp === 0
      ? chainMatch.opponentStatusEffects2
      : chainMatch.opponentStatusEffects1
    : chainMatch.challengerMonster1.hp === 0
    ? chainMatch.challengerStatusEffects2
    : chainMatch.challengerStatusEffects1;

  const inactiveMonsterStatusEffectsFromChain = getOtherMonsterStatusEffects(
    chainMatch,
    activeMonsterFromChain,
    isChallengerOnChain
  );

  const inactiveMonsterOpponentStatusEffectsFromChain =
    getOtherMonsterStatusEffects(
      chainMatch,
      activeMonsterOpponentFromChain,
      !isChallengerOnChain
    );

  const self: Player = isChallengerOnChain
    ? {
        address: chainMatch._match.challengerTeam.owner,
        userName: usernamesMapping.has(chainMatch._match.challengerTeam.owner)
          ? usernamesMapping.get(chainMatch._match.challengerTeam.owner)!
          : shortenAddress(chainMatch._match.challengerTeam.owner),
      }
    : {
        address: chainMatch._match.opponentTeam.owner,
        userName: usernamesMapping.has(chainMatch._match.opponentTeam.owner)
          ? usernamesMapping.get(chainMatch._match.opponentTeam.owner)!
          : shortenAddress(chainMatch._match.opponentTeam.owner),
      };

  const opponent: Player = isChallengerOnChain
    ? {
        address: chainMatch._match.opponentTeam.owner,
        userName: usernamesMapping.has(chainMatch._match.opponentTeam.owner)
          ? usernamesMapping.get(chainMatch._match.opponentTeam.owner)!
          : shortenAddress(chainMatch._match.opponentTeam.owner),
      }
    : {
        address: chainMatch._match.challengerTeam.owner,
        userName: usernamesMapping.has(chainMatch._match.challengerTeam.owner)
          ? usernamesMapping.get(chainMatch._match.challengerTeam.owner)!
          : shortenAddress(chainMatch._match.challengerTeam.owner),
      };

  if (chainMatch.id === BigInt(0)) {
    return undefined;
  }

  return {
    phase: chainMatch._match.phase,
    id: chainMatch.id,
    round: chainMatch._match.round,
    timeout: chainMatch._match.timeout,
    activeMonster: {
      isWaitingForCommit: isChallengerOnChain
        ? chainMatch._match.currentChallengerMove.commit === EMPTY_BYTES
        : chainMatch._match.currentOpponentMove.commit === EMPTY_BYTES,
      tokenId: activeMonsterFromChain.tokenId,
      config: {
        ...getConfigById(
          activeMonsterFromChain.monsterType.toString() as MonsterId
        ),
        attributes: {
          attack: Number(activeMonsterFromChain.attack),
          defense: Number(activeMonsterFromChain.defense),
          speed: Number(activeMonsterFromChain.speed),
          hp: Number(activeMonsterFromChain.hp),
          hpInitial: Number(activeMonsterFromChain.hpInitial),
        },
      },
      statusEffects: convertChainStatusEffectsToStatusEffects(
        contracts,
        activeMonsterStatusEffectsFromChain
      ),
    },
    inactiveMonster: {
      isWaitingForCommit: false,
      tokenId: inactiveMonsterFromChain.tokenId,
      config: {
        ...getConfigById(
          inactiveMonsterFromChain.monsterType.toString() as MonsterId
        ),
        attributes: {
          attack: Number(inactiveMonsterFromChain.attack),
          defense: Number(inactiveMonsterFromChain.defense),
          speed: Number(inactiveMonsterFromChain.speed),
          hp: Number(inactiveMonsterFromChain.hp),
          hpInitial: Number(inactiveMonsterFromChain.hpInitial),
        },
      },
      statusEffects: convertChainStatusEffectsToStatusEffects(
        contracts,
        inactiveMonsterStatusEffectsFromChain
      ),
    },
    activeMonsterOpponent: {
      isWaitingForCommit: isChallengerOnChain
        ? chainMatch._match.currentOpponentMove.commit === EMPTY_BYTES
        : chainMatch._match.currentChallengerMove.commit === EMPTY_BYTES,
      tokenId: activeMonsterOpponentFromChain.tokenId,
      config: {
        ...getConfigById(
          activeMonsterOpponentFromChain.monsterType.toString() as MonsterId
        ),
        attributes: {
          attack: Number(activeMonsterOpponentFromChain.attack),
          defense: Number(activeMonsterOpponentFromChain.defense),
          speed: Number(activeMonsterOpponentFromChain.speed),
          hp: Number(activeMonsterOpponentFromChain.hp),
          hpInitial: Number(activeMonsterOpponentFromChain.hpInitial),
        },
      },
      statusEffects: convertChainStatusEffectsToStatusEffects(
        contracts,
        activeMonsterOpponentStatusEffectsFromChain
      ),
    },
    inactiveMonsterOpponent: {
      isWaitingForCommit: false,
      tokenId: inactiveMonsterOpponentFromChain.tokenId,
      config: {
        ...getConfigById(
          inactiveMonsterOpponentFromChain.monsterType.toString() as MonsterId
        ),
        attributes: {
          attack: Number(inactiveMonsterOpponentFromChain.attack),
          defense: Number(inactiveMonsterOpponentFromChain.defense),
          speed: Number(inactiveMonsterOpponentFromChain.speed),
          hp: Number(inactiveMonsterOpponentFromChain.hp),
          hpInitial: Number(inactiveMonsterOpponentFromChain.hpInitial),
        },
      },
      statusEffects: convertChainStatusEffectsToStatusEffects(
        contracts,
        inactiveMonsterOpponentStatusEffectsFromChain
      ),
    },
    self,
    opponent,
    escaped: chainMatch._match.escaped,
  };
};

type Commit = {
  hash: string;
  move: Address;
  isRevealed: boolean;
};

const getLastCommitHashKey = (
  matchMakerAddress: Address,
  matchId: bigint = BigInt(0)
) => {
  return `ocb:lastCommitHash:${matchMakerAddress}:${matchId}`;
};

const useUsernamesMapping = (chainId: string, chainMatch?: MatchFromChain) => {
  const { data: usernames }: { data: string[] | undefined } = useContractRead({
    address: contractAddresses[chainId].usernames || zeroAddress,
    abi: UsernamesV1Abi,
    functionName: "getNames",
    args: [
      [
        chainMatch?._match.challengerTeam.owner,
        chainMatch?._match.opponentTeam.owner,
      ],
    ],
    enabled:
      chainId !== `${arbitrum.id}` &&
      !!chainMatch?._match.challengerTeam.owner &&
      !!chainMatch?._match.opponentTeam.owner,
  });

  return useMemo(() => {
    const mapping = new Map<Address, string | undefined>();
    if (!chainMatch || !usernames) return mapping;

    mapping.set(
      chainMatch?._match.challengerTeam.owner,
      usernames?.[0] || undefined
    );
    mapping.set(
      chainMatch?._match.opponentTeam.owner,
      usernames?.[1] || undefined
    );
    return mapping;
  }, [chainMatch, usernames]);
};

export const useMatch = (
  user: Address,
  matchId?: bigint,
  triggerTimeouts?: boolean
) => {
  const { chainId } = useContext(AppContext);
  const { wallet } = usePrivyWagmi();

  const isConfidential = isSapphire(chainId);
  const matchMakerAbi = useMemo(() => {
    return isConfidential ? MatchMakerV3AbiConfidential : MatchMakerV3Abi;
  }, [isConfidential]);

  const [error, setError] = useState<Error | undefined>();
  const [isCommitting, setIsCommitting] = useState(false);
  const [isRevealing, setIsRevealing] = useState(false);
  const [selectedMove, setSelectedMoveInternal] = useState<
    Address | undefined
  >();

  const resetError = useCallback(() => setError(undefined), []);

  const { runWithCorrectNonce } = useRunWithCorrectNonce(wallet);
  const matchMaker = useMemo(() => {
    return contractAddresses[chainId].matchMaker;
  }, [chainId]);

  // Fetch the current match for the user from the blockchain
  const {
    data: chainMatch,
    isLoading,
    refetch,
  }: {
    data: MatchFromChain | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: matchMaker || zeroAddress,
    abi: matchMakerAbi,
    functionName: matchId ? "getMatchById" : "getMatchByUser",
    args: matchId ? [matchId] : [user],
    enabled: !!user && !!matchMaker,
  });

  const [lastCommit, setLastCommit] = useState<Commit | undefined>();

  useEffect(() => {
    if (chainMatch?.id && !lastCommit) {
      const fromLocalStorage = localStorage.getItem(
        getLastCommitHashKey(matchMaker, chainMatch?.id)
      );
      if (fromLocalStorage) {
        setLastCommit(JSON.parse(fromLocalStorage));
      }
    }
  }, [chainMatch?.id, lastCommit, matchMaker]);

  useInterval(
    () => {
      // Your custom logic here
      if (matchMaker) {
        refetch?.();
        setCurrentTimestamp(getCurrentTimestamp());
      }
    },
    // Delay in milliseconds or null to stop it
    2_000
  );

  // Fetch the match events for a given match
  const {
    contracts,
    logs,
    allLogs,
    markAsRead: markLogRead,
    refetch: refetchLogs,
  } = useMatchEvents(
    `${chainId}`,
    matchMaker,
    chainMatch?.eventLogger,
    chainMatch?.id
  );

  const usernamesMapping = useUsernamesMapping(`${chainId}`, chainMatch);

  // Convert chain match data to a more readable Match format
  const match = convertChainMatchToMatch(
    user,
    contracts,
    usernamesMapping,
    chainMatch as MatchFromChain
  );

  const [currentTimestamp, setCurrentTimestamp] = useState<number>(
    getCurrentTimestamp()
  );

  const isRoundTimedOut = useMemo(() => {
    if (!match?.timeout) return false;

    return currentTimestamp >= Number(match.timeout);
  }, [currentTimestamp, match?.timeout]);

  // Callback to set the selected move and store it in local storage
  const setSelectedMove = useCallback(
    (selectedMoveContractName: string) => {
      if (typeof match?.round === "undefined") return;

      let address: Address | undefined;
      for (let [_address, name] of contracts.entries()) {
        if (name === selectedMoveContractName) {
          address = _address as `0x${string}`;
          break;
        }
      }

      if (address) {
        setSelectedMoveInternal(address);
      }
    },
    [contracts, match?.round]
  );

  const isCommitEnabled =
    !isConfidential &&
    !!match &&
    (lastCommit?.isRevealed || !lastCommit) &&
    !!selectedMove &&
    match.phase === MatchPhase.Commit &&
    match?.activeMonster.isWaitingForCommit &&
    !isCommitting;

  const commit = useMemo(() => {
    return isCommitEnabled
      ? async (...args: any) =>
          writeContract({
            address: matchMaker,
            abi: matchMakerAbi,
            functionName: "commit",
            args: [
              match?.id || BigInt(0),
              getCommitHash(selectedMove || zeroAddress),
            ],
            gas: GAS,
            ...args,
          })
      : undefined;
  }, [isCommitEnabled, match?.id, matchMaker, matchMakerAbi, selectedMove]);

  const isWithdrawEnabled = (match?.id || BigInt(0)) > BigInt(0);
  const withdraw = useMemo(() => {
    return isWithdrawEnabled
      ? async (...args: any) => {
          return writeContractConfidential({
            request: {
              address: matchMaker,
              abi: matchMakerAbi,
              functionName: "withdrawFromMatch",
              args: [match?.id || BigInt(0)],
              gas: GAS,
              ...args,
            },
            chainId,
            mode: "prepared",
          });
        }
      : undefined;
  }, [isWithdrawEnabled, match?.id, matchMaker, matchMakerAbi, chainId]);

  const withdrawWithRetries = useCallback(async () => {
    if (withdraw) {
      try {
        await runWithCorrectNonce(withdraw, noop, "withdraw");
      } catch (err) {
        setError(err as Error);
      }
    }
  }, [runWithCorrectNonce, withdraw]);

  const goToRevealPhaseEnabled =
    triggerTimeouts &&
    isRoundTimedOut &&
    match?.phase === MatchPhase.Commit &&
    !match?.activeMonster.isWaitingForCommit;

  const updateBlockTimestamp = useMemo(() => {
    return async (...args: any) => {
      // we currently only need this on the PMON L3
      if (chainId !== sapphire.id) {
        return writeContract({
          address: matchMaker,
          abi: matchMakerAbi,
          functionName: "updateBlockTimestamp",
          gas: GAS,
          ...args,
        });
      }
    };
  }, [chainId, matchMaker, matchMakerAbi]);

  const goToRevealPhase = useMemo(() => {
    return goToRevealPhaseEnabled
      ? async (...args: any) =>
          writeContract({
            address: matchMaker,
            abi: matchMakerAbi,
            functionName: "goToRevealPhase",
            args: [match?.id],
            gas: GAS,
            ...args,
          })
      : undefined;
  }, [goToRevealPhaseEnabled, match?.id, matchMaker, matchMakerAbi]);

  useEffect(() => {
    async function run() {
      if (goToRevealPhase) {
        try {
          await runWithCorrectNonce(
            goToRevealPhase,
            noop,
            "goToRevealPhase",
            async (err: any) => {
              if (
                err instanceof Error &&
                err.message.includes("MatchMakerV3: timeout not expired")
              ) {
                await runWithCorrectNonce(
                  updateBlockTimestamp,
                  noop,
                  "updateBlockTimestamp"
                );
              }
            }
          );
        } catch (err) {
          setError(err as Error);
        }
      }
    }

    run();
  }, [runWithCorrectNonce, goToRevealPhase, updateBlockTimestamp]);

  const isRevealEnabledNonConfidential =
    !lastCommit?.isRevealed &&
    lastCommit?.move !== zeroAddress &&
    !!lastCommit?.move &&
    !isRevealing &&
    match?.phase === MatchPhase.Reveal &&
    !match?.activeMonster.isWaitingForCommit &&
    (!match?.activeMonsterOpponent.isWaitingForCommit || isRoundTimedOut);

  const isRevealEnabledConfidential = selectedMove && !isRevealing;

  const isRevealEnabled = isConfidential
    ? isRevealEnabledConfidential
    : isRevealEnabledNonConfidential;

  const reveal = useMemo(() => {
    return isRevealEnabled
      ? async (...args: any) => {
          return writeContractConfidential({
            request: {
              address: matchMaker,
              abi: matchMakerAbi,
              functionName: "reveal",
              args: isConfidential
                ? [match?.id || BigInt(0), selectedMove || zeroAddress]
                : [
                    match?.id || BigInt(0),
                    lastCommit?.move || zeroAddress,
                    toHex("secret", {
                      size: 32,
                    }),
                  ],
              gas: GAS,
              ...args,
            },
            chainId,
            mode: "prepared",
          });
        }
      : undefined;
  }, [
    isConfidential,
    isRevealEnabled,
    lastCommit?.move,
    match?.id,
    matchMaker,
    matchMakerAbi,
    selectedMove,
    chainId,
  ]);

  // Auto-commit when a move is selected
  useEffect(() => {
    async function run() {
      if (commit && !reveal) {
        setIsCommitting(true);
        try {
          runWithCorrectNonce(
            commit,
            (hash: Hex) => {
              setIsCommitting(false);
              refetch();
              refetchLogs();

              const commit = {
                hash: hash,
                move: selectedMove!,
                isRevealed: false,
              };
              setLastCommit(commit);
              localStorage.setItem(
                getLastCommitHashKey(matchMaker, chainMatch?.id),
                JSON.stringify(commit)
              );
            },
            "commit"
          );
        } catch (err) {
          setError(err as Error);
          setIsCommitting(false);
        }
      }
    }

    run();
  }, [
    commit,
    reveal,
    runWithCorrectNonce,
    refetch,
    refetchLogs,
    selectedMove,
    chainMatch?.id,
    matchMaker,
  ]);

  const onRevealCallback = useCallback(() => {
    setSelectedMoveInternal(undefined);
    const newLastCommit = {
      ...lastCommit!,
      isRevealed: true,
    };
    setLastCommit(newLastCommit);
    localStorage.setItem(
      getLastCommitHashKey(matchMaker, chainMatch?.id),
      JSON.stringify(newLastCommit)
    );

    setIsRevealing(false);
    refetch();
    refetchLogs();
  }, [lastCommit, matchMaker, chainMatch?.id, refetch, refetchLogs]);

  // Auto-reveal when the reveal function is available
  useEffect(() => {
    async function run() {
      if (!commit && reveal) {
        setIsRevealing(true);

        try {
          await runWithCorrectNonce(reveal, onRevealCallback, "reveal");
        } catch (err) {
          setError(err as Error);
        } finally {
          setIsRevealing(false);
        }
      }
    }

    run();
  }, [reveal, runWithCorrectNonce, onRevealCallback, commit]);

  // Add state for current phase and a ref for previous phase
  const [currentPhase, setCurrentPhase] = useState<MatchPhase | undefined>(
    undefined
  );
  const previousPhaseRef = useRef<MatchPhase | undefined>(undefined);

  // Update current and previous phase states when match data changes
  useEffect(() => {
    if (match && match.phase !== currentPhase) {
      // Update previousPhaseRef with the old currentPhase before updating currentPhase
      previousPhaseRef.current = currentPhase;
      setCurrentPhase(match.phase);
    }
  }, [match, currentPhase]);

  const getMonsterById = useCallback(
    (tokenId: bigint) => {
      if (!match) return undefined;

      if (match.activeMonster.tokenId === tokenId) {
        return match.activeMonster;
      } else if (match.inactiveMonster.tokenId === tokenId) {
        return match.inactiveMonster;
      } else if (match.activeMonsterOpponent.tokenId === tokenId) {
        return match.activeMonsterOpponent;
      } else if (match.inactiveMonsterOpponent.tokenId === tokenId) {
        return match.inactiveMonsterOpponent;
      } else {
        return undefined;
      }
    },
    [match]
  );

  console.log("allLogs", allLogs);

  return {
    error,
    getMonsterById,
    logs,
    allLogs,
    markLogRead,
    currentPhase,
    previousPhase: previousPhaseRef.current,
    match,
    isLoading,
    selectedMove,
    setSelectedMove,
    withdraw: withdrawWithRetries,
    isCommitting,
    resetError,
    isConfidential,
  };
};
