import { useInterval } from "usehooks-ts";
import { useContractRead } from "wagmi";
import semver from "semver";
import { AppVersionsV1Abi } from "../abis";
import { contracts } from "../contracts.ts";
import { PLACEHOLDER_CHAIN_ID } from "../../../chains.ts";
import { zeroAddress } from "viem";

const APP_VERSION_REFRESH_INTERVAL = 30_000;

type AppVersionResult = {
  currentHash: string | undefined;
  minVersion: string | undefined;
  isLoading: boolean;
  needsUpdate: boolean;
  version: string;
};

const currentHash = import.meta.env.VITE_REVISION as string;

export const useAppVersion = (chainId: string): AppVersionResult => {
  const contract = contracts[chainId].appVersions;

  const {
    data: minVersion,
    isLoading,
    refetch,
  }: {
    data: string | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: contracts[chainId].appVersions || zeroAddress,
    abi: AppVersionsV1Abi,
    functionName: "minVersion",
    args: [],
    enabled: chainId !== PLACEHOLDER_CHAIN_ID && !!contract,
    cacheTime: 10_000,
  });

  useInterval(
    () => {
      if (contract) {
        refetch?.();
      }
    },
    // Delay in milliseconds or null to stop it
    APP_VERSION_REFRESH_INTERVAL
  );

  const needsUpdate =
    import.meta.env.MODE === "production" &&
    semver.lt(APP_VERSION, minVersion || "0.0.0");

  return {
    currentHash,
    needsUpdate,
    minVersion,
    isLoading,
    version: APP_VERSION,
  };
};
