import { motion } from "framer-motion";
import React, { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import { RetinaImage } from "../../components";
import { AppContext, UserContext } from "../../context";
import { useShowInstallMessage } from "../../hooks";
import { arbitrum } from "viem/chains";

export const LayoutAuth: React.FC = () => {
  const location = useLocation();
  const { isLoading, user } = useContext(UserContext);
  const { showInstallMessage } = useShowInstallMessage();

  const { chainId } = useContext(AppContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (
      chainId === arbitrum.id &&
      location.pathname !== "/battle-preparation"
    ) {
      navigate("/battle-preparation", {
        replace: true,
      });
    }
  }, [chainId, navigate, location.pathname]);

  // return (
  //   <div className="flex flex-col flex-1 justify-center items-center bg-base-100">
  //     <SpriteAnimator
  //       sprite={SPRITES.magma}
  //       width={381}
  //       height={186}
  //       fps={12}
  //       onEnd={() => {}}
  //     />
  //   </div>
  // );

  if (showInstallMessage) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  // Loading state
  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col py-safe-or-4">
        <div className="flex flex-1 flex-col justify-center items-center space-y-8">
          <RetinaImage
            imageKey="loading"
            alt="Loading"
            className="w-3/5 aspect-square"
          />

          <div className="flex flex-col text-center space-y-2 px-8">
            <h1 className="font-display font-black text-2xl text-text-primary">
              Loading the Polyverse
            </h1>
            <p className="text-sm text-text-secondary">
              Conjuring up new adventures and frens. Hang tight, your journey
              into the world of Polychain Monsters is about to begin!
            </p>
          </div>

          <motion.div
            className="flex flex-col justify-center items-center w-10 h-10 bg-background-highlight/40 border border-background-highlight/20"
            animate={{
              scale: [1, 1.5, 1.5, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["5%", "5%", "50%", "50%", "5%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
            }}
          >
            <motion.div
              className="w-5 h-5 bg-background-highlight/80"
              animate={{
                scale: [1, 1.25, 1.25, 1, 1],
                rotate: [0, 0, 180, 180, 0],
                borderRadius: ["5%", "5%", "50%", "50%", "5%"],
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Infinity,
                repeatDelay: 1,
              }}
            />
          </motion.div>
        </div>
      </div>
    );
  }

  // If user exists, show the child components, else redirect.
  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};
