import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

import { useMeasure } from "@uidotdev/usehooks";

import { AnimatedNumber } from "../animatedNumber";
import { Skeleton } from "../skeleton";
import { MonsterBoardStatusEffectsProps } from "./monsterBoardProps";

const imgVariants = {
  enter: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const MAX_STATUS_EFFECTS = 10;

export const MonsterBoardStatusEffects: React.FC<
  MonsterBoardStatusEffectsProps
> = ({ statusEffects, loading }) => {
  const [ref, { width, height }] = useMeasure();

  // State to keep track of the number of elements that can fit inside the container
  const [numberElements, setNumberElements] = useState(3);

  useEffect(() => {
    // Handle resizing of the window
    const handleResize = () => {
      if (height) {
        // Define padding and heights based on Tailwind values
        const pY = 4; // p-1 in Tailwind is 0.25rem or 4px
        const divHeight = 24; // h-6 in Tailwind is 1.5rem or 24px
        const spaceY = 4; // space-y-1 in Tailwind is 0.25rem or 4px

        // Calculate the number of elements that can fit inside the container
        const numberElements = Math.min(
          MAX_STATUS_EFFECTS,
          Math.floor((height - 2 * pY) / (divHeight + spaceY)),
        );

        setNumberElements(numberElements);
      }
    };

    // Calculate initially
    handleResize();

    // Add event listener for resizing
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, height, width]);

  return (
    <div
      ref={ref}
      className="flex flex-col-reverse flex-none justify-between items-center w-8 py-1 space-y-1 rounded-lg bg-background-primary/80 border-background-primary/10 backdrop-blur-sm shadow-sm"
    >
      {Array.from({ length: numberElements }).map((_, index) => {
        // If loading, show skeleton
        if (loading) {
          return <Skeleton key={index} className="h-6 w-6 rounded" />;
        }

        // Otherwise, show status effect
        const statusEffect = statusEffects?.[index];
        const isBoostEffect = [
          "boostAttack",
          "boostDefense",
          "boostSpeed",
        ].includes(statusEffect?.key);

        return (
          <div className="relative" key={`${statusEffect}_${index}`}>
            <div
              key={index}
              className="h-6 w-6 bg-background-dark/5 rounded overflow-hidden shadow-inner"
            >
              {statusEffect && (
                <motion.img
                  src={statusEffect.imageURI}
                  className="flex flex-1 aspect-square"
                  initial="enter"
                  animate="visible"
                  exit="exit"
                  variants={imgVariants}
                />
              )}
            </div>

            {!isBoostEffect && statusEffect?.remainingTurns > 0 && (
              <div className="absolute -bottom-0.5 -right-0.5 text-text-primary text-2xs font-display font-black flex justify-center items-center w-3 h-3 rounded-full bg-background-primary">
                <AnimatedNumber
                  targetNumber={parseInt(
                    statusEffect.remainingTurns.toString(),
                  )}
                />
              </div>
            )}

            {statusEffect?.count !== undefined && statusEffect.count > 1 && (
              <div className="absolute -top-0.5 -left-0.5 text-text-light text-2xs font-display font-black flex justify-center items-center w-3 h-3 rounded-full bg-background-highlight">
                <AnimatedNumber
                  targetNumber={parseInt(statusEffect.count.toString())}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
