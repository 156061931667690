import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";

const BUGSNAG_API_KEY = "6fc127a6aa5577fb93169e077cb66284";

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  appVersion: import.meta.env.PACKAGE_VERSION || "0.0.0",
  enabledReleaseStages: ["production"],
  onError: function (event) {
    let user = localStorage.getItem("ocb:user:did");
    if (user) {
      event.setUser(user);
    }
  },
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

BugsnagPerformance.start({
  apiKey: BUGSNAG_API_KEY,
  enabledReleaseStages: ["production"],
});

import App from "./App.tsx";

window.onerror = function (message, source, lineno, colno, error) {};

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);
