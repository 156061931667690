import { motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

import { usePrivy } from "@privy-io/react-auth";

import {
  Button,
  ModalInstall,
  ModalProfile,
  ProfilePicture,
} from "../../components";
import { useLongPress, useShowInstallMessage } from "../../hooks";
import { contentTransition, contentVariants } from "../../utility";
import { getToken } from "firebase/messaging";
import { UserContext } from "../../context";

const LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY = "ocb:notifications-enabked";
const LOCAL_STORAGE_MESSAGING_TOKEN_KEY = "ocb:messaging-token";

const NOTIFICATIONS_ENABLED = "1";
const NOTIFICATIONS_DISABLED = "0";

type AlphaCountdownProps = {
  launchDate?: Date;
};

type CountdownRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
};

const countdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRendererProps) => {
  if (completed) {
    return <span className="text-2xl">00:00:00</span>;
  } else {
    return (
      <span>
        {days} d {hours} h {minutes} m {seconds} s
      </span>
    );
  }
};

export const AlphaCountdown = ({ launchDate }: AlphaCountdownProps) => {
  const { ready } = usePrivy();
  const navigate = useNavigate();
  const { showInstallMessage } = useShowInstallMessage();

  const { user, userName, statistics, profilePictureURI } =
    useContext(UserContext)!;

  const [modalProfileVisibility, setModalProfileVisibility] = useState(false);
  const [installVisibility, setInstallVisibility] = useState(false);
  const [isDebugCounter, setIsDebugCounter] = useState(0);
  const [notificationsEnabled, setNotificationsEnabled] = useState(
    localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY) ===
      NOTIFICATIONS_ENABLED,
  );
  const [messagingToken, setMessagingToken] = useState(
    localStorage.getItem("messaging-token"),
  );

  const buttonSoundKey = showInstallMessage ? "warning" : "click";

  const requestPermissions = useCallback(() => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setNotificationsEnabled(true);
        localStorage.setItem(
          LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
          NOTIFICATIONS_ENABLED,
        );
      } else {
        setNotificationsEnabled(false);
        localStorage.setItem(
          LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY,
          NOTIFICATIONS_DISABLED,
        );
      }
    });
  }, []);

  const onDebugClick = useCallback(() => {
    setIsDebugCounter((counter) => counter + 1);
  }, []);

  const onLongPress = useCallback(() => {
    navigate("/battle-preparation", { replace: true });
  }, [navigate]);
  const longPressProps = useLongPress(onLongPress, 5_000);

  const onComplete = useCallback(() => {
    navigate("/battle-preparation", { replace: true });
  }, [navigate]);

  return (
    <>
      <div className="flex flex-1 bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro sm:bg-background-intro-sm md:bg-background-intro-md lg:bg-background-intro-lg">
        <div className="flex flex-1 px-8 py-safe-or-8 justify-center items-center bg-black/20">
          <motion.div
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={contentTransition}
            className="flex p-2 rounded-2xl w-full max-w-md aspect-[3/4] bg-background-primary/20 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            <div className="flex flex-1 flex-col p-4 space-y-8 rounded-xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
              <div className="flex flex-1 flex-col space-y-4 justify-center items-center">
                <div className="flex flex-row justify-end">
                  <ProfilePicture
                    size={40}
                    imageURI={profilePictureURI}
                    onClick={() => setModalProfileVisibility(true)}
                  />
                </div>
                <h1
                  className="text-xl text-center font-display text-text-primary select-none"
                  {...longPressProps}
                >
                  Alpha Test starting in:
                </h1>
                <h2
                  className="font-display font-black text-center text-2xl bg-gradient-to-b from-primary-500 to-mental-500 bg-clip-text text-transparent"
                  onClick={onDebugClick}
                >
                  <Countdown
                    date={launchDate}
                    renderer={countdownRenderer}
                    onComplete={onComplete}
                  />
                </h2>
                {launchDate && (
                  <p className="text-sm text-center text-text-secondary">
                    Battle your frens in the world's first fully on-chain
                    monster battles on {launchDate.toDateString()} at{" "}
                    {`${launchDate.getHours()}`.padStart(2, "0")}:
                    {`${launchDate.getMinutes()}`.padStart(2, "0")}.
                  </p>
                )}
                {isDebugCounter >= 20 && (
                  <span className="break-all text-xs">{messagingToken}</span>
                )}
              </div>
              {notificationsEnabled ? (
                <>
                  <Button
                    variant="light"
                    onClick={requestPermissions}
                    disabled={true}
                    sound={buttonSoundKey}
                  >
                    Notifications enabled
                    <span className="material-symbols-rounded ml-1">check</span>
                  </Button>
                </>
              ) : (
                <Button
                  variant="light"
                  onClick={requestPermissions}
                  disabled={!ready}
                  sound={buttonSoundKey}
                >
                  Get Notified{" "}
                  <span className="material-symbols-rounded ml-1">
                    notifications
                  </span>
                </Button>
              )}
            </div>
          </motion.div>
        </div>
        <ModalInstall
          isVisible={installVisibility}
          onClose={() => setInstallVisibility(false)}
        />
        <ModalProfile
          isVisible={modalProfileVisibility}
          walletAddress={user}
          userName={userName}
          imageURI={profilePictureURI}
          onClose={() => setModalProfileVisibility(false)}
        />
      </div>
    </>
  );
};
