import { useCallback, useContext, useEffect, useState } from "react";
import { Address, zeroAddress } from "viem";
import { GraphContext } from "../../../context/graphContext";

// Define a base interface for tokens
interface BaseToken {
  id: bigint;
  uri: string;
  epoch: {
    randomness?: bigint;
    timestamp: bigint;
  };
}

// Extend the base interface for specific token types
export interface EnrichedToken extends BaseToken {
  decodedUri: any; // Adjust the type as necessary
}

export interface EnrichedOpenedToken extends BaseToken {
  decodedUri: any; // Adjust the type as necessary
}

// Update your hook to be generic
export const useFetchTokensByOwner = <T extends EnrichedOpenedToken>(
  owner?: Address | string | undefined,
  isOpened: boolean = false,
  isIncludeBurns: boolean = false
): { isLoading: boolean; refetch: () => Promise<void>; tokens: T[] } => {
  const [isLoading, setIsLoading] = useState(false);
  const [tokens, setTokens] = useState<T[]>([]);

  const {
    getOpenedTokensIncludingBurns,
    getOpenedTokensByOpenerIncludingBurns,
    getOpenedTokensByOpener,
    getTokensByOwner,
  } = useContext(GraphContext);

  const fetchTokens = useCallback(async () => {
    if (owner) {
      setIsLoading(true);
      const res = isOpened
        ? isIncludeBurns
          ? owner === zeroAddress
            ? await getOpenedTokensIncludingBurns()
            : await getOpenedTokensByOpenerIncludingBurns(owner as Address)
          : await getOpenedTokensByOpener(owner as Address)
        : await getTokensByOwner(owner as Address);
      setTokens(
        (res?.tokens || []).map(
          (token) =>
            ({
              ...token,
              decodedUri: token.uri
                ? JSON.parse(atob(token.uri.split(",")[1]))
                : null,
            } as unknown as T)
        ) || []
      );
      setIsLoading(false);
    }
  }, [owner, isOpened, isIncludeBurns]);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  const refetch = useCallback(async () => {
    return fetchTokens();
  }, [fetchTokens]);

  return { isLoading, refetch, tokens };
};
