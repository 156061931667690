// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { Eip721Types } from './sources/eip721/types';
import * as importedModule$0 from "./sources/eip721/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
};

export type BattleEvent = {
  id: Scalars['ID'];
  matchId: Scalars['BigInt'];
  action: Scalars['BigInt'];
  data: Scalars['Bytes'];
  timestamp: Scalars['BigInt'];
  player: Scalars['Bytes'];
  opponent: Scalars['Bytes'];
  monster: Scalars['BigInt'];
  opponentMonster: Scalars['BigInt'];
  round: Scalars['BigInt'];
};

export type BattleEvent_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  matchId?: InputMaybe<Scalars['BigInt']>;
  matchId_not?: InputMaybe<Scalars['BigInt']>;
  matchId_gt?: InputMaybe<Scalars['BigInt']>;
  matchId_lt?: InputMaybe<Scalars['BigInt']>;
  matchId_gte?: InputMaybe<Scalars['BigInt']>;
  matchId_lte?: InputMaybe<Scalars['BigInt']>;
  matchId_in?: InputMaybe<Array<Scalars['BigInt']>>;
  matchId_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  action?: InputMaybe<Scalars['BigInt']>;
  action_not?: InputMaybe<Scalars['BigInt']>;
  action_gt?: InputMaybe<Scalars['BigInt']>;
  action_lt?: InputMaybe<Scalars['BigInt']>;
  action_gte?: InputMaybe<Scalars['BigInt']>;
  action_lte?: InputMaybe<Scalars['BigInt']>;
  action_in?: InputMaybe<Array<Scalars['BigInt']>>;
  action_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  data?: InputMaybe<Scalars['Bytes']>;
  data_not?: InputMaybe<Scalars['Bytes']>;
  data_gt?: InputMaybe<Scalars['Bytes']>;
  data_lt?: InputMaybe<Scalars['Bytes']>;
  data_gte?: InputMaybe<Scalars['Bytes']>;
  data_lte?: InputMaybe<Scalars['Bytes']>;
  data_in?: InputMaybe<Array<Scalars['Bytes']>>;
  data_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  data_contains?: InputMaybe<Scalars['Bytes']>;
  data_not_contains?: InputMaybe<Scalars['Bytes']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  player?: InputMaybe<Scalars['Bytes']>;
  player_not?: InputMaybe<Scalars['Bytes']>;
  player_gt?: InputMaybe<Scalars['Bytes']>;
  player_lt?: InputMaybe<Scalars['Bytes']>;
  player_gte?: InputMaybe<Scalars['Bytes']>;
  player_lte?: InputMaybe<Scalars['Bytes']>;
  player_in?: InputMaybe<Array<Scalars['Bytes']>>;
  player_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  player_contains?: InputMaybe<Scalars['Bytes']>;
  player_not_contains?: InputMaybe<Scalars['Bytes']>;
  opponent?: InputMaybe<Scalars['Bytes']>;
  opponent_not?: InputMaybe<Scalars['Bytes']>;
  opponent_gt?: InputMaybe<Scalars['Bytes']>;
  opponent_lt?: InputMaybe<Scalars['Bytes']>;
  opponent_gte?: InputMaybe<Scalars['Bytes']>;
  opponent_lte?: InputMaybe<Scalars['Bytes']>;
  opponent_in?: InputMaybe<Array<Scalars['Bytes']>>;
  opponent_not_in?: InputMaybe<Array<Scalars['Bytes']>>;
  opponent_contains?: InputMaybe<Scalars['Bytes']>;
  opponent_not_contains?: InputMaybe<Scalars['Bytes']>;
  monster?: InputMaybe<Scalars['BigInt']>;
  monster_not?: InputMaybe<Scalars['BigInt']>;
  monster_gt?: InputMaybe<Scalars['BigInt']>;
  monster_lt?: InputMaybe<Scalars['BigInt']>;
  monster_gte?: InputMaybe<Scalars['BigInt']>;
  monster_lte?: InputMaybe<Scalars['BigInt']>;
  monster_in?: InputMaybe<Array<Scalars['BigInt']>>;
  monster_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  opponentMonster?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_not?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_gt?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_lt?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_gte?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_lte?: InputMaybe<Scalars['BigInt']>;
  opponentMonster_in?: InputMaybe<Array<Scalars['BigInt']>>;
  opponentMonster_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  round?: InputMaybe<Scalars['BigInt']>;
  round_not?: InputMaybe<Scalars['BigInt']>;
  round_gt?: InputMaybe<Scalars['BigInt']>;
  round_lt?: InputMaybe<Scalars['BigInt']>;
  round_gte?: InputMaybe<Scalars['BigInt']>;
  round_lte?: InputMaybe<Scalars['BigInt']>;
  round_in?: InputMaybe<Array<Scalars['BigInt']>>;
  round_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BattleEvent_filter>>>;
  or?: InputMaybe<Array<InputMaybe<BattleEvent_filter>>>;
};

export type BattleEvent_orderBy =
  | 'id'
  | 'matchId'
  | 'action'
  | 'data'
  | 'timestamp'
  | 'player'
  | 'opponent'
  | 'monster'
  | 'opponentMonster'
  | 'round';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type Contract = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['BigInt']>;
  mintedTokens: Array<Token>;
};


export type ContractmintedTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
};

export type Contract_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  name_not?: InputMaybe<Scalars['String']>;
  name_gt?: InputMaybe<Scalars['String']>;
  name_lt?: InputMaybe<Scalars['String']>;
  name_gte?: InputMaybe<Scalars['String']>;
  name_lte?: InputMaybe<Scalars['String']>;
  name_in?: InputMaybe<Array<Scalars['String']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']>>;
  name_contains?: InputMaybe<Scalars['String']>;
  name_contains_nocase?: InputMaybe<Scalars['String']>;
  name_not_contains?: InputMaybe<Scalars['String']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  name_starts_with?: InputMaybe<Scalars['String']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  name_ends_with?: InputMaybe<Scalars['String']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  symbol_not?: InputMaybe<Scalars['String']>;
  symbol_gt?: InputMaybe<Scalars['String']>;
  symbol_lt?: InputMaybe<Scalars['String']>;
  symbol_gte?: InputMaybe<Scalars['String']>;
  symbol_lte?: InputMaybe<Scalars['String']>;
  symbol_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_not_in?: InputMaybe<Array<Scalars['String']>>;
  symbol_contains?: InputMaybe<Scalars['String']>;
  symbol_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_contains?: InputMaybe<Scalars['String']>;
  symbol_not_contains_nocase?: InputMaybe<Scalars['String']>;
  symbol_starts_with?: InputMaybe<Scalars['String']>;
  symbol_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with?: InputMaybe<Scalars['String']>;
  symbol_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_ends_with?: InputMaybe<Scalars['String']>;
  symbol_ends_with_nocase?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with?: InputMaybe<Scalars['String']>;
  symbol_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['BigInt']>;
  totalSupply_not?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mintedTokens_?: InputMaybe<Token_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Contract_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Contract_filter>>>;
};

export type Contract_orderBy =
  | 'id'
  | 'name'
  | 'symbol'
  | 'totalSupply'
  | 'mintedTokens';

export type Epoch = {
  id: Scalars['ID'];
  number: Scalars['BigInt'];
  startBlock: Scalars['BigInt'];
  endBlock?: Maybe<Scalars['BigInt']>;
  timestamp: Scalars['BigInt'];
  randomness?: Maybe<Scalars['BigInt']>;
  tokens: Array<Token>;
  tokenIds: Array<Scalars['BigInt']>;
};


export type EpochtokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
};

export type EpochCounter = {
  id: Scalars['ID'];
  count: Scalars['BigInt'];
};

export type EpochCounter_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  count?: InputMaybe<Scalars['BigInt']>;
  count_not?: InputMaybe<Scalars['BigInt']>;
  count_gt?: InputMaybe<Scalars['BigInt']>;
  count_lt?: InputMaybe<Scalars['BigInt']>;
  count_gte?: InputMaybe<Scalars['BigInt']>;
  count_lte?: InputMaybe<Scalars['BigInt']>;
  count_in?: InputMaybe<Array<Scalars['BigInt']>>;
  count_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<EpochCounter_filter>>>;
  or?: InputMaybe<Array<InputMaybe<EpochCounter_filter>>>;
};

export type EpochCounter_orderBy =
  | 'id'
  | 'count';

export type Epoch_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  number?: InputMaybe<Scalars['BigInt']>;
  number_not?: InputMaybe<Scalars['BigInt']>;
  number_gt?: InputMaybe<Scalars['BigInt']>;
  number_lt?: InputMaybe<Scalars['BigInt']>;
  number_gte?: InputMaybe<Scalars['BigInt']>;
  number_lte?: InputMaybe<Scalars['BigInt']>;
  number_in?: InputMaybe<Array<Scalars['BigInt']>>;
  number_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startBlock?: InputMaybe<Scalars['BigInt']>;
  startBlock_not?: InputMaybe<Scalars['BigInt']>;
  startBlock_gt?: InputMaybe<Scalars['BigInt']>;
  startBlock_lt?: InputMaybe<Scalars['BigInt']>;
  startBlock_gte?: InputMaybe<Scalars['BigInt']>;
  startBlock_lte?: InputMaybe<Scalars['BigInt']>;
  startBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  startBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endBlock?: InputMaybe<Scalars['BigInt']>;
  endBlock_not?: InputMaybe<Scalars['BigInt']>;
  endBlock_gt?: InputMaybe<Scalars['BigInt']>;
  endBlock_lt?: InputMaybe<Scalars['BigInt']>;
  endBlock_gte?: InputMaybe<Scalars['BigInt']>;
  endBlock_lte?: InputMaybe<Scalars['BigInt']>;
  endBlock_in?: InputMaybe<Array<Scalars['BigInt']>>;
  endBlock_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  randomness?: InputMaybe<Scalars['BigInt']>;
  randomness_not?: InputMaybe<Scalars['BigInt']>;
  randomness_gt?: InputMaybe<Scalars['BigInt']>;
  randomness_lt?: InputMaybe<Scalars['BigInt']>;
  randomness_gte?: InputMaybe<Scalars['BigInt']>;
  randomness_lte?: InputMaybe<Scalars['BigInt']>;
  randomness_in?: InputMaybe<Array<Scalars['BigInt']>>;
  randomness_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tokens_?: InputMaybe<Token_filter>;
  tokenIds?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIds_not?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIds_contains?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIds_contains_nocase?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIds_not_contains?: InputMaybe<Array<Scalars['BigInt']>>;
  tokenIds_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Epoch_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Epoch_filter>>>;
};

export type Epoch_orderBy =
  | 'id'
  | 'number'
  | 'startBlock'
  | 'endBlock'
  | 'timestamp'
  | 'randomness'
  | 'tokens'
  | 'tokenIds';

export type HolderStatsData = {
  id: Scalars['ID'];
  items1: Scalars['BigInt'];
  items2_3: Scalars['BigInt'];
  items4_10: Scalars['BigInt'];
  items11_25: Scalars['BigInt'];
  items26_50: Scalars['BigInt'];
  items51: Scalars['BigInt'];
  total: Scalars['BigInt'];
};

export type HolderStatsData_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  items1?: InputMaybe<Scalars['BigInt']>;
  items1_not?: InputMaybe<Scalars['BigInt']>;
  items1_gt?: InputMaybe<Scalars['BigInt']>;
  items1_lt?: InputMaybe<Scalars['BigInt']>;
  items1_gte?: InputMaybe<Scalars['BigInt']>;
  items1_lte?: InputMaybe<Scalars['BigInt']>;
  items1_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items1_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items2_3?: InputMaybe<Scalars['BigInt']>;
  items2_3_not?: InputMaybe<Scalars['BigInt']>;
  items2_3_gt?: InputMaybe<Scalars['BigInt']>;
  items2_3_lt?: InputMaybe<Scalars['BigInt']>;
  items2_3_gte?: InputMaybe<Scalars['BigInt']>;
  items2_3_lte?: InputMaybe<Scalars['BigInt']>;
  items2_3_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items2_3_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items4_10?: InputMaybe<Scalars['BigInt']>;
  items4_10_not?: InputMaybe<Scalars['BigInt']>;
  items4_10_gt?: InputMaybe<Scalars['BigInt']>;
  items4_10_lt?: InputMaybe<Scalars['BigInt']>;
  items4_10_gte?: InputMaybe<Scalars['BigInt']>;
  items4_10_lte?: InputMaybe<Scalars['BigInt']>;
  items4_10_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items4_10_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items11_25?: InputMaybe<Scalars['BigInt']>;
  items11_25_not?: InputMaybe<Scalars['BigInt']>;
  items11_25_gt?: InputMaybe<Scalars['BigInt']>;
  items11_25_lt?: InputMaybe<Scalars['BigInt']>;
  items11_25_gte?: InputMaybe<Scalars['BigInt']>;
  items11_25_lte?: InputMaybe<Scalars['BigInt']>;
  items11_25_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items11_25_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items26_50?: InputMaybe<Scalars['BigInt']>;
  items26_50_not?: InputMaybe<Scalars['BigInt']>;
  items26_50_gt?: InputMaybe<Scalars['BigInt']>;
  items26_50_lt?: InputMaybe<Scalars['BigInt']>;
  items26_50_gte?: InputMaybe<Scalars['BigInt']>;
  items26_50_lte?: InputMaybe<Scalars['BigInt']>;
  items26_50_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items26_50_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items51?: InputMaybe<Scalars['BigInt']>;
  items51_not?: InputMaybe<Scalars['BigInt']>;
  items51_gt?: InputMaybe<Scalars['BigInt']>;
  items51_lt?: InputMaybe<Scalars['BigInt']>;
  items51_gte?: InputMaybe<Scalars['BigInt']>;
  items51_lte?: InputMaybe<Scalars['BigInt']>;
  items51_in?: InputMaybe<Array<Scalars['BigInt']>>;
  items51_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  total?: InputMaybe<Scalars['BigInt']>;
  total_not?: InputMaybe<Scalars['BigInt']>;
  total_gt?: InputMaybe<Scalars['BigInt']>;
  total_lt?: InputMaybe<Scalars['BigInt']>;
  total_gte?: InputMaybe<Scalars['BigInt']>;
  total_lte?: InputMaybe<Scalars['BigInt']>;
  total_in?: InputMaybe<Array<Scalars['BigInt']>>;
  total_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<HolderStatsData_filter>>>;
  or?: InputMaybe<Array<InputMaybe<HolderStatsData_filter>>>;
};

export type HolderStatsData_orderBy =
  | 'id'
  | 'items1'
  | 'items2_3'
  | 'items4_10'
  | 'items11_25'
  | 'items26_50'
  | 'items51'
  | 'total';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type Query = {
  battleEvent?: Maybe<BattleEvent>;
  battleEvents: Array<BattleEvent>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  wallet?: Maybe<Wallet>;
  wallets: Array<Wallet>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  holderStatsData?: Maybe<HolderStatsData>;
  holderStatsDatas: Array<HolderStatsData>;
  supplyDataPoint?: Maybe<SupplyDataPoint>;
  supplyDataPoints: Array<SupplyDataPoint>;
  epochCounter?: Maybe<EpochCounter>;
  epochCounters: Array<EpochCounter>;
  epoch?: Maybe<Epoch>;
  epoches: Array<Epoch>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QuerybattleEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybattleEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BattleEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BattleEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywalletArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywalletsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Wallet_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Wallet_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontractArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontractsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Contract_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Contract_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransferArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransfersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transfer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transfer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryholderStatsDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryholderStatsDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HolderStatsData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<HolderStatsData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysupplyDataPointArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysupplyDataPointsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SupplyDataPoint_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SupplyDataPoint_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryepochCounterArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryepochCountersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EpochCounter_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<EpochCounter_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryepochArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryepochesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Epoch_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Epoch_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type Subscription = {
  battleEvent?: Maybe<BattleEvent>;
  battleEvents: Array<BattleEvent>;
  token?: Maybe<Token>;
  tokens: Array<Token>;
  wallet?: Maybe<Wallet>;
  wallets: Array<Wallet>;
  contract?: Maybe<Contract>;
  contracts: Array<Contract>;
  transfer?: Maybe<Transfer>;
  transfers: Array<Transfer>;
  holderStatsData?: Maybe<HolderStatsData>;
  holderStatsDatas: Array<HolderStatsData>;
  supplyDataPoint?: Maybe<SupplyDataPoint>;
  supplyDataPoints: Array<SupplyDataPoint>;
  epochCounter?: Maybe<EpochCounter>;
  epochCounters: Array<EpochCounter>;
  epoch?: Maybe<Epoch>;
  epoches: Array<Epoch>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionbattleEventArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbattleEventsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BattleEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BattleEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwalletArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwalletsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Wallet_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Wallet_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontractArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontractsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Contract_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Contract_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransferArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransfersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transfer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transfer_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionholderStatsDataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionholderStatsDatasArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<HolderStatsData_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<HolderStatsData_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsupplyDataPointArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsupplyDataPointsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<SupplyDataPoint_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SupplyDataPoint_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionepochCounterArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionepochCountersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<EpochCounter_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<EpochCounter_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionepochArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionepochesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Epoch_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Epoch_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type SupplyDataPoint = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
  block: Scalars['BigInt'];
  totalSupply: Scalars['BigInt'];
};

export type SupplyDataPoint_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalSupply?: InputMaybe<Scalars['BigInt']>;
  totalSupply_not?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lt?: InputMaybe<Scalars['BigInt']>;
  totalSupply_gte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_lte?: InputMaybe<Scalars['BigInt']>;
  totalSupply_in?: InputMaybe<Array<Scalars['BigInt']>>;
  totalSupply_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SupplyDataPoint_filter>>>;
  or?: InputMaybe<Array<InputMaybe<SupplyDataPoint_filter>>>;
};

export type SupplyDataPoint_orderBy =
  | 'id'
  | 'timestamp'
  | 'block'
  | 'totalSupply';

export type Token = {
  id: Scalars['ID'];
  owner?: Maybe<Wallet>;
  minter?: Maybe<Wallet>;
  opener?: Maybe<Wallet>;
  uri?: Maybe<Scalars['String']>;
  transfers: Array<Transfer>;
  contract?: Maybe<Contract>;
  epoch?: Maybe<Epoch>;
};


export type TokentransfersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Transfer_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transfer_filter>;
};

export type Token_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner?: InputMaybe<Scalars['String']>;
  owner_not?: InputMaybe<Scalars['String']>;
  owner_gt?: InputMaybe<Scalars['String']>;
  owner_lt?: InputMaybe<Scalars['String']>;
  owner_gte?: InputMaybe<Scalars['String']>;
  owner_lte?: InputMaybe<Scalars['String']>;
  owner_in?: InputMaybe<Array<Scalars['String']>>;
  owner_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_contains?: InputMaybe<Scalars['String']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_not_contains?: InputMaybe<Scalars['String']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_starts_with?: InputMaybe<Scalars['String']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_ends_with?: InputMaybe<Scalars['String']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_?: InputMaybe<Wallet_filter>;
  minter?: InputMaybe<Scalars['String']>;
  minter_not?: InputMaybe<Scalars['String']>;
  minter_gt?: InputMaybe<Scalars['String']>;
  minter_lt?: InputMaybe<Scalars['String']>;
  minter_gte?: InputMaybe<Scalars['String']>;
  minter_lte?: InputMaybe<Scalars['String']>;
  minter_in?: InputMaybe<Array<Scalars['String']>>;
  minter_not_in?: InputMaybe<Array<Scalars['String']>>;
  minter_contains?: InputMaybe<Scalars['String']>;
  minter_contains_nocase?: InputMaybe<Scalars['String']>;
  minter_not_contains?: InputMaybe<Scalars['String']>;
  minter_not_contains_nocase?: InputMaybe<Scalars['String']>;
  minter_starts_with?: InputMaybe<Scalars['String']>;
  minter_starts_with_nocase?: InputMaybe<Scalars['String']>;
  minter_not_starts_with?: InputMaybe<Scalars['String']>;
  minter_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  minter_ends_with?: InputMaybe<Scalars['String']>;
  minter_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minter_not_ends_with?: InputMaybe<Scalars['String']>;
  minter_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minter_?: InputMaybe<Wallet_filter>;
  opener?: InputMaybe<Scalars['String']>;
  opener_not?: InputMaybe<Scalars['String']>;
  opener_gt?: InputMaybe<Scalars['String']>;
  opener_lt?: InputMaybe<Scalars['String']>;
  opener_gte?: InputMaybe<Scalars['String']>;
  opener_lte?: InputMaybe<Scalars['String']>;
  opener_in?: InputMaybe<Array<Scalars['String']>>;
  opener_not_in?: InputMaybe<Array<Scalars['String']>>;
  opener_contains?: InputMaybe<Scalars['String']>;
  opener_contains_nocase?: InputMaybe<Scalars['String']>;
  opener_not_contains?: InputMaybe<Scalars['String']>;
  opener_not_contains_nocase?: InputMaybe<Scalars['String']>;
  opener_starts_with?: InputMaybe<Scalars['String']>;
  opener_starts_with_nocase?: InputMaybe<Scalars['String']>;
  opener_not_starts_with?: InputMaybe<Scalars['String']>;
  opener_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  opener_ends_with?: InputMaybe<Scalars['String']>;
  opener_ends_with_nocase?: InputMaybe<Scalars['String']>;
  opener_not_ends_with?: InputMaybe<Scalars['String']>;
  opener_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  opener_?: InputMaybe<Wallet_filter>;
  uri?: InputMaybe<Scalars['String']>;
  uri_not?: InputMaybe<Scalars['String']>;
  uri_gt?: InputMaybe<Scalars['String']>;
  uri_lt?: InputMaybe<Scalars['String']>;
  uri_gte?: InputMaybe<Scalars['String']>;
  uri_lte?: InputMaybe<Scalars['String']>;
  uri_in?: InputMaybe<Array<Scalars['String']>>;
  uri_not_in?: InputMaybe<Array<Scalars['String']>>;
  uri_contains?: InputMaybe<Scalars['String']>;
  uri_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_not_contains?: InputMaybe<Scalars['String']>;
  uri_not_contains_nocase?: InputMaybe<Scalars['String']>;
  uri_starts_with?: InputMaybe<Scalars['String']>;
  uri_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri_not_starts_with?: InputMaybe<Scalars['String']>;
  uri_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  uri_ends_with?: InputMaybe<Scalars['String']>;
  uri_ends_with_nocase?: InputMaybe<Scalars['String']>;
  uri_not_ends_with?: InputMaybe<Scalars['String']>;
  uri_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transfers_?: InputMaybe<Transfer_filter>;
  contract?: InputMaybe<Scalars['String']>;
  contract_not?: InputMaybe<Scalars['String']>;
  contract_gt?: InputMaybe<Scalars['String']>;
  contract_lt?: InputMaybe<Scalars['String']>;
  contract_gte?: InputMaybe<Scalars['String']>;
  contract_lte?: InputMaybe<Scalars['String']>;
  contract_in?: InputMaybe<Array<Scalars['String']>>;
  contract_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_contains?: InputMaybe<Scalars['String']>;
  contract_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_not_contains?: InputMaybe<Scalars['String']>;
  contract_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_starts_with?: InputMaybe<Scalars['String']>;
  contract_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_ends_with?: InputMaybe<Scalars['String']>;
  contract_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_?: InputMaybe<Contract_filter>;
  epoch?: InputMaybe<Scalars['String']>;
  epoch_not?: InputMaybe<Scalars['String']>;
  epoch_gt?: InputMaybe<Scalars['String']>;
  epoch_lt?: InputMaybe<Scalars['String']>;
  epoch_gte?: InputMaybe<Scalars['String']>;
  epoch_lte?: InputMaybe<Scalars['String']>;
  epoch_in?: InputMaybe<Array<Scalars['String']>>;
  epoch_not_in?: InputMaybe<Array<Scalars['String']>>;
  epoch_contains?: InputMaybe<Scalars['String']>;
  epoch_contains_nocase?: InputMaybe<Scalars['String']>;
  epoch_not_contains?: InputMaybe<Scalars['String']>;
  epoch_not_contains_nocase?: InputMaybe<Scalars['String']>;
  epoch_starts_with?: InputMaybe<Scalars['String']>;
  epoch_starts_with_nocase?: InputMaybe<Scalars['String']>;
  epoch_not_starts_with?: InputMaybe<Scalars['String']>;
  epoch_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  epoch_ends_with?: InputMaybe<Scalars['String']>;
  epoch_ends_with_nocase?: InputMaybe<Scalars['String']>;
  epoch_not_ends_with?: InputMaybe<Scalars['String']>;
  epoch_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  epoch_?: InputMaybe<Epoch_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Token_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Token_filter>>>;
};

export type Token_orderBy =
  | 'id'
  | 'owner'
  | 'owner__id'
  | 'owner__balance'
  | 'minter'
  | 'minter__id'
  | 'minter__balance'
  | 'opener'
  | 'opener__id'
  | 'opener__balance'
  | 'uri'
  | 'transfers'
  | 'contract'
  | 'contract__id'
  | 'contract__name'
  | 'contract__symbol'
  | 'contract__totalSupply'
  | 'epoch'
  | 'epoch__id'
  | 'epoch__number'
  | 'epoch__startBlock'
  | 'epoch__endBlock'
  | 'epoch__timestamp'
  | 'epoch__randomness';

export type Transaction = {
  id: Scalars['ID'];
};

export type Transaction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
};

export type Transaction_orderBy =
  | 'id';

export type Transfer = {
  id: Scalars['ID'];
  token: Token;
  from?: Maybe<Wallet>;
  to?: Maybe<Wallet>;
  timestamp: Scalars['BigInt'];
  block: Scalars['BigInt'];
  transactionHash: Scalars['String'];
};

export type Transfer_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  token?: InputMaybe<Scalars['String']>;
  token_not?: InputMaybe<Scalars['String']>;
  token_gt?: InputMaybe<Scalars['String']>;
  token_lt?: InputMaybe<Scalars['String']>;
  token_gte?: InputMaybe<Scalars['String']>;
  token_lte?: InputMaybe<Scalars['String']>;
  token_in?: InputMaybe<Array<Scalars['String']>>;
  token_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_contains?: InputMaybe<Scalars['String']>;
  token_contains_nocase?: InputMaybe<Scalars['String']>;
  token_not_contains?: InputMaybe<Scalars['String']>;
  token_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_starts_with?: InputMaybe<Scalars['String']>;
  token_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_starts_with?: InputMaybe<Scalars['String']>;
  token_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_ends_with?: InputMaybe<Scalars['String']>;
  token_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_not_ends_with?: InputMaybe<Scalars['String']>;
  token_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_?: InputMaybe<Token_filter>;
  from?: InputMaybe<Scalars['String']>;
  from_not?: InputMaybe<Scalars['String']>;
  from_gt?: InputMaybe<Scalars['String']>;
  from_lt?: InputMaybe<Scalars['String']>;
  from_gte?: InputMaybe<Scalars['String']>;
  from_lte?: InputMaybe<Scalars['String']>;
  from_in?: InputMaybe<Array<Scalars['String']>>;
  from_not_in?: InputMaybe<Array<Scalars['String']>>;
  from_contains?: InputMaybe<Scalars['String']>;
  from_contains_nocase?: InputMaybe<Scalars['String']>;
  from_not_contains?: InputMaybe<Scalars['String']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']>;
  from_starts_with?: InputMaybe<Scalars['String']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from_not_starts_with?: InputMaybe<Scalars['String']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  from_ends_with?: InputMaybe<Scalars['String']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_not_ends_with?: InputMaybe<Scalars['String']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  from_?: InputMaybe<Wallet_filter>;
  to?: InputMaybe<Scalars['String']>;
  to_not?: InputMaybe<Scalars['String']>;
  to_gt?: InputMaybe<Scalars['String']>;
  to_lt?: InputMaybe<Scalars['String']>;
  to_gte?: InputMaybe<Scalars['String']>;
  to_lte?: InputMaybe<Scalars['String']>;
  to_in?: InputMaybe<Array<Scalars['String']>>;
  to_not_in?: InputMaybe<Array<Scalars['String']>>;
  to_contains?: InputMaybe<Scalars['String']>;
  to_contains_nocase?: InputMaybe<Scalars['String']>;
  to_not_contains?: InputMaybe<Scalars['String']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']>;
  to_starts_with?: InputMaybe<Scalars['String']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_starts_with?: InputMaybe<Scalars['String']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  to_ends_with?: InputMaybe<Scalars['String']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_not_ends_with?: InputMaybe<Scalars['String']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  to_?: InputMaybe<Wallet_filter>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block?: InputMaybe<Scalars['BigInt']>;
  block_not?: InputMaybe<Scalars['BigInt']>;
  block_gt?: InputMaybe<Scalars['BigInt']>;
  block_lt?: InputMaybe<Scalars['BigInt']>;
  block_gte?: InputMaybe<Scalars['BigInt']>;
  block_lte?: InputMaybe<Scalars['BigInt']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']>>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transactionHash?: InputMaybe<Scalars['String']>;
  transactionHash_not?: InputMaybe<Scalars['String']>;
  transactionHash_gt?: InputMaybe<Scalars['String']>;
  transactionHash_lt?: InputMaybe<Scalars['String']>;
  transactionHash_gte?: InputMaybe<Scalars['String']>;
  transactionHash_lte?: InputMaybe<Scalars['String']>;
  transactionHash_in?: InputMaybe<Array<Scalars['String']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['String']>>;
  transactionHash_contains?: InputMaybe<Scalars['String']>;
  transactionHash_contains_nocase?: InputMaybe<Scalars['String']>;
  transactionHash_not_contains?: InputMaybe<Scalars['String']>;
  transactionHash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  transactionHash_starts_with?: InputMaybe<Scalars['String']>;
  transactionHash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transactionHash_not_starts_with?: InputMaybe<Scalars['String']>;
  transactionHash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transactionHash_ends_with?: InputMaybe<Scalars['String']>;
  transactionHash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transactionHash_not_ends_with?: InputMaybe<Scalars['String']>;
  transactionHash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transfer_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transfer_filter>>>;
};

export type Transfer_orderBy =
  | 'id'
  | 'token'
  | 'token__id'
  | 'token__uri'
  | 'from'
  | 'from__id'
  | 'from__balance'
  | 'to'
  | 'to__id'
  | 'to__balance'
  | 'timestamp'
  | 'block'
  | 'transactionHash';

export type Wallet = {
  id: Scalars['ID'];
  ownedTokens: Array<Token>;
  mintedTokens: Array<Token>;
  balance?: Maybe<Scalars['BigInt']>;
};


export type WalletownedTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
};


export type WalletmintedTokensArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Token_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Token_filter>;
};

export type Wallet_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  ownedTokens_?: InputMaybe<Token_filter>;
  mintedTokens_?: InputMaybe<Token_filter>;
  balance?: InputMaybe<Scalars['BigInt']>;
  balance_not?: InputMaybe<Scalars['BigInt']>;
  balance_gt?: InputMaybe<Scalars['BigInt']>;
  balance_lt?: InputMaybe<Scalars['BigInt']>;
  balance_gte?: InputMaybe<Scalars['BigInt']>;
  balance_lte?: InputMaybe<Scalars['BigInt']>;
  balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Wallet_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Wallet_filter>>>;
};

export type Wallet_orderBy =
  | 'id'
  | 'ownedTokens'
  | 'mintedTokens'
  | 'balance';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  BattleEvent: ResolverTypeWrapper<BattleEvent>;
  BattleEvent_filter: BattleEvent_filter;
  BattleEvent_orderBy: BattleEvent_orderBy;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  Contract: ResolverTypeWrapper<Contract>;
  Contract_filter: Contract_filter;
  Contract_orderBy: Contract_orderBy;
  Epoch: ResolverTypeWrapper<Epoch>;
  EpochCounter: ResolverTypeWrapper<EpochCounter>;
  EpochCounter_filter: EpochCounter_filter;
  EpochCounter_orderBy: EpochCounter_orderBy;
  Epoch_filter: Epoch_filter;
  Epoch_orderBy: Epoch_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HolderStatsData: ResolverTypeWrapper<HolderStatsData>;
  HolderStatsData_filter: HolderStatsData_filter;
  HolderStatsData_orderBy: HolderStatsData_orderBy;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  OrderDirection: OrderDirection;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Subscription: ResolverTypeWrapper<{}>;
  SupplyDataPoint: ResolverTypeWrapper<SupplyDataPoint>;
  SupplyDataPoint_filter: SupplyDataPoint_filter;
  SupplyDataPoint_orderBy: SupplyDataPoint_orderBy;
  Token: ResolverTypeWrapper<Token>;
  Token_filter: Token_filter;
  Token_orderBy: Token_orderBy;
  Transaction: ResolverTypeWrapper<Transaction>;
  Transaction_filter: Transaction_filter;
  Transaction_orderBy: Transaction_orderBy;
  Transfer: ResolverTypeWrapper<Transfer>;
  Transfer_filter: Transfer_filter;
  Transfer_orderBy: Transfer_orderBy;
  Wallet: ResolverTypeWrapper<Wallet>;
  Wallet_filter: Wallet_filter;
  Wallet_orderBy: Wallet_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  BattleEvent: BattleEvent;
  BattleEvent_filter: BattleEvent_filter;
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  Bytes: Scalars['Bytes'];
  Contract: Contract;
  Contract_filter: Contract_filter;
  Epoch: Epoch;
  EpochCounter: EpochCounter;
  EpochCounter_filter: EpochCounter_filter;
  Epoch_filter: Epoch_filter;
  Float: Scalars['Float'];
  HolderStatsData: HolderStatsData;
  HolderStatsData_filter: HolderStatsData_filter;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  Query: {};
  String: Scalars['String'];
  Subscription: {};
  SupplyDataPoint: SupplyDataPoint;
  SupplyDataPoint_filter: SupplyDataPoint_filter;
  Token: Token;
  Token_filter: Token_filter;
  Transaction: Transaction;
  Transaction_filter: Transaction_filter;
  Transfer: Transfer;
  Transfer_filter: Transfer_filter;
  Wallet: Wallet;
  Wallet_filter: Wallet_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext & { chainName: string }, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext & { chainName: string }, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext & { chainName: string }, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type BattleEventResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['BattleEvent'] = ResolversParentTypes['BattleEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  matchId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  action?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  player?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  opponent?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  monster?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  opponentMonster?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  round?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type ContractResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  symbol?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalSupply?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  mintedTokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<ContractmintedTokensArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EpochResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Epoch'] = ResolversParentTypes['Epoch']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startBlock?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endBlock?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  randomness?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<EpochtokensArgs, 'skip' | 'first'>>;
  tokenIds?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EpochCounterResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['EpochCounter'] = ResolversParentTypes['EpochCounter']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  count?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HolderStatsDataResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['HolderStatsData'] = ResolversParentTypes['HolderStatsData']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  items1?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  items2_3?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  items4_10?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  items11_25?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  items26_50?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  items51?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type QueryResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  battleEvent?: Resolver<Maybe<ResolversTypes['BattleEvent']>, ParentType, ContextType, RequireFields<QuerybattleEventArgs, 'id' | 'subgraphError'>>;
  battleEvents?: Resolver<Array<ResolversTypes['BattleEvent']>, ParentType, ContextType, RequireFields<QuerybattleEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: Resolver<Maybe<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QuerytokenArgs, 'id' | 'subgraphError'>>;
  tokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<QuerytokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  wallet?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType, RequireFields<QuerywalletArgs, 'id' | 'subgraphError'>>;
  wallets?: Resolver<Array<ResolversTypes['Wallet']>, ParentType, ContextType, RequireFields<QuerywalletsArgs, 'skip' | 'first' | 'subgraphError'>>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<QuerycontractArgs, 'id' | 'subgraphError'>>;
  contracts?: Resolver<Array<ResolversTypes['Contract']>, ParentType, ContextType, RequireFields<QuerycontractsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transfer?: Resolver<Maybe<ResolversTypes['Transfer']>, ParentType, ContextType, RequireFields<QuerytransferArgs, 'id' | 'subgraphError'>>;
  transfers?: Resolver<Array<ResolversTypes['Transfer']>, ParentType, ContextType, RequireFields<QuerytransfersArgs, 'skip' | 'first' | 'subgraphError'>>;
  holderStatsData?: Resolver<Maybe<ResolversTypes['HolderStatsData']>, ParentType, ContextType, RequireFields<QueryholderStatsDataArgs, 'id' | 'subgraphError'>>;
  holderStatsDatas?: Resolver<Array<ResolversTypes['HolderStatsData']>, ParentType, ContextType, RequireFields<QueryholderStatsDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
  supplyDataPoint?: Resolver<Maybe<ResolversTypes['SupplyDataPoint']>, ParentType, ContextType, RequireFields<QuerysupplyDataPointArgs, 'id' | 'subgraphError'>>;
  supplyDataPoints?: Resolver<Array<ResolversTypes['SupplyDataPoint']>, ParentType, ContextType, RequireFields<QuerysupplyDataPointsArgs, 'skip' | 'first' | 'subgraphError'>>;
  epochCounter?: Resolver<Maybe<ResolversTypes['EpochCounter']>, ParentType, ContextType, RequireFields<QueryepochCounterArgs, 'id' | 'subgraphError'>>;
  epochCounters?: Resolver<Array<ResolversTypes['EpochCounter']>, ParentType, ContextType, RequireFields<QueryepochCountersArgs, 'skip' | 'first' | 'subgraphError'>>;
  epoch?: Resolver<Maybe<ResolversTypes['Epoch']>, ParentType, ContextType, RequireFields<QueryepochArgs, 'id' | 'subgraphError'>>;
  epoches?: Resolver<Array<ResolversTypes['Epoch']>, ParentType, ContextType, RequireFields<QueryepochesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: Resolver<Maybe<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  battleEvent?: SubscriptionResolver<Maybe<ResolversTypes['BattleEvent']>, "battleEvent", ParentType, ContextType, RequireFields<SubscriptionbattleEventArgs, 'id' | 'subgraphError'>>;
  battleEvents?: SubscriptionResolver<Array<ResolversTypes['BattleEvent']>, "battleEvents", ParentType, ContextType, RequireFields<SubscriptionbattleEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  token?: SubscriptionResolver<Maybe<ResolversTypes['Token']>, "token", ParentType, ContextType, RequireFields<SubscriptiontokenArgs, 'id' | 'subgraphError'>>;
  tokens?: SubscriptionResolver<Array<ResolversTypes['Token']>, "tokens", ParentType, ContextType, RequireFields<SubscriptiontokensArgs, 'skip' | 'first' | 'subgraphError'>>;
  wallet?: SubscriptionResolver<Maybe<ResolversTypes['Wallet']>, "wallet", ParentType, ContextType, RequireFields<SubscriptionwalletArgs, 'id' | 'subgraphError'>>;
  wallets?: SubscriptionResolver<Array<ResolversTypes['Wallet']>, "wallets", ParentType, ContextType, RequireFields<SubscriptionwalletsArgs, 'skip' | 'first' | 'subgraphError'>>;
  contract?: SubscriptionResolver<Maybe<ResolversTypes['Contract']>, "contract", ParentType, ContextType, RequireFields<SubscriptioncontractArgs, 'id' | 'subgraphError'>>;
  contracts?: SubscriptionResolver<Array<ResolversTypes['Contract']>, "contracts", ParentType, ContextType, RequireFields<SubscriptioncontractsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transfer?: SubscriptionResolver<Maybe<ResolversTypes['Transfer']>, "transfer", ParentType, ContextType, RequireFields<SubscriptiontransferArgs, 'id' | 'subgraphError'>>;
  transfers?: SubscriptionResolver<Array<ResolversTypes['Transfer']>, "transfers", ParentType, ContextType, RequireFields<SubscriptiontransfersArgs, 'skip' | 'first' | 'subgraphError'>>;
  holderStatsData?: SubscriptionResolver<Maybe<ResolversTypes['HolderStatsData']>, "holderStatsData", ParentType, ContextType, RequireFields<SubscriptionholderStatsDataArgs, 'id' | 'subgraphError'>>;
  holderStatsDatas?: SubscriptionResolver<Array<ResolversTypes['HolderStatsData']>, "holderStatsDatas", ParentType, ContextType, RequireFields<SubscriptionholderStatsDatasArgs, 'skip' | 'first' | 'subgraphError'>>;
  supplyDataPoint?: SubscriptionResolver<Maybe<ResolversTypes['SupplyDataPoint']>, "supplyDataPoint", ParentType, ContextType, RequireFields<SubscriptionsupplyDataPointArgs, 'id' | 'subgraphError'>>;
  supplyDataPoints?: SubscriptionResolver<Array<ResolversTypes['SupplyDataPoint']>, "supplyDataPoints", ParentType, ContextType, RequireFields<SubscriptionsupplyDataPointsArgs, 'skip' | 'first' | 'subgraphError'>>;
  epochCounter?: SubscriptionResolver<Maybe<ResolversTypes['EpochCounter']>, "epochCounter", ParentType, ContextType, RequireFields<SubscriptionepochCounterArgs, 'id' | 'subgraphError'>>;
  epochCounters?: SubscriptionResolver<Array<ResolversTypes['EpochCounter']>, "epochCounters", ParentType, ContextType, RequireFields<SubscriptionepochCountersArgs, 'skip' | 'first' | 'subgraphError'>>;
  epoch?: SubscriptionResolver<Maybe<ResolversTypes['Epoch']>, "epoch", ParentType, ContextType, RequireFields<SubscriptionepochArgs, 'id' | 'subgraphError'>>;
  epoches?: SubscriptionResolver<Array<ResolversTypes['Epoch']>, "epoches", ParentType, ContextType, RequireFields<SubscriptionepochesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: SubscriptionResolver<Maybe<ResolversTypes['Transaction']>, "transaction", ParentType, ContextType, RequireFields<SubscriptiontransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: SubscriptionResolver<Array<ResolversTypes['Transaction']>, "transactions", ParentType, ContextType, RequireFields<SubscriptiontransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
}>;

export type SupplyDataPointResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['SupplyDataPoint'] = ResolversParentTypes['SupplyDataPoint']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalSupply?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TokenResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Token'] = ResolversParentTypes['Token']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  minter?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  opener?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  uri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transfers?: Resolver<Array<ResolversTypes['Transfer']>, ParentType, ContextType, RequireFields<TokentransfersArgs, 'skip' | 'first'>>;
  contract?: Resolver<Maybe<ResolversTypes['Contract']>, ParentType, ContextType>;
  epoch?: Resolver<Maybe<ResolversTypes['Epoch']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransferResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Transfer'] = ResolversParentTypes['Transfer']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Token'], ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Wallet']>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WalletResolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['Wallet'] = ResolversParentTypes['Wallet']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ownedTokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<WalletownedTokensArgs, 'skip' | 'first'>>;
  mintedTokens?: Resolver<Array<ResolversTypes['Token']>, ParentType, ContextType, RequireFields<WalletmintedTokensArgs, 'skip' | 'first'>>;
  balance?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext & { chainName: string }, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext & { chainName: string }> = ResolversObject<{
  BattleEvent?: BattleEventResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Bytes?: GraphQLScalarType;
  Contract?: ContractResolvers<ContextType>;
  Epoch?: EpochResolvers<ContextType>;
  EpochCounter?: EpochCounterResolvers<ContextType>;
  HolderStatsData?: HolderStatsDataResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SupplyDataPoint?: SupplyDataPointResolvers<ContextType>;
  Token?: TokenResolvers<ContextType>;
  Transaction?: TransactionResolvers<ContextType>;
  Transfer?: TransferResolvers<ContextType>;
  Wallet?: WalletResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext & { chainName: string }> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = Eip721Types.Context & BaseMeshContext;


import { fileURLToPath } from '@graphql-mesh/utils';
const baseDir = pathModule.join(pathModule.dirname(fileURLToPath(import.meta.url)), '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/eip721/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const eip721Transforms = [];
const additionalTypeDefs = [] as any[];
const eip721Handler = new GraphqlHandler({
              name: "eip721",
              config: {"endpoint":"https://graph-blast-testnet.testsystem.link/subgraphs/name/{context.chainName:bondingCurveMons}"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("eip721"),
              logger: logger.child("eip721"),
              importFn,
            });
sources[0] = {
          name: 'eip721',
          handler: eip721Handler,
          transforms: eip721Transforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: BattleEventsDocument,
        get rawSDL() {
          return printWithCache(BattleEventsDocument);
        },
        location: 'BattleEventsDocument.graphql'
      },{
        document: HolderStatsDatasDocument,
        get rawSDL() {
          return printWithCache(HolderStatsDatasDocument);
        },
        location: 'HolderStatsDatasDocument.graphql'
      },{
        document: SupplyDataPointsDocument,
        get rawSDL() {
          return printWithCache(SupplyDataPointsDocument);
        },
        location: 'SupplyDataPointsDocument.graphql'
      },{
        document: TokensByOwnerDocument,
        get rawSDL() {
          return printWithCache(TokensByOwnerDocument);
        },
        location: 'TokensByOwnerDocument.graphql'
      },{
        document: OpenedTokensByOpenerDocument,
        get rawSDL() {
          return printWithCache(OpenedTokensByOpenerDocument);
        },
        location: 'OpenedTokensByOpenerDocument.graphql'
      },{
        document: OpenedTokensDocument,
        get rawSDL() {
          return printWithCache(OpenedTokensDocument);
        },
        location: 'OpenedTokensDocument.graphql'
      },{
        document: OpenedTokensByOwnerNotBurnedDocument,
        get rawSDL() {
          return printWithCache(OpenedTokensByOwnerNotBurnedDocument);
        },
        location: 'OpenedTokensByOwnerNotBurnedDocument.graphql'
      },{
        document: TransfersByTransactionHashDocument,
        get rawSDL() {
          return printWithCache(TransfersByTransactionHashDocument);
        },
        location: 'TransfersByTransactionHashDocument.graphql'
      },{
        document: EpochsDocument,
        get rawSDL() {
          return printWithCache(EpochsDocument);
        },
        location: 'EpochsDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type BattleEventsQueryVariables = Exact<{
  matchId: Scalars['BigInt'];
}>;


export type BattleEventsQuery = { battleEvents: Array<Pick<BattleEvent, 'id' | 'matchId' | 'action' | 'data' | 'timestamp' | 'player' | 'opponent' | 'monster' | 'opponentMonster' | 'round'>> };

export type HolderStatsDatasQueryVariables = Exact<{
  id?: Scalars['ID'];
}>;


export type HolderStatsDatasQuery = { holderStatsDatas: Array<Pick<HolderStatsData, 'id' | 'items1' | 'items2_3' | 'items4_10' | 'items11_25' | 'items26_50' | 'items51' | 'total'>> };

export type SupplyDataPointsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type SupplyDataPointsQuery = { supplyDataPoints: Array<Pick<SupplyDataPoint, 'id' | 'timestamp' | 'totalSupply'>> };

export type TokensByOwnerQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  owner: Scalars['String'];
}>;


export type TokensByOwnerQuery = { tokens: Array<(
    Pick<Token, 'id' | 'uri'>
    & { minter?: Maybe<Pick<Wallet, 'id'>>, owner?: Maybe<Pick<Wallet, 'id'>>, epoch?: Maybe<Pick<Epoch, 'id'>> }
  )> };

export type OpenedTokensByOpenerQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  opener: Scalars['String'];
}>;


export type OpenedTokensByOpenerQuery = { tokens: Array<(
    Pick<Token, 'id' | 'uri'>
    & { minter?: Maybe<Pick<Wallet, 'id'>>, owner?: Maybe<Pick<Wallet, 'id'>>, opener?: Maybe<Pick<Wallet, 'id'>>, epoch?: Maybe<Pick<Epoch, 'startBlock' | 'endBlock' | 'timestamp' | 'randomness' | 'number'>> }
  )> };

export type OpenedTokensQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type OpenedTokensQuery = { tokens: Array<(
    Pick<Token, 'id' | 'uri'>
    & { minter?: Maybe<Pick<Wallet, 'id'>>, owner?: Maybe<Pick<Wallet, 'id'>>, opener?: Maybe<Pick<Wallet, 'id'>>, epoch?: Maybe<Pick<Epoch, 'startBlock' | 'endBlock' | 'timestamp' | 'randomness' | 'number'>> }
  )> };

export type OpenedTokensByOwnerNotBurnedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  owner: Scalars['String'];
}>;


export type OpenedTokensByOwnerNotBurnedQuery = { tokens: Array<(
    Pick<Token, 'id' | 'uri'>
    & { minter?: Maybe<Pick<Wallet, 'id'>>, owner?: Maybe<Pick<Wallet, 'id'>>, opener?: Maybe<Pick<Wallet, 'id'>>, epoch?: Maybe<Pick<Epoch, 'startBlock' | 'endBlock' | 'timestamp' | 'randomness' | 'number'>> }
  )> };

export type TransfersByTransactionHashQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  transactionHash: Scalars['String'];
}>;


export type TransfersByTransactionHashQuery = { transfers: Array<(
    Pick<Transfer, 'id' | 'transactionHash'>
    & { from?: Maybe<Pick<Wallet, 'id'>>, to?: Maybe<Pick<Wallet, 'id'>>, token: Pick<Token, 'id'> }
  )> };

export type EpochsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type EpochsQuery = { epoches: Array<Pick<Epoch, 'id' | 'startBlock' | 'endBlock' | 'timestamp' | 'randomness'>> };


export const BattleEventsDocument = gql`
    query BattleEvents($matchId: BigInt!) {
  battleEvents(where: {matchId: $matchId}) {
    id
    matchId
    action
    data
    timestamp
    player
    opponent
    monster
    opponentMonster
    round
  }
}
    ` as unknown as DocumentNode<BattleEventsQuery, BattleEventsQueryVariables>;
export const HolderStatsDatasDocument = gql`
    query HolderStatsDatas($id: ID! = "holderstats") {
  holderStatsDatas(where: {id: $id}) {
    id
    items1
    items2_3
    items4_10
    items11_25
    items26_50
    items51
    total
  }
}
    ` as unknown as DocumentNode<HolderStatsDatasQuery, HolderStatsDatasQueryVariables>;
export const SupplyDataPointsDocument = gql`
    query SupplyDataPoints($first: Int = 100, $skip: Int = 0) {
  supplyDataPoints(
    first: $first
    skip: $skip
    orderBy: timestamp
    orderDirection: desc
  ) {
    id
    timestamp
    totalSupply
  }
}
    ` as unknown as DocumentNode<SupplyDataPointsQuery, SupplyDataPointsQueryVariables>;
export const TokensByOwnerDocument = gql`
    query TokensByOwner($first: Int = 1000, $skip: Int = 0, $owner: String!) {
  tokens(first: $first, skip: $skip, where: {owner: $owner, epoch: null}) {
    id
    uri
    minter {
      id
    }
    owner {
      id
    }
    epoch {
      id
    }
  }
}
    ` as unknown as DocumentNode<TokensByOwnerQuery, TokensByOwnerQueryVariables>;
export const OpenedTokensByOpenerDocument = gql`
    query OpenedTokensByOpener($first: Int = 1000, $skip: Int = 0, $opener: String!) {
  tokens(
    first: $first
    skip: $skip
    where: {opener: $opener, epoch_: {startBlock_gt: 0}}
  ) {
    id
    uri
    minter {
      id
    }
    owner {
      id
    }
    opener {
      id
    }
    epoch {
      startBlock
      endBlock
      timestamp
      randomness
      number
    }
  }
}
    ` as unknown as DocumentNode<OpenedTokensByOpenerQuery, OpenedTokensByOpenerQueryVariables>;
export const OpenedTokensDocument = gql`
    query OpenedTokens($first: Int = 1000, $skip: Int = 0) {
  tokens(first: $first, skip: $skip, where: {epoch_: {startBlock_gt: 0}}) {
    id
    uri
    minter {
      id
    }
    owner {
      id
    }
    opener {
      id
    }
    epoch {
      startBlock
      endBlock
      timestamp
      randomness
      number
    }
  }
}
    ` as unknown as DocumentNode<OpenedTokensQuery, OpenedTokensQueryVariables>;
export const OpenedTokensByOwnerNotBurnedDocument = gql`
    query OpenedTokensByOwnerNotBurned($first: Int = 1000, $skip: Int = 0, $owner: String!) {
  tokens(
    first: $first
    skip: $skip
    where: {owner: $owner, epoch_: {startBlock_gt: 0}}
  ) {
    id
    uri
    minter {
      id
    }
    owner {
      id
    }
    opener {
      id
    }
    epoch {
      startBlock
      endBlock
      timestamp
      randomness
      number
    }
  }
}
    ` as unknown as DocumentNode<OpenedTokensByOwnerNotBurnedQuery, OpenedTokensByOwnerNotBurnedQueryVariables>;
export const TransfersByTransactionHashDocument = gql`
    query TransfersByTransactionHash($first: Int = 1000, $skip: Int = 0, $transactionHash: String!) {
  transfers(
    first: $first
    skip: $skip
    where: {transactionHash: $transactionHash}
  ) {
    id
    from {
      id
    }
    to {
      id
    }
    token {
      id
    }
    transactionHash
  }
}
    ` as unknown as DocumentNode<TransfersByTransactionHashQuery, TransfersByTransactionHashQueryVariables>;
export const EpochsDocument = gql`
    query Epochs($first: Int = 1000, $skip: Int = 0) {
  epoches(first: $first, skip: $skip) {
    id
    startBlock
    endBlock
    timestamp
    randomness
  }
}
    ` as unknown as DocumentNode<EpochsQuery, EpochsQueryVariables>;










export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    BattleEvents(variables: BattleEventsQueryVariables, options?: C): Promise<BattleEventsQuery> {
      return requester<BattleEventsQuery, BattleEventsQueryVariables>(BattleEventsDocument, variables, options) as Promise<BattleEventsQuery>;
    },
    HolderStatsDatas(variables?: HolderStatsDatasQueryVariables, options?: C): Promise<HolderStatsDatasQuery> {
      return requester<HolderStatsDatasQuery, HolderStatsDatasQueryVariables>(HolderStatsDatasDocument, variables, options) as Promise<HolderStatsDatasQuery>;
    },
    SupplyDataPoints(variables?: SupplyDataPointsQueryVariables, options?: C): Promise<SupplyDataPointsQuery> {
      return requester<SupplyDataPointsQuery, SupplyDataPointsQueryVariables>(SupplyDataPointsDocument, variables, options) as Promise<SupplyDataPointsQuery>;
    },
    TokensByOwner(variables: TokensByOwnerQueryVariables, options?: C): Promise<TokensByOwnerQuery> {
      return requester<TokensByOwnerQuery, TokensByOwnerQueryVariables>(TokensByOwnerDocument, variables, options) as Promise<TokensByOwnerQuery>;
    },
    OpenedTokensByOpener(variables: OpenedTokensByOpenerQueryVariables, options?: C): Promise<OpenedTokensByOpenerQuery> {
      return requester<OpenedTokensByOpenerQuery, OpenedTokensByOpenerQueryVariables>(OpenedTokensByOpenerDocument, variables, options) as Promise<OpenedTokensByOpenerQuery>;
    },
    OpenedTokens(variables?: OpenedTokensQueryVariables, options?: C): Promise<OpenedTokensQuery> {
      return requester<OpenedTokensQuery, OpenedTokensQueryVariables>(OpenedTokensDocument, variables, options) as Promise<OpenedTokensQuery>;
    },
    OpenedTokensByOwnerNotBurned(variables: OpenedTokensByOwnerNotBurnedQueryVariables, options?: C): Promise<OpenedTokensByOwnerNotBurnedQuery> {
      return requester<OpenedTokensByOwnerNotBurnedQuery, OpenedTokensByOwnerNotBurnedQueryVariables>(OpenedTokensByOwnerNotBurnedDocument, variables, options) as Promise<OpenedTokensByOwnerNotBurnedQuery>;
    },
    TransfersByTransactionHash(variables: TransfersByTransactionHashQueryVariables, options?: C): Promise<TransfersByTransactionHashQuery> {
      return requester<TransfersByTransactionHashQuery, TransfersByTransactionHashQueryVariables>(TransfersByTransactionHashDocument, variables, options) as Promise<TransfersByTransactionHashQuery>;
    },
    Epochs(variables?: EpochsQueryVariables, options?: C): Promise<EpochsQuery> {
      return requester<EpochsQuery, EpochsQueryVariables>(EpochsDocument, variables, options) as Promise<EpochsQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;