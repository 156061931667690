import { useContractRead } from "wagmi";
import { LeaderboardV1Abi, LeaderboardManagerV1Abi } from "../../abis";
import { contracts } from "../../contracts.ts";
import { useEffect, useState } from "react";
import { Address, zeroAddress } from "viem";
import { PLACEHOLDER_CHAIN_ID } from "../../../../chains.ts";

export type LeaderboardEntryFromChain = {
  stats: {
    wins: bigint;
    losses: bigint;
    escapes: bigint;
  };
  player: Address;
  username: string;
};

export type LeaderboardEntry = {
  wins: number;
  losses: number;
  escapes: number;
  player: Address;
  username: string;
};

export function useLeaderboard(chainId: string) {
  const [offset, setOffset] = useState(BigInt(0));
  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);

  const { data: leaderboardAddress } = useContractRead({
    address: contracts[chainId].leaderboardManager,
    abi: LeaderboardManagerV1Abi,
    functionName: "leaderboards",
    args: [BigInt(1)],
    enabled: chainId !== PLACEHOLDER_CHAIN_ID,
  });

  const {
    data,
    isLoading,
    refetch,
  }: {
    data: LeaderboardEntryFromChain[] | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: leaderboardAddress as Address,
    abi: LeaderboardV1Abi,
    functionName: "getAllStats",
    args: [offset],
    enabled: !!leaderboardAddress && leaderboardAddress !== zeroAddress,
  });

  useEffect(() => {
    if (data) {
      setLeaderboard((prev) => {
        return [
          ...prev,
          ...data.map((entry) => {
            return {
              wins: Number(entry.stats.wins),
              losses: Number(entry.stats.losses),
              escapes: Number(entry.stats.escapes),
              player: entry.player,
              username: entry.username,
            };
          }),
        ];
      });
    }
  }, [data]);

  useEffect(() => {
    if (leaderboard.length === Number(offset) + 100) {
      setOffset((prev) => prev + BigInt(100));
    }
  }, [leaderboard, offset]);

  return {
    leaderboard,
    isLoading,
    refetch,
  };
}
