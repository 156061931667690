import { useCallback, useContext, useEffect, useState } from "react";
import { GraphContext, HolderStats } from "../../../context/graphContext";

export const useFetchHolderStats = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [holderStats, setHolderStats] = useState<HolderStats | undefined>();

  const { getHolderStats } = useContext(GraphContext);

  const fetchHolderStats = useCallback(async () => {
    setIsLoading(true);
    const res = await getHolderStats();
    setHolderStats(res.holderStatsDatas[0]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchHolderStats();
  }, [fetchHolderStats]);

  const refetch = useCallback(() => {
    fetchHolderStats();
  }, [fetchHolderStats]);

  return { isLoading, refetch, holderStats };
};
