import React, { useEffect, useMemo, useState } from "react";

import { useCopyToClipboard } from "@uidotdev/usehooks";

import { shortenAddress } from "../../utility";
import { Button } from "..";
import { QuickDepositBoxProps } from "./quickDepositBoxProps.ts";

export const QuickDepositBox: React.FC<QuickDepositBoxProps> = ({
  address,
  onStart,
  isInProgress,
  isSwapping,
  isWaitingForConfirmation,
  isFinished,
  buttonVariant = "primary",
}) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();

  // Copy Wallet Address logic
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (isPressed) {
      timer = setTimeout(() => setIsPressed(false), 1000); // Reset after 1 second
    }
    return () => clearTimeout(timer);
  }, [isPressed]);

  const buttonClass = useMemo(() => {
    return isPressed
      ? "flex-none bg-background-nature text-text-light"
      : "flex-none";
  }, [isPressed]);

  const spanClass = useMemo(() => {
    return `text-base ${isPressed ? "text-text-light" : ""}`;
  }, [isPressed]);

  return (
    <div className="flex flex-row w-full p-4 space-x-2 rounded-lg backdrop-blur-sm bg-background-primary/60 border border-background-primary/20 shadow-sm">
      <div className="flex flex-1 flex-col">
        <span className="font-display text-sm">
          {isFinished
            ? "Finished!"
            : isSwapping
            ? "In progress…"
            : isWaitingForConfirmation
            ? "Deposit received"
            : isInProgress
            ? "Waiting for deposit…"
            : "Deposit"}
        </span>
        <span className="font-regular text-xs">
          {isFinished
            ? "Switch back to PMON Chain. It may take up to 20 minutes for your PMON to arrive."
            : isSwapping
            ? "Please wait"
            : isWaitingForConfirmation
            ? "Click to confirm bridging process."
            : isInProgress
            ? "To cancel, switch to PMON Chain using the dropdown below."
            : "Use ETH from Arbitrum One."}
        </span>
      </div>
      {(isWaitingForConfirmation || !isInProgress) && !isSwapping && (
        <Button
          variant={buttonVariant}
          size="sm"
          onClick={onStart}
          className={buttonClass}
          sound="copy"
        >
          <span className={spanClass}>
            {isFinished
              ? "Switch"
              : isWaitingForConfirmation
              ? "Confirm"
              : "Deposit"}
          </span>
        </Button>
      )}
    </div>
  );
};
