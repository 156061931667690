import { useContext, useEffect, useMemo, useState } from "react";
import { parseEther } from "viem";
import { Address, useBalance } from "wagmi";
import { AppContext } from "../../../context";
import { useInterval } from "react-use";
import { formatEthRounded } from "../../../utility";

const MIN_ETH = "0.01";
const MINIMUM_BALANCE = parseEther(MIN_ETH);
const MINIMUM_BALANCE_PMON = parseEther("10");

export const useEmbeddedWallet = (
  walletAddress?: Address,
  decimals: number = 6
) => {
  const { chainId } = useContext(AppContext);

  const {
    data: dataETH,
    isError: isErrorETH,
    isLoading: isLoadingETH,
    refetch,
  } = useBalance({
    address: walletAddress,
    chainId,
  });

  useInterval(() => {
    refetch();
  }, 10_000);

  const [enoughFunds, setEnoughFunds] = useState({
    eth: false,
    pmon: false,
  });

  useEffect(() => {
    if (!isLoadingETH && !isErrorETH && dataETH?.value) {
      setEnoughFunds((prev) => ({
        ...prev,
        eth: dataETH.value >= MINIMUM_BALANCE,
      }));
    }
  }, [dataETH, isErrorETH, isLoadingETH]);

  const balances = useMemo(
    () => ({
      eth: dataETH
        ? `${formatEthRounded(dataETH.formatted, decimals)} ${dataETH.symbol}`
        : "0 ETH",
    }),
    [dataETH, decimals]
  );

  return {
    enoughFunds,
    balances,
    native: dataETH?.value || BigInt(0),
    minimum: {
      eth: MIN_ETH,
      pmon: MINIMUM_BALANCE_PMON,
    },
  };
};
