import { ModalUpdate } from "../modalUpdate";
import { useEffect, useState } from "react";
import { useAppVersion } from "../../hooks";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { PLACEHOLDER_CHAIN_ID } from "../../chains.ts";

type AppVersionManagerProps = {
  isUpdating: boolean;
  onUpdate: () => void;
};

const noop = () => {};

export const AppVersionManager = ({
  isUpdating,
  onUpdate,
}: AppVersionManagerProps) => {
  const { wallet } = usePrivyWagmi();
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const { needsUpdate } = useAppVersion(
    wallet?.chainId || PLACEHOLDER_CHAIN_ID
  );

  useEffect(() => {
    if (needsUpdate) {
      setIsUpdateAvailable(true);
    } else {
      setIsUpdateAvailable(false);
    }
  }, [needsUpdate]);

  return (
    <ModalUpdate
      isVisible={isUpdateAvailable}
      isUpdating={isUpdating}
      onClose={noop}
      onReload={onUpdate}
    />
  );
};
