import { useCallback, useContext, useEffect, useState } from "react";
import {
  GraphContext,
  TransfersByTransactionHash,
} from "../../../context/graphContext";

export const useFetchTransfersByTransactionHash = (
  transactionHash?: string
): {
  isLoading: boolean;
  refetch: (txHash: string) => void;
  transfers: TransfersByTransactionHash[];
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [transfers, setTransfers] = useState<TransfersByTransactionHash[]>([]);

  const { getTransfersByTransactionHash } = useContext(GraphContext);

  const fetchTransfers = useCallback(async (txHash: string) => {
    setIsLoading(true);
    const res = await getTransfersByTransactionHash(txHash);
    setTransfers(res.transfers || []);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (transactionHash) {
      fetchTransfers(transactionHash);
    }
  }, [fetchTransfers, transactionHash]);

  const refetch = useCallback(
    (txHash: string) => {
      fetchTransfers(txHash);
    },
    [fetchTransfers]
  );

  return { isLoading, refetch, transfers };
};
