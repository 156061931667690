import { Chain } from "viem";
import { arbitrum } from "viem/chains";

export const PLACEHOLDER_CHAIN_ID = "0";

export const sapphire = {
  id: 23294,
  name: "Oasis Sapphire",
  network: "sapphire",
  nativeCurrency: {
    decimals: 18,
    name: "Rose",
    symbol: "ROSE",
  },
  rpcUrls: {
    public: {
      http: ["https://sapphire.oasis.io"],
      webSocket: ["wss://sapphire.oasis.io/ws"],
    },
    default: {
      http: ["https://sapphire.oasis.io"],
      webSocket: ["wss://sapphire.oasis.io/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Oasis Foundation",
      url: "https://explorer.sapphire.oasis.io/",
    },
  },
} as const satisfies Chain;

export const sapphireTestnet = {
  id: 23295,
  name: "Oasis Sapphire Testnet",
  network: "sapphireTest",
  nativeCurrency: {
    decimals: 18,
    name: "Rose",
    symbol: "ROSE",
  },
  rpcUrls: {
    public: {
      http: ["https://testnet.sapphire.oasis.dev"],
      webSocket: ["wss://testnet.sapphire.oasis.dev/ws"],
    },
    default: {
      http: ["https://testnet.sapphire.oasis.dev"],
      webSocket: ["wss://testnet.sapphire.oasis.dev/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Oasis Foundation",
      url: "https://testnet.explorer.sapphire.oasis.dev/",
    },
  },
} as const satisfies Chain;

export const polychainMonstersTestnet = {
  id: 16890849097,
  name: "Polychain Monsters Testnet",
  network: "polymonTest",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: ["https://polychain-monsters.alt.technology"],
      webSocket: ["wss://polychain-monsters.alt.technology/ws"],
    },
    default: {
      http: ["https://polychain-monsters.alt.technology"],
      webSocket: ["wss://polychain-monsters.alt.technology/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blockscout",
      url: "https://polychain-monsters-explorer.alt.technology",
    },
  },
} as const satisfies Chain;

export const arbitrumNova = {
  id: 42170,
  name: "Arbitrum Nova",
  network: "arbitrumNova",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: ["https://nova.arbitrum.io/rpc"],
      webSocket: ["wss://arbitrum-nova.publicnode.com"],
    },
    default: {
      http: ["https://nova.arbitrum.io/rpc"],
      webSocket: ["wss://arbitrum-nova.publicnode.com"],
    },
  },
  blockExplorers: {
    default: {
      name: "Arbiscan",
      url: "https://nova.arbiscan.io/",
    },
  },
} as const satisfies Chain;

export const blastTestnet = {
  id: 168587773,
  name: "Blast Testnet",
  network: "blastTestnet",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    public: {
      http: [import.meta.env.VITE_BLAST_RPC || "https://sepolia.blast.io"],
      // webSocket: ["wss://rpc.ankr.com/blast_testnet_sepolia/ws"],
    },
    default: {
      http: [import.meta.env.VITE_BLAST_RPC || "https://sepolia.blast.io"],
      // webSocket: ["wss://rpc.ankr.com/blast_testnet_sepolia/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "Blast Sepolia Explorer",
      url: "https://testnet.blastscan.io/",
    },
  },
} as const satisfies Chain;

export const pmon = {
  id: 42001,
  name: "PMON Chain",
  network: "pmonChain",
  nativeCurrency: {
    decimals: 18,
    name: "PMON",
    symbol: "PMON",
  },
  rpcUrls: {
    public: {
      http: ["https://rpc.pmon.xyz"],
      webSocket: ["wss://pmon.xyz/ws"],
    },
    default: {
      http: ["https://rpc.pmon.xyz"],
      webSocket: ["wss://pmon.xyz/ws"],
    },
  },
  blockExplorers: {
    default: {
      name: "PMON Explorer",
      url: "https://explorer.pmon.xyz/",
    },
  },
} as const satisfies Chain;

export const getChain = (chainId?: string): Chain => {
  switch (chainId) {
    case "eip155:16890849097":
    case "16890849097":
      return polychainMonstersTestnet;
    case "eip155:23294":
    case "23294":
      return sapphire;
    case "eip155:23295":
    case "23295":
      return sapphireTestnet;
    case "eip155:42161":
    case "42161":
      return arbitrum;
    case "42170":
      return arbitrumNova;
    case "eip155:42001":
    case "42001":
      return pmon;
    case "eip155:168587773":
    case "168587773":
      return blastTestnet;
    default:
      return pmon;
  }
};
