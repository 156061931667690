import { motion } from "framer-motion";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useLockedBody } from "usehooks-ts";

import { usePrivy } from "@privy-io/react-auth";
import { useToggle } from "@uidotdev/usehooks";

import IMAGES from "../../assets/images";
import { useAppVersion, useEmbeddedWallet, useUserName } from "../../hooks";
import { DropdownOption } from "../dropdown";
import { KeyValue } from "../keyValue";
import { Modal } from "../modal";
import { contentTransition, contentVariants } from "./modalProfileMotion";
import { ModalProfileProps } from "./modalProfileProps";
import {
  ActionBar,
  ActionSheet,
  Button,
  Dropdown,
  MonsterCard,
  Ping,
  QuickDepositBox,
  WalletAddressBox,
} from "..";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { AppContext } from "../../context";
import { getCleanChainId, getCleanChainIdString } from "../../utility";
import { arbitrum } from "viem/chains";
import { noop } from "lodash";
import {
  useCombinedBridgeProcess,
  useSwap,
} from "../../hooks/chain/swapAndBridge";
import { Address } from "wagmi";
import { parseEther } from "viem";
import { pmon } from "../../chains.ts";

const NETWORKS: DropdownOption[] = [
  {
    label: "PMON L3 Chain",
    value: 42001,
  },
  {
    label: "Chain: Oasis Sapphire",
    value: 23294,
  },
  {
    label: "Arbitrum (Bridging)",
    value: arbitrum.id,
  },
];

const MODE = parseInt(import.meta.env.VITE_GAME_MODE || "N/A");

export const ModalProfile: React.FC<ModalProfileProps> = ({
  userName = "",
  walletAddress,
  team = [],
  statistics = [],
  imageURI = IMAGES.trainer,
  isVisible,
  source = "battlePreparation",
  onClose,
}) => {
  const { wallet } = usePrivyWagmi();
  const { chainId, setChainId, setNoPromptOnSignature } =
    useContext(AppContext);
  const { version } = useAppVersion(getCleanChainIdString(chainId));
  const [network, setNetwork] = useState(
    NETWORKS.find((n) => n.value === chainId) || NETWORKS[0]
  );
  const handleSetNetwork = useCallback(
    (network: DropdownOption) => {
      const cleanChainId = getCleanChainId(network.value);
      setChainId(cleanChainId);
      localStorage.setItem("ocb:chainId", `${cleanChainId}`);
      wallet?.switchChain(cleanChainId);
      window.location.reload();
    },
    [setChainId, wallet]
  );

  const isDepositMode = chainId === arbitrum.id;

  useEffect(() => {
    setNoPromptOnSignature(isDepositMode);

    return () => {
      setNoPromptOnSignature(true);
    };
  }, [isDepositMode, setNoPromptOnSignature]);

  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const {
    persistUserName,
    isLoading,
    isAlreadyRegistered,
    setNewName,
    newName,
    refetch,
  } = useUserName(chainId, {
    address: walletAddress,
  });

  // Private Wallet logic
  const navigate = useNavigate();

  const { native, enoughFunds } = useEmbeddedWallet(walletAddress);

  // User Name Action Sheet
  const [actionSheetVisible, toggleActionSheetVisible] = useToggle(false);
  const [isInputFocused, toggleIsInputFocused] = useToggle(false);

  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (isAlreadyRegistered) {
      setError("Username already taken.");
    }
  }, [isAlreadyRegistered]);

  const isButtonDisabled = useMemo(() => {
    return newName === "" || newName === userName || error !== "" || isLoading;
  }, [newName, userName, error, isLoading]);

  const backgroundColor = useMemo(() => {
    if (isInputFocused) {
      return "bg-background-primary/80";
    }
    return "bg-background-primary/60";
  }, [isInputFocused]);

  const borderColor = useMemo(() => {
    return error === ""
      ? "border-background-primary"
      : "border-background-fire";
  }, [error]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 20) {
      setError("Username cannot exceed 20 characters.");
    } else if (/\s/.test(value)) {
      // Check for spaces using a regular expression
      setError("Username cannot contain spaces.");
    } else {
      setNewName(value);
      setError("");
    }
  };

  const { logout, ready, authenticated, user, exportWallet } = usePrivy();

  // Check that your user is authenticated
  const isAuthenticated = ready && authenticated;

  // Check that your user has an embedded wallet
  const hasEmbeddedWallet = !!user?.linkedAccounts.find(
    (account) =>
      account.type === "wallet" && account.walletClientType === "privy"
  );

  const handleLogoutAsync = useCallback(async () => {
    logout()
      .then(() => {
        localStorage.clear();
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [logout, navigate]);

  const { executeAll, currentStep } = useCombinedBridgeProcess({
    address: wallet?.address as Address,
    isEnabled: isDepositMode,
  });

  const onQuickDeposit = useCallback(() => {
    if (isDepositMode) {
      executeAll();
    } else {
      handleSetNetwork({
        label: "Arbitrum One",
        value: arbitrum.id,
      });
    }
  }, [executeAll, handleSetNetwork, isDepositMode]);

  return (
    <Modal isVisible={isVisible}>
      <div className="flex flex-1 flex-col justify-end px-2 space-y-4">
        <div className="flex flex-1 flex-col justify-center">
          <motion.div
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={contentTransition}
            className="relative flex p-2 rounded-3xl w-full bg-background-primary/50 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            <div className="flex flex-1 flex-col pt-4 px-2 pb-2 space-y-2 rounded-2xl bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
              <div className="flex flex-col items-center space-y-2">
                {!isDepositMode && (
                  <img
                    className="h-auto w-1/3 shadow-sm aspect-square rounded-full"
                    src={imageURI}
                  />
                )}

                <div
                  className="relative flex"
                  onClick={isDepositMode ? noop : toggleActionSheetVisible}
                >
                  <h2 className="text-lg text-center font-display font-bold">
                    {isDepositMode ? (
                      "Deposit Mode"
                    ) : (
                      <>{userName || "Your Name"}</>
                    )}
                  </h2>
                  {isDepositMode ? (
                    <Ping
                      color="bg-background-water"
                      className="absolute top-0 -right-2"
                    />
                  ) : (
                    !userName && <Ping className="absolute top-0 -right-2" />
                  )}
                </div>
              </div>

              {isDepositMode && (
                <div className="flex flex-col">
                  <p className="text-text-primary text-xs">
                    Send a minimum of <strong>0.001 ETH</strong> to the address
                    below on <strong>Arbitrum One</strong> for automatic
                    conversion to PMON, which will be deposited into your L3
                    account. Review the{" "}
                    <a
                      href="https://app.polychainmonsters.com/disclaimer"
                      target="_blank"
                      className="text-center underline"
                    >
                      Disclaimer
                    </a>{" "}
                    before proceeding. Deposits on other chains will be ignored.
                    The swap rate is the current rate on Uniswap.
                  </p>
                </div>
              )}

              <WalletAddressBox address={walletAddress} />

              {chainId === pmon.id ||
                (chainId === arbitrum.id && (
                  <QuickDepositBox
                    address={walletAddress}
                    onStart={onQuickDeposit}
                    isWaitingForConfirmation={
                      isDepositMode &&
                      native >= parseEther("0.001") &&
                      currentStep === 0
                    }
                    isInProgress={isDepositMode}
                    isSwapping={currentStep > 0}
                    isFinished={currentStep === 5}
                    buttonVariant={
                      native > 0n && !isDepositMode ? "light" : "primary"
                    }
                  />
                ))}

              {!isDepositMode && statistics?.length > 0 && (
                <div className="flex flex-row space-x-2">
                  {statistics.map((statistic) => (
                    <div
                      key={statistic.name}
                      className="flex flex-1 aspect-square items-center rounded-lg justify-center bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm"
                    >
                      <KeyValue
                        keyText={statistic.name}
                        valueText={statistic.value}
                      />
                    </div>
                  ))}
                </div>
              )}

              {version && (
                <div className="flex flex-row justify-center">
                  {MODE ? (
                    <span className="text-text-secondary text-xs p-2 text-center">
                      Version / Mode
                      <br />
                      {version} / {MODE}
                    </span>
                  ) : (
                    <span className="text-text-secondary text-xs p-2 text-center">
                      Version
                      <br />
                      {version}
                    </span>
                  )}
                </div>
              )}

              {team?.length > 0 && (
                <div className="flex flex-row space-x-2">
                  {team.map((monster) => (
                    <MonsterCard
                      key={monster.tokenId}
                      monster={monster}
                      className="rounded-lg"
                    />
                  ))}
                </div>
              )}
            </div>

            {!isDepositMode && (
              <Button
                variant="light"
                size="2xs"
                onClick={onClose}
                className="absolute -top-2 -right-2"
                sound="close"
              >
                <span className="material-symbols-rounded text-lg">close</span>
              </Button>
            )}
          </motion.div>
        </div>

        {source === "battlePreparation" && (
          <ActionBar variant="floating" className="rounded-3xl">
            <div className="flex flex-col flex-1 space-y-2">
              <Dropdown
                // label="Select Network"
                options={NETWORKS}
                selected={network}
                onSelect={handleSetNetwork}
              />
              <div className="flex flex-row flex-1 space-x-2">
                <Button
                  variant="light"
                  size="xs"
                  className="flex-1"
                  disabled={!isAuthenticated}
                  onClick={handleLogoutAsync}
                  sound="error"
                >
                  Logout
                </Button>
                <Button
                  variant="light"
                  size="xs"
                  className="flex-1"
                  disabled={!isAuthenticated || !hasEmbeddedWallet}
                  onClick={exportWallet}
                  sound="select"
                >
                  Export Wallet
                </Button>
              </div>
            </div>
          </ActionBar>
        )}
      </div>
      <ActionSheet
        title="Update User Name"
        isVisible={actionSheetVisible}
        onClose={() => toggleActionSheetVisible()}
      >
        <div className="flex flex-col space-y-4 pt-4">
          <div className="flex flex-col space-y-2 px-4">
            <div
              className={twMerge(
                "rounded-lg border-0.5 font-display font-bold text-lg w-full shadow-sm backdrop-blur-sm",
                backgroundColor,
                borderColor
              )}
            >
              <input
                type="text"
                value={newName}
                onChange={handleChange}
                onFocus={() => toggleIsInputFocused()}
                onBlur={() => toggleIsInputFocused()}
                placeholder={enoughFunds.eth ? "Your Name" : "Insufficient ETH"}
                disabled={isLoading || !enoughFunds.eth}
                className="p-4 text-text-primary placeholder-text-secondary bg-transparent w-full focus:ring-1 focus:ring-border-primary appearance-none outline-none"
              />
            </div>
            <p className="text-text-error text-sm font-bold">{error}</p>
          </div>

          <div className="flex flex-col px-4">
            <Button
              onClick={async () => {
                const res = await persistUserName?.();
                if (res?.hash) {
                  refetch?.();
                  toggleActionSheetVisible();
                }
              }}
              disabled={isButtonDisabled}
              sound="success"
            >
              Update
            </Button>
          </div>
        </div>
      </ActionSheet>
    </Modal>
  );
};
