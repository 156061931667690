import IMAGES from "../../../assets/images";
import { ButtonVariant } from "../../../components";
import { MonsterElement } from "../../../types";

const ELEMENT_BACKGROUND_IMAGES: Record<MonsterElement, string> = {
  [MonsterElement.None]: "background-default",
  [MonsterElement.Electric]: "background-default",
  [MonsterElement.Fire]: "background-default",
  [MonsterElement.Water]: "background-default",
  [MonsterElement.Nature]: "background-default",
  [MonsterElement.Toxic]: "background-default",
  [MonsterElement.Mental]: "background-default",
};

const ELEMENT_BUTTON_VARIANTS: Record<MonsterElement, ButtonVariant> = {
  [MonsterElement.None]: "light",
  [MonsterElement.Fire]: "fire",
  [MonsterElement.Mental]: "mental",
  [MonsterElement.Nature]: "nature",
  [MonsterElement.Toxic]: "toxic",
  [MonsterElement.Water]: "water",
  [MonsterElement.Electric]: "electric",
};

export const getBattleVisualsBackgroundImage = (
  element: MonsterElement
): string => {
  return ELEMENT_BACKGROUND_IMAGES[element] || "background-default";
};

export const getBattleVisualsButtonVariant = (
  element: MonsterElement
): ButtonVariant => {
  return ELEMENT_BUTTON_VARIANTS[element] || "light";
};
