import { useCallback, useEffect, useState, useMemo } from "react";
import { useInterval } from "usehooks-ts";
import { EventLoggerV1Abi } from "../abis";
import { useContractRead } from "wagmi";
import { Address } from "viem";
import { useContracts } from "./useContracts.ts";
import {
  AddStatusEffectLog,
  ApplyMonsterStatusEffectLog,
  ApplyMoveStatusEffectLog,
  ApplyOtherStatusEffectLog,
  ChainEventLog,
  DamageLog,
  decodeEvent,
  EventLog,
  EventLogType,
  HealLog,
  MonsterDefeatedLog,
  RemoveStatusEffectsByGroupLog,
  RevealMoveLog,
} from "./events";
import { PLACEHOLDER_CHAIN_ID } from "../../../chains.ts";
import { useBattleEvents } from "../../graph/events";
import { BattleEvent } from "../../../context/graphContext.tsx";

const getCacheKey = (matchMakerAddress: Address, matchId: bigint) =>
  `ocb:lastReadLogId:${matchMakerAddress}:${matchId}`;

export const useMatchEvents = (
  chainId: string,
  matchMaker: Address,
  eventLogger?: Address,
  matchId: bigint = BigInt(0)
) => {
  const [offset, setOffset] = useState(BigInt(0));
  const [logs, setLogs] = useState<EventLogType[]>([]);
  const [lastReadId, setLastReadId] = useState<bigint>(
    BigInt(localStorage.getItem(getCacheKey(matchMaker, matchId)) || 0)
  );

  useEffect(() => {
    if (matchId === BigInt(0)) return;
    localStorage.setItem(
      getCacheKey(matchMaker, matchId),
      lastReadId.toString()
    );
  }, [lastReadId, matchId, matchMaker]);

  const { contracts } = useContracts(chainId);

  const { battleEvents, isLoading, refetch } = useBattleEvents(matchId);

  useInterval(
    () => {
      // Your custom logic here
      refetch?.();
    },
    // Delay in milliseconds or null to stop it
    matchId > BigInt(0) ? 2_000 : null
  );

  const parseEventLogs = useCallback(
    (eventLogs: BattleEvent[]): EventLogType[] => {
      const monsterDefeatedByRound = new Map<bigint, bigint>();

      let parsed = eventLogs
        .map((eventLog) => {
          // I know the any typing is not clean but something cleaner is not worth it yet
          const decodedEvent: EventLogType | undefined = decodeEvent(
            BigInt(eventLog.id),
            BigInt(eventLog.action),
            BigInt(eventLog.timestamp),
            eventLog.data as Address,
            eventLog.player?.toLowerCase() as Address,
            eventLog.opponent?.toLowerCase() as Address,
            BigInt(eventLog.monster),
            BigInt(eventLog.opponentMonster),
            BigInt(eventLog.round)
          );

          if (decodedEvent)
            if (
              decodedEvent instanceof AddStatusEffectLog ||
              decodedEvent instanceof ApplyMonsterStatusEffectLog ||
              decodedEvent instanceof ApplyMoveStatusEffectLog ||
              decodedEvent instanceof ApplyOtherStatusEffectLog
            ) {
              decodedEvent.statusEffectName = contracts.get(
                decodedEvent?.statusEffect
              );
            }

          if (
            decodedEvent instanceof ApplyMoveStatusEffectLog ||
            decodedEvent instanceof DamageLog ||
            decodedEvent instanceof HealLog ||
            decodedEvent instanceof RemoveStatusEffectsByGroupLog ||
            decodedEvent instanceof RevealMoveLog
          ) {
            decodedEvent.moveName = contracts.get(decodedEvent?.move);
          }

          if (decodedEvent instanceof MonsterDefeatedLog) {
            monsterDefeatedByRound.set(
              decodedEvent.round,
              decodedEvent.monsterId
            );
          }

          return decodedEvent;
        })
        .filter((event) => event !== undefined) as EventLogType[];

      if (monsterDefeatedByRound.size > 0) {
        parsed = parsed.filter((eventLog: EventLogType) => {
          if (
            eventLog instanceof AddStatusEffectLog &&
            // basically all debuffs
            (eventLog.statusEffectName === "ConfusedEffect" ||
              eventLog.statusEffectName === "FoggedEffect" ||
              eventLog.statusEffectName === "DamageOverTimeEffect")
          ) {
            return (
              monsterDefeatedByRound.get(eventLog.round) !== eventLog.monsterId
            );
          }

          return true;
        });
      }

      return parsed;
    },

    [contracts]
  );

  useEffect(() => {
    if (logs.length === Number(offset) + 100) {
      setOffset((prev) => prev + BigInt(100));
    }
  }, [logs, offset]);

  useEffect(() => {
    // parse the data
    const parsedEventLogs = parseEventLogs(battleEvents || []);
    setLogs((prev) => {
      // Create a set of existing event IDs for quick lookup
      const existingEventIds = new Set(prev.map((event) => event.id));

      // Filter out events from parsedEventLogs that already exist
      const newEvents = parsedEventLogs.filter(
        (event) => !existingEventIds.has(event.id)
      );

      if (newEvents.length > 0) {
        return [...prev, ...newEvents];
      }

      // Return the combined list
      return prev;
    });
  }, [battleEvents, offset, parseEventLogs]);

  const markAsRead = useCallback((id: bigint) => {
    setLastReadId(id);
  }, []);

  const unreadLogs = useMemo(
    () => logs.filter((log) => log.id > lastReadId),
    // only change if read size changes (we only increase)
    [logs, lastReadId]
  );

  return {
    contracts,
    allLogs: logs,
    logs: unreadLogs,
    isLoading,
    markAsRead,
    refetch,
  };
};
