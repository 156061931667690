import { Transition, Variants } from "framer-motion";

export const variants: Variants = {
  open: { y: 0, opacity: 1 },
  closed: { y: 100, opacity: 0 },
};

export const transition: Transition = {
  type: "spring",
  damping: 20,
  stiffness: 150,
};
