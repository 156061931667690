import "./App.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { useOrientation, useInterval } from "react-use";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { Workbox } from "workbox-window";
import { configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

import { PrivyClientConfig, PrivyProvider } from "@privy-io/react-auth";

import { AppRouter } from "./AppRouter";
import { getChain, pmon } from "./chains.ts";
import { AppVersionManager, ModalOrientation } from "./components";
import { AppContext, UserProvider } from "./context";
import { useLockOrientation, useOperatingSystem } from "./hooks";
import {
  LOCAL_STORAGE_MESSAGING_TOKEN_KEY,
  LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY,
  NOTIFICATIONS_REQUESTED,
} from "./config";
import firebaseConfig from "./firebase-config.json";
import { PrivyWagmiConnector } from "@privy-io/wagmi-connector";
import * as sapphire from "@oasisprotocol/sapphire-paratime";
import { getCleanChainId } from "./utility";
import { GraphProvider } from "./context/graphContext.tsx";
import { sapphire as sapphireChain } from "viem/chains";

const activeChainId = getCleanChainId(
  localStorage.getItem("ocb:chainId") || pmon.id
);
const activeChain = getChain(`${activeChainId}`);

const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

let configureChainsConfig = configureChains([activeChain], [publicProvider()]);

const oldPublicProviderFunc = configureChainsConfig.publicClient;

// not so clean override for making sapphire work but fine until wagmi 2.0
configureChainsConfig = {
  ...configureChainsConfig,
  // @ts-ignore
  publicClient: ({ chainId }) => {
    const provider = oldPublicProviderFunc({ chainId });
    // @ts-ignore
    return chainId === sapphireChain.id ? sapphire.wrap(provider) : provider;
  },
};

const PRIVY_ID = "clo4avrzb02voms0f6y2kwh0g";

const privyClientConfig: PrivyClientConfig = {
  // additionalChains: [activeChain],
  supportedChains: [activeChain],
  defaultChain: activeChain,
  loginMethods: ["email", "google"],

  embeddedWallets: {
    createOnLogin: "users-without-wallets",
    noPromptOnSignature: true,
  },
  appearance: {
    theme: "light",
    accentColor: "#ff3369",
  },
  legal: {
    termsAndConditionsUrl: "https://app.polychainmonsters.com/terms-of-service",
    privacyPolicyUrl: "https://app.polychainmonsters.com/privacy-policy",
  },
};

function App() {
  const wb = useRef<InstanceType<typeof Workbox>>();
  const registration = useRef<ServiceWorkerRegistration>();

  useLockOrientation();

  const [notificationsRequested, setNotificationsRequested] = useState(
    localStorage.getItem(LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY) ===
      NOTIFICATIONS_REQUESTED
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [noPromptOnSignature, setNoPromptOnSignature] = useState(true);

  const { isIOS, isAndroid } = useOperatingSystem();
  const { type: orientation } = useOrientation();

  const [chainId, setChainId] = useState<number>(
    getCleanChainId(localStorage.getItem("ocb:chainId") || pmon.id)
  );

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      wb.current = new Workbox(
        import.meta.env.MODE === "production" ? "/sw.js" : "/dev-sw.js?dev-sw",
        {
          type: import.meta.env.MODE === "production" ? "classic" : "module",
        }
      );

      wb.current?.addEventListener("controlling", (event) => {
        window.location.reload();
      });

      wb.current.register().then((swRegistration) => {
        registration.current = swRegistration;
      });
    }
  }, []);

  useInterval(() => {
    const notificationsRequested = localStorage.getItem(
      LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY
    );
    if (notificationsRequested) {
      setNotificationsRequested(true);
    }
  }, 5_000);

  useEffect(() => {
    if (notificationsRequested && registration.current) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const messaging = getMessaging(firebaseApp);

          getToken(messaging, {
            vapidKey:
              "BLiTxRvo-RE1JB2TNUY2Se8iEqFEhX-6X0pqVQ6WApX1Anr8pGIgIRdh0KmBhMyd0ymms-cQzcQACGnEAvgwhyw",
            serviceWorkerRegistration: registration.current,
          }).then((currentToken) => {
            localStorage.setItem(
              LOCAL_STORAGE_MESSAGING_TOKEN_KEY,
              currentToken
            );
          });
        }
      });
    }
  }, [notificationsRequested]);

  useEffect(() => {
    console.log("Just for update 2");
  }, []);

  const onUpdate = useCallback(async () => {
    setIsUpdating(true);

    setTimeout(() => {
      setIsUpdating(false);
      window.location.reload();
    }, 5000);
  }, []);

  if (
    (isIOS || isAndroid) &&
    (orientation === "landscape" || orientation === "landscape-primary")
  ) {
    return <ModalOrientation />;
  }

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          chainId,
          noPromptOnSignature,
          setChainId,
          setNoPromptOnSignature,
        }}
      >
        <GraphProvider>
          <PrivyProvider
            appId={PRIVY_ID}
            config={{
              ...privyClientConfig,
              embeddedWallets: {
                ...privyClientConfig.embeddedWallets,
                noPromptOnSignature,
              },
            }}
          >
            <PrivyWagmiConnector wagmiChainsConfig={configureChainsConfig}>
              <UserProvider>
                <AppRouter />
              </UserProvider>
              <AppVersionManager isUpdating={isUpdating} onUpdate={onUpdate} />
            </PrivyWagmiConnector>
          </PrivyProvider>
        </GraphProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
