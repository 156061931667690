import { motion } from "framer-motion";
import React from "react";

import { animateVariants } from "./battleRoundLogMotion";
import { BattleRoundLogProps } from "./battleRoundLogProps";

export const BattleRoundLog: React.FC<BattleRoundLogProps> = ({
  message,
  isVisible,
}) => {
  return (
    <motion.div
      initial="visible"
      animate={isVisible ? "visible" : "hidden"}
      variants={animateVariants}
      className="flex flex-col flex-1 absolute top-0 right-4 left-4 pt-safe-or-4"
    >
      <div className="flex flex-row flex-1 px-4 py-8 justify-center shadow-xl bg-background-primary/80 backdrop-blur-sm rounded-lg border border-background-primary/20">
        <p className="font-display font-black text-center text-base text-text-highlight">
          {message}
        </p>
      </div>
    </motion.div>
  );
};
