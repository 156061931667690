import { motion } from "framer-motion";
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import ScratchCard from "react-scratchcard-v2";
import { useLockedBody } from "usehooks-ts";
import { Address } from "viem";

import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { Canvas } from "@react-three/fiber";
import { useToggle } from "@uidotdev/usehooks";

import { AppContext } from "../../context";
import { useReverseSwap } from "../../hooks";
import { Modal } from "../modal";
import { Scene } from "./Scene.tsx";
import { ModalPacksRevealingProps } from "./modalPacksRevealingProps.ts";
import reveal from "./reveal.png";
import { Button, ModalInfo } from "..";

const info = [
  {
    title: "What is a reverse swap?",
    text: "You can reverse-swap a monster NFT for a new pack. For every reverse swap, there is a small fee, which you are donating to a battle reward pool.",
  },
];

export const ModalPacksRevealing: React.FC<ModalPacksRevealingProps> = ({
  isVisible,
  onClose,
  monster,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const navigate = useNavigate();
  const { wallet } = usePrivyWagmi();
  const [isInfoVisible, toggleIsInfoVisible] = useToggle(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [isReverseSwapping, setIsReverseSwapping] = useState(false);
  const [isReverseSwapped, setIsReverseSwapped] = useState(false);

  const { setNoPromptOnSignature } = useContext(AppContext);
  useEffect(() => {
    setNoPromptOnSignature(false);

    return () => {
      setNoPromptOnSignature(true);
    };
  }, [setNoPromptOnSignature]);

  const { reverseSwapAsync } = useReverseSwap(
    monster?.id,
    wallet?.address as Address,
    !isReverseSwapped
  );

  const onReveal = useCallback(() => {
    setIsRevealed(true);
    localStorage.setItem(`revealed:${monster?.id}`, "1");
  }, [monster?.id]);

  const onReverseSwap = useCallback(async () => {
    if (isReverseSwapping) {
      return;
    }

    try {
      setIsReverseSwapping(true);
      await reverseSwapAsync?.();
      navigate("/packs", { replace: true });
    } catch (err) {
      // @todo handle error
    } finally {
      setIsReverseSwapping(false);
      navigate("/booster-packs", { replace: true });
    }
  }, [navigate, isReverseSwapping, reverseSwapAsync]);

  return (
    <Modal
      className="p-0 m-0"
      classNameOverlay="pt-0 p-0 m-0"
      isVisible={isVisible}
      withSafeArea={false}
    >
      <div className="flex flex-col flex-1 items-center justify-center">
        <Canvas
          camera={{
            fov: 100,
            near: 0.1,
            far: 200,
          }}
        >
          <Scene />
        </Canvas>
        {!!monster && (
          <div className="absolute z-10 flex flex-col items-center justify-center top-0 left-0 right-0 bottom-0">
            {isRevealed && (
              <motion.div
                animate={{
                  scale: [0.1, 1, 1.5, 1],
                  transition: { duration: 1, type: "spring", stiffness: 100 },
                }}
              >
                <h1 className="text-text-light font-bold text-4xl mb-4">
                  {monster.decodedUri.name}
                </h1>
              </motion.div>
            )}
            <ScratchCard
              brushSize={10}
              height={320}
              width={320}
              image={reveal}
              finishPercent={40}
              onComplete={onReveal}
            >
              <img
                height={320}
                width={320}
                src={monster.decodedUri.image}
                className="rounded-3xl"
                alt={monster.decodedUri.name}
              />
            </ScratchCard>
            {isRevealed && (
              <motion.div
                animate={{
                  scale: [0.1, 1, 1.5, 1],
                  transition: { duration: 1, type: "spring", stiffness: 100 },
                }}
                className="mt-4 flex justify-center"
              >
                <Button
                  variant="primary"
                  onClick={onReverseSwap}
                  disabled={isReverseSwapping}
                >
                  Reverse Swap
                </Button>
                <Button
                  size="sm"
                  variant="light"
                  className="ml-2 w-12 h-12"
                  onClick={
                    toggleIsInfoVisible as unknown as MouseEventHandler<HTMLButtonElement>
                  }
                >
                  <i className="material-symbols-rounded text-lg">info</i>
                </Button>
              </motion.div>
            )}
          </div>
        )}
      </div>

      {onClose && (
        <div className="absolute z-50 top-[10%] right-4">
          <Button variant="light" size="2xs" onClick={onClose} sound="close">
            <span className="material-symbols-rounded text-lg">close</span>
          </Button>
        </div>
      )}

      <ModalInfo
        isVisible={isInfoVisible}
        title={"Reverse Swap"}
        subTitle={"Bla bla bla subtitle!"}
        infoItems={info}
        onClose={toggleIsInfoVisible}
      />
    </Modal>
  );
};
