import IMAGES from "../../../assets/images";
import { MonsterElement } from "../../../types";
import { DamageMove } from "./ActionProps";

export const DAMAGE_MOVES: DamageMove[] = [
  {
    key: "cinderStrike",
    title: "Cinder Strike",
    description:
      "Unleash a fierce strike enveloped in cinders towards the enemy, with a chance to set them ablaze.",
    elements: [MonsterElement.Fire, MonsterElement.Fire],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveFireFire,
  },
  {
    key: "wildfireCleanse",
    title: "Wildfire Cleanse",
    description:
      "Ignite a wildfire that engulfs both nature and adversarial enhancements, potentially purging the enemy of their buffs.",
    elements: [MonsterElement.Fire, MonsterElement.Nature],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveFireNature,
  },
  {
    key: "emberErosion",
    title: "Ember Erosion",
    description:
      "Hurl a concoction of burning embers and corrosive toxins at the enemy's Elemental Wall, potentially breaking it down.",
    elements: [MonsterElement.Fire, MonsterElement.Toxic],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveFireToxic,
  },
  {
    key: "plasmaBlaze",
    title: "Plasma Blaze",
    description:
      "Unleash a blazing strike supercharged with electric plasma, possibly fogging the enemy’s vision.",
    elements: [MonsterElement.Fire, MonsterElement.Electric],
    contractName: "ControlMove",
    imageURI: IMAGES.moveFireElectric,
  },
  {
    key: "thornBarrage",
    title: "Thorn Barrage",
    description:
      "Unleash a torrent of thorns towards the enemy, potentially entangling them.",
    elements: [MonsterElement.Nature, MonsterElement.Nature],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveNatureNature,
  },
  {
    key: "tidalGrove",
    title: "Tidal Grove",
    description:
      "Summon a surge of water amidst a grove of nature to cleanse the enemy of their buffs.",
    elements: [MonsterElement.Nature, MonsterElement.Water],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveNatureWater,
  },
  {
    key: "staticSpores",
    title: "Static Spores",
    description:
      "Release electrically charged spores that may break your enemy’s Elemental Wall.",
    elements: [MonsterElement.Nature, MonsterElement.Electric],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveNatureElectric,
  },
  {
    key: "botanicBlur",
    title: "Botanic Blur",
    description:
      "Conjure a mesmerizing botanical mirage around the enemy, potentially blurring their vision.",
    elements: [MonsterElement.Nature, MonsterElement.Mental],
    contractName: "ControlMove",
    imageURI: IMAGES.moveNatureMental,
  },
  {
    key: "tidalSlam",
    title: "Tidal Slam",
    description:
      "Command the tide's force for a slamming attack, possibly soaking the enemy in chilling water.",
    elements: [MonsterElement.Water, MonsterElement.Water],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveWaterWater,
  },
  {
    key: "boilingFog",
    title: "Boiling Fog",
    description:
      "Summon a mist of boiling fog intertwined with fiery essence, potentially purging the enemy of their buffs.",
    elements: [MonsterElement.Water, MonsterElement.Fire],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveWaterFire,
  },
  {
    key: "mindFlood",
    title: "Mind Flood",
    description:
      "Unleash a water torrent charged with mental energy, possibly breaking down the enemy's Elemental Wall.",
    elements: [MonsterElement.Water, MonsterElement.Mental],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveWaterMental,
  },
  {
    key: "toxicWave",
    title: "Toxic Wave",
    description:
      "Conjure a wave imbued with toxic substances, potentially blinding the enemy.",
    elements: [MonsterElement.Water, MonsterElement.Toxic],
    contractName: "ControlMove",
    imageURI: IMAGES.moveWaterToxic,
  },
  {
    key: "lightningBolt",
    title: "Lightning Bolt",
    description:
      "Summon a bolt of lightning to strike the enemy, potentially shocking them with pulsing electricity.",
    elements: [MonsterElement.Electric, MonsterElement.Electric],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveElectricElectric,
  },
  {
    key: "shockingThought",
    title: "Shocking Thought",
    description:
      "Send a jolt of electric current to disrupt the enemy's mind, with a chance to purge their buffs.",
    elements: [MonsterElement.Electric, MonsterElement.Mental],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveElectricMental,
  },
  {
    key: "electricSplash",
    title: "Electric Splash",
    description:
      "Create a splash of electrified water aimed at the enemy's Elemental Wall, potentially breaking it down.",
    elements: [MonsterElement.Electric, MonsterElement.Water],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveElectricWater,
  },
  {
    key: "plasmaFlicker",
    title: "Plasma Flicker",
    description:
      "Ignite a flicker of fiery plasma with electric charge, with a chance to paralyze the enemy.",
    elements: [MonsterElement.Electric, MonsterElement.Fire],
    contractName: "ControlMove",
    imageURI: IMAGES.moveElectricFire,
  },
  {
    key: "thoughtErosion",
    title: "Thought Erosion",
    description:
      "Unleash a torrent of disruptive thoughts toward the enemy, potentially distressing their mind.",
    elements: [MonsterElement.Mental, MonsterElement.Mental],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveMentalMental,
  },
  {
    key: "venomousVision",
    title: "Venomous Vision",
    description:
      "Cast a venomous illusion before the enemy, with a chance to cleanse them of their buffs.",
    elements: [MonsterElement.Mental, MonsterElement.Toxic],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveMentalToxic,
  },
  {
    key: "mindBlaze",
    title: "Mind Blaze",
    description:
      "Ignite a fiery mental assault against the enemy’s Elemental Wall, potentially breaking it apart.",
    elements: [MonsterElement.Mental, MonsterElement.Fire],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveMentalFire,
  },
  {
    key: "psychicBloom",
    title: "Psychic Bloom",
    description:
      "Conjure a field of disorienting flora around the enemy, potentially causing confusion.",
    elements: [MonsterElement.Mental, MonsterElement.Nature],
    contractName: "ControlMove",
    imageURI: IMAGES.moveMentalNature,
  },
  {
    key: "poisonCloud",
    title: "Poison Cloud",
    description:
      "Engulf the enemy in a venomous cloud, with a chance to poison them over time.",
    elements: [MonsterElement.Toxic, MonsterElement.Toxic],
    contractName: "DamageOverTimeMove",
    imageURI: IMAGES.moveToxicToxic,
  },
  {
    key: "voltageVoid",
    title: "Voltage Void",
    description:
      "Unleash a corrosive void that may devour the electric aura around the enemy, potentially purging their buffs.",
    elements: [MonsterElement.Toxic, MonsterElement.Electric],
    contractName: "PurgeBuffsMove",
    imageURI: IMAGES.moveToxicElectric,
  },
  {
    key: "venomousVines",
    title: "Venomous Vines",
    description:
      "Summon toxic vines that may breach the enemy's Elemental Wall, causing confusion.",
    elements: [MonsterElement.Toxic, MonsterElement.Nature],
    contractName: "WallBreakerMove",
    imageURI: IMAGES.moveToxicNature,
  },
  {
    key: "chokingMist",
    title: "Choking Mist",
    description:
      "Eject a suffocating mist around the enemy, potentially obscuring their vision and aim.",
    elements: [MonsterElement.Toxic, MonsterElement.Water],
    contractName: "ControlMove",
    imageURI: IMAGES.moveToxicWater,
  },
];
