import { motion } from "framer-motion";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useQueue } from "@uidotdev/usehooks";

import { UserContext } from "../../context";
import { EventLogType, useLongPress } from "../../hooks";
import { processLog } from "../../routes";
import { Modal } from "../modal";
import { ModalHeader } from "../modalHeader";
import { ModalBattleLogProps } from "./modalBattleLogProps";

interface LogInQueue {
  message: ReactNode;
  round: bigint;
  originalLog: EventLogType;
}

export const ModalBattleLog: React.FC<ModalBattleLogProps> = ({
  isDebug,
  isVisible,
  onClose,
  logs,
  players,
  matchId,
  round,
}) => {
  const [isDebugLocal, setIsDebugLocal] = useState(false);
  const { add, clear, queue } = useQueue<LogInQueue>([]);
  const { walletAddress } = useContext(UserContext)!;

  const onEnableLocalDebug = useCallback(() => {
    setIsDebugLocal(!isDebugLocal);
  }, [isDebugLocal, setIsDebugLocal]);
  const longPressProps = useLongPress(onEnableLocalDebug, 5_000);

  const processLogs = useCallback(() => {
    logs?.map((log) => {
      const round = log.round;

      const addToQueue = (message: ReactNode) => {
        add({ message, round, originalLog: log });
      };

      processLog(
        log,
        players,
        addToQueue,
        undefined,
        undefined,
        undefined,
        walletAddress,
      );
    });
  }, [logs, players, add, walletAddress]);

  useEffect(() => {
    clear();
    processLogs();
  }, [logs, clear, processLogs]);

  const groupedQueue = useMemo(() => groupByRound(queue), [queue]);

  return (
    <Modal isVisible={isVisible}>
      <div {...longPressProps}>
        <ModalHeader
          title="Battle Log"
          onClose={onClose}
          subTitle={`Match ID: ${matchId} | Round: ${round}`}
        />
      </div>
      <div className="flex flex-col my-4 space-y-2">
        {Object.entries(groupedQueue)
          .reverse()
          .map(([round, logsInRound]) => (
            <React.Fragment key={round}>
              <span className="text-base text-text-light font-bold font-display pt-4">{`Round ${
                parseInt(round) + 1
              }`}</span>
              {logsInRound.map((logInQueue, index) => (
                <React.Fragment key={logInQueue.originalLog.id.toString()}>
                  {(isDebug || isDebugLocal) && (
                    <span className="text-xs text-text-accent">
                      {logInQueue.originalLog.id.toString()}:{" "}
                      {logInQueue.originalLog.debugName}
                    </span>
                  )}
                  <LogMessage key={index} message={logInQueue.message} />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
      </div>
    </Modal>
  );
};

const groupByRound = (queue: LogInQueue[]): { [key: string]: LogInQueue[] } => {
  return queue.reduce<{ [key: string]: LogInQueue[] }>((acc, logInQueue) => {
    // Convert round to a string key
    const roundKey = logInQueue.round.toString();
    if (!acc[roundKey]) {
      acc[roundKey] = [];
    }
    acc[roundKey].push(logInQueue);
    return acc;
  }, {});
};

const LogMessage = ({ message }: { message: ReactNode }) => {
  return (
    <motion.div className="flex flex-row p-2 space-x-2 bg-background-primary rounded-lg border border-background-primary/20">
      <div className="text-md text-text-primary">{message}</div>
    </motion.div>
  );
};
