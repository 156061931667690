import { motion } from "framer-motion";
import React from "react";
import { twMerge } from "tailwind-merge";

import {
  animateHorizontalVariants,
  animateVariants,
} from "./battleActionLogMotion";
import { BattleActionLogProps } from "./battleActionLogProps";

export const BattleActionLog: React.FC<BattleActionLogProps> = ({
  message,
  isVisible,
  className,
  animateHorizontal,
}) => {
  return (
    <motion.div
      initial="visible"
      animate={isVisible ? "visible" : "hidden"}
      variants={animateHorizontal ? animateHorizontalVariants : animateVariants}
      className={twMerge("min-h-[64px] flex flex-row w-full", className)}
    >
      <div className="flex flex-1 flex-row justify-center p-4 shadow-xl bg-background-primary/80 backdrop-blur-xl rounded-lg border border-background-primary/20">
        <div className="text-md text-text-primary">{message}</div>
      </div>
    </motion.div>
  );
};
