// Boost related effects
import { Monster } from "../monster";
import { MonsterElement } from "../monsterElement";

export type BoostEffectId =
  | "boostAttack"
  | "boostDefense"
  | "boostSpeed"
  | "boostHealth";

// Control related effects
export type ControlEffectId =
  | "controlBlinded"
  | "controlBlurred"
  | "controlDisoriented"
  | "controlFogged"
  | "controlParalyzed"
  | "controlSmogged";

// Damage over time effects
export type DotEffectId =
  | "dotBurned"
  | "dotDrenched"
  | "dotEntangled"
  | "dotPoisoned"
  | "dotPsyched"
  | "dotShocked";

// Other effects
export type OtherEffectId = "wallConfused";

// Shield related effects (provided by you)
export type ShieldTacticId =
  | "shieldCleansingShield"
  | "shieldCloudCover"
  | "shieldElementalWall"
  | "shieldTailwind";

// Combining all the subtypes into the main type
export type StatusEffectId =
  | BoostEffectId
  | ControlEffectId
  | DotEffectId
  | OtherEffectId
  | ShieldTacticId
  | "effectPurged"
  | "none";

export const translateFromChain = (
  monster: Monster,
  chainName?: string,
): StatusEffectId => {
  switch (chainName) {
    case "DamageOverTimeEffect": {
      switch (monster.config.element) {
        case MonsterElement.Electric:
          return "dotShocked";
        case MonsterElement.Fire:
          return "dotBurned";
        case MonsterElement.Water:
          return "dotDrenched";
        case MonsterElement.Mental:
          return "dotPsyched";
        case MonsterElement.Nature:
          return "dotEntangled";
        case MonsterElement.Toxic:
          return "dotPoisoned";
      }
      break;
    }
    case "FoggedEffect": {
      switch (monster.config.element) {
        case MonsterElement.Electric:
          return "controlParalyzed";
        case MonsterElement.Fire:
          return "controlFogged";
        case MonsterElement.Water:
          return "controlBlinded";
        case MonsterElement.Mental:
          return "controlDisoriented";
        case MonsterElement.Nature:
          return "controlBlurred";
        case MonsterElement.Toxic:
          return "controlSmogged";
      }
      break;
    }
    case "SpeedAuraEffect":
    case "SpeedAuraMove":
      return "boostSpeed";
    case "AttackAuraEffect":
    case "AttackAuraMove":
      return "boostAttack";
    case "DefenseAuraEffect":
    case "DefenseAuraMove":
      return "boostDefense";
    case "ConfusedEffect":
      return "wallConfused";
    case "TailwindEffect":
      return "shieldTailwind";
    case "ElementalWallEffect":
      return "shieldElementalWall";
    case "CloudCoverEffect":
      return "shieldCloudCover";
  }

  return "none";
};
