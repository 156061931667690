import { motion } from "framer-motion";
import React from "react";
import { useLockedBody } from "usehooks-ts";

import { useOperatingSystem } from "../../hooks";
import { Button } from "../button";
import { Modal } from "../modal";
import { transitions, variants } from "./modalUpdateMotion";
import { ModalUpdateProps } from "./modalUpdateProps";

export const ModalUpdate = ({
  isVisible,
  isUpdating,
  onClose,
  onReload,
}: ModalUpdateProps) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  return (
    <Modal
      isVisible={isVisible}
      className="flex flex-co items-center justify-center"
    >
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={transitions}
        className="relative flex p-2 rounded-2xl w-full max-w-md aspect-[3/4] bg-background-primary/60 backdrop-blur-xl border border-background-primary/20 shadow-lg"
      >
        <div className="flex flex-1 flex-col p-4 rounded-xl bg-background-primary border border-background-primary/20 shadow-sm">
          <div className="flex flex-1 flex-col">
            <div className="flex flex-1 flex-col space-y-8 justify-center text-center">
              <h1 className="font-display font-black text-2xl">
                New Update Available!
              </h1>
              <div className="space-y-8 text-text-secondary">
                <p>
                  A new update is available! Please reload the App to get the
                  latest version.
                </p>
              </div>
            </div>
            <Button disabled={isUpdating} variant="dark" onClick={onReload}>
              {isUpdating ? "Updating..." : "Reload"}
            </Button>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};
