import cn from "classnames";
import { motion } from "framer-motion";
import { noop } from "lodash";
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInterval } from "react-use";
import { zeroAddress } from "viem";

import { useToggle } from "@uidotdev/usehooks";

import IMAGES from "../../assets/images";
import { getChain } from "../../chains.ts";
import {
  Button,
  KeyValue,
  ModalInfo,
  ModalOpenPacks,
  ModalPacksMinting,
  ResponsiveBarChart,
  RetinaImage,
  RetinaImageCarousel,
} from "../../components";
import { AppContext, UserContext } from "../../context";
import {
  useBondingCurve,
  useBuyPrice,
  useEmbeddedWallet,
  useMintPacks,
} from "../../hooks";
import { useSellPrice } from "../../hooks/chain/packs/useSellPrice.ts";
import { useFetchSupplyData } from "../../hooks/graph/tokens";
import { useFetchHolderStats } from "../../hooks/graph/tokens/useFetchHolderStats.ts";
import {
  EnrichedToken,
  useFetchTokensByOwner,
} from "../../hooks/graph/tokens/useFetchTokensByOwner.tsx";
import { useFetchTransfersByTransactionHash } from "../../hooks/graph/transfers/useFetchTransfersByTransactionHash.tsx";
import { getPacksForChain } from "../../packs.ts";
import {
  contentTransition,
  contentVariants,
  formatBigNumber,
  formatEthRounded,
  getCleanChainId,
  getCleanChainIdString,
} from "../../utility";
import { info } from "./info";
import { useNavigate } from "react-router-dom";

const GRAPH_LABELS = ["1", "2-3", "4-10", "11-25", "26-50", "51+"];

const COST = 5;
const BALANCE = 100;

const removePackSuffix = (name: string = "") => {
  return name.replace(" Pack", "");
};

const SectionHeader = ({
  title,
  amount,
}: {
  title: string;
  amount: number;
}) => {
  return (
    <div className="flex flex-row items-center justify-between p-2 border-b-0.5 border-background-primary/20">
      <h2 className="font-display font-black text-text-light text-2xl">
        {title}
      </h2>
      <div className="flex flex-row space-x-4 items-center justify-center">
        <div className="flex flex-row space-x-1 items-center justify-center">
          <i className="material-symbols-rounded text-text-light text-xl">
            personal_bag
          </i>
          <span className="font-mono font-bold text-text-light text-sm">
            {amount}
          </span>
        </div>
      </div>
    </div>
  );
};

export const BoosterPacks: React.FC = () => {
  const { walletAddress } = useContext(UserContext)!;

  const { balances, native } = useEmbeddedWallet(walletAddress);
  const [error, setError] = useState<unknown>();
  const [amount, setAmount] = useState<number | "">(1);
  const [isInfoVisible, toggleIsInfoVisible] = useToggle(false);
  const [isMintLoading, setIsMintLoading] = useState(false);
  const [isMintSuccess, setIsMintSuccess] = useState(false);
  const [mintTxHash, setMintTxHash] = useState<string | undefined>();
  const [selectedPack, setSelectedPack] = useState<
    | { name: string; imageUri: string; amount: number; tokenIds: bigint[] }
    | undefined
  >();

  const { chainId, setNoPromptOnSignature } = useContext(AppContext);
  const activeChain = getChain(getCleanChainIdString(chainId));

  useEffect(() => {
    setNoPromptOnSignature(false);

    return () => {
      setNoPromptOnSignature(true);
    };
  }, [setNoPromptOnSignature]);

  const {
    balanceLoading: isPackBalanceLoading,
    balance: packBalance,
    ethLockedFormattedRounded,
    ethLockedLoading: isEthLockedLoading,
    maxSupply,
    supplyLoading: isPackSupplyLoading,
    supply,
    refetch: refetchBondingCurve,
  } = useBondingCurve(walletAddress);

  const {
    buyPrice: price,
    buyPricePerPack: pricePerPack,
    buyPriceLoading: isPriceLoading,
    buyPriceFormattedRounded: priceFormattedRounded,
    buyPricePerPackFormattedRounded: pricePerPackFormattedRounded,
  } = useBuyPrice(BigInt(amount));

  const { sellPriceFormattedRounded, sellPriceLoading: isSellPriceLoading } =
    useSellPrice(packBalance);

  const { mintAsync } = useMintPacks(
    walletAddress || zeroAddress,
    BigInt(amount),
    price
  );

  const {
    isLoading: isPacksLoading,
    tokens: packs,
    refetch: refetchPacks,
  } = useFetchTokensByOwner<EnrichedToken>(walletAddress, false);
  // group packs by name and put into a map
  const packMap = useMemo(
    () =>
      packs.reduce((acc, curr) => {
        if (!acc.has(curr.decodedUri.name)) {
          acc.set(curr.decodedUri.name, []);
        }
        acc.get(curr.decodedUri.name)?.push(curr);
        return acc;
      }, new Map<string, any[]>()),
    [packs]
  );

  console.log("native and price", native, price);
  const maxAmount = price
    ? BigInt(Math.min(100, Number(native / (pricePerPack || 1n))))
    : 0n;

  // Conditions updated for the amount being an empty string
  const buttonIncDisabled = amount !== "" && (amount + 1) * COST > BALANCE;
  const buttonDecDisabled = amount === 1 || amount === "";
  const buttonDisabled =
    amount === "" || amount === 0 || amount * COST > BALANCE;

  const onIncrement = useCallback(() => {
    if (typeof amount === "number" && amount <= Number(maxAmount)) {
      setAmount(Math.min(amount + 1, Number(maxAmount)));
    }
  }, [amount, maxAmount]);

  const onDecrement = useCallback(() => {
    if (typeof amount === "number") {
      setAmount(Math.max(amount - 1, 1));
    }
  }, [amount]);

  const onMax = useCallback(() => {
    if (maxAmount > 0n) {
      setAmount(Number(maxAmount));
    }
  }, [maxAmount]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Allow the input to be cleared to an empty string
    if (value === "") {
      setAmount(""); // Use an empty string to clear the input
      return;
    }

    const parsedValue = parseInt(value, 10);

    // Check if the parsed value is a number and within the allowed range
    if (!isNaN(parsedValue) && parsedValue >= 1) {
      setAmount(parsedValue); // Set amount to the parsed value if it's within range
    }
  }, []);

  const handleOpen = useCallback(async () => {
    if (mintAsync) {
      try {
        setIsMintLoading(true);
        const res = await mintAsync();
        if (res?.hash) {
          setMintTxHash(res?.hash);
        }
      } catch (err: unknown) {
        if ((err as any)?.message?.includes("User rejected")) {
          setIsMintLoading(false);
          return;
        }
        console.error(err);
        setError(err);
      }
    }
  }, [mintAsync]);

  const { transfers, refetch } = useFetchTransfersByTransactionHash(mintTxHash);
  useEffect(() => {
    if (transfers?.length) {
      refetchBondingCurve?.();
      refetchPacks?.();
      setIsMintSuccess(true);
      setMintTxHash(undefined);
    }
  }, [refetchBondingCurve, refetchPacks, transfers?.length]);

  useInterval(
    () => {
      if (mintTxHash) {
        refetch(mintTxHash);
      }
    },
    mintTxHash ? 2000 : null
  );

  const { dataPoints: supplyDataPoints, refetch: refetchSupplyData } =
    useFetchSupplyData();

  const { holderStats } = useFetchHolderStats();

  const graphLabels = useMemo(() => {
    if (!holderStats) {
      return ["1", "2-3", "4-10", "11-25", "26-50", "51+"];
    }

    return [
      holderStats.items1,
      holderStats.items2_3,
      holderStats.items4_10,
      holderStats.items11_25,
      holderStats.items26_50,
      holderStats.items51,
    ];
  }, [holderStats]);

  const graphData = useMemo(() => {
    return [
      holderStats?.items1 || 0,
      holderStats?.items2_3 || 0,
      holderStats?.items4_10 || 0,
      holderStats?.items11_25 || 0,
      holderStats?.items26_50 || 0,
      holderStats?.items51 || 0,
    ];
  }, [holderStats]);

  const onCloseModal = useCallback(() => {
    setError(undefined);
    setIsMintSuccess(false);
    setIsMintLoading(false);
    setAmount(1);
    // scroll to packs collection
    const element = document.getElementById("packs-collection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const onSelectPack = useCallback(
    (pack: EnrichedToken, tokenIds: bigint[]) => {
      setSelectedPack({
        name: removePackSuffix(pack.decodedUri.name),
        imageUri: pack.decodedUri.image,
        amount: tokenIds.length,
        tokenIds,
      });
    },
    []
  );

  const onCloseOpenPackModal = useCallback(() => {
    setSelectedPack(undefined);
    refetchPacks?.();
    refetchSupplyData?.();
  }, [refetchPacks, refetchSupplyData]);

  const packsForChain = useMemo(() => {
    return getPacksForChain(chainId);
  }, [chainId]);

  const navigate = useNavigate();
  const onDeposit = useCallback(() => {
    navigate("/battle-preparation", {
      replace: true,
      state: {
        deposit: true,
      },
    });
  }, [navigate]);

  return (
    <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro w-full">
      <div className="flex flex-col flex-1 pt-safe-offset-4 pb-4 px-4 bg-black/50 backdrop-blur-sm space-y-4 h-screen overflow-y-auto">
        <div className="flex flex-row justify-between">
          <h1 className="font-display font-black text-text-light text-3xl">
            Booster Packs
          </h1>

          <Button
            size="xs"
            variant="dark"
            className="w-10 h-10 shadow-lg"
            onClick={
              toggleIsInfoVisible as unknown as MouseEventHandler<HTMLButtonElement>
            }
          >
            <i className="material-symbols-rounded text-lg">info</i>
          </Button>
        </div>

        <div className="flex flex-col flex-1 justify-center">
          {/** Card */}
          {native === 0n && (
            <motion.div
              transition={contentTransition}
              variants={contentVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              className="flex flex-col p-2 rounded-3xl bg-background-primary/20 backdrop-blur-xl border border-background-primary/20 shadow-lg"
            >
              {/** Card Inner */}
              <div className="flex flex-col space-y-2 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
                <div className="max-h-80">
                  <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                    {/** Pack Details */}
                    <div className="flex flex-col justify-between items-left">
                      <span className="text-text-primary text-xl font-display font-bold text-left">
                        Top-up Gas
                      </span>
                      <span className="text-text-secondary text-left text-sm">
                        Use our quick deposit feature to top-up some gas
                        directly from your favorite exchange or from Arbitrum
                        One.
                      </span>
                      <Button
                        className="mt-4"
                        variant="primary"
                        onClick={onDeposit}
                        sound="click"
                      >
                        Top-up
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </div>
        <div className="flex flex-col flex-1 justify-center">
          <motion.div
            transition={contentTransition}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="flex flex-col p-2 rounded-3xl bg-background-primary/40 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            {/** Card Inner */}
            <div className="flex flex-col space-y-2 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
              <div>
                {packsForChain.length > 1 ? (
                  <RetinaImageCarousel imageKeys={packsForChain} />
                ) : packsForChain.length == 1 ? (
                  <RetinaImage
                    className="rounded-xl"
                    imageKey={packsForChain[0] as keyof typeof IMAGES}
                  />
                ) : (
                  <div></div>
                )}
              </div>

              {/** Pack Body */}
              <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                {/** Pack Details */}
                <div className="flex flex-row justify-between items-center">
                  <span className="text-text-primary text-xl font-display font-bold text-left">
                    Elemental Echoes
                  </span>
                  <span className="text-text-tertiary uppercase font-black text-2xs text-right">
                    1 NFT
                  </span>
                </div>

                {/** Costs */}
                <div className="flex flex-row justify-between">
                  <KeyValue
                    keyText="Cost"
                    valueText={
                      isPriceLoading
                        ? "…"
                        : `~${pricePerPackFormattedRounded} ${activeChain.nativeCurrency.symbol}`
                    }
                    className={cn("text-left font-bold", {
                      "text-text-tertiary": isPriceLoading,
                    })}
                  />
                  <KeyValue
                    keyText="Total"
                    valueText={
                      isPriceLoading
                        ? "…"
                        : `~${priceFormattedRounded} ${activeChain.nativeCurrency.symbol}`
                    }
                    className={cn("text-center font-bold", {
                      "text-text-tertiary": isPriceLoading,
                    })}
                  />
                  <KeyValue
                    keyText="Wallet"
                    valueText={`${formatEthRounded(balances.eth, 5)} ${
                      activeChain.nativeCurrency.symbol
                    }`}
                    className="text-right font-bold"
                  />
                </div>

                {/** Actions */}
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-row space-x-2 bg-transparent">
                    {/** Input */}
                    <div className="relative flex flex-row flex-1 items-center rounded-full pr-1 bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
                      <input
                        className="bg-transparent font-display font-bold text-text-secondary text-md min-w-0 pl-4 outline-none focus:ring-0"
                        onChange={onChange}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={amount}
                        min={1}
                        max={Number(maxAmount)}
                        size={10}
                      />
                      <Button
                        variant="primary"
                        size="2xs"
                        onClick={onMax}
                        className="h-8 w-auto px-4 absolute right-1"
                        sound="click2"
                        disabled={maxAmount === 0n}
                      >
                        Max
                      </Button>
                    </div>

                    {/** Actions */}
                    <div className="flex flex-row space-x-2">
                      <Button
                        variant="dark"
                        size="xs"
                        onClick={onDecrement}
                        className="w-10"
                        disabled={buttonDecDisabled}
                        sound="click2"
                      >
                        <i className="material-symbols-rounded text-md">
                          remove
                        </i>
                      </Button>
                      <Button
                        variant="dark"
                        size="xs"
                        onClick={onIncrement}
                        className="w-10"
                        disabled={buttonIncDisabled}
                        sound="click2"
                      >
                        <i className="material-symbols-rounded text-md">add</i>
                      </Button>
                    </div>
                  </div>

                  <Button
                    variant="primary"
                    disabled={
                      native === 0n ||
                      isMintLoading ||
                      buttonDisabled ||
                      maxAmount === 0n
                    }
                    onClick={isMintLoading ? noop : handleOpen}
                    sound="click"
                  >
                    Mint Packs
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col flex-1 justify-center">
          {/** Card */}
          <motion.div
            transition={contentTransition}
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="flex flex-col p-2 rounded-3xl bg-background-primary/40 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            {/** Card Inner */}
            <div className="flex flex-col space-y-2 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
              <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                <div className="h-80">
                  {/** Pack Visual */}
                  <ResponsiveBarChart data={graphData} labels={GRAPH_LABELS} />
                </div>
              </div>

              {/** Pack Body */}
              <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                {/** Pack Details */}
                <div className="flex flex-row justify-between items-center">
                  <span className="text-text-primary text-xl font-display font-bold text-left">
                    Ownership Distribution
                  </span>
                  <span
                    className="text-text-tertiary uppercase font-black text-right"
                    onClick={
                      toggleIsInfoVisible as unknown as MouseEventHandler<HTMLSpanElement>
                    }
                  >
                    <i className="material-symbols-rounded text-xl">info</i>
                  </span>
                </div>

                {/** Costs */}
                <div className="flex flex-row justify-between">
                  <KeyValue
                    keyText="Current Supply"
                    valueText={
                      isPackSupplyLoading
                        ? "…"
                        : `${supply}/${formatBigNumber(maxSupply || 0n)} NFTs`
                    }
                    className={cn("text-left font-bold", {
                      "text-text-tertiary": isPackSupplyLoading,
                    })}
                  />
                  <KeyValue
                    keyText="Your Balance"
                    valueText={
                      isPackBalanceLoading ? "…" : `${packBalance} NFTs`
                    }
                    className={cn("text-right font-bold", {
                      "text-text-tertiary": isPackBalanceLoading,
                    })}
                  />
                </div>

                <div className="flex flex-row justify-between">
                  <KeyValue
                    keyText={`${activeChain.nativeCurrency.symbol} Locked`}
                    valueText={
                      isEthLockedLoading
                        ? "…"
                        : `${ethLockedFormattedRounded} ${activeChain.nativeCurrency.symbol}`
                    }
                    className={cn("text-left font-bold", {
                      "text-text-tertiary": isEthLockedLoading,
                    })}
                  />
                  <KeyValue
                    keyText="REV. SWAP AMOUNT"
                    valueText={
                      isSellPriceLoading
                        ? "…"
                        : `${sellPriceFormattedRounded} ${activeChain.nativeCurrency.symbol}`
                    }
                    className={cn("text-right font-bold", {
                      "text-text-tertiary": isSellPriceLoading,
                    })}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div
          className="flex flex-col flex-1 justify-center"
          id="packs-collection"
        >
          {/** Card */}
          <motion.div
            variants={contentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={contentTransition}
            className="flex flex-col p-2 rounded-3xl bg-background-primary/20 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            <div className="flex flex-col space-y-4 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/40 border border-background-primary/20 shadow-sm">
              <SectionHeader title="Pack Collection" amount={packs.length} />
              <div className="grid grid-cols-2 gap-4">
                {isPacksLoading && (
                  <motion.div className="flex col-span-2 px-4 pb-4">
                    <p className="text-text-secondary">Updating…</p>
                  </motion.div>
                )}
                {packs.length === 0 ? (
                  <div className="flex col-span-2 px-4 pb-4">
                    <p className="text-text-secondary text-xs font-display">
                      {packBalance === 0n ? (
                        <>
                          You don't have any packs yet. Mint some to start your
                          collection!
                        </>
                      ) : (
                        <>
                          You opened all your packs, view your monsters in the
                          collection.
                        </>
                      )}
                    </p>
                  </div>
                ) : (
                  <>
                    {Array.from(packMap).map(([key, packs]) => (
                      <div
                        key={key}
                        className="flex flex-1 flex-col p-2 space-y-2 rounded-2xl bg-background-primary/40 border border-background-primary/10 backdrop-blur-sm shadow-lg active:bg-background-accent hover:bg-background-accent"
                        onClick={() =>
                          onSelectPack(
                            packs[0],
                            packs.map((p) => p.id)
                          )
                        }
                      >
                        <img
                          className="rounded-lg aspect-square"
                          src={packs[0].decodedUri.image}
                          alt={`Pack ${key}`}
                        />
                        <div className="flex flex-1 flex-col p-2 space-y-1 rounded-lg bg-background-primary/80 border border-background-primary/10 backdrop-blur-sm shadow-sm">
                          <div className="flex flex-row items-between items-start">
                            <span className="flex flex-1 text-sm text-text-primary font-display font-bold">
                              {removePackSuffix(packs[0].decodedUri.name)}
                            </span>
                            <span className="flex flex-1 text-2xs text-text-secondary font-display font-bold justify-end">
                              {packs.length}x
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <ModalInfo
        isVisible={isInfoVisible}
        title={"How to Mint Packs?"}
        subTitle={"Minting packs is easy and fun!"}
        infoItems={info}
        onClose={toggleIsInfoVisible}
      />

      <ModalOpenPacks
        pack={selectedPack}
        isVisible={!!selectedPack}
        onClose={onCloseOpenPackModal}
      />

      <ModalPacksMinting
        isVisible={isMintSuccess || isMintLoading}
        onClose={onCloseModal}
        isDone={isMintSuccess || !!error}
        buttonText={error ? "Close" : "View Packs"}
        text={
          error ? (
            <>
              Minting failed. Please try again.
              <div className="flex flex-col p-2 rounded-md border-0.5 border-background-primary bg-background-primary/80 overflow-hidden">
                <p className="text-text-error text-2xs leading-4">{`${error}`}</p>
              </div>
            </>
          ) : isMintSuccess ? (
            <>
              You minted {amount} pack{Number(amount) > 1 ? "s" : ""}! Scroll
              down to view your new collection.
            </>
          ) : (
            <>
              Minting {amount} pack{Number(amount) > 1 ? "s" : ""}…
            </>
          )
        }
      />
    </div>
  );
};
