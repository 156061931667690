import IMAGES from "../assets/images";
import { StatusEffectId } from "../types";

const imageMap: Record<StatusEffectId, string> = {
  // Boosts
  boostAttack: IMAGES.boostAttack,
  boostDefense: IMAGES.boostDefense,
  boostSpeed: IMAGES.boostSpeed,
  boostHealth: IMAGES.boostHeal,

  // Effects
  controlBlinded: IMAGES.controlBlinded,
  controlBlurred: IMAGES.controlBlurred,
  controlDisoriented: IMAGES.controlDisoriented,
  controlFogged: IMAGES.controlFogged,
  controlParalyzed: IMAGES.controlParalyzed,
  controlSmogged: IMAGES.controlSmogged,

  dotBurned: IMAGES.dotBurned,
  dotDrenched: IMAGES.dotDrenched,
  dotEntangled: IMAGES.dotEntangled,
  dotPoisoned: IMAGES.dotPoisoned,
  dotPsyched: IMAGES.dotPsyched,
  dotShocked: IMAGES.dotShocked,

  effectPurged: IMAGES.effectPurged,
  wallConfused: IMAGES.wallConfused,

  // Shields
  shieldCleansingShield: IMAGES.shieldCleansingShield,
  shieldCloudCover: IMAGES.shieldCloudCover,
  shieldElementalWall: IMAGES.shieldElementalWall,
  shieldTailwind: IMAGES.shieldTailwind,

  none: IMAGES.monsterPlaceholder,
};

export function getImageForEffect(effect: StatusEffectId): string {
  return imageMap[effect] || IMAGES.monsterPlaceholder;
}
