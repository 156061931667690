import React, { useCallback } from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { ModalComingSoonProps } from "./modalComingSoonProps.ts";
import { ActionBar, Button } from "..";
import { useNavigate } from "react-router-dom";

export const ModalComingSoon: React.FC<ModalComingSoonProps> = ({
  isVisible,
  onClose,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/booster-packs", { replace: true });
  }, [navigate]);

  return (
    <Modal title="Coming Soon" isVisible={isVisible} className="py-4">
      <div className="flex flex-1 flex-col px-2">
        <div className="flex flex-1 flex-col justify-center">
          <div className="font-display text-text-light text-center">
            <>
              <p className="text-3xl font-black">Stay tuned.</p>
              <p className="text-lg">This page is coming soon!</p>
            </>
          </div>
        </div>
        <ActionBar variant="floating">
          <Button
            variant="light"
            size="xs"
            className="flex-1"
            onClick={handleClick}
            sound="click2"
          >
            OK I will wait!
          </Button>
        </ActionBar>
      </div>
    </Modal>
  );
};
