import { NavLink } from "react-router-dom";

import { Tab } from "./bottomTabNavigationProps";

export const BOTTOM_TAB_NAVIGATION_HEIGHT = 48;

const tabs: Tab[] = [
  { name: "Packs", icon: "layers", path: "/booster-packs" },
  { name: "Openings", icon: "orders", path: "/openings" },
  { name: "Binder Battles", icon: "whatshot", path: "/binders" },
  { name: "Battle", icon: "explosion", path: "/battle-preparation" },
];

export const BottomTabNavigation = () => {
  return (
    <nav className="bg-background-primary border-t-0.5 border-background-tertiary pb-safe-or-4 pt-4">
      <div className="flex flex-row flex-1 justify-between items-center">
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            to={tab.path}
            className={({ isActive }) =>
              [
                "flex flex-1 items-center justify-center ",
                isActive ? "text-text-highlight" : "text-text-placeholder",
              ].join(" ")
            }
          >
            <span className="material-symbols-rounded text-xxl">
              {tab.icon}
            </span>
          </NavLink>
        ))}
      </div>
    </nav>
  );
};
