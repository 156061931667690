import React from "react";
import { twMerge } from "tailwind-merge";

import {
  getColorForElement,
  getIconForElement,
  getNameForElement,
} from "../../utility";
import { ElementBadgeProps } from "./elementBadgeProps";

// export const ElementBadge: React.FC<ElementBadgeProps> = ({
//   element,
//   className,
//   badgeSize = 20,
//   iconSize = 12,
// }) => {
//   return (
//     <div
//       className={twMerge(
//         "flex flex-row space-x-1 justify-center items-center rounded-full border border-background-primary/80 px-2",
//         `bg-${getColorForElement(element)}`,
//         className,
//       )}
//       style={{ height: badgeSize }}
//     >
//       {/* <span
//         className="material-symbols-rounded text-text-light"
//         style={{ fontSize: iconSize }}
//       >
//         {getIconForElement(element)}
//       </span> */}
//       <span className="text-text-light font-display font-bold text-2xs">
//         {getNameForElement(element)}
//       </span>
//     </div>
//   );
// };

export const ElementBadge: React.FC<ElementBadgeProps> = ({
  element,
  className,
  badgeSize = 20,
  iconSize = 12,
}) => {
  return (
    <div
      className={twMerge(
        "flex justify-center items-center rounded-full border border-background-primary/80",
        `bg-${getColorForElement(element)}`,
        className,
      )}
      style={{ width: badgeSize, height: badgeSize }}
    >
      <span
        className="material-symbols-rounded text-text-light"
        style={{ fontSize: iconSize }}
      >
        {getIconForElement(element)}
      </span>
    </div>
  );
};
