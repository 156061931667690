import { Monster, MonsterElement } from "../../../types";
import {
  AppliedStatusEffect,
  BOOST_ABILITIES,
  BoostAbility,
  DAMAGE_MOVES,
  DamageMove,
  SHIELD_TACTICS,
  STATUS_EFFECTS,
  ShieldTactic,
} from "../BattleActions";

export const getActionDamageMovesForElement = (
  element: MonsterElement,
): DamageMove[] => {
  const damageMoves = DAMAGE_MOVES.filter(
    (move) => move.elements[0] === element,
  );
  return damageMoves;
};

export const getActionShieldTactics = (): ShieldTactic[] => {
  return SHIELD_TACTICS;
};

export const getActionBoostAbilities = (): BoostAbility[] => {
  return BOOST_ABILITIES;
};

export const getActionStatusEffects = (): AppliedStatusEffect[] => {
  return STATUS_EFFECTS;
};

export const getActionDamageMove = ({
  monster,
  moveName = "Unknown",
}: {
  monster: Monster;
  moveName?: string;
}): DamageMove | undefined => {
  const element = monster.config.element;
  const damageMoves = getActionDamageMovesForElement(element);
  // Finds and returns the move with the matching contractName, or returns an object with title "Unknown".
  return damageMoves.find((move) => move.contractName === moveName);
};
