import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";

import { AnimatedNumberProps } from "./animatedNumberProps";

export const AnimatedNumber: React.FC<AnimatedNumberProps> = ({
  className,
  targetNumber,
  durationMs = 10,
}) => {
  const controls = useAnimation();
  const [currentNumber, setCurrentNumber] = useState<number>(targetNumber);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;

    if (currentNumber !== targetNumber) {
      intervalId = setInterval(() => {
        if (currentNumber < targetNumber) {
          setCurrentNumber((prev) => prev + 1);
        } else if (currentNumber > targetNumber) {
          setCurrentNumber((prev) => prev - 1);
        } else {
          clearInterval(intervalId);
        }
      }, durationMs);
    }

    return () => clearInterval(intervalId);
  }, [currentNumber, targetNumber, durationMs]);

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <motion.span className={className} animate={controls}>
      {currentNumber}
    </motion.span>
  );
};
