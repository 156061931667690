import { useContractRead } from "wagmi";

interface AllowanceResult {
  allowance: bigint | undefined;
  isError: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export function usePmonAllowance(
  ownerAddress: string,
  spenderAddress: string
): AllowanceResult {
  const {
    data,
    isError,
    isLoading,
    refetch,
  }: {
    data?: bigint;
    isError: boolean;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: "0xBC9B77acA82f6BE43927076D71cd453b625165B8", // PMON contract address (verify or update as needed)
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
          {
            name: "_spender",
            type: "address",
          },
        ],
        name: "allowance",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "allowance",
    args: [ownerAddress, spenderAddress],
  });

  return {
    allowance: data,
    isError,
    isLoading,
    refetch,
  };
}
