import { MonsterAttributes } from "../../types";
import { SortByKey } from "./types";

export function generateTokenId(): bigint {
  const randomNum = Math.floor(Math.random() * Number(10n ** 18n));
  return BigInt(randomNum);
}

export function capitalizeFirstChar(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function mapSortKeyToConfigKey(key: SortByKey): string {
  const map: { [key in SortByKey]: string } = {
    attack: "ATK",
    defense: "DEF",
    speed: "SP",
    hp: "HP",
  };

  return map[key];
}
