import { useContractRead } from "wagmi";

interface BalanceResult {
  balance: bigint | undefined;
  isError: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export function usePmonBalance(address: string): BalanceResult {
  const {
    data,
    isError,
    isLoading,
    refetch,
  }: {
    data?: bigint;
    isError: boolean;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: "0xBC9B77acA82f6BE43927076D71cd453b625165B8", // PMON contract address (verify or update as needed)
    abi: [
      {
        constant: true,
        inputs: [
          {
            name: "_owner",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            name: "",
            type: "uint256",
          },
        ],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
    ],
    functionName: "balanceOf",
    args: [address],
  });

  return {
    balance: data,
    isError,
    isLoading,
    refetch,
  };
}
