import cn from "classnames";
import { formatDistance } from "date-fns";
import { MouseEventHandler, useCallback, useState } from "react";
import { useInterval } from "react-use";
import { twMerge } from "tailwind-merge";
import { zeroAddress } from "viem";
import { shortenRarity } from "../../utility";
import { Button } from "../button";
import { KeyValue } from "../keyValue";
import { EpochCardProps } from "./epochCardProps.ts";
import { RarityLevel } from "../../types/index.ts";

export const EpochCard = ({
  epoch,
  onClick,
  onInfoClick,
  className,
  isForceRevealed,
}: EpochCardProps) => {
  const openingPossibleAt = Number(epoch.timestamp) * 1_000;
  const now = Date.now();

  const dateString = epoch.randomness
    ? "Ready!"
    : now > openingPossibleAt
    ? "Almost there!"
    : formatDistance(openingPossibleAt, now);

  const [revealedTokenIds, setRevealTokenIds] = useState<string[]>([]);

  const updateRevealedTokenIds = useCallback(() => {
    const ids = epoch.tokens.map((token) => {
      return localStorage.getItem(`revealed:${token.id}`) ? token.id : null;
    });

    setRevealTokenIds(ids);
  }, [epoch.tokens]);

  useInterval(updateRevealedTokenIds, 2_000);

  return (
    <>
      <div className="flex flex-1 flex-col space-y-2 p-2 rounded-3xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
        <div className="flex flex-col p-2 rounded-2xl space-y-2 bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
          <div className="flex flex-row justify-between items-center ">
            <div className="flex flex-row flex-1 justify-between space-x-2 px-1">
              <span className="text-text-primary text-xl font-display font-bold text-left">
                Epoch #{`${epoch.number}`}
              </span>
              <span
                className="text-text-tertiary uppercase font-black text-right"
                onClick={onInfoClick as unknown as MouseEventHandler<HTMLButtonElement>}
              >
                <i className="material-symbols-rounded text-xl">info</i>
              </span>
            </div>
          </div>
          <div className="flex flex-row justify-between space-x-4">
            <div className="flex flex-1 aspect-4/3 items-center rounded-lg justify-center bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
              <KeyValue
                keyText="Randomness"
                valueText={
                  epoch.randomness
                    ? `${String(epoch.randomness).slice(0, 8)}…`
                    : "Waiting…"
                }
                className={cn("text-center font-bold", {
                  "text-text-tertiary": !epoch.randomness,
                })}
              />
            </div>
            <div className="flex flex-1 aspect-4/3 items-center rounded-lg justify-center bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
              <KeyValue
                keyText="Packs"
                valueText={epoch.tokens.length}
                className="text-center font-bold"
              />
            </div>
            <div className="flex flex-1 aspect-4/3 items-center rounded-lg justify-center bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
              <KeyValue
                keyText="ETA"
                valueText={dateString}
                className="text-center font-bold"
              />
            </div>
          </div>
        </div>
        <div
          className={cn("grid grid-cols-1 gap-2", {
            ["grid-cols-2"]: epoch.tokens.length > 1,
          })}
        >
          {epoch.tokens.map((token) => {
            const isRevealed =
              isForceRevealed ||
              revealedTokenIds.includes(token.id) ||
              token.owner.id === zeroAddress;

            return (
              <div
                key={token.id}
                className={cn(
                  "flex flex-1 flex-col p-2 space-y-2 rounded-2xl bg-background-primary/40 border border-background-primary/10 shadow-lg items-center w-full relative",
                  {
                    "backdrop-blur-sm": true,
                  }
                )}
                style={{ flexBasis: "calc(33.333% - 16px)" }} // Added this line for a basic approach
              >
                <div className="relative flex justify-center items-center w-full">
                  <img
                    className={cn("rounded-lg aspect-square shadow-sm", {
                      "blur-xl": !isRevealed || !epoch.randomness,
                    })}
                    src={token.decodedUri.image}
                    alt={`Monster ${token.id}`}
                  />
                  {!isRevealed && (
                    <div className="absolute z-10 flex justify-center items-center w-full h-full">
                      <Button
                        variant="primary"
                        disabled={!epoch.randomness}
                        onClick={onClick?.bind(null, token)}
                        size="sm"
                      >
                        Reveal
                      </Button>
                    </div>
                  )}
                </div>
                <div
                  className={cn(
                    "flex flex-1 flex-col p-2 space-y-1 rounded-lg bg-background-primary/80 border border-background-primary/10 backdrop-blur-sm shadow-sm w-full",
                    {
                      "blur-sm": !isRevealed,
                    }
                  )}
                >
                  <div className="flex flex-row items-between items-center w-full">
                    <span className="flex flex-1 text-sm text-text-primary font-display font-bold">
                      {token.decodedUri.name}
                    </span>
                    <span className="flex flex-1 text-2xs text-text-secondary font-display font-bold justify-end">
                      {shortenRarity(RarityLevel.Common)}
                    </span>
                  </div>
                  <div className="flex flex-row items-between">
                    <div className="flex flex-row space-x-1 items-center">
                      <span className={twMerge("font-display text-xs", "red")}>
                        Fire
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
