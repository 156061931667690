import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import {
  variantBottom,
  variantTop,
  variantVersus,
} from "./modalBattleIntroMotion";
import { ModalBattleIntroProps } from "./modalBattleIntroProps";
import { Player } from "./player";

export const ModalBattleIntro: React.FC<ModalBattleIntroProps> = ({
  userName,
  opponentUserName,
  imageURI,
  opponentImageURI,
  isVisible,
  onClose,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        onClose();
      }, 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible, onClose]);

  if (
    userName === undefined ||
    opponentUserName === undefined ||
    imageURI === undefined ||
    opponentImageURI === undefined
  ) {
    return null;
  }

  return (
    <Modal isVisible={isVisible}>
      <div className="flex flex-1 flex-col items-center justify-center space-y-12">
        <motion.div initial="hidden" animate="visible" variants={variantTop}>
          <Player userName={opponentUserName} imageURI={opponentImageURI} />
        </motion.div>
        <motion.span
          initial="hidden"
          animate="visible"
          variants={variantVersus}
          className="font-mono font-bold text-text-light text-3xl"
        >
          VS
        </motion.span>
        <motion.div initial="hidden" animate="visible" variants={variantBottom}>
          <Player userName={userName} imageURI={imageURI} />
        </motion.div>
      </div>
    </Modal>
  );
};
