import React, { useContext, useMemo } from "react";
import { Simulate } from "react-dom/test-utils";
import { useNavigate } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import { UserContext } from "../../context";
import { PlayerProps } from "../../types";
import { Modal } from "../modal";
import { ModalBattleResultProps } from "./modalBattleResultProps";
import { ActionBar, Button } from "..";

import play = Simulate.play;
import { useLongPress } from "../../hooks";

type PlayerResult = {
  winnerPlayer: PlayerProps;
  loserPlayer: PlayerProps;
};

export const ModalBattleResult: React.FC<ModalBattleResultProps> = ({
  isVisible,
  winner,
  players,
  onFinish,
  isEscaped,
  onLongPress,
}) => {
  const { walletAddress } = useContext(UserContext)!;

  // Lock body when modal is visible
  useLockedBody(isVisible, "root");
  const navigate = useNavigate();

  const handleLeaveBattle = () => {
    onFinish?.();
    navigate("/battle-preparation", { replace: true });
  };

  const playerResult = useMemo<PlayerResult | undefined>(() => {
    if (players === undefined) return undefined;
    const playerArray = Object.values(players).filter(
      (o) => typeof (o as PlayerProps).address !== "undefined",
    );

    const loserPlayer = playerArray.find(
      (player) => (player as PlayerProps).address !== winner,
    ) as PlayerProps;

    const winnerPlayer = playerArray.find(
      (player) => (player as PlayerProps).address !== loserPlayer?.address,
    ) as PlayerProps;

    return { winnerPlayer, loserPlayer };
  }, [players, winner]);

  const winnerPlayer = playerResult?.winnerPlayer as PlayerProps;
  const loserPlayer = playerResult?.loserPlayer as PlayerProps;
  const isWinner = walletAddress === winner;

  const longPressProps = useLongPress(onLongPress, 5_000);

  return (
    <Modal title="Battle Result" isVisible={isVisible} className="py-4">
      <div className="flex flex-1 flex-col px-2">
        <div className="flex flex-1 flex-col justify-center">
          <div className="font-display text-text-light text-center">
            {isWinner ? (
              <>
                <p className="text-3xl font-black">Congratulations!</p>
                <p className="text-lg">
                  You won against {loserPlayer?.userName || "Unknown"}
                </p>
              </>
            ) : (
              <>
                <p
                  className="text-3xl font-black select-none"
                  {...longPressProps}
                >
                  {winnerPlayer?.userName ? "Sorry!" : "Escaped!"}
                </p>
                <p className="text-lg">
                  {isEscaped
                    ? "You escaped from the battle"
                    : `You lost against ${winnerPlayer?.userName || "Unknown"}`}
                </p>
              </>
            )}
          </div>
        </div>
        <ActionBar variant="floating">
          <Button
            variant="light"
            size="xs"
            className="flex-1"
            onClick={handleLeaveBattle}
            sound="click2"
          >
            Leave Battle
          </Button>
        </ActionBar>
      </div>
    </Modal>
  );
};
