import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { useContractReads, UseContractReadsConfig } from "wagmi";
import { Address, zeroAddress, formatEther } from "viem";

type PackInfo = {
  balanceLoading: boolean;
  balance: bigint | undefined;
  ethLocked: bigint | undefined;
  ethLockedLoading: boolean;
  ethLockedFormatted: string | undefined;
  ethLockedFormattedRounded: string | undefined;
  maxSupply: bigint | undefined;
  refetch: () => void;
  supplyLoading: boolean;
  supply: bigint | undefined;
};

export const useBondingCurve = (address?: Address): PackInfo => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  const contracts = contract
    ? [
        {
          ...contract,
          functionName: "balanceOf",
          args: [address],
          enabled: !!address && address !== zeroAddress,
        },
        {
          ...contract,
          functionName: "totalSupply",
          args: [],
        },
        {
          ...contract,
          functionName: "getBalance",
          args: [],
        },
        {
          ...contract,
          functionName: "bondingCurveSpecs",
          args: [],
        },
        {
          ...contract,
          functionName: "MAX_SUPPLY",
          args: [],
        },
      ]
    : [];

  const { data, isLoading, refetch } = useContractReads({
    contracts,
  } as UseContractReadsConfig<any>);

  return {
    balanceLoading: isLoading,
    balance: (data?.[0]?.result as bigint) ?? 0n,
    ethLocked: (data?.[2]?.result as bigint) ?? 0n,
    ethLockedFormatted: formatEther((data?.[2]?.result as bigint) ?? 0n),
    ethLockedFormattedRounded: `${parseFloat(
      formatEther((data?.[2]?.result as bigint) ?? 0n)
    ).toFixed(5)}`,
    ethLockedLoading: isLoading,
    refetch,
    maxSupply: (data?.[4]?.result as bigint) ?? 0n,
    supplyLoading: isLoading,
    supply: (data?.[1].result as bigint) ?? 0n,
  };
};
