const IMAGES = {
  backgroundDefault: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundFire: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundNature: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundWater: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundToxic: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundElectric: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,
  backgroundMental: new URL(
    "./backgrounds/background-default.jpg",
    import.meta.url
  ).href,

  monsterPlaceholder: new URL("./monsters/placeholder.jpg", import.meta.url)
    .href,

  // Moves
  moveElectricElectric: new URL(
    "./moves/move-electric-electric-lightning-bolt.jpg",
    import.meta.url
  ).href,
  moveElectricFire: new URL(
    "./moves/move-electric-fire-plasma-flicker.jpg",
    import.meta.url
  ).href,
  moveElectricMental: new URL(
    "./moves/move-electric-mental-shocking-thought.jpg",
    import.meta.url
  ).href,
  moveElectricWater: new URL(
    "./moves/move-electric-water-electric-splash.jpg",
    import.meta.url
  ).href,

  moveFireElectric: new URL(
    "./moves/move-fire-electric-plasma-blaze.jpg",
    import.meta.url
  ).href,
  moveFireFire: new URL(
    "./moves/move-fire-fire-cinder-strike.jpg",
    import.meta.url
  ).href,
  moveFireNature: new URL(
    "./moves/move-fire-nature-wildfire-cleanse.jpg",
    import.meta.url
  ).href,
  moveFireToxic: new URL(
    "./moves/move-fire-toxic-ember-erosion.jpg",
    import.meta.url
  ).href,

  moveMentalFire: new URL(
    "./moves/move-mental-fire-mind-blaze.jpg",
    import.meta.url
  ).href,
  moveMentalMental: new URL(
    "./moves/move-mental-mental-thought-erosion.jpg",
    import.meta.url
  ).href,
  moveMentalNature: new URL(
    "./moves/move-mental-nature-psychic-bloom.jpg",
    import.meta.url
  ).href,
  moveMentalToxic: new URL(
    "./moves/move-mental-toxic-venomous-vision.jpg",
    import.meta.url
  ).href,

  moveNatureElectric: new URL(
    "./moves/move-nature-electric-static-spores.jpg",
    import.meta.url
  ).href,
  moveNatureMental: new URL(
    "./moves/move-nature-mental-botanic-blur.jpg",
    import.meta.url
  ).href,
  moveNatureNature: new URL(
    "./moves/move-nature-nature-thorn-barrage.jpg",
    import.meta.url
  ).href,
  moveNatureWater: new URL(
    "./moves/move-nature-water-tidal-grove.jpg",
    import.meta.url
  ).href,

  moveToxicElectric: new URL(
    "./moves/move-toxic-electric-voltage-void.jpg",
    import.meta.url
  ).href,
  moveToxicNature: new URL(
    "./moves/move-toxic-nature-venomous-vines.jpg",
    import.meta.url
  ).href,
  moveToxicWater: new URL(
    "./moves/move-toxic-water-choking-mist.jpg",
    import.meta.url
  ).href,
  moveToxicToxic: new URL(
    "./moves/move-toxic-toxic-poison-cloud.jpg",
    import.meta.url
  ).href,

  moveWaterFire: new URL(
    "./moves/move-water-fire-boiling-fog.jpg",
    import.meta.url
  ).href,
  moveWaterMental: new URL(
    "./moves/move-water-mental-mind-flood.jpg",
    import.meta.url
  ).href,
  moveWaterToxic: new URL(
    "./moves/move-water-toxic-toxic-wave.jpg",
    import.meta.url
  ).href,
  moveWaterWater: new URL(
    "./moves/move-water-water-tidal-slam.jpg",
    import.meta.url
  ).href,

  // Boosts
  boostAttack: new URL("./boosts/boost-attack.jpg", import.meta.url).href,
  boostDefense: new URL("./boosts/boost-defense.jpg", import.meta.url).href,
  boostSpeed: new URL("./boosts/boost-speed.jpg", import.meta.url).href,
  boostHeal: new URL("./boosts/boost-health.jpg", import.meta.url).href,

  // Effects
  controlBlinded: new URL("./effects/control-blinded.jpg", import.meta.url)
    .href,
  controlBlurred: new URL("./effects/control-blurred.jpg", import.meta.url)
    .href,
  controlDisoriented: new URL(
    "./effects/control-disoriented.jpg",
    import.meta.url
  ).href,
  controlFogged: new URL("./effects/control-fogged.jpg", import.meta.url).href,
  controlParalyzed: new URL("./effects/control-paralyzed.jpg", import.meta.url)
    .href,
  controlSmogged: new URL("./effects/control-smogged.jpg", import.meta.url)
    .href,

  dotBurned: new URL("./effects/dot-burned.jpg", import.meta.url).href,
  dotDrenched: new URL("./effects/dot-drenched.jpg", import.meta.url).href,
  dotEntangled: new URL("./effects/dot-entangled.jpg", import.meta.url).href,
  dotPoisoned: new URL("./effects/dot-poisoned.jpg", import.meta.url).href,
  dotPsyched: new URL("./effects/dot-psyched.jpg", import.meta.url).href,
  dotShocked: new URL("./effects/dot-shocked.jpg", import.meta.url).href,

  effectPurged: new URL("./effects/se-purged.jpg", import.meta.url).href,
  wallConfused: new URL("./effects/wall-confused.jpg", import.meta.url).href,

  // Shields
  shieldCleansingShield: new URL(
    "./shields/shield-cleansing-shield.jpg",
    import.meta.url
  ).href,
  shieldCloudCover: new URL("./shields/shield-cloud-cover.jpg", import.meta.url)
    .href,
  shieldElementalWall: new URL(
    "./shields/shield-elemental-wall.jpg",
    import.meta.url
  ).href,
  shieldTailwind: new URL("./shields/shield-tailwind.jpg", import.meta.url)
    .href,

  // Monsters
  monsterBlazehorn: new URL("./monsters/blazehorn.jpg", import.meta.url).href,
  monsterForetusk: new URL("./monsters/foretusk.jpg", import.meta.url).href,
  monsterAquasteer: new URL("./monsters/aquasteer.jpg", import.meta.url).href,
  monsterFlampanda: new URL("./monsters/flampanda.jpg", import.meta.url).href,
  monsterVerdubear: new URL("./monsters/verdubear.jpg", import.meta.url).href,
  monsterWavepaw: new URL("./monsters/wavepaw.jpg", import.meta.url).href,
  monsterPyrilla: new URL("./monsters/pyrilla.jpg", import.meta.url).href,
  monsterFlorangutan: new URL("./monsters/florangutan.jpg", import.meta.url)
    .href,
  monsterTidalmonk: new URL("./monsters/tidalmonk.jpg", import.meta.url).href,
  monsterFernopig: new URL("./monsters/fernopig.jpg", import.meta.url).href,
  monsterLeafsnout: new URL("./monsters/leafsnout.jpg", import.meta.url).href,
  monsterStreamhog: new URL("./monsters/streamhog.jpg", import.meta.url).href,

  // Monsters
  artworkBearElectric: new URL("./artwork/bear-electric.jpg", import.meta.url)
    .href,
  artworkBearFire: new URL("./artwork/bear-fire.jpg", import.meta.url).href,
  artworkBearMental: new URL("./artwork/bear-mental.jpg", import.meta.url).href,
  artworkBearNature: new URL("./artwork/bear-nature.jpg", import.meta.url).href,
  artworkBearToxic: new URL("./artwork/bear-toxic.jpg", import.meta.url).href,
  artworkBearWater: new URL("./artwork/bear-water.jpg", import.meta.url).href,

  artworkBoarElectric: new URL("./artwork/boar-electric.jpg", import.meta.url)
    .href,
  artworkBoarFire: new URL("./artwork/boar-fire.jpg", import.meta.url).href,
  artworkBoarMental: new URL("./artwork/boar-mental.jpg", import.meta.url).href,
  artworkBoarNature: new URL("./artwork/boar-nature.jpg", import.meta.url).href,
  artworkBoarToxic: new URL("./artwork/boar-toxic.jpg", import.meta.url).href,
  artworkBoarWater: new URL("./artwork/boar-water.jpg", import.meta.url).href,

  artworkBullElectric: new URL("./artwork/bull-electric.jpg", import.meta.url)
    .href,
  artworkBullFire: new URL("./artwork/bull-fire.jpg", import.meta.url).href,
  artworkBullMental: new URL("./artwork/bull-mental.jpg", import.meta.url).href,
  artworkBullNature: new URL("./artwork/bull-nature.jpg", import.meta.url).href,
  artworkBullToxic: new URL("./artwork/bull-toxic.jpg", import.meta.url).href,
  artworkBullWater: new URL("./artwork/bull-water.jpg", import.meta.url).href,

  artworkDragonElectric: new URL(
    "./artwork/dragon-electric.jpg",
    import.meta.url
  ).href,
  artworkDragonFire: new URL("./artwork/dragon-fire.jpg", import.meta.url).href,
  artworkDragonMental: new URL("./artwork/dragon-mental.jpg", import.meta.url)
    .href,
  artworkDragonNature: new URL("./artwork/dragon-nature.jpg", import.meta.url)
    .href,
  artworkDragonToxic: new URL("./artwork/dragon-toxic.jpg", import.meta.url)
    .href,
  artworkDragonWater: new URL("./artwork/dragon-water.jpg", import.meta.url)
    .href,

  artworkElephantElectric: new URL(
    "./artwork/elephant-electric.jpg",
    import.meta.url
  ).href,
  artworkElephantFire: new URL("./artwork/elephant-fire.jpg", import.meta.url)
    .href,
  artworkElephantMental: new URL(
    "./artwork/elephant-mental.jpg",
    import.meta.url
  ).href,
  artworkElephantNature: new URL(
    "./artwork/elephant-nature.jpg",
    import.meta.url
  ).href,
  artworkElephantToxic: new URL("./artwork/elephant-toxic.jpg", import.meta.url)
    .href,
  artworkElephantWater: new URL("./artwork/elephant-water.jpg", import.meta.url)
    .href,

  artworkFoxElectric: new URL("./artwork/fox-electric.jpg", import.meta.url)
    .href,
  artworkFoxFire: new URL("./artwork/fox-fire.jpg", import.meta.url).href,
  artworkFoxMental: new URL("./artwork/fox-mental.jpg", import.meta.url).href,
  artworkFoxNature: new URL("./artwork/fox-nature.jpg", import.meta.url).href,
  artworkFoxToxic: new URL("./artwork/fox-toxic.jpg", import.meta.url).href,
  artworkFoxWater: new URL("./artwork/fox-water.jpg", import.meta.url).href,

  artworkGorillaElectric: new URL(
    "./artwork/gorilla-electric.jpg",
    import.meta.url
  ).href,
  artworkGorillaFire: new URL("./artwork/gorilla-fire.jpg", import.meta.url)
    .href,
  artworkGorillaMental: new URL("./artwork/gorilla-mental.jpg", import.meta.url)
    .href,
  artworkGorillaNature: new URL("./artwork/gorilla-nature.jpg", import.meta.url)
    .href,
  artworkGorillaToxic: new URL("./artwork/gorilla-toxic.jpg", import.meta.url)
    .href,
  artworkGorillaWater: new URL("./artwork/gorilla-water.jpg", import.meta.url)
    .href,

  artworkHorseElectric: new URL("./artwork/horse-electric.jpg", import.meta.url)
    .href,

  artworkLionElectric: new URL("./artwork/lion-electric.jpg", import.meta.url)
    .href,
  artworkLionFire: new URL("./artwork/lion-fire.jpg", import.meta.url).href,
  artworkLionMental: new URL("./artwork/lion-mental.jpg", import.meta.url).href,
  artworkLionNature: new URL("./artwork/lion-nature.jpg", import.meta.url).href,
  artworkLionToxic: new URL("./artwork/lion-toxic.jpg", import.meta.url).href,
  artworkLionWater: new URL("./artwork/lion-water.jpg", import.meta.url).href,

  artworkLizardElectric: new URL(
    "./artwork/lizard-electric.jpg",
    import.meta.url
  ).href,
  artworkLizardFire: new URL("./artwork/lizard-fire.jpg", import.meta.url).href,
  artworkLizardMental: new URL("./artwork/lizard-mental.jpg", import.meta.url)
    .href,
  artworkLizardNature: new URL("./artwork/lizard-nature.jpg", import.meta.url)
    .href,
  artworkLizardToxic: new URL("./artwork/lizard-toxic.jpg", import.meta.url)
    .href,
  artworkLizardWater: new URL("./artwork/lizard-water.jpg", import.meta.url)
    .href,

  artworkTurtleElectric: new URL(
    "./artwork/turtle-electric.jpg",
    import.meta.url
  ).href,
  artworkTurtleFire: new URL("./artwork/turtle-fire.jpg", import.meta.url).href,
  artworkTurtleMental: new URL("./artwork/turtle-mental.jpg", import.meta.url)
    .href,
  artworkTurtleNature: new URL("./artwork/turtle-nature.jpg", import.meta.url)
    .href,
  artworkTurtleToxic: new URL("./artwork/turtle-toxic.jpg", import.meta.url)
    .href,
  artworkTurtleWater: new URL("./artwork/turtle-water.jpg", import.meta.url)
    .href,

  artworkWhaleElectric: new URL("./artwork/whale-electric.jpg", import.meta.url)
    .href,
  artworkWhaleFire: new URL("./artwork/whale-fire.jpg", import.meta.url).href,
  artworkWhaleMental: new URL("./artwork/whale-mental.jpg", import.meta.url)
    .href,
  artworkWhaleNature: new URL("./artwork/whale-nature.jpg", import.meta.url)
    .href,
  artworkWhaleToxic: new URL("./artwork/whale-toxic.jpg", import.meta.url).href,
  artworkWhaleWater: new URL("./artwork/whale-water.jpg", import.meta.url).href,

  // Error
  error: new URL("/images/polymon/error.png", import.meta.url).href,
  error2x: new URL("/images/polymon/error@2x.png", import.meta.url).href,
  error3xx: new URL("/images/polymon/error@3x.png", import.meta.url).href,

  // Loading
  loading: new URL("/images/polymon/loading.png", import.meta.url).href,
  loading2x: new URL("/images/polymon/loading@2x.png", import.meta.url).href,
  loading3xx: new URL("/images/polymon/loading@3x.png", import.meta.url).href,

  // Trainer
  trainer: new URL("./trainers/trainer-default.png", import.meta.url).href,

  // Booster Packs
  elementalEchoes: new URL(
    "/images/packs/pack-elemental-echoes.jpg",
    import.meta.url
  ).href,
  elementalEchoes2x: new URL(
    "/images/packs/pack-elemental-echoes@2x.jpg",
    import.meta.url
  ).href,
  elementalEchoes3x: new URL(
    "/images/packs/pack-elemental-echoes@3x.jpg",
    import.meta.url
  ).href,

  crystalCavern: new URL(
    "/images/packs/nfb-crystal-cavern.jpg",
    import.meta.url
  ).href,
  crystalCavern2x: new URL(
    "/images/packs/nfb-crystal-cavern@2x.jpg",
    import.meta.url
  ).href,
  crystalCavern3x: new URL(
    "/images/packs/nfb-crystal-cavern@3x.jpg",
    import.meta.url
  ).href,
  galacticRift: new URL("/images/packs/nfb-galactic-rift.jpg", import.meta.url)
    .href,
  galacticRift2x: new URL(
    "/images/packs/nfb-galactic-rift@2x.jpg",
    import.meta.url
  ).href,
  galacticRift3x: new URL(
    "/images/packs/nfb-galactic-rift@3x.jpg",
    import.meta.url
  ).href,
  genesisFactory: new URL(
    "/images/packs/nfb-genesis-factory.jpg",
    import.meta.url
  ).href,
  genesisFactory2x: new URL(
    "/images/packs/nfb-genesis-factory@2x.jpg",
    import.meta.url
  ).href,
  genesisFactory3x: new URL(
    "/images/packs/nfb-genesis-factory@3x.jpg",
    import.meta.url
  ).href,
  mutantLaboratory: new URL(
    "/images/packs/nfb-mutant-laboratory.jpg",
    import.meta.url
  ).href,
  mutantLaboratory2x: new URL(
    "/images/packs/nfb-mutant-laboratory@2x.jpg",
    import.meta.url
  ).href,
  mutantLaboratory3x: new URL(
    "/images/packs/nfb-mutant-laboratory@3x.jpg",
    import.meta.url
  ).href,
};

export default IMAGES;
