import { useEffect, useRef, useState } from "react";

type RoundType = bigint | undefined;

// Hook for showing battle round messages
export const useBattleRoundLog = (
  round: RoundType,
  introVisibility: boolean,
) => {
  const [logVisibility, setLogVisibility] = useState<boolean>(false);
  const [logMessage, setLogMessage] = useState<string | null>(null);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const roundNumber = round !== undefined ? Number(round) : -1;

    // Reset the modal
    setLogVisibility(false);

    // Determine the message to show based on the round number
    const newMessage =
      roundNumber === 0 ? "Battle Start" : `Round ${roundNumber + 1}`;

    // Show the modal only if the round number is valid and has changed
    if (roundNumber >= 0 && !introVisibility) {
      setLogMessage(newMessage);
      setLogVisibility(true);

      // Set a timeout to hide the modal after 2000ms
      timer = setTimeout(() => setLogVisibility(false), 2000);
    }

    // Clear the timeout if the component unmounts or the round updates before the timer ends
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [round, introVisibility]); // Only re-run the effect if the round changes

  return { logVisibility, logMessage };
};
