import React from "react";
import { Address } from "viem";

import IMAGES from "../../../assets/images";
import {
  AddStatusEffectLog,
  ApplyMonsterStatusEffectLog,
  ApplyMoveStatusEffectLog,
  ApplyOtherStatusEffectLog,
  CommitMoveLog,
  DamageLog,
  EventLogType,
  GameOverLog,
  HealLog,
  MonsterDefeatedLog,
  RemoveStatusEffectsByGroupLog,
  RevealMoveLog,
} from "../../../hooks";
import {
  BoostEffectId,
  DotEffectId,
  MatchPlayers,
  Monster,
  PlayerProps,
  translateFromChain,
} from "../../../types";
import { getImageForEffect } from "../../../utility";
import { STATUS_EFFECTS } from "../BattleActions";
import {
  getActionDamageMove,
  getBattleLogMessageAddStatusEffect,
  getBattleLogMessageBoostAbility,
  getBattleLogMessageDamageMove,
  getBattleLogMessageDamageMoveMissed,
  getBattleLogMessageDamageOverTime,
  getBattleLogMessageGameOver,
  getBattleLogMessageHeal,
  getBattleLogMessageMonsterDefeated,
  getBattleLogMessageMonsterIdle,
  getBattleLogMessagePurged,
  getBattleLogMessageWallBroken,
} from ".";

export const getPlayerByMonsterId = (
  players: MatchPlayers,
  monsterId: bigint
) => {
  const { self, opponent } = players;

  const selfMonster = self.team.find(
    (monster) => monster.tokenId === monsterId
  );
  const opponentMonster = opponent.team.find(
    (monster) => monster.tokenId === monsterId
  );

  if (selfMonster) {
    return self;
  } else if (opponentMonster) {
    return opponent;
  }

  return undefined;
};

export const getPlayer = ({
  address,
  players,
}: {
  address: Address;
  players: MatchPlayers;
}): PlayerProps => {
  return players.self.address === address ? players.self : players.opponent;
};

export const getPlayerOther = ({
  address,
  players,
}: {
  address: Address;
  players: MatchPlayers;
}): PlayerProps => {
  return players.self.address === address ? players.opponent : players.self;
};

export function processDamageLog(
  log: DamageLog,
  player: PlayerProps,
  playerOther: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const { moveName, isCritical, elementalMultiplier, damage } = log;

  const attackerMonster = getMonsterById(log.attacker);
  const defenderMonster = getMonsterById(log.defender);

  if (!attackerMonster || !defenderMonster) {
    return;
  }

  const damageMove = getActionDamageMove({
    monster: attackerMonster,
    moveName,
  });

  const challenger = player.userName;
  const opponent = playerOther.userName;
  const attacker = attackerMonster.config.name;
  const elementAttacker = attackerMonster.config.element;
  const defender = defenderMonster.config.name;
  const elementDefender = defenderMonster.config.element;
  const move = damageMove?.title || "Unknown";
  const elements = damageMove?.elements;
  const critical = isCritical;
  const effectiveness = Number(elementalMultiplier);

  const battleLogMessages = getBattleLogMessageDamageMove({
    challenger,
    // opponent,
    attacker,
    attackerId: attackerMonster.tokenId as bigint,
    attackerElement: elementAttacker,
    defender,
    defenderId: defenderMonster.tokenId as bigint,
    move,
    // elements,
    defenderElement: elementDefender,
    critical,
    effectiveness,
    damage: Number(damage),
    imageURI: damageMove?.imageURI,
  });

  battleLogMessages.forEach(addActionLog);
}

export function processHealLog(
  log: HealLog,
  player: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const healedMonster = getMonsterById(log.monsterId);
  if (!healedMonster) {
    return;
  }

  const amount = Number(log.heal);
  const playerName = player?.userName;
  const monsterName = healedMonster.config.name;
  const element = healedMonster.config.element;

  const battleLogMessage = getBattleLogMessageHeal({
    playerName,
    monsterName,
    amount,
    element,
  });

  addActionLog(battleLogMessage);
}

export function processAddStatusEffectLog(
  log: AddStatusEffectLog,
  player: PlayerProps,
  playerOther: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const { statusEffectName, remainingTurns } = log;

  const isDot = statusEffectName === "DamageOverTimeEffect";
  const isFogged = statusEffectName === "FoggedEffect";
  const isConfused = statusEffectName === "ConfusedEffect";
  const appliedToOther = isDot || isFogged || isConfused;

  const monster = getMonsterById(log.monsterId);
  if (!monster) {
    return;
  }

  const playerName = appliedToOther ? playerOther.userName : player.userName;
  const monsterName = monster.config.name;
  const element = monster.config.element;
  const effectTurns = Number(remainingTurns);

  const effectId = translateFromChain(monster, statusEffectName);

  // Find the effect by its ID
  const appliedEffect = STATUS_EFFECTS.find(
    (effect) => effect.key === effectId
  );

  // Don't show aura effects
  const isAura = effectTurns > 200;

  if (appliedEffect && !isAura) {
    const effect = appliedEffect.title.toLowerCase();
    const imageURI = getImageForEffect(effectId);
    const battleLogMessage = getBattleLogMessageAddStatusEffect({
      player: playerName,
      monster: monsterName,
      effect,
      effectTurns,
      element,
      imageURI,
    });

    addActionLog(battleLogMessage);
  }
}

export function processApplyMonsterStatusEffectLog(
  log: ApplyMonsterStatusEffectLog,
  player: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const { statusEffectName, extraData } = log;

  const monster = getMonsterById(log.monsterId);
  if (!monster) {
    return;
  }

  const playerName = player.userName;
  const monsterName = monster.config.name;
  const element = monster.config.element;
  const amount = Number(extraData);
  const effectId = translateFromChain(monster, statusEffectName);

  let battleLogMessage: React.ReactNode | null = null;

  if (statusEffectName === "DamageOverTimeEffect") {
    battleLogMessage = getBattleLogMessageDamageOverTime({
      playerName,
      monsterName,
      ability: effectId as DotEffectId,
      amount,
      element,
    });
    return;
  } else if (statusEffectName === "FoggedEffect") {
    console.warn("FoggedEffect not implemented");
    return;
  } else {
    battleLogMessage = getBattleLogMessageBoostAbility({
      playerName,
      monsterName,
      ability: effectId as BoostEffectId,
      amount,
      element,
    });
  }

  addActionLog(battleLogMessage);
}

export function processRemoveStatusEffectsByGroupLog(
  log: RemoveStatusEffectsByGroupLog,
  player: PlayerProps,
  playerOther: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const { groupName, moveName } = log;

  const isWallBroken = moveName === "WallBreakerMove";

  const monster = getMonsterById(log.monsterId);
  if (!monster) {
    return;
  }

  const otherMonster = getMonsterById(log.monster);
  if (!otherMonster) {
    return;
  }

  const playerName = player.userName;
  const monsterName = otherMonster.config.name;
  const element = otherMonster.config.element;
  const monsterOtherName = monster.config.name;

  if (isWallBroken) {
    const battleLogMessage = getBattleLogMessageWallBroken({
      playerName,
      monsterName,
      monsterOtherName,
      element,
    });

    addActionLog(battleLogMessage);
  } else {
    const battleLogMessage = getBattleLogMessagePurged({
      playerName,
      monsterName,
      groupName: groupName.toLocaleLowerCase(),
      element,
    });

    addActionLog(battleLogMessage);
  }
}

export function processApplyOtherStatusEffectLog(
  log: ApplyOtherStatusEffectLog,
  player: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  console.warn("processApplyOtherStatusEffectLog not implemented", log);
}

export function processApplyMoveStatusEffectLog(
  log: ApplyMoveStatusEffectLog,
  playerChallenger: PlayerProps,
  playerOpponent: PlayerProps,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const { statusEffectName, isHit: success, moveName } = log;

  if (!success || statusEffectName === "ConfusedEffect") {
    return;
  }

  const monsterChallenger = getMonsterById(log.monster);
  const monsterOpponent = getMonsterById(log.opponentMonster);
  if (!monsterChallenger || !monsterOpponent) {
    return;
  }

  const defender = monsterOpponent.config.name;
  const elementDefender = monsterOpponent.config.element;
  const opponent = playerOpponent.userName;

  const attacker = monsterChallenger.config.name;
  const elementAttacker = monsterChallenger.config.element;
  const challenger = playerChallenger.userName;

  const damageMove = getActionDamageMove({
    monster: monsterChallenger,
    moveName,
  });

  const attack = damageMove?.title || "Unknown";

  // const effectId = translateFromChain(monsterChallenger, statusEffectName);

  const message = getBattleLogMessageDamageMoveMissed({
    challenger,
    opponent,
    attacker,
    defender,
    attack,
    attackerElement: elementAttacker,
    defenderElement: elementDefender,
  });

  addActionLog(message);
}

export function processGameOverLog(
  log: GameOverLog,
  players: MatchPlayers,
  addActionLog: (element: React.ReactNode) => void
) {
  const winner = log.winner;
  const playerWinner = getPlayer({ address: winner, players });
  const playerLoser = getPlayerOther({ address: winner, players });

  const battleLogMessage = getBattleLogMessageGameOver({
    playerWinnerName: playerWinner?.userName,
    playerLoserName: playerLoser?.userName,
  });

  addActionLog(battleLogMessage);
}

export function processMonsterDefeatedLog(
  log: MonsterDefeatedLog,
  player: PlayerProps | undefined,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  if (!player) {
    return;
  }

  const playerName = player?.userName;

  const monster = getMonsterById(log.monsterId);
  if (!monster) {
    return;
  }

  const monsterName = monster.config.name;
  const element = monster.config.element;

  const battleLogMessage = getBattleLogMessageMonsterDefeated({
    playerName,
    monsterName,
    element,
  });

  addActionLog(battleLogMessage);
}

export function processRevealMissedLog(
  log: RevealMoveLog,
  player: PlayerProps | undefined,
  addActionLog: (element: React.ReactNode) => void,
  getMonsterById: (id: bigint) => Monster | undefined
) {
  const idleMonster = getMonsterById(log.monster);
  if (!player) {
    return;
  }

  const playerName = player?.userName;
  const monsterName = idleMonster?.config?.name;
  const element = idleMonster?.config?.element;

  const battleLogMessage = getBattleLogMessageMonsterIdle({
    playerName,
    monsterName,
    element,
  });

  addActionLog(battleLogMessage);
}

export function processLog(
  log: EventLogType,
  players: MatchPlayers | undefined,
  addMessageToQueue: (element: React.ReactNode) => void,
  clearMessage?: (id: bigint) => void,
  addBattleEffectOpponent?: (effect: string) => void,
  addBattleEffectSelf?: (effect: string) => void,
  walletAddress?: Address
) {
  if (!players) {
    return;
  }

  const address = log.player;
  const player = getPlayer({ address, players });
  const playerOther = getPlayerOther({ address, players });

  const isSelf = address === walletAddress;
  const isOpponent = address !== walletAddress;

  if (!player || !players.getMonsterById) {
    // Ensure player is defined before proceeding
    return;
  }

  if (log instanceof DamageLog) {
    processDamageLog(
      log,
      player,
      playerOther,
      addMessageToQueue,
      players.getMonsterById
    );

    // Display damage move image
    const moveName = log.moveName;
    const attackerMonster = players?.getMonsterById(log.attacker);

    if (attackerMonster) {
      const damageMove = getActionDamageMove({
        monster: attackerMonster,
        moveName,
      });

      if (damageMove) {
        const image = damageMove?.imageURI;
        if (image) {
          if (isSelf) {
            addBattleEffectOpponent?.(image);
          } else if (isOpponent) {
            addBattleEffectSelf?.(image);
          }
        }
      }
    }
  } else if (log instanceof HealLog) {
    processHealLog(log, player, addMessageToQueue, players.getMonsterById);

    // Display heal image
    if (isSelf) {
      addBattleEffectSelf?.(IMAGES.boostHeal);
    } else if (isOpponent) {
      addBattleEffectOpponent?.(IMAGES.boostHeal);
    }
  } else if (log instanceof AddStatusEffectLog) {
    processAddStatusEffectLog(
      log,
      player,
      playerOther,
      addMessageToQueue,
      players.getMonsterById
    );

    // Display status effect image
    const statusEffectName = log.statusEffectName;

    // Don't display damage over time effects & fogged & confused
    if (
      statusEffectName !== "DamageOverTimeEffect" &&
      statusEffectName !== "FoggedEffect" &&
      statusEffectName !== "ConfusedEffect"
    ) {
      const effectId = translateFromChain(player.team[0], statusEffectName);
      const image = getImageForEffect(effectId);

      if (isSelf) {
        addBattleEffectSelf?.(image);
      } else if (isOpponent) {
        addBattleEffectOpponent?.(image);
      }
    }
  } else if (log instanceof ApplyMonsterStatusEffectLog) {
    processApplyMonsterStatusEffectLog(
      log,
      player,
      addMessageToQueue,
      players.getMonsterById
    );

    // // Display status effect image

    const statusEffectName = log.statusEffectName;
    // Don't display damage over time effects
    if (statusEffectName !== "DamageOverTimeEffect") {
      const effectId = translateFromChain(player.team[0], statusEffectName);
      const image = getImageForEffect(effectId);
      if (isSelf) {
        addBattleEffectSelf?.(image);
      } else if (isOpponent) {
        addBattleEffectOpponent?.(image);
      }
    }
  } else if (log instanceof RemoveStatusEffectsByGroupLog) {
    processRemoveStatusEffectsByGroupLog(
      log,
      player,
      playerOther,
      addMessageToQueue,
      players.getMonsterById
    );

    // Display cleansing shield image
    if (log.moveName === "CleansingShieldMove") {
      if (isSelf) {
        addBattleEffectSelf?.(IMAGES.shieldCleansingShield);
      } else if (isOpponent) {
        addBattleEffectOpponent?.(IMAGES.shieldCleansingShield);
      }
    }
  } else if (log instanceof ApplyOtherStatusEffectLog) {
    processApplyOtherStatusEffectLog(
      log,
      player,
      addMessageToQueue,
      players.getMonsterById
    );
  } else if (log instanceof ApplyMoveStatusEffectLog) {
    processApplyMoveStatusEffectLog(
      log,
      player,
      playerOther,
      addMessageToQueue,
      players.getMonsterById
    );
  } else if (log instanceof GameOverLog) {
    processGameOverLog(log, players, addMessageToQueue);
  } else if (log instanceof MonsterDefeatedLog) {
    processMonsterDefeatedLog(
      log,
      getPlayerByMonsterId(players, log.monsterId),
      addMessageToQueue,
      players.getMonsterById
    );
  } else if (log instanceof CommitMoveLog) {
    // Don't display reveal move log
  } else if (log instanceof RevealMoveLog) {
    const moveName = log.moveName;
    const missedReveal = moveName === undefined;
    if (missedReveal) {
      processRevealMissedLog(
        log,
        player,
        addMessageToQueue,
        players.getMonsterById
      );
    }
  } else {
    console.info("Unknown log type", log);
  }

  if (clearMessage) {
    clearMessage(log.id);
  }
}
