import { MonsterElement } from "../types";

const elementMap: Record<MonsterElement, string> = {
  [MonsterElement.None]: "background-default",
  [MonsterElement.Electric]: "background-electric",
  [MonsterElement.Fire]: "background-fire",
  [MonsterElement.Water]: "background-water",
  [MonsterElement.Mental]: "background-mental",
  [MonsterElement.Nature]: "background-nature",
  [MonsterElement.Toxic]: "background-toxic",
};

export function getColorForElement(element: MonsterElement): string {
  return elementMap[element] || "black";
}
