import { useCallback, useContext, useEffect, useState } from "react";

import { BattleEvent, GraphContext } from "../../../context/graphContext.tsx";

export const useBattleEvents = (matchId: BigInt) => {
  const [isLoading, setIsLoading] = useState(false);
  const [battleEvents, setBattleEvents] = useState<BattleEvent[]>([]);

  const { getBattleEvents } = useContext(GraphContext);

  const fetchBattleEvents = useCallback(async () => {
    setIsLoading(true);
    const res = await getBattleEvents(matchId);
    setBattleEvents(res?.battleEvents);
    setIsLoading(false);
  }, [matchId]);

  useEffect(() => {
    fetchBattleEvents();
  }, [fetchBattleEvents]);

  const refetch = useCallback(() => {
    fetchBattleEvents();
  }, [fetchBattleEvents]);

  return { isLoading, refetch, battleEvents };
};
