import { ReactNode } from "react";

import IMAGES from "../../../assets/images";
import { ElementBadge } from "../../../components";
import { getColorForElement } from "../../../utility";
import {
  BoostAbilityProps,
  DamageMoveMissedProps,
  DamageMoveProps,
  DamageOverTimeProps,
  GameOverProps,
  HealMoveProps,
  MonsterDefeatedProps,
  MonsterIdleProps,
  PurgedProps,
  ShieldTacticProps,
  StatusEffectProps,
  WallBrokenProps,
} from "../BattleLogProps";

const effectivenessTextMap: Record<number, string> = {
  50: "It's not very effective.",
  75: "It's slightly effective.",
  100: "A solid hit.",
  125: "A good hit.",
  150: "It's very effective!",
  200: "It's super effective!",
};

const getEffectivenessText = (effectiveness: number): string => {
  return effectivenessTextMap[effectiveness] || "";
};

export const getBattleLogMessageDamageMove = ({
  challenger,
  attacker,
  attackerId,
  defenderId,
  move,
  imageURI,
  critical,
  effectiveness,
  damage,
  attackerElement,
  defenderElement,
}: DamageMoveProps): ReactNode[] => {
  // Retrieve the appropriate text based on the effectiveness value
  const effectivenessText = getEffectivenessText(effectiveness);

  // Create a JSX node for critical text, only if the move was critical
  const criticalNode = critical && <strong>critically</strong>;

  const colorForElementAttacker = attackerElement
    ? `text-${getColorForElement(attackerElement)}`
    : "text-text-primary";

  const colorForElementDefender = defenderElement
    ? `text-${getColorForElement(defenderElement)}`
    : "text-text-primary";

  const isConfusedHit = `${attackerId}` === `${defenderId}`;

  return [
    // Message about the attack move
    <div className="flex flex-row items-center space-x-2">
      {imageURI !== undefined && (
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={imageURI}
          alt={move}
        />
      )}
      <div className="flex flex-col flex-1">
        <span>
          {challenger}'s{" "}
          <strong className={colorForElementAttacker}>{attacker}'s</strong>{" "}
          {criticalNode} <strong>{move}</strong> hits{" "}
          {isConfusedHit && (
            <strong className={colorForElementAttacker}>itself</strong>
          )}{" "}
          for <strong>{damage}</strong> damage
          {isConfusedHit && " out of confusion"}. {effectivenessText}
        </span>
      </div>
    </div>,
  ];
};

export const getBattleLogMessageDamageMoveMissed = ({
  challenger,
  opponent,
  attacker,
  attack,
  defender,
  attackerElement,
  defenderElement,
}: DamageMoveMissedProps): ReactNode => {
  // Array of potential messages constructed as functions. Each function, when invoked,
  // returns a JSX element with a unique battle message based on the given parameters.

  const colorForElementAttacker = attackerElement
    ? `text-${getColorForElement(attackerElement)}`
    : "text-text-primary";

  const colorForElementDefender = defenderElement
    ? `text-${getColorForElement(defenderElement)}`
    : "text-text-primary";

  const messages: ((
    challenger: string,
    attacker: string,
    attack: string,
    opponent: string,
    defender: string
  ) => ReactNode)[] = [
    (challenger, attacker, attack, opponent, defender) => (
      <span>
        {challenger}'s{" "}
        <strong className={colorForElementAttacker}>{attacker}</strong> attacks
        with <strong>{attack}</strong>, but {opponent}'s{" "}
        <strong className={colorForElementDefender}>{defender}</strong> evades!
      </span>
    ),
  ];

  // Randomly select a message from the messages array
  const randomMessageFunction =
    messages[Math.floor(Math.random() * messages.length)];

  // Invoke the selected function with the provided parameters to generate the battle message.
  return randomMessageFunction(
    challenger,
    attacker,
    attack,
    opponent,
    defender
  );
};

export const getBattleLogMessageDamageOverTime = ({
  playerName,
  monsterName,
  ability,
  amount,
  element,
}: DamageOverTimeProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";

  // Define a map of messages for different DOT effects.
  const messages: Record<string, ReactNode> = {
    dotBurned: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotBurned}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> sizzles,
          taking <strong className="text-background-fire">{amount}</strong>{" "}
          damage from being{" "}
          <strong className="text-background-fire">burned</strong>!
        </span>
      </div>
    ),
    dotEntangled: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotEntangled}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> struggles
          against thorny vines, suffering{" "}
          <strong className="text-background-nature">{amount}</strong> damage
          from being{" "}
          <strong className="text-background-nature">entangled</strong>.
        </span>
      </div>
    ),
    dotDrenched: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotDrenched}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> is
          shivering, losing{" "}
          <strong className="text-background-water">{amount}</strong> health to
          the <strong className="text-background-water">drenched</strong>{" "}
          effect.
        </span>
      </div>
    ),
    dotShocked: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotShocked}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> twitches
          from electric shocks, incurring{" "}
          <strong className="text-background-electric">{amount}</strong> damage
          due to being{" "}
          <strong className="text-background-electric">shocked</strong>.
        </span>
      </div>
    ),
    dotPsyched: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotPsyched}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> is reeling
          from mental turmoil, taking{" "}
          <strong className="text-background-mental">{amount}</strong> damage
          from a <strong className="text-background-mental">psyched</strong>{" "}
          state.
        </span>
      </div>
    ),
    dotPoisoned: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.dotPoisoned}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> is weakened
          by toxins, taking{" "}
          <strong className="text-background-toxic">{amount}</strong> damage
          from <strong className="text-background-toxic">poison</strong>.
        </span>
      </div>
    ),
  };

  // Return the appropriate message based on the dot.
  return (
    messages[ability] || <span>Unknown DOT received by {monsterName}.</span>
  );
};

export const getBattleLogMessageAddStatusEffect = ({
  player,
  monster,
  effect,
  effectTurns,
  element,
  imageURI,
}: StatusEffectProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <div className="flex flex-row items-center space-x-2">
      {imageURI !== undefined && (
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={imageURI}
        />
      )}
      <span>
        {player}'s <strong className={colorForElement}>{monster}</strong> is{" "}
        <strong>{effect}</strong>
        {effectTurns && ` for ${effectTurns - 1} turns.`}
      </span>
    </div>
  );
};

export const getBattleLogMessageShieldTactic = ({
  playerName,
  monsterName,
  tactic,
  success,
  element,
}: ShieldTacticProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  // Define a map of tactic messages for successful and unsuccessful attempts.
  const tacticMessages: Record<
    string,
    { success: ReactNode; fail: ReactNode }
  > = {
    shieldCleansingShield: {
      success: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldCleansingShield}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> unveils a{" "}
            <strong>Cleansing Shield</strong>, purging all negative effects with
            a radiant burst!
          </span>
        </div>
      ),
      fail: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldCleansingShield}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> attempts
            to summon a <strong>Cleansing Shield</strong>, but the energies
            falter.
          </span>
        </div>
      ),
    },
    shieldElementalWall: {
      success: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldElementalWall}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> erects a
            formidable <strong>Elemental Wall</strong>, repelling all incoming
            attacks for the next two turns!
          </span>
        </div>
      ),
      fail: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldElementalWall}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> strains
            to form an <strong>Elemental Wall</strong>, but the barrier
            crumbles.
          </span>
        </div>
      ),
    },
    shieldCloudCover: {
      success: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldCloudCover}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> conjures
            a thick <strong>Cloud Cover</strong>, shrouding the battlefield. The
            enemy's accuracy drops for two turns!
          </span>
        </div>
      ),
      fail: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldCloudCover}
          />
          <span>
            <strong className={colorForElement}>{monsterName}</strong> tries to
            summon a <strong>Cloud Cover</strong>, but the sky remains clear.
          </span>
        </div>
      ),
    },
    shieldTailwind: {
      success: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldTailwind}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> whips up
            a <strong>Tailwind</strong>, sharpening focus and raising the chance
            of landing critical hits for three turns!
          </span>
        </div>
      ),
      fail: (
        <div className="flex flex-row items-center space-x-2">
          <img
            className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
            src={IMAGES.shieldTailwind}
          />
          <span>
            {playerName}'s{" "}
            <strong className={colorForElement}>{monsterName}</strong> attempts
            to stir a <strong>Tailwind</strong>, but the air remains still.
          </span>
        </div>
      ),
    },
  };

  // Return the appropriate message based on the tactic and success flag.
  return success ? tacticMessages[tactic].success : tacticMessages[tactic].fail;
};

export const getBattleLogMessageBoostAbility = ({
  playerName,
  monsterName,
  ability,
  amount,
  element,
}: BoostAbilityProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";

  // Define a map of messages for different tactics.
  const tacticMessages: Record<string, ReactNode> = {
    boostAttack: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.boostAttack}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> emanates an
          empowering aura, boosting its <strong>attack attribute</strong> by{" "}
          <strong>{amount}</strong>.
        </span>
      </div>
    ),
    boostDefense: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.boostDefense}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> surrounds
          itself with a protective aura, enhancing its{" "}
          <strong>defense attribute</strong> by <strong>{amount}</strong>.
        </span>
      </div>
    ),
    boostSpeed: (
      <div className="flex flex-row items-center space-x-2">
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={IMAGES.boostSpeed}
        />
        <span>
          {playerName}'s{" "}
          <strong className={colorForElement}>{monsterName}</strong> unleashes a
          swift aura, augmenting its <strong>speed attribute</strong> by{" "}
          <strong>{amount}</strong>.
        </span>
      </div>
    ),
  };

  // Return the appropriate message based on the tactic.
  return (
    tacticMessages[ability] || (
      <span>Unknown tactic executed by {monsterName}.</span>
    )
  );
};

export const getBattleLogMessageHeal = ({
  playerName,
  monsterName,
  amount,
  element,
}: HealMoveProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <div className="flex flex-row items-center space-x-2">
      <img
        className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
        src={IMAGES.boostHeal}
        alt="Heal"
      />

      <span>
        {playerName}'s{" "}
        <strong className={colorForElement}>{monsterName}</strong> restores its
        vitality, healing by <strong>{amount}</strong>.
      </span>
    </div>
  );
};

export const getBattleLogMessagePurged = ({
  playerName,
  monsterName,
  groupName,
  element,
}: PurgedProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <span>
      All <strong>{groupName} effects</strong> from {playerName}'s{" "}
      <strong className={colorForElement}>{monsterName}</strong> were purged.
    </span>
  );
};

export const getBattleLogMessageWallBroken = ({
  playerName,
  monsterName,
  monsterOtherName,
  element,
}: WallBrokenProps): ReactNode => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <span>
      {playerName}'s <strong className={colorForElement}>{monsterName}</strong>{" "}
      broke through <strong>{monsterOtherName}</strong>'s Elemental Wall.
    </span>
  );
};

export const getBattleLogMessageMonsterDefeated = ({
  playerName,
  monsterName,
  element,
}: MonsterDefeatedProps) => {
  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <span>
      {playerName}'s <strong className={colorForElement}>{monsterName}</strong>{" "}
      has been defeated.
    </span>
  );
};

export const getBattleLogMessageGameOver = ({
  playerWinnerName,
  playerLoserName,
}: GameOverProps) => {
  return (
    <span>
      <strong>{playerWinnerName}</strong> has defeated{" "}
      <strong>{playerLoserName}</strong>!
    </span>
  );
};

export const getBattleLogMessageMonsterIdle = ({
  playerName,
  monsterName,
  element,
}: MonsterIdleProps) => {
  if (monsterName === undefined) {
    return (
      <span>
        {playerName}'s <strong className="text-text-primary">Monster</strong>{" "}
        remained idle this round.
      </span>
    );
  }

  const colorForElement = element
    ? `text-${getColorForElement(element)}`
    : "text-text-primary";
  return (
    <span>
      {playerName}'s <strong className={colorForElement}>{monsterName}</strong>{" "}
      remained idle this round.
    </span>
  );
};
