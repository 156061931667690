import { useCallback } from "react";
import { Address, Hex } from "viem";
import { getPublicClient, WriteContractResult } from "@wagmi/core";
import { ConnectedWallet } from "@privy-io/react-auth";
import { logError } from "../../../utility";

const BASE_RETRY_DELAY = 1000; // 1 second
const MAX_RETRIES = 6;

export const useRunWithCorrectNonce = (wallet?: ConnectedWallet) => {
  const runWithCorrectNonce = useCallback(
    async (
      fn: (
        ...args: any
      ) => Promise<{ hash: Hex } | undefined | WriteContractResult>,
      finishFn: (hash: Hex) => void = () => {},
      debugName: string = "",
      errorFn?: (error: unknown) => Promise<void>
    ) => {
      let retryCount = 0;

      while (retryCount < MAX_RETRIES) {
        if (!wallet || !fn) return;

        try {
          const nonce = await getPublicClient().getTransactionCount({
            address: wallet.address as Address,
          });

          const txResult = await fn({ nonce });

          if (txResult?.hash !== undefined) {
            finishFn(txResult.hash as Hex);
            return;
          }
        } catch (error) {
          console.warn(
            `Attempt ${retryCount + 1} for ${debugName} failed:`,
            error
          );

          if (errorFn) {
            await errorFn(error);
          }

          if (retryCount === MAX_RETRIES - 1) {
            logError(error as Error, {
              debugName,
            });
            throw error;
          }
        }

        console.debug("Increase retry count");
        retryCount++;
        let delay: number;
        if (retryCount <= 3) {
          delay = retryCount * BASE_RETRY_DELAY; // Linear increase for the first 3 retries
        } else {
          delay = Math.pow(2, retryCount - 3) * BASE_RETRY_DELAY; // Exponential backoff for subsequent retries
        }
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    },
    [wallet]
  );

  return { runWithCorrectNonce };
};
