import { MonsterElement } from "../types";

const elementMap: Record<MonsterElement, string> = {
  [MonsterElement.None]: "help",
  [MonsterElement.Electric]: "bolt",
  [MonsterElement.Fire]: "local_fire_department",
  [MonsterElement.Water]: "water_drop",
  [MonsterElement.Mental]: "neurology",
  [MonsterElement.Nature]: "spa",
  [MonsterElement.Toxic]: "skull",
};

export function getIconForElement(element: MonsterElement): string {
  return elementMap[element] || "help";
}
