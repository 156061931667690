import React from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { MonsterCard } from "../monsterCard";
import { ModalMonsterDetailProps } from "./modalMonsterDetailProps";

export const ModalMonsterDetail: React.FC<ModalMonsterDetailProps> = ({
  monster,
  statusEffects,
  isVisible,
  onClose,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  return (
    <Modal isVisible={isVisible} onClose={onClose} className="py-4">
      <div className="flex flex-1 flex-col" onClick={onClose}>
        <div className="flex flex-1 items-center justify-center">
          {monster && (
            <div className="flex w-3/6">
              <MonsterCard monster={monster} className="w-50" />
            </div>
          )}
        </div>
        <div className="flex flex-1 flex-col mt-8 space-y-2">
          {statusEffects && statusEffects.length > 0 && (
            <h3 className="text-lg text-center text-white font-display font-bold mb-2">
              Status Effects
            </h3>
          )}

          {statusEffects?.map((statusEffect, index) => {
            const { name, description, imageURI, remainingTurns } =
              statusEffect;

            const isBoostEffect = [
              "boostAttack",
              "boostDefense",
              "boostSpeed",
            ].includes(statusEffect?.key);
            return (
              <div
                key={`${index}-${name}`}
                className="flex flex-row w-full p-2 space-x-2 items-center bg-background-primary/80 backdrop-blur-md rounded-lg shadow-sm border border-background-primary/20"
              >
                <img
                  src={imageURI}
                  className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
                />

                <div className="flex flex-1 flex-col">
                  <span className="font-display font-bold text-s align-top">
                    {name}{" "}
                    {!isBoostEffect && (
                      <span className="text-xs">
                        • {remainingTurns.toString()} turns remaining
                      </span>
                    )}
                  </span>
                  <span className="text-xs">{description}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
