const transition = {
  type: "spring",
  stiffness: 300,
  damping: 20,
};

export const variantTop = {
  hidden: { x: -300, opacity: 0 },
  visible: { x: 0, opacity: 1, transition },
};

export const variantBottom = {
  hidden: { x: 300, opacity: 0 },
  visible: { x: 0, opacity: 1, transition },
};

export const variantVersus = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition },
};
