import { motion } from "framer-motion";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { transition, variants } from "./modalBattleUserMotion";
import { ModalBattleUserProps } from "./modalBattleUserProps";
import { ActionBar, Button, KeyValue, MonsterCard, WalletAddressBox } from "..";

export const ModalBattleUser: React.FC<ModalBattleUserProps> = ({
  userName,
  team,
  statistics,
  imageURI,
  isVisible,
  walletAddress,
  soundEnabled,
  onClose,
  onForfeit,
  onToggleSound,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleForfeitAsync = useCallback(async () => {
    if (!onForfeit) return;

    setLoading(true);
    await onForfeit();
    setLoading(false);
  }, [onForfeit]);

  const handleLeaveBattle = () => {
    navigate("/battle-preparation", { replace: true });
  };

  const handleForfeitAndLeaveBattle = async () => {
    await handleForfeitAsync();
    handleLeaveBattle();
  };

  return (
    <Modal isVisible={isVisible} className="py-4">
      <div className="flex flex-1 flex-col justify-end px-2">
        <div className="flex flex-1 flex-col justify-center">
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={transition}
            className="relative flex p-2 rounded-3xl w-full bg-background-primary/50 backdrop-blur-xl border border-background-primary/20 shadow-lg"
          >
            <div className="flex flex-1 flex-col pt-4 px-2 pb-2 space-y-2 rounded-2xl bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
              <div className="flex flex-col items-center space-y-2">
                <img
                  className="h-auto w-1/3 shadow-sm aspect-square rounded-full"
                  src={imageURI}
                />
                {userName && (
                  <h2 className="text-lg text-center font-display font-bold">
                    {userName}
                  </h2>
                )}
              </div>

              <WalletAddressBox address={walletAddress} />

              {/* {statistics && statistics?.length > 0 && (
                <div className="flex flex-row space-x-2">
                  {statistics.map((statistic) => (
                    <div
                      key={statistic.name}
                      className="flex flex-1 aspect-square items-center rounded-lg justify-center bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm"
                    >
                      <KeyValue
                        keyText={statistic.name}
                        valueText={statistic.value}
                      />
                    </div>
                  ))}
                </div>
              )} */}

              {team && team?.length > 0 && (
                <div className="flex flex-row space-x-2">
                  {team.map((monster) => (
                    <MonsterCard
                      key={monster.tokenId}
                      monster={monster}
                      className="rounded-lg"
                    />
                  ))}
                </div>
              )}
            </div>

            <Button
              variant="light"
              size="2xs"
              onClick={onClose}
              className="absolute -top-2 -right-2"
              disabled={loading}
              sound="close"
            >
              <span className="material-symbols-rounded text-lg">close</span>
            </Button>
          </motion.div>
        </div>
        <ActionBar variant="floating">
          <Button
            variant="light"
            size="xs"
            className="flex-1"
            disabled={loading}
            onClick={handleForfeitAndLeaveBattle}
            sound="error2"
          >
            {loading ? "Loading..." : "Leave Battle"}
          </Button>
          <Button
            variant="light"
            size="xs"
            className="flex-1"
            onClick={onToggleSound}
          >
            {soundEnabled ? "Sound Off" : "Sound On"}
          </Button>
        </ActionBar>
      </div>
    </Modal>
  );
};
