import { createContext } from "react";
import { pmon } from "../chains.ts";

type ContextProps = {
  chainId: number;
  noPromptOnSignature: boolean;
  setChainId: (chainId: number) => void;
  setNoPromptOnSignature: (noPromptOnSignature: boolean) => void;
};

export const AppContext = createContext<ContextProps>({
  chainId: 0,
  noPromptOnSignature: true,
  setChainId: (chainId: number) => {},
  setNoPromptOnSignature: () => {},
});
