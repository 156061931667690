import cn from "classnames";
import React, { useCallback, useMemo, useState } from "react";

import { Modal } from "../modal";
import { InfoItem, ModalInfoProps } from "./modalInfoProps.ts";

interface EnrichedInfoItem extends InfoItem {
  isOpen: boolean;
}

export const ModalInfo: React.FC<ModalInfoProps> = ({
  isVisible,
  title = "",
  subTitle = "",
  onClose,
  infoItems = [],
}) => {
  const [openedInfoItemIndexes, setOpenedInfoItemIndexes] = useState<number[]>(
    []
  );

  const handleInfoItemClick = useCallback((index: number) => {
    setOpenedInfoItemIndexes((prev) => {
      if (prev.includes(index)) {
        return prev.filter((i) => i !== index);
      }
      return [...prev, index];
    });
  }, []);

  const infoItemsEnriched = useMemo<EnrichedInfoItem[]>(() => {
    return infoItems.map((infoItem, index) => {
      return {
        ...infoItem,
        isOpen: openedInfoItemIndexes.includes(index),
      };
    });
  }, [infoItems, openedInfoItemIndexes]);

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      className="py-4"
      title={title}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 items-start justify-center">
          <div className="flex flex-col space-y-2 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
            <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
              <ul role="list" className="divide-y divide-border-base-300">
                {infoItemsEnriched.map((infoItem, index) => (
                  <li
                    key={infoItem.title}
                    className="flex gap-x-4 py-5"
                    onClick={handleInfoItemClick.bind(null, index)}
                  >
                    {infoItem.image && (
                      <img
                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                        src={infoItem.image}
                        alt=""
                      />
                    )}

                    <div className="flex-auto">
                      <div className="flex items-baseline justify-between gap-x-4">
                        <p className="text-sm font-semibold leading-6 text-text-primary">
                          {infoItem.title}
                        </p>
                        <span className="material-symbols-rounded text-sm">
                          {infoItem.isOpen ? "expand_less" : "expand_more"}
                        </span>
                      </div>
                      <p
                        className={cn("mt-1 text-sm text-text-secondary", {
                          "line-clamp-2 ": !infoItem.isOpen,
                        })}
                      >
                        {infoItem.text}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
