export const animateVariants = {
  hidden: {
    y: "-100%", // starts off-screen above the visible area
    opacity: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
  visible: {
    y: "0%", // slides down to its original position at the top
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
  },
};
