import { RarityLevel } from "../types";

export function shortenRarity(rarity?: RarityLevel) {
  switch (rarity) {
    case RarityLevel.Common:
      return "C";
    case RarityLevel.Uncommon:
      return "U";
    case RarityLevel.Rare:
      return "R";
    case RarityLevel.VeryRare:
      return "VR";
    case RarityLevel.UltraRare:
      return "UR";
    case RarityLevel.Mythic:
      return "M";
    default:
      return rarity;
  }
}
