import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { Address, WriteContractResult } from "@wagmi/core";
import { useCallback } from "react";
import { writeContractConfidentialDirect } from "../../../sapphire";
import { zeroAddress } from "viem";

export type PackSell = {
  sellAsync: () => Promise<WriteContractResult | undefined>;
};

export const useSellPacks = (
  tokenIds: bigint[],
  to: Address,
  enabled: boolean
): PackSell => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  const sellAsync = useCallback(async () => {
    if (enabled && tokenIds.length > 0 && to !== zeroAddress) {
      return await writeContractConfidentialDirect({
        contract,
        functionName: "sell",
        args: [tokenIds, to],
        gas: 500_000n,
      });
    }
  }, [tokenIds, to, enabled, contract]);

  return {
    sellAsync,
  };
};
