import IMAGES from "../../../assets/images";
import { ShieldTactic } from "./ActionProps";

export const SHIELD_TACTICS: ShieldTactic[] = [
  {
    key: "shieldCleansingShield",
    title: "Cleansing Shield",
    description:
      "Unleash a purifying shield that sweeps away all negative effects from your monster.",
    imageURI: IMAGES.shieldCleansingShield,
    contractName: "CleansingShieldMove",
  },
  {
    key: "shieldElementalWall",
    title: "Elemental Wall",
    description:
      "Erect a sturdy elemental barrier that blocks all incoming attacks for two turns.",
    imageURI: IMAGES.shieldElementalWall,
    contractName: "ElementalWallMove",
  },
  {
    key: "shieldCloudCover",
    title: "Cloud Cover",
    description:
      "Summon a cloud cover to obscure the enemy, reducing their chance of hitting for two turns.",
    imageURI: IMAGES.shieldCloudCover,
    contractName: "CloudCoverMove",
  },
  {
    key: "shieldTailwind",
    title: "Tailwind",
    description:
      "Stir a tailwind to sharpen focus, increasing the chance of landing critical hits for three turns.",
    imageURI: IMAGES.shieldTailwind,
    contractName: "TailwindMove",
  },
];
