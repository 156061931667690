import { motion } from "framer-motion";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

import { SelectionSlotProps } from "./selectionSlotProps";

export const SelectionSlot = forwardRef<HTMLButtonElement, SelectionSlotProps>(
  ({ className, ...props }, ref) => {
    return (
      <motion.button
        className={twMerge(
          "flex flex-1 w-32 aspect-square p-2 rounded-2xl bg-background-primary/40 border border-background-primary/10 backdrop-blur-sm transition-shadow shadow-lg active:shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
          className,
        )}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
        ref={ref}
        {...props}
      >
        <div className="w-full h-full flex items-center justify-center rounded-lg bg-background-dark/10 border border-background-dark/5">
          <span className="material-symbols-rounded text-text-light">add</span>
        </div>
      </motion.button>
    );
  },
);
