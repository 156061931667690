import { Address } from "viem";

export class EventLog {
  id: bigint;
  name: string;
  debugName: string;
  player: Address;
  opponent: Address;
  monster: bigint;
  opponentMonster: bigint;
  timestamp: bigint;
  round: bigint;

  constructor(
    id: bigint,
    name: string,
    debugName: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
  ) {
    this.id = id;
    this.name = name;
    this.debugName = debugName;
    this.player = player;
    this.opponent = opponent;
    this.monster = monster;
    this.opponentMonster = opponentMonster;
    this.timestamp = timestamp;
    this.round = round;
  }
}

export class MonsterDefeatedLog extends EventLog {
  static INTERFACE = "uint256";
  static ON_CHAIN_NAME = BigInt(1_000_004);

  monsterId: bigint;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [monsterId]: [bigint],
  ) {
    super(
      id,
      name,
      "MonsterDefeatedLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.monsterId = monsterId;
  }
}

export class AddStatusEffectLog extends EventLog {
  static INTERFACE = "address,uint256,uint256";
  static ON_CHAIN_NAME = BigInt(1);

  monsterId: bigint;
  statusEffect: Address;
  statusEffectName?: string;
  remainingTurns: bigint;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [statusEffect, monsterId, remainingTurns]: [Address, bigint, bigint],
  ) {
    super(
      id,
      name,
      "AddStatusEffectLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.statusEffect = statusEffect;
    this.monsterId = monsterId;
    this.remainingTurns = remainingTurns;
  }
}

export class ApplyMonsterStatusEffectLog extends EventLog {
  static INTERFACE = "address,uint256,uint256";
  static ON_CHAIN_NAME = BigInt(2);

  monsterId: bigint;
  statusEffect: Address;
  statusEffectName?: string;
  extraData: bigint;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [statusEffect, monsterId, extraData]: [Address, bigint, bigint],
  ) {
    super(
      id,
      name,
      "ApplyMonsterStatusEffectLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.statusEffect = statusEffect;
    this.monsterId = monsterId;
    this.extraData = extraData;
  }
}

export class ApplyMoveStatusEffectLog extends EventLog {
  static INTERFACE = "address,address,bool";
  static ON_CHAIN_NAME = BigInt(3);

  move: Address;
  moveName?: string;
  statusEffect: Address;
  statusEffectName?: string;
  isHit: boolean;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [statusEffect, move, isHit]: [Address, Address, boolean],
  ) {
    super(
      id,
      name,
      "ApplyMoveStatusEffectLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.statusEffect = statusEffect;
    this.move = move;
    this.isHit = isHit;
  }
}

export class ApplyOtherStatusEffectLog extends EventLog {
  static INTERFACE = "address,bool";
  static ON_CHAIN_NAME = BigInt(4);

  statusEffect: Address;
  statusEffectName?: string;
  isHit: boolean;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [statusEffect, isHit]: [Address, boolean],
  ) {
    super(
      id,
      name,
      "ApplyOtherStatusEffectLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.statusEffect = statusEffect;
    this.isHit = isHit;
  }
}

export class DamageLog extends EventLog {
  static INTERFACE = "address,uint256,uint256,uint256,uint256,bool";
  static ON_CHAIN_NAME = BigInt(5);

  move: Address;
  moveName?: string;
  attacker: bigint;
  defender: bigint;
  damage: bigint;
  elementalMultiplier: bigint;
  isCritical: boolean;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [move, attacker, defender, damage, elementalMultiplier, isCritical]: [
      Address,
      bigint,
      bigint,
      bigint,
      bigint,
      boolean,
    ],
  ) {
    super(
      id,
      name,
      "DamageLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.move = move;
    this.attacker = attacker;
    this.defender = defender;
    this.damage = damage;
    this.elementalMultiplier = elementalMultiplier;
    this.isCritical = isCritical;
  }
}

export class HealLog extends EventLog {
  static INTERFACE = "address,uint256,uint256";
  static ON_CHAIN_NAME = BigInt(6);

  move: Address;
  moveName?: string;
  monsterId: bigint;
  heal: bigint;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [move, monsterId, heal]: [Address, bigint, bigint],
  ) {
    super(
      id,
      name,
      "HealLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.move = move;
    this.monsterId = monsterId;
    this.heal = heal;
  }
}

export class RemoveStatusEffectsByGroupLog extends EventLog {
  static INTERFACE = "address,uint256,uint256";
  static ON_CHAIN_NAME = BigInt(7);

  move: Address;
  moveName?: string;
  monsterId: bigint;
  group: bigint;
  groupName: string;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [move, monsterId, group]: [Address, bigint, bigint],
  ) {
    super(
      id,
      name,
      "RemoveStatusEffectsByGroupLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );

    this.move = move;
    this.monsterId = monsterId;
    this.group = group;

    if (this.group === BigInt(0)) {
      this.groupName = "Buff";
    } else if (this.group === BigInt(1)) {
      this.groupName = "Debuff";
    } else if (this.group === BigInt(2)) {
      this.groupName = "Status";
    } else {
      this.groupName = "Unknown";
    }
  }
}

export class CommitMoveLog extends EventLog {
  static INTERFACE = "address,bytes32";
  static ON_CHAIN_NAME = BigInt(1_000_000);

  player: Address;
  commit: string;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [_player, commit]: [Address, string],
  ) {
    super(
      id,
      name,
      "CommitMoveLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.player = _player;
    this.commit = commit;
  }
}

export class RevealMoveLog extends EventLog {
  static INTERFACE = "address,address";
  static ON_CHAIN_NAME = BigInt(1_000_001);

  player: Address;
  move: Address;
  moveName?: string;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [_player, move]: [Address, Address],
  ) {
    super(
      id,
      name,
      "RevealMoveLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.player = _player;
    this.move = move;
  }
}

export class FirstStrikerLog extends EventLog {
  static INTERFACE = "uint256";
  static ON_CHAIN_NAME = BigInt(1_000_002);

  monsterId: bigint;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [monsterId]: [bigint],
  ) {
    super(
      id,
      name,
      "FirstStrikerLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.monsterId = monsterId;
  }
}

export class GameOverLog extends EventLog {
  static INTERFACE = "address";
  static ON_CHAIN_NAME = BigInt(1_000_003);

  winner: Address;

  constructor(
    id: bigint,
    name: string,
    player: Address,
    opponent: Address,
    monster: bigint,
    opponentMonster: bigint,
    timestamp: bigint,
    round: bigint,
    [winner]: [Address],
  ) {
    super(
      id,
      name,
      "GameOverLog",
      player,
      opponent,
      monster,
      opponentMonster,
      timestamp,
      round,
    );
    this.winner = winner;
  }
}

export type EventLogType =
  | AddStatusEffectLog
  | ApplyMonsterStatusEffectLog
  | ApplyMoveStatusEffectLog
  | ApplyOtherStatusEffectLog
  | DamageLog
  | HealLog
  | RemoveStatusEffectsByGroupLog
  | CommitMoveLog
  | RevealMoveLog
  | FirstStrikerLog
  | GameOverLog
  | MonsterDefeatedLog;

export const eventLogClasses = [
  AddStatusEffectLog,
  ApplyMonsterStatusEffectLog,
  ApplyMoveStatusEffectLog,
  ApplyOtherStatusEffectLog,
  DamageLog,
  HealLog,
  RemoveStatusEffectsByGroupLog,
  CommitMoveLog,
  RevealMoveLog,
  FirstStrikerLog,
  GameOverLog,
  MonsterDefeatedLog,
];

export type ChainEventLog = {
  id: bigint;
  action: bigint;
  timestamp: bigint;
  data: `0x${string}`;
  player: Address;
  opponent: Address;
  monster: bigint;
  opponentMonster: bigint;
  round: bigint;
};
