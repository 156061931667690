import { Monster, MonsterId } from "../../../types";
import { MatchMakerV3Abi, MatchMakerV3AbiConfidential } from "../abis";
import { contracts } from "../contracts.ts";
import { PLACEHOLDER_CHAIN_ID, sapphire } from "../../../chains.ts";
import { useCallback, useMemo, useState } from "react";
import { useRunWithCorrectNonce } from "../helpers";
import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { writeContractConfidential } from "../../../sapphire";
import { isSapphire } from "../../../sapphire/isSapphire.ts";
import { getCleanChainId } from "../../../utility";

const GAS = BigInt(5_000_000);
const MODE = parseInt(import.meta.env.VITE_GAME_MODE || "1");

// Utility function to check if the monster is valid
const isValidMonster = (monster?: Monster): boolean =>
  !!monster && monster.config.id !== MonsterId.None;

export const useMatchMaking = (
  chainId?: string,
  monster1?: Monster,
  monster2?: Monster
) => {
  const { wallet } = usePrivyWagmi();
  const matchMakerAbi = useMemo(() => {
    return isSapphire(wallet?.chainId)
      ? MatchMakerV3AbiConfidential
      : MatchMakerV3Abi;
  }, [wallet?.chainId]);

  const enabled =
    isValidMonster(monster1) &&
    isValidMonster(monster2) &&
    !!chainId &&
    chainId !== PLACEHOLDER_CHAIN_ID;

  const createAndJoinAsync = useCallback(async () => {
    const res = await writeContractConfidential({
      mode: "prepared",
      chainId: getCleanChainId(chainId),
      request: {
        address: contracts[chainId || PLACEHOLDER_CHAIN_ID].matchMaker,
        abi: matchMakerAbi,
        functionName: "createAndJoin",
        args: [MODE, monster1?.config.id, monster2?.config.id],
        gas: GAS,
      },
    });
    return res;
  }, [chainId, matchMakerAbi, monster1?.config.id, monster2?.config.id]);

  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const withdrawAsync = useCallback(async () => {
    setIsWithdrawing(true);
    const res = await writeContractConfidential({
      mode: "prepared",
      chainId: parseInt(chainId || "0"),
      request: {
        address: contracts[chainId || PLACEHOLDER_CHAIN_ID].matchMaker,
        abi: matchMakerAbi,
        functionName: "withdraw",
        args: [MODE],
        gas: GAS,
      },
    });
    setIsWithdrawing(false);
    return res;
  }, [chainId, matchMakerAbi]);

  const { runWithCorrectNonce } = useRunWithCorrectNonce(wallet);

  const join = useCallback(async () => {
    if (createAndJoinAsync) {
      await runWithCorrectNonce(createAndJoinAsync, () => {}, "createAndJoin");
    }
  }, [createAndJoinAsync, runWithCorrectNonce]);

  const withdraw = useCallback(async () => {
    if (withdrawAsync) {
      await runWithCorrectNonce(withdrawAsync, () => {}, "withdraw");
    }
  }, [runWithCorrectNonce, withdrawAsync]);

  return {
    join,
    enabled,
    withdraw,
    isWithdrawing,
  };
};
