/// @dev this key won't be needed anymore after a small refactoring
export const LOCAL_STORAGE_NOTIFICATIONS_ENABLED_KEY =
  "ocb:notifications-enabled";

export const LOCAL_STORAGE_MESSAGING_TOKEN_KEY = "ocb:messaging-token";
export const LOCAL_STORAGE_NOTIFICATIONS_REQUESTED_KEY =
  "ocb:notifications-requested";

export const NOTIFICATIONS_ENABLED = "1";
export const NOTIFICATIONS_DISABLED = "0";

export const NOTIFICATIONS_REQUESTED = "1";
export const NOTIFICATIONS_NOT_REQUESTED = "0";
