import { motion } from "framer-motion";
import React from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal"; 
import { MonsterCard } from "../monsterCard";
import { transitions, variants } from "./modalQueueMotion";
import { ModalQueueProps } from "./modalQueueProps";

export const ModalQueue: React.FC<ModalQueueProps> = ({
  isVisible,
  onClose,
  monsters,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const displayedMonsters = monsters ? Object.values(monsters) : [];
  return (
    <Modal
      title="In Queue"
      isVisible={isVisible}
      className="justify-center"
      onClose={onClose}
    >
      <div className="flex flex-1 flex-col  justify-center py-4">
        <motion.div
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={transitions}
          className="flex flex-col p-4 space-y-4 rounded-3xl bg-background-primary/40 backdrop-blur-xl border border-background-primary/20 shadow-lg"
        >
          <h2 className="font-display font-black text-lg text-center text-text-light">
            Your Team
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {displayedMonsters.map((monster) => (
              <MonsterCard
                key={monster?.tokenId?.toString()}
                monster={monster}
              />
            ))}
          </div>
        </motion.div>
      </div>
      <div className="flex flex-1 flex-col items-center justify-center p-4">
        <motion.div
          className="flex flex-col justify-center items-center w-10 h-10 bg-background-primary/40 border border-background-primary/20"
          animate={{
            scale: [1, 1.5, 1.5, 1, 1],
            rotate: [0, 0, 180, 180, 0],
            borderRadius: ["5%", "5%", "50%", "50%", "5%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 1,
          }}
        >
          <motion.div
            className="w-5 h-5 bg-background-primary/80"
            animate={{
              scale: [1, 1.25, 1.25, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["5%", "5%", "50%", "50%", "5%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
            }}
          />
        </motion.div>
        <div className="flex flex-col space-y-2">
          <h3 className="font-display text-md mt-12 text-center text-text-light">
            Queueing up in the Polyverse …
          </h3>
          <h3 className="font-display text-xs mt-12 text-center text-text-light">
            Opponents will be notified in our{" "}
            <strong className="text-text-highlight">Discord</strong> within 60
            seconds. Prepare for an electrifying battle!
          </h3>
        </div>
      </div>
    </Modal>
  );
};
