import { usePrepareContractWrite, useContractWrite, Address } from "wagmi";
import { parseEther, zeroAddress } from "viem";
import { useEmbeddedWallet } from "../wallet";
import { WriteContractResult } from "@wagmi/core";

const BRIDGING_GAS_COST = parseEther(`${0.00003}`);
console.log("BRIDGING_GAS_COST", BRIDGING_GAS_COST);

interface SwapArgs {
  address: Address;
  isEnabled?: boolean;
}

interface SwapResult {
  swapAsync: (() => Promise<WriteContractResult>) | undefined;
}

export function useSwap({ address, isEnabled }: SwapArgs): SwapResult {
  const { balances, native, ...rest } = useEmbeddedWallet(address);
  const value = native - BRIDGING_GAS_COST;

  const { config } = usePrepareContractWrite({
    address: "0x6022a020834d4CfF2aF8D0Ba6275eB7896054792",
    abi: [
      {
        inputs: [],
        name: "swapWithETH",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
    functionName: "swapWithETH",
    args: [],
    value,
    enabled: value > 0n && isEnabled,
  });

  const { writeAsync: swapAsync } = useContractWrite(config);

  return {
    swapAsync,
  };
}
