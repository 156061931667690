import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { wrap } from "popmotion";
import React, { useMemo, useState } from "react";

import IMAGES from "../../assets/images";
import { RetinaImageCarouselProps } from ".";
import { Button } from "..";

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

const buttonClassName = "absolute w-[40px] h-[40px] z-10";

export const RetinaImageCarousel = ({
  imageKeys,
  alts = [],
  ...rest
}: RetinaImageCarouselProps) => {
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, imageKeys.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const srcs = useMemo(() => {
    // @ts-ignore
    return imageKeys.map((imageKey) => IMAGES[imageKey]);
  }, [imageKeys]);

  // Error handling: Ensure src is provided
  if (srcs.length !== imageKeys.length) {
    throw new Error(
      `Error: No image found for one of the sources RetinaImage.`
    );
  }

  const srcSets = useMemo(() => {
    return srcs.map((src) => {
      const match = src.match(/\.(jpeg|jpg|png|gif|webp)$/);
      if (!match) {
        throw new Error("Error: Invalid image format or `src` format.");
      }

      const ext = match[0]; // capture the file extension

      return `
        ${src} 1x,
        ${src.replace(ext, `@2x${ext}`)} 2x,
        ${src.replace(ext, `@3x${ext}`)} 3x
      `;
    });
  }, [srcs]);

  return (
    <div className="relative flex justify-center items-center w-full h-full">
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          className="absolute w-full h-full rounded-xl"
          key={page}
          src={srcs[imageIndex]}
          srcSet={srcSets[imageIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.5, ease: "easeInOut" },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        />
      </AnimatePresence>
      {imageKeys.length > 1 && (
        <>
          <Button
            size="2xs"
            variant="light"
            className={cn(buttonClassName, "left-2")}
            onClick={() => paginate(1)}
            sound="select"
          >
            <span className="material-symbols-rounded text-lg">
              chevron_left
            </span>
          </Button>
          <Button
            size="2xs"
            variant="light"
            className={cn(buttonClassName, "right-2")}
            onClick={() => paginate(-1)}
            sound="select"
          >
            <span className="material-symbols-rounded text-lg">
              chevron_right
            </span>
          </Button>
        </>
      )}
    </div>
  );
};
