import { useState, useEffect, useCallback } from "react";
import { useSwap } from "./useSwap.ts";
import { useApprovePmon } from "./useApprovePmon.ts";
import { useBridgePmon } from "./useBridgePmon.ts";
import { Address } from "wagmi";
import { usePmonBalance } from "./usePmonBalance.ts";
import { usePmonAllowance } from "./usePmonAllowance.ts";
import { useInterval } from "react-use";

interface CombinedHookArgs {
  address: Address;
  isEnabled: boolean;
}

interface CombinedHookResult {
  executeAll: () => void;
  isLoading: boolean;
  error: Error | null;
  currentStep: number;
}

const STEP_KEY = "ocb:bridging:step";

export function useCombinedBridgeProcess({
  address,
  isEnabled,
}: CombinedHookArgs): CombinedHookResult {
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  // Read step from localStorage when component mounts
  useEffect(() => {
    const savedStep = localStorage.getItem(STEP_KEY);
    if (savedStep) {
      const step = parseInt(savedStep, 10);
      // Ensure that we resume only if the process is still enabled and not already completed
      if (isEnabled) {
        setCurrentStep(step);
      }
    }
  }, [isEnabled]);

  // Update localStorage when currentStep changes
  useEffect(() => {
    if (currentStep < 6) {
      localStorage.setItem(STEP_KEY, currentStep.toString());
    } else {
      // If the process is completed or halted, clear the step from localStorage
      localStorage.removeItem(STEP_KEY);
    }
  }, [currentStep]);

  // Swap logic
  const { swapAsync } = useSwap({
    address,
    isEnabled: currentStep === 1 && isEnabled,
  });
  // Approve logic
  const { approveAsync } = useApprovePmon({
    isEnabled: currentStep === 2 && isEnabled,
  });
  // Bridge logic
  const { depositAsync } = useBridgePmon({
    address,
    isEnabled: currentStep === 3 && isEnabled,
  });

  // PMON balance
  const { balance: pmonBalance, refetch: refetchBalance } =
    usePmonBalance(address);
  const { allowance: pmonAllowance, refetch: refetchAllowance } =
    usePmonAllowance(address, "0x1285D6cE3604D341b29ccfF300d043af1CDb57e3");

  useInterval(() => {
    if (isEnabled) {
      refetchAllowance();
      refetchBalance();
    }
  }, 10_000);

  const executeSwap = useCallback(async () => {
    if (swapAsync && !isLoading) {
      setIsLoading(true);
      try {
        await swapAsync();
        setCurrentStep(2); // Proceed to next step on success
      } catch (e) {
        setError(e as Error);
        // setCurrentStep(0); // Reset on error
      }
      setIsLoading(false);
    }
  }, [swapAsync, isLoading]);

  const executeApprove = useCallback(async () => {
    if (approveAsync && !isLoading) {
      setIsLoading(true);
      try {
        await approveAsync();
        setCurrentStep(3); // Proceed to next step on success
      } catch (e) {
        setError(e as Error);
        // setCurrentStep(0); // Reset on error
      }
      setIsLoading(false);
    }
  }, [approveAsync, isLoading]);

  const executeDeposit = useCallback(async () => {
    if (depositAsync && !isLoading) {
      setIsLoading(true);
      try {
        await depositAsync();
        setCurrentStep(5); // Use a new step to indicate completion beyond the sequence
        // Clear step from localStorage on completion
      } catch (e) {
        setError(e as Error);
        // setCurrentStep(0); // Reset on error
      }
      setIsLoading(false);
    }
  }, [depositAsync, isLoading]);

  // Initiator
  const executeAll = useCallback(() => {
    if (currentStep === 0) {
      // Allow restarting if completed or not started
      setCurrentStep(1); // Start the process
      // setError(null); // Reset errors
    }
  }, [currentStep]);

  useInterval(() => {
    if (isEnabled && currentStep === 0 && pmonBalance && pmonBalance > 0n) {
      if (pmonAllowance && pmonAllowance > pmonBalance) {
        setCurrentStep(3);
      } else {
        setCurrentStep(2);
      }
    }
  }, 5_000);

  // Effect to manage the sequence
  useEffect(() => {
    if (isEnabled) {
      switch (currentStep) {
        case 1:
          executeSwap();
          break;
        case 2:
          executeApprove();
          break;
        case 3:
          executeDeposit();
          break;
        case 5:
          // Completion state, do nothing or reset here
          break;
        default:
          // Do nothing
          break;
      }
    } else if (currentStep !== 5) {
      // If disabled in the middle of the process, optionally reset or handle as needed
      setCurrentStep(0);
      setIsLoading(false);
    }
  }, [currentStep, executeSwap, executeApprove, executeDeposit, isEnabled]);

  // Return a function to start the process, the current loading state, and the current step
  return { executeAll, isLoading, error, currentStep };
}
