import React, { MouseEventHandler } from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { MonsterCard } from "../monsterCard";
import { ModalBinderMonstersProps } from "./modalBinderMonstersProps.ts";
import { Button } from "../button";
import { useToggle } from "@uidotdev/usehooks";
import { ModalInfo } from "../modalInfo";
import { useReverseSwap } from "../../hooks";

const info = [
  {
    title: "What is a Reverse Swap?",
    text: "You can reverse swap a monster NFT back to PMON. For every reverse swap, there is a small fee, which you are donating to a battle reward pool. The fee is automatically deducted from the reverse swap amount.",
  },
  {
    title: "What is a Reroll?",
    text: "A reroll is like a reverse swap, but instead of getting PMON, you get a new pack. Similar to a reverse swap, there is a small fee, which you are donating to a battle reward pool.",
  },
  {
    title: "What if I own the same monster multiple times?",
    text: "Currently, the first monster you mint will be used on this screen. This may change in the future.",
  },
];

export const ModalBinderMonsters: React.FC<ModalBinderMonstersProps> = ({
  monsters,
  isLoading,
  isVisible,
  onClose,
  onReverseSwap,
  onReroll,
}) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const [isInfoVisible, toggleIsInfoVisible] = useToggle(false);

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      className="py-4"
      title={monsters.length > 0 ? monsters[0].config.name : ""}
    >
      <div className="relative h-full w-full flex items-center justify-center">
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 items-center justify-center">
            {monsters.map((monster, index) => {
              return (
                <div
                  className="flex flex-col w-full space-y-2"
                  key={monster.tokenId}
                >
                  <MonsterCard monster={monster} className="w-50" />
                  {isLoading ? (
                    <span className="pt-2 font-display text-md text-text-light text-center">
                      Loading...
                    </span>
                  ) : (
                    <div className="flex flex-row flex-1 space-x-2">
                      <Button
                        variant="light"
                        size="md"
                        className="flex-1"
                        disabled={false}
                        onClick={
                          onReverseSwap.bind(
                            null,
                            monster
                          ) as unknown as MouseEventHandler<HTMLButtonElement>
                        }
                        sound="error"
                      >
                        Reverse Swap
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="flex-1"
                        disabled={false}
                        onClick={
                          onReroll.bind(
                            null,
                            monster
                          ) as unknown as MouseEventHandler<HTMLButtonElement>
                        }
                        sound="select"
                      >
                        Reroll
                      </Button>
                      <Button
                        size="sm"
                        variant="light"
                        className="ml-2 w-12 h-12"
                        onClick={
                          toggleIsInfoVisible as unknown as MouseEventHandler<HTMLButtonElement>
                        }
                      >
                        <i className="material-symbols-rounded text-lg">info</i>
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <ModalInfo
          isVisible={isInfoVisible}
          title={"Help"}
          subTitle={"Bla bla bla subtitle!"}
          infoItems={info}
          onClose={toggleIsInfoVisible}
        />
      </div>
    </Modal>
  );
};
