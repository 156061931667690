import { usePrepareContractWrite, useContractWrite, Address } from "wagmi";
import { parseEther, zeroAddress } from "viem";
import { useEmbeddedWallet } from "../wallet";
import { WriteContractResult } from "@wagmi/core";

const BRIDGING_GAS_COST = parseEther(`${0.00003}`);
console.log("BRIDGING_GAS_COST", BRIDGING_GAS_COST);

interface SwapArgs {
  isEnabled?: boolean;
}

interface SwapResult {
  approveAsync: (() => Promise<WriteContractResult>) | undefined;
}

export function useApprovePmon({ isEnabled }: SwapArgs): SwapResult {
  const { config } = usePrepareContractWrite({
    address: "0xBC9B77acA82f6BE43927076D71cd453b625165B8",
    abi: [
      {
        constant: false,
        inputs: [
          {
            name: "_spender",
            type: "address",
          },
          {
            name: "_value",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [
          {
            name: "",
            type: "bool",
          },
        ],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    functionName: "approve",
    args: ["0x1285d6ce3604d341b29ccff300d043af1cdb57e3", parseEther("1000000")],
    enabled: isEnabled,
  });

  const { writeAsync: approveAsync } = useContractWrite(config);

  return {
    approveAsync,
  };
}
