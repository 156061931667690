import IMAGES from "../../../assets/images";
import { StatusEffectGroup } from "../../../types";
import { AppliedStatusEffect } from "./ActionProps";

export const STATUS_EFFECTS: AppliedStatusEffect[] = [
  {
    key: "dotBurned",
    title: "Burned",
    description:
      "The target has been set ablaze, inflicting 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotBurned,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "dotDrenched",
    title: "Drenched",
    description:
      "The target is soaked in chilling water, inflicting 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotDrenched,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "dotEntangled",
    title: "Entangled",
    description:
      "The target is entangled in thorny vines, inflicting 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotEntangled,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "dotPoisoned",
    title: "Poisoned",
    description:
      "The target has been poisoned, inflicting 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotPoisoned,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "dotPsyched",
    title: "Psyched",
    description:
      "The target's mind is is distressed, inflicting 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotPsyched,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "dotShocked",
    title: "Shocked",
    description:
      "The target is zapped causing a pulsing shock that inflicts 20% Final Damage over two rounds.",
    imageURI: IMAGES.dotShocked,
    group: StatusEffectGroup.DEBUFF,
  },

  {
    key: "controlBlinded",
    title: "Blinded",
    description:
      "The target is blinded by a splash of toxic water, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlBlinded,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "controlBlurred",
    title: "Blurred",
    description:
      "The target's vision is blurred by a surreal, mind-bending haze, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlBlurred,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "controlDisoriented",
    title: "Disoriented",
    description:
      "The target is disoriented by overwhelming mental and natural forces, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlDisoriented,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "controlFogged",
    title: "Fogged",
    description:
      "The target is fogged by a dense, electrically-charged mist, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlFogged,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "controlParalyzed",
    title: "Paralyzed",
    description:
      "The target is paralyzed by an electric fire shock, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlParalyzed,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "controlSmogged",
    title: "Smogged",
    description:
      "The target is enveloped in a thick smog of toxic mist, causing a 30% chance to miss attacks.",
    imageURI: IMAGES.controlSmogged,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "wallConfused",
    title: "Confused",
    description:
      "The target is confused, with a 50% chance to attack itself, dealing 50% of base damage.",
    imageURI: IMAGES.wallConfused,
    group: StatusEffectGroup.DEBUFF,
  },
  {
    key: "shieldTailwind",
    title: "Tail-Winded",
    description:
      "The monster is invigorated by a gusting tailwind, increasing its chance for critical strikes by 40% for the next 3 turns.",
    imageURI: IMAGES.shieldTailwind,
    group: StatusEffectGroup.BUFF,
  },
  {
    key: "shieldElementalWall",
    title: "Walled",
    description:
      "A barrier of elemental energy envelops the monster, granting immunity to all incoming attacks for 2 turns with an 80% success rate.",
    imageURI: IMAGES.shieldElementalWall,
    group: StatusEffectGroup.WALL,
  },
  {
    key: "shieldCloudCover",
    title: "Cloud-Covered",
    description:
      "A shroud of clouds conceals the monster, reducing the enemy's chance to hit by 40% for 2 turns.",
    imageURI: IMAGES.shieldCloudCover,
    group: StatusEffectGroup.BUFF,
  },
  {
    key: "boostAttack",
    title: "Attack Aura",
    description:
      "A fierce glow surrounds the monster, honing its offensive might and boosting its attack attribute by 20.",
    imageURI: IMAGES.boostAttack,
    group: StatusEffectGroup.BUFF,
  },
  {
    key: "boostDefense",
    title: "Defense Aura",
    description:
      "A defensive sheen envelopes the monster, reinforcing its resilience and increasing its defense attribute by 20, then 10, and 5 on subsequent uses.",
    imageURI: IMAGES.boostDefense,
    group: StatusEffectGroup.BUFF,
  },
  {
    key: "boostSpeed",
    title: "Speed Aura",
    description:
      "Winds of haste empower the monster, enhancing its reflexes and raising its speed attribute by 20.",
    imageURI: IMAGES.boostSpeed,
    group: StatusEffectGroup.BUFF,
  },
];
