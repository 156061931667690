import { useMemo } from "react";
import { useContractRead } from "wagmi";
import { ContractApiV1Abi } from "../abis";
import { contracts } from "../contracts.ts";
import { Address } from "viem";
import { PLACEHOLDER_CHAIN_ID } from "../../../chains.ts";

const VERSION = BigInt(0);

const names = [
  "DamageOverTimeMove",
  "ControlMove",
  "PurgeBuffsMove",
  "WallBreakerMove",
  "CleansingShieldMove",
  "CloudCoverMove",
  "ElementalWallMove",
  "TailwindMove",
  "AttackAuraMove",
  "DefenseAuraMove",
  "HealMove",
  "SpeedAuraMove",
  "DamageOverTimeEffect",
  "FoggedEffect",
  "CloudCoverEffect",
  "ElementalWallEffect",
  "TailwindEffect",
  "AttackAuraEffect",
  "DefenseAuraEffect",
  "SpeedAuraEffect",
  "ConfusedEffect",
];

export const useContracts = (chainId: string) => {
  const {
    data: contractApi,
    isLoading,
  }: { data: Address[] | undefined; isLoading: boolean } = useContractRead({
    address: contracts[chainId].contractApi,
    abi: ContractApiV1Abi,
    functionName: "getContracts",
    args: [VERSION, names],
    enabled: chainId !== PLACEHOLDER_CHAIN_ID,
    cacheTime: 300_000,
  });

  // map the returned data as a reverse mapping from address to name and put into useMemo
  const contractsMapped: Map<Address, string> = useMemo(() => {
    const map = new Map<Address, string>();
    contractApi?.forEach((address: Address, index) => {
      map.set(address, names[index]);
    });
    return map;
  }, [contractApi]);

  return {
    contracts: contractsMapped,
    isLoading,
  };
};
