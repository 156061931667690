import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { useContractRead, UseContractReadConfig } from "wagmi";
import { Address, formatEther } from "viem";
import { useInterval } from "usehooks-ts";

type BuyPriceInfo = {
  buyPriceLoading: boolean;
  buyPrice: bigint | undefined;
  buyPriceFormatted: string | undefined;
  buyPriceFormattedRounded: string | undefined;
  buyPricePerPack: bigint | undefined;
  buyPricePerPackFormatted: string | undefined;
  buyPricePerPackFormattedRounded: string | undefined;
};

export const useBuyPrice = (amountToBuy: bigint = 0n): BuyPriceInfo => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  // Using useContractRead for a single contract call
  const { data, isLoading, refetch } = useContractRead({
    ...contract,
    functionName: "getBuyPriceInclFees",
    args: [amountToBuy],
    enabled: amountToBuy > 0n,
  } as UseContractReadConfig);

  useInterval(() => {
    refetch();
  }, 5_000);

  return {
    buyPriceLoading: isLoading,
    buyPrice: (data as bigint) ?? 0n,
    buyPriceFormatted: formatEther((data as bigint) ?? 0n),
    buyPriceFormattedRounded: `${parseFloat(
      formatEther((data as bigint) ?? 0n)
    ).toFixed(5)}`,
    buyPricePerPack:
      amountToBuy > 0n ? ((data as bigint) ?? 0n) / amountToBuy : 0n,
    buyPricePerPackFormatted:
      amountToBuy > 0n
        ? formatEther(((data as bigint) ?? 0n) / amountToBuy)
        : "0",
    buyPricePerPackFormattedRounded:
      amountToBuy > 0n
        ? `${parseFloat(
            formatEther(((data as bigint) ?? 0n) / amountToBuy)
          ).toFixed(5)}`
        : "0.00000",
  };
};
