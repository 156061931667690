import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { useContractRead, useContractWrite } from "wagmi";
import { Address, zeroAddress } from "viem";
import { WriteContractResult } from "@wagmi/core";
import { writeContractConfidentialDirect } from "../../../sapphire";
import { useCallback } from "react";

export type PackReverseSwap = {
  reverseSwapAsync: () => Promise<WriteContractResult | undefined>;
};

export const useReverseSwap = (
  tokenId?: bigint,
  to?: Address,
  enabled?: boolean
): PackReverseSwap => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  const { data: fee }: { data: bigint | undefined } = useContractRead({
    ...contract,
    functionName: "FEE",
    args: [],
  });

  const reverseSwapAsync = useCallback(async () => {
    if (
      typeof tokenId !== "undefined" &&
      to !== zeroAddress &&
      enabled &&
      typeof fee !== "undefined"
    ) {
      return await writeContractConfidentialDirect({
        contract,
        functionName: "reverseSwap",
        args: [tokenId, to],
        value: fee,
        gas: 500_000n,
      });
    }
  }, [tokenId, to, fee, enabled, contract]);

  return {
    reverseSwapAsync,
  };
};
