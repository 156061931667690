import IMAGES from "../assets/images";
import { MonsterConfig, MonsterElement, MonsterId, TypeRarity } from "../types";
import { MonsterTypes } from "./monsterTypes";

const Blazehorn: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Blazehorn,
  element: MonsterElement.Fire,
  name: "Blazehorn",
  imageURI: IMAGES.artworkBullFire,
};

const Flampanda: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Flampanda,
  element: MonsterElement.Fire,
  name: "Flampanda",
  imageURI: IMAGES.artworkBearFire,
};

const Pyrilla: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Pyrilla,
  element: MonsterElement.Fire,
  name: "Pyrilla",
  imageURI: IMAGES.artworkGorillaFire,
};

const Fernopig: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Fernopig,
  element: MonsterElement.Fire,
  name: "Fernopig",
  imageURI: IMAGES.artworkBoarFire,
};

const Foretusk: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Foretusk,
  element: MonsterElement.Nature,
  name: "Foretusk",
  imageURI: IMAGES.artworkBullNature,
};

const Verdubear: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Verdubear,
  element: MonsterElement.Nature,
  name: "Verdubear",
  imageURI: IMAGES.artworkBearNature,
};

const Florangutan: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Florangutan,
  element: MonsterElement.Nature,
  name: "Florangutan",
  imageURI: IMAGES.artworkGorillaNature,
};

const Leafsnout: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Leafsnout,
  element: MonsterElement.Nature,
  name: "Leafsnout",
  imageURI: IMAGES.artworkBoarNature,
};

const Aquasteer: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Aquasteer,
  element: MonsterElement.Water,
  name: "Aquasteer",
  imageURI: IMAGES.artworkBullWater,
};

const Wavepaw: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Wavepaw,
  element: MonsterElement.Water,
  name: "Wavepaw",
  imageURI: IMAGES.artworkBearWater,
};

const Tidalmonk: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Tidalmonk,
  element: MonsterElement.Water,
  name: "Tidalmonk",
  imageURI: IMAGES.artworkGorillaWater,
};

const Streamhog: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Streamhog,
  element: MonsterElement.Water,
  name: "Streamhog",
  imageURI: IMAGES.artworkBoarWater,
};

const Thunderhoof: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Thunderhoof,
  element: MonsterElement.Electric,
  name: "Thunderhoof",
  imageURI: IMAGES.artworkBullElectric,
};

const Shockfur: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Shockfur,
  element: MonsterElement.Electric,
  name: "Shockfur",
  imageURI: IMAGES.artworkBearElectric,
};

const Electrang: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Electrang,
  element: MonsterElement.Electric,
  name: "Electrang",
  imageURI: IMAGES.artworkGorillaElectric,
};

const Zappig: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Zappig,
  element: MonsterElement.Electric,
  name: "Zappig",
  imageURI: IMAGES.artworkBoarElectric,
};

const Psyhorn: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Psyhorn,
  element: MonsterElement.Mental,
  name: "Psyhorn",
  imageURI: IMAGES.artworkBullMental,
};

const Dreamgrowl: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Dreamgrowl,
  element: MonsterElement.Mental,
  name: "Dreamgrowl",
  imageURI: IMAGES.artworkBearMental,
};

const Cerebrilla: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Cerebrilla,
  element: MonsterElement.Mental,
  name: "Cerebrilla",
  imageURI: IMAGES.artworkGorillaMental,
};

const Enighog: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Enighog,
  element: MonsterElement.Mental,
  name: "Enighog",
  imageURI: IMAGES.artworkBoarMental,
};

const Toxicorn: MonsterConfig = {
  ...MonsterTypes.bull,
  id: MonsterId.Toxicorn,
  element: MonsterElement.Toxic,
  name: "Toxicorn",
  imageURI: IMAGES.artworkBullToxic,
};

const Poisonclaw: MonsterConfig = {
  ...MonsterTypes.bear,
  id: MonsterId.Poisonclaw,
  element: MonsterElement.Toxic,
  name: "Poisonclaw",
  imageURI: IMAGES.artworkBearToxic,
};

const Gloomgrip: MonsterConfig = {
  ...MonsterTypes.gorilla,
  id: MonsterId.Gloomgrip,
  element: MonsterElement.Toxic,
  name: "Gloomgrip",
  imageURI: IMAGES.artworkGorillaToxic,
};

const Fumebrow: MonsterConfig = {
  ...MonsterTypes.boar,
  id: MonsterId.Fumebrow,
  element: MonsterElement.Toxic,
  name: "Fumebrow",
  imageURI: IMAGES.artworkBoarToxic,
};

const Firizard: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Firizard,
  element: MonsterElement.Fire,
  name: "Firizard",
  imageURI: IMAGES.artworkLizardFire,
};

const Floralon: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Floralon,
  element: MonsterElement.Nature,
  name: "Floralon",
  imageURI: IMAGES.artworkLizardNature,
};

const Reptide: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Reptide,
  element: MonsterElement.Water,
  name: "Reptide",
  imageURI: IMAGES.artworkLizardWater,
};

const Chargecrest: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Chargecrest,
  element: MonsterElement.Electric,
  name: "Chargecrest",
  imageURI: IMAGES.artworkLizardElectric,
};

const Cogniscale: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Cogniscale,
  element: MonsterElement.Mental,
  name: "Cogniscale",
  imageURI: IMAGES.artworkLizardMental,
};

const Acidtongue: MonsterConfig = {
  ...MonsterTypes.lizard,
  id: MonsterId.Acidtongue,
  element: MonsterElement.Toxic,
  name: "Acidtongue",
  imageURI: IMAGES.artworkLizardToxic,
};

const Magmaphant: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Magmaphant,
  element: MonsterElement.Fire,
  name: "Magmaphant",
  imageURI: IMAGES.artworkElephantFire,
};

const Grovemaw: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Grovemaw,
  element: MonsterElement.Nature,
  name: "Grovemaw",
  imageURI: IMAGES.artworkElephantNature,
};

const Aquarump: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Aquarump,
  element: MonsterElement.Water,
  name: "Aquarump",
  imageURI: IMAGES.artworkElephantWater,
};

const Voltusk: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Voltusk,
  element: MonsterElement.Electric,
  name: "Voltusk",
  imageURI: IMAGES.artworkElephantElectric,
};

const Mentolox: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Mentolox,
  element: MonsterElement.Mental,
  name: "Mentolox",
  imageURI: IMAGES.artworkElephantMental,
};

const Sludgetrunk: MonsterConfig = {
  ...MonsterTypes.elephant,
  id: MonsterId.Sludgetrunk,
  element: MonsterElement.Toxic,
  name: "Sludgetrunk",
  imageURI: IMAGES.artworkElephantToxic,
};

const Flarejaw: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Flarejaw,
  element: MonsterElement.Fire,
  name: "Flarejaw",
  imageURI: IMAGES.artworkWhaleFire,
};

const Verdantide: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Verdantide,
  element: MonsterElement.Nature,
  name: "Verdantide",
  imageURI: IMAGES.artworkWhaleNature,
};

const Bubblestream: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Bubblestream,
  element: MonsterElement.Water,
  name: "Bubblestream",
  imageURI: IMAGES.artworkWhaleWater,
};

const Sparkfin: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Sparkfin,
  element: MonsterElement.Electric,
  name: "Sparkfin",
  imageURI: IMAGES.artworkWhaleElectric,
};

const Psycheleap: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Psycheleap,
  element: MonsterElement.Mental,
  name: "Psycheleap",
  imageURI: IMAGES.artworkWhaleMental,
};

const Slimefin: MonsterConfig = {
  ...MonsterTypes.whale,
  id: MonsterId.Slimefin,
  element: MonsterElement.Toxic,
  name: "Slimefin",
  imageURI: IMAGES.artworkWhaleToxic,
};

const Flamlion: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Flamlion,
  element: MonsterElement.Fire,
  name: "Flamlion",
  imageURI: IMAGES.artworkLionFire,
};

const Greenwhelp: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Greenwhelp,
  element: MonsterElement.Nature,
  name: "Greenwhelp",
  imageURI: IMAGES.artworkLionNature,
};

const Ripplemane: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Ripplemane,
  element: MonsterElement.Water,
  name: "Ripplemane",
  imageURI: IMAGES.artworkLionWater,
};

const Voltmane: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Voltmane,
  element: MonsterElement.Electric,
  name: "Voltmane",
  imageURI: IMAGES.artworkLionElectric,
};

const Cerebropaw: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Cerebropaw,
  element: MonsterElement.Mental,
  name: "Cerebropaw",
  imageURI: IMAGES.artworkLionMental,
};

const Venomroar: MonsterConfig = {
  ...MonsterTypes.lion,
  id: MonsterId.Venomroar,
  element: MonsterElement.Toxic,
  name: "Venomroar",
  imageURI: IMAGES.artworkLionToxic,
};

const Emberfuzz: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Emberfuzz,
  element: MonsterElement.Fire,
  name: "Emberfuzz",
  imageURI: IMAGES.artworkFoxFire,
};

const Vineroot: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Vineroot,
  element: MonsterElement.Nature,
  name: "Vineroot",
  imageURI: IMAGES.artworkFoxNature,
};

const Bublifox: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Bublifox,
  element: MonsterElement.Water,
  name: "Bublifox",
  imageURI: IMAGES.artworkFoxWater,
};

const Zaptuft: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Zaptuft,
  element: MonsterElement.Electric,
  name: "Zaptuft",
  imageURI: IMAGES.artworkFoxElectric,
};

const Psygleam: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Psygleam,
  element: MonsterElement.Mental,
  name: "Psygleam",
  imageURI: IMAGES.artworkFoxMental,
};

const Sludgeprowl: MonsterConfig = {
  ...MonsterTypes.fox,
  id: MonsterId.Sludgeprowl,
  element: MonsterElement.Toxic,
  name: "Sludgeprowl",
  imageURI: IMAGES.artworkFoxToxic,
};

const Cinderplate: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Cinderplate,
  element: MonsterElement.Fire,
  name: "Cinderplate",
  imageURI: IMAGES.artworkTurtleFire,
};

const Terraform: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Terraform,
  element: MonsterElement.Nature,
  name: "Terraform",
  imageURI: IMAGES.artworkTurtleNature,
};

const Hydroshell: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Hydroshell,
  element: MonsterElement.Water,
  name: "Hydroshell",
  imageURI: IMAGES.artworkTurtleWater,
};

const Zappadome: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Zappadome,
  element: MonsterElement.Electric,
  name: "Zappadome",
  imageURI: IMAGES.artworkTurtleElectric,
};

const Mindshell: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Mindshell,
  element: MonsterElement.Mental,
  name: "Mindshell",
  imageURI: IMAGES.artworkTurtleMental,
};

const Pestiplate: MonsterConfig = {
  ...MonsterTypes.turtle,
  id: MonsterId.Pestiplate,
  element: MonsterElement.Toxic,
  name: "Pestiplate",
  imageURI: IMAGES.artworkTurtleToxic,
};

const Magmalore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Magmalore,
  element: MonsterElement.Fire,
  name: "Magmalore",
  imageURI: IMAGES.artworkDragonFire,
};

const Djungalore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Djungalore,
  element: MonsterElement.Nature,
  name: "Djungalore",
  imageURI: IMAGES.artworkDragonNature,
};

const Tsunalore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Tsunalore,
  element: MonsterElement.Water,
  name: "Tsunalore",
  imageURI: IMAGES.artworkDragonWater,
};

const Shocklore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Shocklore,
  element: MonsterElement.Electric,
  name: "Shocklore",
  imageURI: IMAGES.artworkDragonElectric,
};

const Mentalore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Mentalore,
  element: MonsterElement.Mental,
  name: "Mentalore",
  imageURI: IMAGES.artworkDragonMental,
};

const Sporelore: MonsterConfig = {
  ...MonsterTypes.dragon,
  id: MonsterId.Sporelore,
  element: MonsterElement.Toxic,
  name: "Sporelore",
  imageURI: IMAGES.artworkDragonToxic,
};

export const Monsters = {
  Blazehorn,
  Flampanda,
  Pyrilla,
  Fernopig,
  Foretusk,
  Verdubear,
  Florangutan,
  Leafsnout,
  Aquasteer,
  Wavepaw,
  Tidalmonk,
  Streamhog,
  Thunderhoof,
  Shockfur,
  Electrang,
  Zappig,
  Psyhorn,
  Dreamgrowl,
  Cerebrilla,
  Enighog,
  Toxicorn,
  Poisonclaw,
  Gloomgrip,
  Fumebrow,
  Firizard,
  Floralon,
  Reptide,
  Chargecrest,
  Cogniscale,
  Acidtongue,
  Magmaphant,
  Grovemaw,
  Aquarump,
  Voltusk,
  Mentolox,
  Sludgetrunk,
  Flarejaw,
  Verdantide,
  Bubblestream,
  Sparkfin,
  Psycheleap,
  Slimefin,
  Flamlion,
  Greenwhelp,
  Ripplemane,
  Voltmane,
  Cerebropaw,
  Venomroar,
  Emberfuzz,
  Vineroot,
  Bublifox,
  Zaptuft,
  Psygleam,
  Sludgeprowl,
  Cinderplate,
  Terraform,
  Hydroshell,
  Zappadome,
  Mindshell,
  Pestiplate,
  Magmalore,
  Djungalore,
  Tsunalore,
  Shocklore,
  Mentalore,
  Sporelore,
};

type MonsterName = keyof typeof Monsters
const MonsterNames = Object.keys(Monsters) as MonsterName[]
const MonstersById: Partial<Record<MonsterId, MonsterConfig>> = {}
MonsterNames.forEach(name=>MonstersById[Monsters[name].id]=Monsters[name] )

export const getMonsterIdByName = (name: MonsterName): MonsterId => {
  if(!Monsters[name]){
    throw new Error(`Invalid monster name: ${name}`);
  }
  return Monsters[name].id
};

export const getConfigById = (id: MonsterId): MonsterConfig => {
  if(!MonstersById[id]){
    return Blazehorn;
  }
  return MonstersById[id]!
};
