import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { useToggle } from "@uidotdev/usehooks";

import IMAGES from "../../assets/images";
import { shortenRarity } from "../../utility";
import { KeyValue } from "../keyValue";
import { MonsterCardProps } from "./monsterCardProps";
import { getRandomTransformOrigin, revealVariants } from "./motion";

const noop = () => {};

export const MonsterCard = ({
  monster,
  onClick,
  className,
  revealable = false,
  isRevealed = true,
  isDisabled = false,
}: MonsterCardProps) => {
  const controls = useAnimation();
  const controlsRevealed = useAnimation();
  const controlsUnrevealed = useAnimation();

  const [isRevealing, toggleIsRevealing] = useToggle(false);
  const [isRevealedState, toggleIsRevealed] = useToggle(!revealable);

  const { tokenId, config } = monster || {};
  const { name, imageURI, attributes, typeRarity: rarity } = config || {};
  const imageProps = revealable
    ? { scale: 0, opacity: 0 }
    : { opacity: 1, scale: 1 };

  const displayedAttributes = useMemo(() => {
    if (!attributes) return [];

    const { attack, defense, hpInitial, speed } = attributes;
    const keys = ["ATK", "DEF", "HP", "SP"];
    const values = isRevealedState
      ? [attack, defense, hpInitial, speed].map((value) => value.toString())
      : ["?", "?", "?", "?"];

    return keys.map((keyText, index) => ({
      keyText,
      valueText: values[index],
    }));
  }, [attributes, isRevealedState]);

  /**
   * Reveal Effect
   */

  // toggleIsRevealing when isRevealed changes to true
  useEffect(() => {
    if (isRevealed) {
      toggleIsRevealing();
    }
  }, [isRevealed, toggleIsRevealing]);

  // Reveal the monster
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isRevealing && !isRevealedState) {
      controls.start("start");
      timeout = setTimeout(() => {
        toggleIsRevealing();
        toggleIsRevealed();
        controls.stop();
        controls.set("reset");
      }, 1500);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    controls,
    isRevealedState,
    isRevealing,
    toggleIsRevealed,
    toggleIsRevealing,
  ]);

  // Fade out the unrevealed image and zoom in the revealed image
  useEffect(() => {
    const sequence = async () => {
      if (isRevealedState) {
        // Fade out the unrevealed image
        await controlsUnrevealed.start({
          opacity: 0,
          transition: { duration: 0.1 },
        });
        // Zoom in the revealed image faster
        await controlsRevealed.start({
          scale: 1,
          opacity: 1,
          transition: { duration: 0.1 },
        });
      }
    };
    sequence();
  }, [isRevealedState, controlsUnrevealed, controlsRevealed]);

  return (
    <motion.div
      onClick={isDisabled ? noop : onClick}
      whileTap={{ scale: onClick ? 0.99 : 1 }}
      whileHover={{ scale: onClick ? 1.02 : 1 }}
      className={twMerge(
        "flex flex-1 flex-col p-2 space-y-2 rounded-2xl bg-background-primary/40 border border-background-primary/10 backdrop-blur-sm transition-shadow shadow-lg active:shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
        className,
        onClick ? "cursor-pointer" : ""
      )}
    >
      <motion.div
        style={{
          ...getRandomTransformOrigin(),
        }}
        variants={revealVariants}
        animate={controls}
        className="relative rounded-lg aspect-square"
      >
        {/* Unrevealed Image */}
        <motion.img
          className={twMerge(
            "rounded-lg aspect-square shadow-sm absolute inset-0",
            isDisabled ? "grayscale shadow-inner" : ""
          )}
          src={IMAGES.elementalEchoes}
          alt={`Unrevealed Monster`}
          animate={controlsUnrevealed}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        />

        {/* Revealed Image */}
        <motion.div
          className="rounded-lg aspect-square shadow-sm absolute inset-0"
          animate={controlsRevealed}
          initial={imageProps}
          exit={imageProps}
        >
          <motion.img
            className={twMerge(
              "rounded-lg aspect-square shadow-sm absolute inset-0",
              isDisabled ? "grayscale shadow-inner" : ""
            )}
            src={imageURI || IMAGES.monsterPlaceholder}
            alt={`Monster ${tokenId}`}
          />
          {/* Rarity */}
          {isRevealedState && (
            <div className="absolute bottom-1 right-1 flex flex-col justify-center items-center w-8 h-6 rounded-full bg-background-primary/50 border border-background-primary/10 backdrop-blur-sm shadow-sm">
              <span className="text-text-tertiary text-2xs font-display font-bold">
                {shortenRarity(rarity)}
              </span>
            </div>
          )}
        </motion.div>
      </motion.div>
      <div className="flex flex-1 flex-col p-2 space-y-1 rounded-lg bg-background-primary/80 border border-background-primary/10 backdrop-blur-sm shadow-sm">
        <span className="text-center text-s text-text-primary font-display font-bold">
          {isRevealedState ? name : "• • •"}
        </span>
        <div className="flex flex-1 flex-row space-x-1">
          {displayedAttributes.map((attribute, index) => {
            const { keyText, valueText } = attribute;
            return (
              <KeyValue
                key={`${keyText}-${valueText}-${index}`}
                keyText={keyText}
                valueText={valueText}
                className="flex-1 text-center text-xs font-bold"
              />
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};
