import React, { useContext, useEffect } from "react";

import { UserContext } from "../../../context";
import { processLog } from "../../../routes/Battle/utility";
import { MatchPlayers } from "../../../types";
import { EventLogType } from ".";

export function useProcessLogs({
  logs,
  players,
  markLogRead,
  addActionLog,
  addBattleEffectOpponent,
  addBattleEffectSelf,
}: {
  logs: EventLogType[];
  players: MatchPlayers | undefined;
  markLogRead: (id: bigint) => void;
  addActionLog: (element: React.ReactNode) => void;
  addBattleEffectOpponent: (effect: string) => void;
  addBattleEffectSelf: (effect: string) => void;
}) {
  const { walletAddress } = useContext(UserContext)!;

  useEffect(() => {
    if (!markLogRead) return;
    logs.forEach((log) => {
      processLog(
        log,
        players,
        addActionLog,
        markLogRead,
        addBattleEffectOpponent,
        addBattleEffectSelf,
        walletAddress,
      );
    });
  }, [
    logs,
    players,
    markLogRead,
    addActionLog,
    addBattleEffectOpponent,
    addBattleEffectSelf,
    walletAddress,
  ]);
}
