import React, { useCallback, useState } from "react";
import { useLockedBody } from "usehooks-ts";

import { Modal } from "../modal";
import { ModalOpenPacksProps } from "./modalOpenPacksProps.ts";
import { Button } from "../button";
import { noop } from "lodash";
import { KeyValue } from "../keyValue";
import cn from "classnames";
import { useOpenPacks } from "../../hooks";
import { ModalActionComplete } from "../modalActionComplete";
import { useNavigate } from "react-router-dom";

export const ModalOpenPacks = ({
  pack,
  isVisible,
  onClose,
}: ModalOpenPacksProps) => {
  // Lock body when modal is visible
  useLockedBody(isVisible, "root");

  const navigate = useNavigate();

  const [error, setError] = useState<Error | undefined>();
  const [openingHash, setOpeningHash] = useState<string | undefined>();
  const [isOpening, setIsOpening] = useState(false);
  const [amount, setAmount] = useState<number | string>(1);

  const onIncrement = useCallback(() => {
    setAmount(Math.min(Number(amount) + 1, pack?.amount || 0));
  }, [amount, pack?.amount]);

  const onDecrement = useCallback(() => {
    setAmount(Math.max(Number(amount) - 1, 1));
  }, [amount]);

  const onMax = useCallback(() => {
    setAmount(pack?.amount || 0);
  }, [pack?.amount]);

  const buttonDecDisabled = Number(amount) <= 1;
  const buttonIncDisabled = pack?.amount
    ? pack.amount < Number(amount) + 1
    : true;

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Allow the input to be cleared to an empty string
    if (value === "") {
      setAmount(""); // Use an empty string to clear the input
      return;
    }

    const parsedValue = parseInt(value, 10);

    // Check if the parsed value is a number and within the allowed range
    if (!isNaN(parsedValue) && parsedValue >= 1) {
      setAmount(parsedValue); // Set amount to the parsed value if it's within range
    }
  }, []);

  // take the first token IDs from pack.tokenIds and use the amount as the length
  const { openAsync } = useOpenPacks(
    pack?.tokenIds.slice(0, Number(amount)) || []
  );

  const onOpen = useCallback(async () => {
    if (openAsync) {
      setIsOpening(true);
      try {
        const opening = await openAsync();
        setOpeningHash(opening?.hash);
      } catch (err) {
        if (!(err as any)?.message?.includes("User rejected")) {
          setError(err as Error);
        }
      } finally {
        setIsOpening(false);
      }
    }
  }, [openAsync]);

  const onCloseModal = useCallback(() => {
    if (error) {
      setError(undefined);
    } else {
      navigate("/openings");
    }
  }, [error, navigate]);

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      className="py-4"
      title={pack?.name}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 items-start justify-center">
          <div className="flex flex-col space-y-2 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/60 border border-background-primary/20 shadow-sm">
            {!!pack && (
              <>
                <div className="h-80">
                  {/** Pack Visual */}
                  <img
                    className="w-full max-h-full rounded-xl"
                    src={pack.imageUri}
                    alt={pack.name}
                  />
                </div>
                {/** Pack Body */}
                <div className="flex flex-1 flex-col space-y-4 p-4 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                  {/** Pack Details */}
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-text-primary text-xl font-display font-bold text-left">
                      {pack?.amount > 1 ? (
                        <span>Open 1 of {pack?.amount} Packs</span>
                      ) : (
                        <span>Open {pack?.amount} Pack</span>
                      )}
                    </span>
                  </div>

                  {/** Costs */}
                  <div className="flex flex-row justify-between">
                    <KeyValue
                      keyText="Cost"
                      valueText={"Only Gas"}
                      className={cn("text-left font-bold", {
                        "text-text-tertiary": false,
                      })}
                    />
                    <KeyValue
                      keyText="Available Packs"
                      valueText={`${pack.amount}`}
                      className="text-right font-bold"
                    />
                  </div>

                  {/** Actions */}
                  <div className="flex flex-col space-y-2">
                    <div className="flex flex-row space-x-2 bg-transparent">
                      {/** Input */}
                      <div className="relative flex flex-row flex-1 items-center rounded-full pr-1 bg-background-primary/50 backdrop-blur-sm border border-background-primary/20 shadow-sm">
                        <input
                          className="bg-transparent h-full font-display font-bold text-text-secondary text-sm pl-4 outline-none focus:ring-0"
                          onChange={onChange}
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          value={amount}
                          min={1}
                          max={pack?.amount}
                        />
                        <Button
                          variant="primary"
                          size="2xs"
                          onClick={onMax}
                          className="h-8 w-auto px-4 absolute right-1"
                          disabled={isOpening}
                        >
                          Max
                        </Button>
                      </div>

                      {/** Actions */}
                      <div className="flex flex-row space-x-2">
                        <Button
                          variant="dark"
                          size="xs"
                          onClick={onDecrement}
                          className="w-10"
                          disabled={buttonDecDisabled || isOpening}
                        >
                          <i className="material-symbols-rounded text-md">
                            remove
                          </i>
                        </Button>
                        <Button
                          variant="dark"
                          size="xs"
                          onClick={onIncrement}
                          className="w-10"
                          disabled={buttonIncDisabled || isOpening}
                        >
                          <i className="material-symbols-rounded text-md">
                            add
                          </i>
                        </Button>
                      </div>
                    </div>

                    <Button
                      variant="primary"
                      disabled={isOpening}
                      onClick={isOpening ? noop : onOpen}
                    >
                      {isOpening ? (
                        "Opening…"
                      ) : (
                        <>
                          Open {amount} Pack{Number(amount) > 1 ? "s" : ""}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <ModalActionComplete
          text={
            openingHash
              ? `Pack${
                  Number(amount) > 1 ? "s" : ""
                } submitted for opening. Hit the button below to track your reveal!`
              : `${error}`
          }
          isError={!!error}
          isDone={!!openingHash || !!error}
          buttonText={error ? "Close" : "Track Reveal"}
          isVisible={!!error || !!openingHash}
          onClose={onCloseModal}
          title={error ? "Error" : "Opening Packs"}
        />
      </div>
    </Modal>
  );
};
