import React from "react";
import { twMerge } from "tailwind-merge";

// Define the shape of a segment option.
type SegmentOption = {
  label: string;
  value: string;
};

// Define the props for the Segments component.
type SegmentsProps = {
  options: SegmentOption[];
  onClick: (value: string) => void;
  selectedOption: string;
  className?: string;
  textColor?: string;
  textColorActive?: string;
  backgroundColor?: string;
  backgroundColorActive?: string;
  borderColor?: string;
  borderColorActive?: string;
};

export const Segments: React.FC<SegmentsProps> = ({
  options,
  onClick,
  className = "",
  textColor = "text-text-primary",
  textColorActive = "text-text-primary",
  backgroundColor = "bg-background-accent/20",
  backgroundColorActive = "bg-background-primary",
  borderColor = "border-transparent",
  borderColorActive = "border-border-primary",
  selectedOption,
}) => {
  return (
    <div
      className={twMerge(
        "flex justify-between space-x-1 rounded-full bg-background-primary/40 backdrop-blur-sm border border-background-primary/20 p-1",
        className
      )}
    >
      {options.map((segment) => {
        const isSelected = selectedOption === segment.value;

        // Determine styles based on whether the segment is selected.
        const tColor = isSelected ? textColorActive : textColor;
        const borderStyle = isSelected ? borderColorActive : borderColor;
        const bgColor = isSelected ? backgroundColorActive : backgroundColor;

        return (
          <button
            key={segment.label}
            onClick={() => onClick(segment.value)}
            className={`flex flex-1 justify-center items-center py-2 rounded-full text-sm font-semibold ${tColor} ${bgColor}`}
          >
            {segment.label}
          </button>
        );
      })}
    </div>
  );
};
