import { RarityLevel } from "../types";

export const RARITY_LEVEL_SIZE = {
  [RarityLevel.Common]: 3,
  [RarityLevel.Uncommon]: 2,
  [RarityLevel.Rare]: 2,
  [RarityLevel.VeryRare]: 2,
  [RarityLevel.UltraRare]: 1,
  [RarityLevel.Mythic]: 1,
};

export const RARITIES = {
  [RarityLevel.Common]: 50 / RARITY_LEVEL_SIZE[RarityLevel.Common] / 100,
  [RarityLevel.Uncommon]: 22 / RARITY_LEVEL_SIZE[RarityLevel.Uncommon] / 100,
  [RarityLevel.Rare]: 14 / RARITY_LEVEL_SIZE[RarityLevel.Rare] / 100,
  [RarityLevel.VeryRare]: 10 / RARITY_LEVEL_SIZE[RarityLevel.VeryRare] / 100,
  [RarityLevel.UltraRare]: 3 / RARITY_LEVEL_SIZE[RarityLevel.UltraRare] / 100,
  [RarityLevel.Mythic]: 1 / RARITY_LEVEL_SIZE[RarityLevel.Mythic] / 100,
};
