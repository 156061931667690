import { MonsterElement } from "../types";

const elementToString: Record<MonsterElement, string> = {
  [MonsterElement.None]: "None",
  [MonsterElement.Electric]: "Electric",
  [MonsterElement.Fire]: "Fire",
  [MonsterElement.Water]: "Water",
  [MonsterElement.Mental]: "Mental",
  [MonsterElement.Nature]: "Nature",
  [MonsterElement.Toxic]: "Toxic",
};

export function getStringForElement(element: MonsterElement): string {
  return elementToString[element];
}
