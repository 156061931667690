import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";
import useSound from "../../sound/useSound.ts";

import SOUNDS from "../../assets/sounds";
import { MonsterStatusEffect, StatusEffectGroup } from "../../types";
import { AnimatedNumber } from "../animatedNumber";
import { MonsterBoardHealth } from "./monsterBoardHealth";
import { MonsterBoardProps } from "./monsterBoardProps";
import { MonsterBoardStatusEffects } from "./monsterBoardStatusEffects";
import { Skeleton } from "..";

const { BUFF, DEBUFF, WALL } = StatusEffectGroup;
const positiveGroups = new Set([BUFF, WALL]);

function consolidateStatusEffects(
  statusEffects: MonsterStatusEffect[]
): MonsterStatusEffect[] {
  return Object.values(
    statusEffects.reduce((acc: Record<string, MonsterStatusEffect>, effect) => {
      if (acc[effect.key]) {
        acc[effect.key].remainingTurns += effect.remainingTurns;
        acc[effect.key].count = (acc[effect.key].count || 1) + 1;
      } else {
        acc[effect.key] = { ...effect, count: 1 };
      }
      return acc;
    }, {})
  );
}

export const MonsterBoard: React.FC<MonsterBoardProps> = ({
  monster,
  className,
  statusEffects,
  battleEffect,
  onClick,
}) => {
  const loading = !monster;
  const config = monster?.config;
  const { name, imageURI, attributes } = config || {};
  const { hp = 0, hpInitial = 0 } = attributes || {};
  const isDead = hp <= 0;
  const displayBattleEffect = battleEffect && !loading;
  const [lastBattleEffect, setLastBattleEffect] = useState<string>()

  useEffect(()=>{
    if(battleEffect){
      setLastBattleEffect(battleEffect)
    }
  },[battleEffect, setLastBattleEffect])

  // Split status effects into positive and negative groups.
  const [statusEffectsPositive, statusEffectsNegative] = useMemo(() => {
    if (!statusEffects) return [[], []];

    const positives: MonsterStatusEffect[] = [];
    const negatives: MonsterStatusEffect[] = [];

    for (const statusEffect of statusEffects) {
      if (statusEffect.group && positiveGroups.has(statusEffect.group)) {
        positives.push(statusEffect);
        consolidateStatusEffects(positives);
      } else if (statusEffect.group === DEBUFF) {
        negatives.push(statusEffect);
      }
    }

    return [
      consolidateStatusEffects(positives),
      consolidateStatusEffects(negatives),
    ];
  }, [statusEffects]);

  const [play] = useSound(SOUNDS.click2);

  const handleCLick = () => {
    if (onClick === undefined) return;
    onClick();
    play();
  };

  return (
    <div
      onClick={handleCLick}
      className={twMerge(
        "flex flex-col flex-initial h-fit w-10/12 rounded-2xl p-2 space-y-2 bg-background-primary/40 border border-background-primary/10 backdrop-blur-md transition-shadow shadow-lg active:shadow-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring",
        className
      )}
    >
      <div className="flex flex-row space-x-2">
        <MonsterBoardStatusEffects
          statusEffects={statusEffectsNegative}
          loading={loading}
        />
        <div className="grow h-fit rounded-lg overflow-hidden bg-background-primary/80 border-background-primary/10 backdrop-blur-sm shadow-sm">
          {loading ? (
            <div className="flex flex-1 w-full h-full aspect-square p-1 bg-background-primary">
              <Skeleton className="flex flex-1 rounded-md w-full h-full aspect-square" />
            </div>
          ) : (
            <div className="relative flex flex-col flex-1">
              <motion.img
                src={imageURI}
                className={twMerge(
                  "flex flex-1 aspect-square",
                  isDead && "grayscale"
                )}
              />
              <AnimatePresence>
                  <motion.div
                    initial="hidden"
                    animate={displayBattleEffect ? 'visible' : 'hidden'}
                    variants={{
                      hidden: {
                        opacity: 0,
                        scale: 0.5,
                        transition: {
                          type: "spring",
                          stiffness: 300,
                          damping: 20,
                          duration: 2
                        },
                      },
                      visible: {
                        opacity: 1,
                        scale: 0.9,
                        transition: {
                          type: "spring",
                          stiffness: 300,
                          damping: 20,
                          duration: 2
                        },
                      },
                    }}
                    className="absolute top-0 right-0 bottom-0 left-0"
                  >
                    <img
                      alt="Battle Effect"
                      src={lastBattleEffect}
                      className="absolute top-0 right-0 bottom-0 left-0 rounded-md shadow-lg"
                    />
                  </motion.div>
              </AnimatePresence>
            </div>
          )}
        </div>
        <MonsterBoardStatusEffects
          statusEffects={statusEffectsPositive}
          loading={loading}
        />
      </div>

      <div className="p-2 rounded-lg bg-background-primary/80 border-background-primary/10 backdrop-blur-sm shadow-sm">
        {loading ? (
          <div className="flex flex-1 flex-col justify-between">
            <div className="flex flex-1 justify-between">
              <Skeleton className="w-20 h-4" />
              <Skeleton className="w-12 h-4" />
            </div>
            <Skeleton className="w-full h-2 mt-2 rounded-full" />
          </div>
        ) : (
          <>
            <div className="flex justify-between text-text-primary text-sm font-bold font-display">
              <span>{name}</span>
              <span>
                <AnimatedNumber targetNumber={hp} />
                <span> / {hpInitial}</span>
              </span>
            </div>
            <MonsterBoardHealth hp={hp} hpInitial={hpInitial} />
          </>
        )}
      </div>
    </div>
  );
};
