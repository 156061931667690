import { useMemo } from "react";
import { Address } from "viem";
import { useContractRead } from "wagmi";

import { LeaderboardManagerV1Abi, LeaderboardV1Abi } from "../../abis";
import { contracts } from "../../contracts.ts";
import { PLACEHOLDER_CHAIN_ID } from "../../../../chains.ts";

type PlayerStatsFromChain = [bigint, bigint, bigint];

type PlayerStats = {
  wins: number;
  losses: number;
  escapes: number;
};

export function usePlayerStats(chainId: string, address: Address) {
  const { data: leaderboardAddress } = useContractRead({
    address: contracts[chainId].leaderboardManager,
    abi: LeaderboardManagerV1Abi,
    functionName: "leaderboards",
    args: [BigInt(1)],
    enabled: chainId !== PLACEHOLDER_CHAIN_ID,
  });

  const {
    data,
    isLoading,
    refetch,
  }: {
    data: PlayerStatsFromChain | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: leaderboardAddress as Address,
    abi: LeaderboardV1Abi,
    functionName: "playerStats",
    args: [address],
    enabled:
      !!leaderboardAddress && !!address && chainId !== PLACEHOLDER_CHAIN_ID,
  });

  const stats: PlayerStats | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return {
      wins: Number(data[0]),
      losses: Number(data[1]),
      escapes: Number(data[2]),
    };
  }, [data]);

  return {
    stats,
    isLoading,
    refetch,
  };
}
