import { MonsterStatusEffect } from "..";
import { MonsterElement } from "../monsterElement";

export type MonsterType =
  | "bull"
  | "bear"
  | "gorilla"
  | "boar"
  | "lizard"
  | "elephant"
  | "whale"
  | "lion"
  | "fox"
  | "turtle"
  | "dragon";

export enum RarityLevel {
  Common = "Common",
  Uncommon = "Uncommon",
  Rare = "Rare",
  VeryRare = "Very Rare",
  UltraRare = "Ultra Rare",
  Mythic = "Mythic",
}

export const TypeRarity: Record<MonsterType, RarityLevel> = {
  bull: RarityLevel.Common,
  bear: RarityLevel.Common,
  gorilla: RarityLevel.Common,
  boar: RarityLevel.Uncommon,
  lizard: RarityLevel.Uncommon,
  elephant: RarityLevel.Rare,
  whale: RarityLevel.Rare,
  lion: RarityLevel.VeryRare,
  fox: RarityLevel.VeryRare,
  turtle: RarityLevel.UltraRare,
  dragon: RarityLevel.Mythic,
};

export enum MonsterId {
  None = "0",
  Blazehorn = "1",
  Foretusk = "2",
  Aquasteer = "3",

  Thunderhoof = "4",
  Psyhorn = "5",
  Toxicorn = "6",

  Flampanda = "7",
  Verdubear = "8",
  Wavepaw = "9",

  Shockfur = "10",
  Dreamgrowl = "11",
  Poisonclaw = "12",

  Pyrilla = "13",
  Florangutan = "14",
  Tidalmonk = "15",

  Electrang = "16",
  Cerebrilla = "17",
  Gloomgrip = "18",

  Fernopig = "19",
  Leafsnout = "20",
  Streamhog = "21",

  Zappig = "22",
  Enighog = "23",
  Fumebrow = "24",

  Firizard = "25",
  Floralon = "26",
  Reptide = "27",

  Chargecrest = "28",
  Cogniscale = "29",
  Acidtongue = "30",

  Magmaphant = "31",
  Grovemaw = "32",
  Aquarump = "33",

  Voltusk = "34",
  Mentolox = "35",
  Sludgetrunk = "36",

  Flarejaw = "37",
  Verdantide = "38",
  Bubblestream = "39",

  Sparkfin = "40",
  Psycheleap = "41",
  Slimefin = "42",

  Flamlion = "43",
  Greenwhelp = "44",
  Ripplemane = "45",

  Voltmane = "46",
  Cerebropaw = "47",
  Venomroar = "48",

  Emberfuzz = "49",
  Vineroot = "50",
  Bublifox = "51",

  Zaptuft = "52",
  Psygleam = "53",
  Sludgeprowl = "54",

  Cinderplate = "55",
  Terraform = "56",
  Hydroshell = "57",

  Zappadome = "58",
  Mindshell = "59",
  Pestiplate = "60",

  Magmalore = "61",
  Djungalore = "62",
  Tsunalore = "63",

  Shocklore = "64",
  Mentalore = "65",
  Sporelore = "66",
}

export interface MonsterAttributes {
  attack: number;
  defense: number;
  speed: number;
  hp: number;
  hpInitial: number;
}

export interface TypeConfig {
  attributes: MonsterAttributes;
  type: MonsterType;
  typeRarity: RarityLevel;
}

export interface MonsterConfig extends TypeConfig {
  id: MonsterId;
  element: MonsterElement;
  name: string;
  imageURI: string;
}

export interface Monster {
  tokenId?: bigint;
  config: MonsterConfig;
  isWaitingForCommit?: boolean;
  statusEffects?: MonsterStatusEffect[];
}
