import { motion } from "framer-motion";
import React, { useMemo } from "react";
import useSound from "../../sound/useSound.ts";

import SOUNDS from "../../assets/sounds";
import { ActionCardProps } from "./actionCardProps";
import { ElementBadge } from "..";

export const ActionCard: React.FC<ActionCardProps> = ({
  title,
  description,
  imageURI,
  elements,
  onClick,
  sound,
}) => {
  const soundSource = useMemo(() => {
    if (sound) {
      return SOUNDS[sound];
    }

    return undefined;
  }, [sound]);

  const [play] = useSound(soundSource);

  const handleCLick = () => {
    if (onClick) {
      onClick();
      sound !== undefined && play();
    }
  };

  return (
    <motion.button
      onClick={handleCLick}
      whileTap={{ scale: 0.99 }}
      whileHover={{ scale: 1.05 }}
      className="flex flex-row w-full p-2 space-x-2 items-center bg-background-primary/80 backdrop-blur-md rounded-lg shadow-sm border border-background-primary/20 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
    >
      {imageURI && (
        <img
          className="w-8 h-8 rounded-full shadow-sm border-2 border-background-primary/20"
          src={imageURI}
          alt={title}
        />
      )}

      {/* {elements?.length === 2 && (
        <div className="relative flex flex-col w-8 h-8">
          <ElementBadge
            element={elements[0]}
            badgeSize={20}
            iconSize={14}
            className="absolute top-0 left-0"
          />
          <ElementBadge
            element={elements[1]}
            badgeSize={20}
            iconSize={14}
            className="absolute bottom-0 right-0"
          />
        </div>
      )} */}

      <div className="flex flex-col flex-1 text-left text-text-primary space-y-1">
        <div className="flex flex-row flex-1 justify-between items-center">
          <h3 className="font-display font-bold">{title}</h3>
          <div className="flex flex-row space-x-1">
            {elements?.map((element, index) => (
              <ElementBadge element={element} key={index} />
            ))}
          </div>
        </div>
        <p className="text-xs">{description}</p>
      </div>
    </motion.button>
  );
};
