import { useCallback, useState } from "react";
import { Address } from "viem";
import { useContractRead } from "wagmi";

import { UsernamesV1Abi } from "../abis";
import { contracts } from "../contracts.ts";
import { PLACEHOLDER_CHAIN_ID } from "../../../chains.ts";
import { writeContract } from "@wagmi/core";

export const useUserName = (
  chainId: number | string,
  { address }: { address?: Address }
) => {
  const [newName, setNewNameInternal] = useState("");
  const {
    data,
    isLoading,
    refetch,
  }: {
    data: string | undefined;
    isLoading: boolean;
    refetch: () => void;
  } = useContractRead({
    address: contracts[chainId].usernames,
    abi: UsernamesV1Abi,
    functionName: "addressToName",
    args: [address],
    enabled: !!address && chainId !== PLACEHOLDER_CHAIN_ID,
  });

  const [isLoadingWrite, setIsLoadingWrite] = useState(false);
  const [error, setError] = useState<Error>();

  if (error) {
    console.error(error);
  }

  const writeAsync = useCallback(async () => {
    try {
      setIsLoadingWrite(true);
      return await writeContract({
        address: contracts[chainId].usernames,
        abi: UsernamesV1Abi,
        functionName: "registerName",
        args: [newName],
        gas: BigInt(1_000_000),
      });
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoadingWrite(false);
    }
  }, [chainId, newName]);

  const setNewName = useCallback((value: string) => {
    if (value.length < 20 && !/\s/.test(value)) {
      setNewNameInternal(value);
    }
  }, []);

  return {
    userName: data,
    isLoading: isLoading || isLoadingWrite,
    isAlreadyRegistered: !!error,
    refetch,
    setNewName,
    newName,
    persistUserName: writeAsync,
  };
};
