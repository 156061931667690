const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

const randomDuration = () => Math.random() * 0.03 + 0.1;

export const getRandomTransformOrigin = () => ({
  originX: (16 + 40 * Math.random()) / 100,
  originY: (15 + 36 * Math.random()) / 100,
});

export const revealVariants = {
  start: () => ({
    rotate: [0.8, -0.8, 0],
    transition: {
      repeat: Infinity,
      delay: getRandomDelay(),
      duration: randomDuration(),
      ease: "linear",
    },
  }),
  reset: {
    rotate: 0,
  },
};
