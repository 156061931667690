import { Transition, Variants } from "framer-motion";

export const contentVariants: Variants = {
  initial: { y: 100, opacity: 0 },
  animate: { y: 0, opacity: 1 },
  exit: { y: 100, opacity: 0 },
};

export const contentVariantsHorizontalFromRight = {
  initial: { x: "100%", opacity: 0 }, // start off-screen to the right
  animate: { x: 0, opacity: 1 }, // animate to the center
  exit: { x: "-100%", opacity: 0 }, // exit off-screen to the left
};

export const contentVariantsHorizontalFromLeft = {
  initial: { x: "-100%", opacity: 0 }, // start off-screen to the left
  animate: { x: 0, opacity: 1 }, // animate to the center
  exit: { x: "100%", opacity: 0 }, // exit off-screen to the right
};

export const contentTransition: Transition = {
  type: "spring",
  damping: 20,
  stiffness: 150,
};
