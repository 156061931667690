import { useNavigate } from "react-router-dom";
import { Button } from "..";
import { MonsterElement } from "../../types";
import { ButtonOpenPacksProps } from "./buttonOpenPacksProps";

export const ButtonOpenPacks: React.FC<ButtonOpenPacksProps> = ({
  element,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/booster-packs");
  };

  const elementName = element ? getElementName(element) : "";

  return (
    <div className="flex flex-col space-y-4 text-center">
      <h1 className="font-display font-black text-text-light text-xl">
        {`You don't have any ${elementName} monsters.`}
      </h1>
      <Button variant="primary" size="lg" sound="click" onClick={handleClick}>
        OPEN PACKS
      </Button>
    </div>
  );
};

function getElementName(element: MonsterElement): string {
  switch (element) {
    case MonsterElement.Electric:
      return "Electric";
    case MonsterElement.Fire:
      return "Fire";
    case MonsterElement.Water:
      return "Water";
    case MonsterElement.Mental:
      return "Mental";
    case MonsterElement.Nature:
      return "Nature";
    case MonsterElement.Toxic:
      return "Toxic";
    default:
      return "";
  }
}
