import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { WriteContractResult } from "@wagmi/core";
import { useCallback } from "react";
import { writeContractConfidentialDirect } from "../../../sapphire";

export type PackRip = {
  openAsync: () => Promise<WriteContractResult | undefined>;
};

export const useOpenPacks = (tokenIds: bigint[]): PackRip => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  const openAsync = useCallback(async () => {
    if (tokenIds.length > 0) {
      return await writeContractConfidentialDirect({
        contract,
        functionName: "requestRevealForTokens",
        args: [tokenIds],
        gas: 500_000n + 100_000n * BigInt(tokenIds.length),
      });
    }
  }, [tokenIds, contract]);

  return {
    openAsync,
  };
};
