import { TypeConfig, TypeRarity } from "../types";

const bull: TypeConfig = {
  type: "bull",
  typeRarity: TypeRarity.bull,
  attributes: {
    hp: 20,
    speed: 20,
    attack: 30,
    defense: 30,
    hpInitial: 20,
  },
};

const bear: TypeConfig = {
  type: "bear",
  typeRarity: TypeRarity.bear,
  attributes: {
    hp: 25,
    speed: 25,
    attack: 25,
    defense: 25,
    hpInitial: 25,
  },
};

const gorilla: TypeConfig = {
  type: "gorilla",
  typeRarity: TypeRarity.gorilla,
  attributes: {
    hp: 40,
    speed: 10,
    attack: 30,
    defense: 20,
    hpInitial: 40,
  },
};

const boar: TypeConfig = {
  type: "boar",
  typeRarity: TypeRarity.boar,
  attributes: {
    hp: 20,
    speed: 40,
    attack: 10,
    defense: 30,
    hpInitial: 20,
  },
};

const lizard: TypeConfig = {
  type: "lizard",
  typeRarity: TypeRarity.lizard,
  attributes: {
    hp: 20,
    speed: 30,
    attack: 20,
    defense: 30,
    hpInitial: 20,
  },
};

const elephant: TypeConfig = {
  type: "elephant",
  typeRarity: TypeRarity.elephant,
  attributes: {
    hp: 30,
    speed: 10,
    attack: 30,
    defense: 30,
    hpInitial: 30,
  },
};

const whale: TypeConfig = {
  type: "whale",
  typeRarity: TypeRarity.whale,
  attributes: {
    hp: 40,
    speed: 10,
    attack: 10,
    defense: 40,
    hpInitial: 40,
  },
};

const lion: TypeConfig = {
  type: "lion",
  typeRarity: TypeRarity.lion,
  attributes: {
    hp: 20,
    speed: 40,
    attack: 20,
    defense: 20,
    hpInitial: 20,
  },
};
const fox: TypeConfig = {
  type: "fox",
  typeRarity: TypeRarity.fox,
  attributes: {
    hp: 10,
    speed: 40,
    attack: 40,
    defense: 10,
    hpInitial: 10,
  },
};
const turtle: TypeConfig = {
  type: "turtle",
  typeRarity: TypeRarity.turtle,
  attributes: {
    hp: 30,
    speed: 10,
    attack: 10,
    defense: 50,
    hpInitial: 30,
  },
};
const dragon: TypeConfig = {
  type: "dragon",
  typeRarity: TypeRarity.dragon,
  attributes: {
    hp: 20,
    speed: 20,
    attack: 40,
    defense: 20,
    hpInitial: 20,
  },
};

export const MonsterTypes = {
  bull,
  bear,
  gorilla,
  boar,
  lizard,
  elephant,
  whale,
  lion,
  fox,
  turtle,
  dragon,
};
