import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { Address, zeroAddress } from "viem";
import { WriteContractResult } from "@wagmi/core";
import { useCallback } from "react";
import { writeContractConfidentialDirect } from "../../../sapphire";

export type PackMint = {
  mintAsync: () => Promise<WriteContractResult | undefined>;
};

export const useMintPacks = (
  to: Address,
  amount: bigint,
  price: bigint = 0n
): PackMint => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  const mintAsync = useCallback(async () => {
    if (to !== zeroAddress && amount > 0n && price > 0n) {
      return await writeContractConfidentialDirect({
        contract,
        functionName: "buy",
        args: [to, amount],
        value: price,
        gas: 500_000n + 100_000n * amount,
      });
    }
  }, [to, amount, price, contract]);

  return {
    mintAsync,
  };
};
