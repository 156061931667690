import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Address } from "viem";
import {
  TokensByOwnerDocument,
  TokensByOwnerQuery,
  TransfersByTransactionHashDocument,
  TransfersByTransactionHashQuery,
  execute,
  SupplyDataPointsDocument,
  SupplyDataPointsQuery,
  HolderStatsDatasQuery,
  HolderStatsDatasDocument,
  OpenedTokensByOpenerDocument,
  OpenedTokensByOpenerQuery,
  OpenedTokensByOwnerNotBurnedQuery,
  OpenedTokensByOwnerNotBurnedDocument,
  OpenedTokensQuery,
  OpenedTokensDocument,
  BattleEventsQuery,
  BattleEventsDocument,
} from "../graphclient";
import { AppContext } from ".";

type ProviderProps = {
  children: React.ReactNode;
};

export interface BattleEvent {
  id: string;
  matchId: string;
  action: string;
  data: string;
  timestamp: string;
  player: string;
  opponent: string;
  monster: string;
  opponentMonster: string;
  round: string;
}

export interface HolderStats {
  id: string;
  items1: string;
  items2_3: string;
  items4_10: string;
  items11_25: string;
  items26_50: string;
  items51: string;
  total: string;
}

export interface GetSupplyDataPoints {
  id: string;
  timestamp: string;
  totalSupply: string;
}

export interface GetTokensByOwnerToken {
  id: string;
  uri?: string | null;
  minter?: {
    id: string;
  } | null;
  owner?: {
    id: string;
  } | null;
}

export interface GetOpenedTokensByOwnerToken extends GetTokensByOwnerToken {
  epoch?: {
    startBlock: string;
    endBlock?: string;
    timestamp: string;
    randomness?: string;
  } | null;
}

export interface TransfersByTransactionHash {
  id: string;
  from?: {
    id: string;
  } | null;
  to?: {
    id: string;
  } | null;
  token?: {
    id: string;
  } | null;
  transactionHash: string;
}

export const GraphContext = createContext<{
  getBattleEvents: (matchId: BigInt) => Promise<BattleEventsQuery>;
  getHolderStats: () => Promise<HolderStatsDatasQuery>;
  getSupplyDataPoints: () => Promise<SupplyDataPointsQuery>;
  getTokensByOwner: (owner: Address) => Promise<TokensByOwnerQuery>;
  getOpenedTokensByOpener: (
    opener: Address
  ) => Promise<OpenedTokensByOwnerNotBurnedQuery>;
  getOpenedTokensIncludingBurns: () => Promise<OpenedTokensQuery>;
  getOpenedTokensByOpenerIncludingBurns: (
    opener: Address
  ) => Promise<OpenedTokensByOpenerQuery>;
  getTransfersByTransactionHash: (
    transactionHash: string
  ) => Promise<TransfersByTransactionHashQuery>;
}>({
  getBattleEvents: async () => ({} as BattleEventsQuery),
  getHolderStats: async () => ({} as HolderStatsDatasQuery),
  getSupplyDataPoints: async () => ({} as SupplyDataPointsQuery),
  getTokensByOwner: async () => ({} as TokensByOwnerQuery),
  getOpenedTokensByOpener: async () =>
    ({} as OpenedTokensByOwnerNotBurnedQuery),
  getOpenedTokensIncludingBurns: async () => ({} as OpenedTokensQuery),
  getOpenedTokensByOpenerIncludingBurns: async () =>
    ({} as OpenedTokensByOpenerQuery),
  getTransfersByTransactionHash: async () =>
    ({} as TransfersByTransactionHashQuery),
});

const PMON_GRAPH = import.meta.env.VITE_PMON_GRAPH;

export const GraphProvider: React.FC<ProviderProps> = ({ children }) => {
  const { chainId } = useContext(AppContext);

  const chainName = useMemo(() => {
    switch (chainId) {
      case 23294:
        return "ocbSapphire";
      case 42001:
        return PMON_GRAPH || "bondingCurveMons";
      default:
        return "bondingCurveMons";
    }
  }, [chainId]);

  const getBattleEvents = useCallback(
    async (matchId: BigInt): Promise<BattleEventsQuery> => {
      return execute(
        BattleEventsDocument,
        { matchId: `${matchId}` },
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: BattleEventsQuery }) => {
          console.log("result", result);
          return result?.data;
        }
      );
    },
    []
  );

  const getHolderStats =
    useCallback(async (): Promise<HolderStatsDatasQuery> => {
      return execute(
        HolderStatsDatasDocument,
        {},
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: HolderStatsDatasQuery }) => {
          return result?.data;
        }
      );
    }, []);

  const getSupplyDataPoints =
    useCallback(async (): Promise<SupplyDataPointsQuery> => {
      return execute(
        SupplyDataPointsDocument,
        {},
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: SupplyDataPointsQuery }) => {
          return result?.data;
        }
      );
    }, []);

  // getTokensByOwner
  const getTokensByOwner = useCallback(
    async (owner: Address): Promise<TokensByOwnerQuery> => {
      return execute(
        TokensByOwnerDocument,
        {
          owner: owner.toLowerCase(),
        },
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: TokensByOwnerQuery }) => {
          return result?.data;
        }
      );
    },
    []
  );

  const getOpenedTokensByOpener = useCallback(
    async (opener: Address): Promise<OpenedTokensByOwnerNotBurnedQuery> => {
      return execute(
        OpenedTokensByOwnerNotBurnedDocument,
        {
          owner: opener.toLowerCase(),
        },
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: OpenedTokensByOwnerNotBurnedQuery }) => {
          return result?.data;
        }
      );
    },
    []
  );

  const getOpenedTokensIncludingBurns =
    useCallback(async (): Promise<OpenedTokensQuery> => {
      return execute(
        OpenedTokensDocument,
        {},
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: OpenedTokensQuery }) => {
          return result?.data;
        }
      );
    }, []);

  const getOpenedTokensByOpenerIncludingBurns = useCallback(
    async (opener: Address): Promise<OpenedTokensByOpenerQuery> => {
      return execute(
        OpenedTokensByOpenerDocument,
        {
          opener: opener.toLowerCase(),
        },
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: OpenedTokensByOpenerQuery }) => {
          return result?.data;
        }
      );
    },
    []
  );

  const getTransfersByTransactionHash = useCallback(
    async (
      transactionHash: string
    ): Promise<TransfersByTransactionHashQuery> => {
      return execute(
        TransfersByTransactionHashDocument,
        {
          transactionHash,
        },
        {
          chainName,
        }
      ).then(
        // @ts-ignore
        (result: { data: TransfersByTransactionHashQuery }) => {
          return result?.data;
        }
      );
    },
    []
  );

  return (
    <GraphContext.Provider
      value={{
        getBattleEvents,
        getHolderStats,
        getSupplyDataPoints,
        getTokensByOwner,
        getOpenedTokensByOpener,
        getOpenedTokensIncludingBurns,
        getOpenedTokensByOpenerIncludingBurns,
        getTransfersByTransactionHash,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};
