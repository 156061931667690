import React from "react";
import { useParams } from "react-router-dom";
import { Battle } from "../Battle";
import { Address } from "viem";

const noop = () => null;

export const BattleDebug: React.FC = () => {
  const { id, player } = useParams();
  return (
    <Battle
      impersonatedAccount={player as Address}
      impersonatedMatchId={BigInt(id!)}
      hideBattleResult={true}
    />
  );
};
