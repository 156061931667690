const SOUNDS = {
  click: new URL("./action_click_1.mp3", import.meta.url).href,
  click2: new URL("./action_click_2.mp3", import.meta.url).href,
  close: new URL("./action_close.mp3", import.meta.url).href,
  copy: new URL("./action_copy.mp3", import.meta.url).href,
  select: new URL("./action_select.mp3", import.meta.url).href,
  warning: new URL("./system_warning.mp3", import.meta.url).href,
  success: new URL("./system_success.mp3", import.meta.url).href,
  successLong: new URL("./system_success_long.mp3", import.meta.url).href,
  error: new URL("./system_error_1.mp3", import.meta.url).href,
  error2: new URL("./system_error_2.mp3", import.meta.url).href,
  queueWaiting: new URL("./queue_waiting.mp3", import.meta.url).href,
  queueSuccess: new URL("./queue_success.mp3", import.meta.url).href,
  none: new URL("./none.mp3", import.meta.url).href,
  battle: new URL("./battle.mp3", import.meta.url).href,
};

export default SOUNDS;
