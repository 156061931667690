import { motion } from "framer-motion";
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

import { usePrivyWagmi } from "@privy-io/wagmi-connector";
import { useToggle } from "@uidotdev/usehooks";

import { Button, ModalBinderMonsters, ModalInfo } from "../../components";
import { ModalLeaderBoardBinderBattles } from "../../components/modalLeaderBoard";
import { Monsters, getConfigById } from "../../config";
import { AppContext, UserContext } from "../../context";
import { useReverseSwap, useSellPacks } from "../../hooks";
import { useFetchTokensByOwner } from "../../hooks/graph/tokens";
import { Monster, MonsterId, RarityLevel } from "../../types";
import {
  contentTransition,
  contentVariants,
  getNameForElement,
  shortenRarity,
} from "../../utility";
import { capitalizeFirstChar } from "../Collection/helper";
import {
  BinderSlot,
  ProgressDonutProps,
  SectionContent,
  SectionHeaderProps,
  SectionProps,
} from "./types";

function getScoreForMonster(monsterId: MonsterId) {
  const monsterConfig = getConfigById(monsterId);
  switch (monsterConfig.typeRarity) {
    case RarityLevel.Common:
      return 1;
    case RarityLevel.Uncommon:
      return 2;
    case RarityLevel.Rare:
      return 5;
    case RarityLevel.VeryRare:
      return 10;
    case RarityLevel.UltraRare:
      return 20;
    case RarityLevel.Mythic:
      return 50;
    default:
      return 0;
  }
}

const info = [
  {
    title: "Are there any rewards for collectors?",
    text: "We are not sure if there will ever be rewards for collectors here. You should only open packs if you love collecting the monsters.",
  },
  {
    title: "Will the monster scores change?",
    text: "Most likely, yes. We will experiment with them.",
  },
  {
    title: "Will there be a collector leaderboard?",
    text: "Yes, we're working on it!",
  },
  {
    title: "Can monsters be transferred?",
    text: "Soon! But remember that a transfer will also transfer the PMON locked with the monster.",
  },
];

const MONSTER_TYPES = Object.values(Monsters)
  .map((monster) => monster.type)
  .reduce((acc, curr) => {
    if (!acc.includes(curr)) {
      acc.push(curr);
    }
    return acc;
  }, [] as string[]);

export const Binders: React.FC = () => {
  const { user, userName } = useContext(UserContext)!;
  const { wallet } = usePrivyWagmi();
  const [leaderBoardVisibility, toggleLeaderBoardVisibility] = useToggle(false);
  const [isInfoVisible, toggleIsInfoVisible] = useToggle(false);

  const { setNoPromptOnSignature } = useContext(AppContext);
  useEffect(() => {
    setNoPromptOnSignature(false);

    return () => {
      setNoPromptOnSignature(true);
    };
  }, [setNoPromptOnSignature]);

  const { tokens, refetch } = useFetchTokensByOwner(wallet?.address, true);

  const [selectedMonsters, setSelectedMonsters] = useState<Monster[]>([]);
  const onResetMonsterDetail = useCallback(() => {
    setSelectedMonsters([]);
  }, []);

  // Get the monsters for each type
  const monstersByType: SectionContent[] = useMemo(() => {
    return MONSTER_TYPES.map((type) => {
      return {
        type: capitalizeFirstChar(type),
        monsters: Object.values(Monsters)
          .filter((monster) => monster.type === type)
          .map((monster) => {
            const collectedMonsters = tokens.filter(
              (t) => t.decodedUri.name === getConfigById(monster.id).name
            );

            const monsterConfig = {
              id: monster.id,
              type: monster.type,
              name: monster.name,
              imageURI: monster.imageURI,
              typeRarity: monster.typeRarity,
              element: monster.element,
              attributes: monster.attributes,
            };

            const slot: BinderSlot = {
              onClick: () => {
                setSelectedMonsters(
                  collectedMonsters.map((c) => ({
                    tokenId: c.id,
                    config: monsterConfig,
                  }))
                );
              },
              score:
                collectedMonsters.length > 0
                  ? getScoreForMonster(monster.id)
                  : 0,
              numCollected: collectedMonsters.length,
              config: monsterConfig,
            };

            return slot;
          }),
      };
    }, []);
  }, [tokens]);

  const collectionScore = useMemo(() => {
    return monstersByType
      .map(({ monsters }) => {
        return monsters
          .map((m) => m.score)
          .reduce((acc, curr) => acc + curr, 0);
      })
      .reduce((acc, curr) => acc + curr, 0);
  }, [monstersByType]);

  const [isSelling, setIsSelling] = useState(false);
  const { sellAsync } = useSellPacks(
    [
      selectedMonsters.length > 0
        ? BigInt(selectedMonsters[0].tokenId || "0")
        : BigInt(0),
    ],
    wallet?.address as `0x${string}`,
    selectedMonsters.length > 0
  );

  const onSell = useCallback(async () => {
    setIsSelling(true);
    try {
      await sellAsync?.();
      await refetch();
      setSelectedMonsters([]);
    } catch (err) {
      // do nothing yet
    } finally {
      setIsSelling(false);
    }
  }, [refetch, sellAsync]);

  const [isReverseSwapping, setIsReverseSwapping] = useState(false);
  const { reverseSwapAsync } = useReverseSwap(
    selectedMonsters.length > 0
      ? BigInt(selectedMonsters[0].tokenId || "0")
      : BigInt(0),
    wallet?.address as `0x${string}`,
    selectedMonsters.length > 0
  );

  const onReverseSwap = useCallback(async () => {
    setIsReverseSwapping(true);
    try {
      await reverseSwapAsync?.();
      await refetch();
      setSelectedMonsters([]);
    } catch (err) {
      // do nothing yet
    } finally {
      setIsReverseSwapping(false);
    }
  }, [refetch, reverseSwapAsync]);

  return (
    <>
      <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro">
        <div className="flex flex-col flex-1 pt-safe-offset-4 pb-4 bg-black/50 backdrop-blur-sm space-y-4 h-screen overflow-y-auto">
          <div className="flex flex-col space-y-0 px-4">
            <div className="flex flex-row flex-1 justify-between">
              <h1 className="font-display font-black text-text-light text-3xl">
                Binders
              </h1>
              <Button
                size="xs"
                variant="dark"
                className="w-10 h-10 shadow-lg"
                onClick={
                  toggleIsInfoVisible as unknown as MouseEventHandler<HTMLButtonElement>
                }
              >
                <i className="material-symbols-rounded text-lg">info</i>
              </Button>
            </div>
            <span className="text-text-light text-sm">
              Collection Score: <i>coming soon...</i>
            </span>
          </div>

          <div className="px-2 space-y-4">
            {monstersByType.map(({ type, monsters }) => {
              const progress = monsters.reduce((acc, curr) => {
                return acc + curr.numCollected;
              }, 0);

              return (
                <Section
                  key={type}
                  title={type}
                  progress={progress}
                  goal={monsters.length}
                  binderSlots={monsters}
                  score={
                    monsters
                      .map((m) => m.score)
                      .reduce((acc, curr) => acc + curr, 0) || 0
                  }
                />
              );
            })}
          </div>
        </div>
      </div>

      <ModalInfo
        isVisible={isInfoVisible}
        title={"Binders"}
        subTitle={"Bla bla bla subtitle!"}
        infoItems={info}
        onClose={toggleIsInfoVisible}
      />

      <ModalBinderMonsters
        monsters={selectedMonsters}
        isVisible={selectedMonsters.length > 0}
        onClose={onResetMonsterDetail}
        onReverseSwap={onSell}
        onReroll={onReverseSwap}
        isLoading={isSelling || isReverseSwapping}
      />

      <ModalLeaderBoardBinderBattles
        player={user}
        userName={userName}
        isVisible={leaderBoardVisibility}
        onClose={toggleLeaderBoardVisibility}
      />
    </>
  );
};

const ProgressDonut: React.FC<ProgressDonutProps> = ({
  progress,
  goal,
  size,
}) => {
  const radius = size / 2;
  const strokeWidth = radius / 4;
  const normalizedRadius = radius - strokeWidth / 2;
  const circumference = normalizedRadius * 2 * Math.PI;

  const progressPercent = Math.min(Math.max(progress / goal, 0), 1);
  const strokeDashoffset = circumference - progressPercent * circumference;

  return (
    <div className="flex flex-col items-center justify-center relative">
      <svg
        height={radius * 2}
        width={radius * 2}
        style={{ transform: "rotate(-90deg)" }}
      >
        {/* Background circle */}
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeOpacity="0.5"
          strokeLinecap="round" // Rounded corners for the background circle
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        {/* Foreground circle */}
        <circle
          stroke="white"
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference + " " + circumference}
          style={{ strokeDashoffset }}
          strokeLinecap="round" // Rounded corners for the progress circle
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  );
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  progress,
  goal,
  score,
}) => {
  return (
    <div className="flex flex-row items-center justify-between p-2 border-b-0.5 border-background-primary/20">
      <h2 className="font-display font-black text-text-light text-2xl">
        {title}
      </h2>
      <div className="flex flex-row space-x-4 items-center justify-center">
        <div className="flex flex-row space-x-2 items-center justify-center">
          <ProgressDonut progress={progress} goal={goal} size={18} />
          <span className="font-mono font-bold text-text-light text-sm">
            {progress}/{goal}
          </span>
        </div>
        <div className="flex flex-row space-x-1 items-center justify-center">
          <i className="material-symbols-rounded text-text-light text-xl">
            stars
          </i>
          <span className="font-mono font-bold text-text-light text-sm">
            {score}
          </span>
        </div>
      </div>
    </div>
  );
};

const Slot: React.FC<BinderSlot> = ({
  numCollected,
  score,
  config: { id, name, imageURI, typeRarity, element },
  onClick,
}) => {
  const isCollected = numCollected > 0;
  const color = isCollected ? "text-text-highlight" : "text-text-tertiary";
  return (
    <div
      key={id}
      className="flex flex-1 flex-col p-2 space-y-2 rounded-2xl bg-background-primary/40 border border-background-primary/10 backdrop-blur-sm shadow-lg"
      onClick={onClick}
    >
      <img
        className={twMerge(
          "rounded-lg aspect-square",
          numCollected === 0 && "grayscale shadow-inner",
          numCollected > 0 && "shadow-sm"
        )}
        src={imageURI}
        alt={`Monster ${id}`}
      />
      <div className="flex flex-1 flex-col p-2 space-y-1 rounded-lg bg-background-primary/80 border border-background-primary/10 backdrop-blur-sm shadow-sm">
        <div className="flex flex-row items-between items-center">
          <span className="flex flex-1 text-sm text-text-primary font-display font-bold">
            {name}
          </span>
          <span className="flex flex-1 text-2xs text-text-secondary font-display font-bold justify-end">
            {shortenRarity(typeRarity)}
          </span>
        </div>
        <div className="flex flex-row items-between">
          <div className="flex flex-row space-x-1 items-center">
            {/* <i className={twMerge("material-symbols-rounded text-sm", color)}>
              palette
            </i> */}
            <span className={twMerge("font-display text-xs", color)}>
              {getNameForElement(element)}
            </span>
          </div>
          <div className="flex flex-1 flex-row space-x-1 items-center justify-end">
            <span className={twMerge("font-display text-xs", color)}>
              {score}
            </span>
            {/* <i className={twMerge("material-symbols-rounded text-sm", color)}>
              offline_bolt
            </i> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Section: React.FC<SectionProps> = ({
  title,
  progress,
  goal,
  score,
  binderSlots,
}) => {
  return (
    <motion.div
      variants={contentVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={contentTransition}
      className="flex flex-col p-2 rounded-3xl bg-background-primary/20 backdrop-blur-xl border border-background-primary/20 shadow-lg"
    >
      <div className="flex flex-col space-y-4 p-2 rounded-2xl backdrop-blur-xl bg-background-primary/40 border border-background-primary/20 shadow-sm">
        <SectionHeader
          title={title}
          progress={progress}
          goal={goal}
          score={score}
        />
        <div className="grid grid-cols-2 gap-4">
          {binderSlots.map((slot) => (
            <Slot key={slot.config.id} {...slot} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};
