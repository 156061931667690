export const getPacksForChain = (chainId: string | number): string[] => {
  switch (`${chainId}`) {
    case "eip155:42161":
    case "42161":
    case "eip155:16890849097":
    case "16890849097":
    case "eip155:42001":
    case "42001":
    case "23294":
    case "eip155:23294":
      return ["elementalEchoes"];
    case "eip155:168587773":
    case "168587773":
      return [
        "crystalCavern",
        "galacticRift",
        "genesisFactory",
        "mutantLaboratory",
      ];
    default:
      throw new Error(`Unknown chainId: ${chainId}`);
  }
};
