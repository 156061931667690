import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-use";

import { Button, MonsterCard } from "../../components";
import { getConfigById } from "../../config";
import { MonsterId } from "../../types";
import { contentTransition, contentVariants } from "../../utility";

interface Reveal {
  revealId: string;
  content: {
    packId: number;
    monsterId: MonsterId;
    revealed: boolean;
    revealable: boolean;
  }[];
}

const useReveal = (id: string, amount: number) => {
  const [revealData, setRevealData] = useState<Reveal>();

  const revealBoosterPack = (revealId: string, packId: number) => {
    setRevealData((currentRevealData) => {
      if (!currentRevealData) return currentRevealData;

      const updatedContent = currentRevealData.content.map((content) => {
        if (content.packId === packId) {
          return {
            ...content,
            revealed: true,
            revealable: false,
          };
        }

        return content;
      });

      return {
        ...currentRevealData,
        content: updatedContent,
      };
    });
  };

  useEffect(() => {
    const mockedReveal: Reveal = {
      revealId: id,
      content: [
        {
          packId: 1,
          monsterId: MonsterId.Aquasteer,
          revealed: false,
          revealable: true,
        },
        {
          packId: 2,
          monsterId: MonsterId.Blazehorn,
          revealed: false,
          revealable: true,
        },
        {
          packId: 3,
          monsterId: MonsterId.Fernopig,
          revealed: false,
          revealable: true,
        },
      ],
    };

    setRevealData(mockedReveal);
  }, [id, amount]);

  return { revealData, revealBoosterPack };
};

export const Reveal: React.FC = () => {
  const navigate = useNavigate();
  const { id = "" } = useParams();
  const { state } = useLocation();
  const amount = state.usr.amount;

  const [index, setIndex] = useState(0);

  // Index never goes above amount - 1
  const handleIncrement = () => {
    setIndex((currentIndex) => Math.min(currentIndex + 1, amount - 1));
  };

  // Index never goes below 0
  const handleDecrement = () => {
    setIndex((currentIndex) => Math.max(currentIndex - 1, 0));
  };

  // Navigate back to the booster packs page
  const handleBack = () => {
    navigate("/booster-packs", { replace: true });
  };

  // Get the reveal data for the current index
  const { revealData, revealBoosterPack } = useReveal(id, amount);

  const monsterData = useMemo(() => {
    const content = revealData?.content[index];
    if (!content) return undefined;
    return {
      ...content,
      config: getConfigById(content.monsterId),
    };
  }, [revealData, index]);

  return (
    <div className="flex flex-col flex-1 bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro">
      <div className="flex flex-col flex-1 pt-safe-offset-4 bg-black/50 backdrop-blur-sm space-y-4">
        {/**
         * Header
         */}

        <div className="flex flex-row justify-between space-x-2 px-2">
          <div className="w-8 h-8" />
          <h1 className="font-display font-black text-text-light text-3xl">
            Reveal
          </h1>
          <Button
            size="xs"
            variant="light"
            className="w-8 h-8"
            sound="click"
            onClick={handleBack}
          >
            <span className="material-symbols-rounded text-text-tertiary text-base">
              close
            </span>
          </Button>
        </div>

        {/**
         * Content
         */}

        <div className="flex flex-col flex-1">
          <div className="flex flex-col flex-1 justify-center px-8">
            {monsterData && (
              <motion.div
                key={`${monsterData.monsterId}-${monsterData.packId}`}
                transition={contentTransition}
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                className="flex flex-col p-2 rounded-3xl space-y-2 bg-background-primary/20 backdrop-blur-xl border border-background-primary/20 shadow-lg"
              >
                <MonsterCard
                  monster={monsterData}
                  isRevealed={monsterData.revealed}
                  revealable={monsterData.revealable}
                />

                <div className="flex flex-1 flex-col space-y-4 p-2 rounded-xl bg-background-primary/60 border border-background-primary/20 backdrop-blur-sm shadow-sm">
                  {/** Pack Details */}
                  <div className="flex flex-row justify-between items-center">
                    <span className="text-text-primary text-xl font-display font-bold text-left">
                      Elemental Echoes
                    </span>
                    <span className="text-text-tertiary uppercase font-black text-2xs text-right">
                      1 NFT
                    </span>
                  </div>

                  <Button
                    variant="primary"
                    size="md"
                    disabled={monsterData.revealed}
                    onClick={() => revealBoosterPack(id, index + 1)}
                  >
                    {monsterData.revealed ? "Revealed" : "Reveal"}
                  </Button>
                </div>
              </motion.div>
            )}
          </div>

          {/**
           * Action Bar
           */}
          <div className="flex flex-row items-center justify-between pb-safe-offset-2 pt-2 px-2 bg-background-primary/80 backdrop-blur-sm border border-t border-background-primary/20">
            <Button
              size="xs"
              variant="light"
              className="w-12 h-12 shadow-none"
              sound="click"
              disabled={index === 0}
              onClick={handleDecrement}
            >
              <span className="material-symbols-rounded ">chevron_left</span>
            </Button>
            <div className="flex flex-row flex-1 space-x-1 font-display text-text-tertiary text-sm align-baseline items-center justify-center">
              <span className="flex flex-col flex-1 text-right">
                {index + 1}
              </span>
              <span className="flex flex-col flex-1 text-center">/</span>
              <span className="flex flex-col flex-1 text-left">{amount}</span>
            </div>
            <Button
              size="xs"
              variant="light"
              className="w-12 h-12 shadow-none"
              sound="click"
              disabled={index === amount - 1}
              onClick={handleIncrement}
            >
              <span className="material-symbols-rounded ">chevron_right</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
