import { motion } from "framer-motion";
import React, { forwardRef, useCallback, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import useSound from "../../sound/useSound.ts";

import SOUNDS from "../../assets/sounds";
import { ButtonProps } from "./buttonProps";
import { buttonVariants } from "./buttonVariants";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, children = "Button", variant, size, sound, onClick, ...props },
    ref
  ) => {
    const soundSource = useMemo(() => {
      if (sound) {
        return SOUNDS[sound];
      }
      return undefined;
    }, [sound]);

    const [play] = useSound(soundSource, {
      id: `button-${sound}`,
    });

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick?.(event);
        sound !== undefined && play();
      },
      [onClick, sound, play]
    );

    return (
      <motion.button
        className={twMerge(buttonVariants({ variant, size }), className)}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 1.05 }}
        ref={ref}
        onClick={handleClick}
        {...props}
      >
        {children}
      </motion.button>
    );
  }
);

Button.displayName = "Button";
