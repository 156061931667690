import { motion } from "framer-motion";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useInterval } from "usehooks-ts";

import { useToggle } from "@uidotdev/usehooks";

import {
  ActionBar,
  ActionSheet,
  Button,
  ModalComingSoon,
  ModalInfo,
  Segments,
} from "../../components";
import { EpochCard } from "../../components/epochCard";
import { ModalPacksRevealing } from "../../components/modalPacksRevealing";
import { Monsters } from "../../config";
import { UserContext } from "../../context";
import {
  EnrichedOpenedToken,
  EnrichedToken,
  useFetchTokensByOwner,
} from "../../hooks/graph/tokens/useFetchTokensByOwner.tsx";
import { Monster, MonsterElement } from "../../types";
import {
  contentTransition,
  contentVariants,
  getStringForElement,
} from "../../utility";
import {
  capitalizeFirstChar,
  generateTokenId,
  mapSortKeyToConfigKey,
} from "./helper";
import {
  SortBy,
  SortByKey,
  allowedElements,
  allowedTypes,
  sortByKeys,
} from "./types";
import { zeroAddress } from "viem";
import { ButtonOpenPacks } from "../../components/buttonOpenPacks";

const SEGMENTS = [
  { label: "Yours", value: "yours" },
  { label: "All", value: "all" },
];

const epochCardInfo = [
  {
    title: "What is a reveal epoch?",
    text: "Tokens are revealed in epochs. Each epoch is usually open for a couple of minutes, and once a randomness value is available for that epoch, all monsters in that epoch will be revealed. More technical details about epochs will follow in the future.",
  },
];

export const Openings: React.FC = () => {
  const { walletAddress } = useContext(UserContext)!;
  const [sortBy, setSortBy] = useState<SortBy>();
  const [filterTypes, setFilterTypes] = useState<string[]>([]);
  const [filterElements, setFilterElements] = useState<MonsterElement[]>([]);
  const [selectedSegment, setSelectedSegment] = useState(SEGMENTS[0].value);
  const [isSheetVisible, toggleSheetVisible] = useToggle(false);
  const [selectedPack, setSelectedPack] = useState<EnrichedToken>();
  const [isEpochCardInfoVisible, setIsEpochCardInfoVisible] = useState<boolean>(false)

  // Actions

  const handleClickMonster = useCallback((monster: Monster) => {}, []);

  const handleClickFilter = useCallback(() => {
    toggleSheetVisible();
  }, [toggleSheetVisible]);

  const handleSortBy = useCallback(
    (key: SortByKey) => {
      setSortBy((prevSortBy) => {
        if (prevSortBy?.key === key) {
          return undefined;
        }
        return { key, order: "desc" };
      });
    },
    [setSortBy]
  );

  const handleFilterElement = useCallback(
    (btnElement: MonsterElement) => {
      setFilterElements((prevElements) =>
        prevElements.includes(btnElement)
          ? prevElements.filter((e) => e !== btnElement)
          : [...prevElements, btnElement]
      );
    },
    [setFilterElements]
  );

  const handleFilterType = useCallback(
    (btnType: string) => {
      setFilterTypes((prevTypes) =>
        prevTypes.includes(btnType)
          ? prevTypes.filter((e) => e !== btnType)
          : [...prevTypes, btnType]
      );
    },
    [setFilterTypes]
  );

  const handleClear = useCallback(() => {
    setFilterElements([]);
    setFilterTypes([]);
    setSortBy(undefined);
    toggleSheetVisible();
  }, [setFilterElements, setFilterTypes, setSortBy, toggleSheetVisible]);

  // Openings

  const numberFilters = filterElements.length + filterTypes.length;

  const { refetch, tokens } = useFetchTokensByOwner<EnrichedOpenedToken>(
    selectedSegment === "all" ? zeroAddress : walletAddress,
    true,
    true
  );

  useInterval(refetch, 10_000);

  const tokensGroupedByEpochs = useMemo(() => {
    // Initialize an empty Map to hold the grouping
    const grouped = tokens.reduce((acc, token) => {
      // Use the epoch timestamp as the key, or a fallback for tokens without an epoch
      const epochKey = token.epoch ? token.epoch.timestamp : "undefined";

      // Get the current group for this epochKey, or initialize it if it doesn't exist
      const currentGroup = acc.get(`${epochKey}`) || [];

      // Add the current token to the group
      currentGroup.push(token);

      // Update the Map
      acc.set(`${epochKey}`, currentGroup);

      return acc;
    }, new Map<string, EnrichedOpenedToken[]>()); // Initialize the accumulator as a new Map

    const sortedArray = Array.from(grouped).sort(
      (
        a: [string, EnrichedOpenedToken[]],
        b: [string, EnrichedOpenedToken[]]
      ) => {
        // Compare bigint epoch timestamps directly without converting to Number
        return Number(
          (b[0] === "undefined" ? BigInt(0) : BigInt(b[0])) -
            (a[0] === "undefined" ? BigInt(0) : BigInt(a[0]))
        );
      }
    );

    // Create a new Map from the sorted array
    return new Map(sortedArray);
  }, [tokens]);

  const onCloseRevealModal = useCallback(() => {
    setSelectedPack(undefined);
  }, []);

  const onClickToken = useCallback(
    (token: EnrichedToken) => {
      setSelectedPack(token);
    },
    [setSelectedPack]
  );

  return (
    <div className="flex flex-1 flex-col bg-no-repeat bg-cover bg-center bg-fixed bg-background-intro">
      <div className="flex flex-col flex-1 pt-safe-offset-4 pb-4 px-4 bg-black/50 backdrop-blur-sm space-y-4 h-screen overflow-y-auto">
        <h1 className="font-display font-black text-text-light text-3xl">
          Pack Openings
        </h1>

        <Segments
          options={SEGMENTS}
          onClick={setSelectedSegment}
          selectedOption={selectedSegment}
          backgroundColorActive="bg-background-secondary"
          borderColorActive="border-border-secondary"
        />

        {tokensGroupedByEpochs.size > 0 ? (
          <div className="grid grid-cols-1 gap-4">
            {Array.from(tokensGroupedByEpochs).map(([timestamp, tokens]) => (
              <motion.div
                key={timestamp}
                variants={contentVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={contentTransition}
              >
                <EpochCard
                  onClick={onClickToken}
                  onInfoClick={() => setIsEpochCardInfoVisible(true)}
                  epoch={{
                    timestamp,
                    // @ts-ignore
                    number: tokens[0].epoch.number,
                    tokens,
                    randomness: tokens[0].epoch.randomness,
                  }}
                  isForceRevealed={selectedSegment === "all"}
                />
              </motion.div>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full">
           <ButtonOpenPacks />
          </div>
        )}
      </div>

      {/*<div className="absolute bottom-16 right-2 z-10 pb-safe">*/}
      {/*  <Button*/}
      {/*    variant="light"*/}
      {/*    size="xs"*/}
      {/*    className="w-12 h-12 shadow-lg"*/}
      {/*    onClick={handleClickFilter}*/}
      {/*  >*/}
      {/*    <i className="material-symbols-rounded text-xl text-text-primary">*/}
      {/*      filter_list*/}
      {/*    </i>*/}
      {/*  </Button>*/}
      {/*  {numberFilters > 0 && (*/}
      {/*    <div className="h-5 w-5 rounded-full bg-background-highlight absolute bottom-0 right-0 flex flex-col justify-center items-center">*/}
      {/*      <span className="font-display text-xs text-text-light">*/}
      {/*        {numberFilters}*/}
      {/*      </span>*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      <ActionSheet
        title="Sort & Filter"
        isVisible={isSheetVisible}
        onClose={toggleSheetVisible}
        className="px-4 pb-safe-offset-4"
      >
        <div className="flex flex-col space-y-4">
          {/** Sort By */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Sort By
            </h2>

            <div className="grid grid-cols-4 gap-2">
              {sortByKeys.map((sortKey) => {
                const displayKey = mapSortKeyToConfigKey(sortKey); // Mapping to display value
                const isSelected = sortBy?.key === sortKey;
                const variant = isSelected ? "light" : "unselected";
                const onClick = () => handleSortBy(sortKey);
                return (
                  <Button
                    key={sortKey}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {displayKey} {/* Display the mapped key */}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Monster Type */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Type
            </h2>

            <div className="grid grid-cols-4 gap-2">
              {allowedTypes.map((btnType) => {
                const variant = filterTypes.includes(btnType)
                  ? "light"
                  : "unselected";
                const onClick = () => handleFilterType(btnType);

                return (
                  <Button
                    key={btnType}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {capitalizeFirstChar(btnType)}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Monster Element */}
          <div className="flex flex-col p-4 rounded-2xl bg-background-dark/20">
            <h2 className="font-display font-bold text-md text-text-light mb-4">
              Elements
            </h2>

            <div className="grid grid-cols-3 gap-2">
              {allowedElements.map((btnElement) => {
                const variant = filterElements.includes(btnElement)
                  ? "light"
                  : "unselected";
                const onClick = () => handleFilterElement(btnElement);

                return (
                  <Button
                    key={btnElement}
                    size="sm"
                    variant={variant}
                    className="w-full"
                    onClick={onClick}
                    sound="click"
                  >
                    {getStringForElement(btnElement)}
                  </Button>
                );
              })}
            </div>
          </div>

          {/** Clear */}
          <Button onClick={handleClear}>Clear</Button>
        </div>
      </ActionSheet>

      <ModalInfo
        isVisible={isEpochCardInfoVisible}
        title={"Epochs"}
        subTitle={"Bla bla bla subtitle!"}
        infoItems={epochCardInfo}
        onClose={() => setIsEpochCardInfoVisible(false)}
        />

      <ModalPacksRevealing
        monster={selectedPack}
        isVisible={!!selectedPack}
        onClose={onCloseRevealModal}
      />
      <ModalComingSoon isVisible={false} onClose={() => {}} />
    </div>
  );
};
