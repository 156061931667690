export function formatBigNumber(num: number | bigint): string {
  if (typeof num === "bigint") num = Number(num);

  let formattedNumber: string;

  if (num < 1000) {
    formattedNumber = num.toString();
  } else if (num < 1000000) {
    formattedNumber = (num / 1000).toFixed(1) + "k";
  } else if (num < 1000000000) {
    formattedNumber = (num / 1000000).toFixed(1) + "M";
  } else if (num < 1000000000000) {
    formattedNumber = (num / 1000000000).toFixed(1) + "B";
  } else {
    formattedNumber = (num / 1000000000000).toFixed(1) + "T";
  }

  // Remove ".0" if the formatted number ends with it
  if (formattedNumber.endsWith(".0")) {
    formattedNumber = formattedNumber.slice(0, -2);
  }

  return formattedNumber;
}
