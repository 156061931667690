import IMAGES from "../../../assets/images";
import { BoostAbility } from "./ActionProps";

export const BOOST_ABILITIES: BoostAbility[] = [
  {
    key: "boostAttack",
    title: "Attack Aura",
    description:
      "Emanate an empowering aura, boosting the attack attribute of your monster by 20.",
    imageURI: IMAGES.boostAttack,
    contractName: "AttackAuraMove",
  },
  {
    key: "boostDefense",
    title: "Defense Aura",
    description:
      "Surround your monster with a protective aura, enhancing the defense attribute by 20, then 10, and 5 on subsequent uses.",
    imageURI: IMAGES.boostDefense,
    contractName: "DefenseAuraMove",
  },
  {
    key: "boostSpeed",
    title: "Speed Aura",
    description:
      "Unleash a swift aura to hasten your monster, augmenting the speed attribute by 20.",
    imageURI: IMAGES.boostSpeed,
    contractName: "SpeedAuraMove",
  },
  {
    key: "boostHeal",
    title: "Heal",
    description:
      "Restore your monster's vitality, healing 40 HP initially, then 20, and 10 on subsequent uses.",
    imageURI: IMAGES.boostHeal,
    contractName: "HealMove",
  },
];
