import { Chart as ChartJS, registerables } from "chart.js/auto";
import { useRef } from "react";
import { Bar } from "react-chartjs-2";

import { formatEthRounded } from "../../utility";
import { ChartProps } from "./types";

ChartJS.register(...registerables);

const skipped = (ctx: any, value: any) => {
  return ctx.p0.skip || ctx.p1.skip ? value : undefined;
};

export const ResponsiveBarChart = (props: ChartProps) => {
  const chartRef = useRef<any>(null);

  return (
    <div className="w-full h-80">
      <Bar
        data={{
          labels: props.labels,
          datasets: [
            {
              label: "Holders",
              data: props.data,
              backgroundColor: "#ff3369",
              borderColor: "#ff3369",
              borderWidth: 2,
              borderRadius: 6,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
          },
          maintainAspectRatio: false,
          responsive: true,
          scales: {
            y: {
              grid: {
                color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                callback: function (value: any) {
                  if (parseInt(value) % 1 === 0) {
                    return value;
                  }
                },
              },
            },
            x: {
              grid: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          },
        }}
        ref={chartRef}
      />
    </div>
  );
};
