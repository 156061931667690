import { useEffect } from "react";
import { useQueue } from "@uidotdev/usehooks";

export const useBattleEffects = () => {
  const { add, remove, first, queue } = useQueue<string>([]);

  useEffect(() => {
    // we need to watch the first and the queue length (if the same item is in the queue twice then first would not change if your remove one item)
    if (first || queue?.length) {
      const timeoutId = setTimeout(() => {
        remove();
      }, 3100);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [first, queue, remove]);

  return {
    addBattleEffect: add,
    battleEffect: first,
  };
};
