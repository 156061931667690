import { PlayerProps } from "./playerProps";

export const Player: React.FC<PlayerProps> = ({ userName, imageURI }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-2">
      <img
        src={imageURI}
        className="h-32 w-32 rounded-full border-4 border-background-primary/40"
        alt="Profile Picture"
      />
      <span className="font-display text-text-light text-xl">{userName}</span>
    </div>
  );
};
