import { ReactNode, useEffect, useState } from "react";

import { useQueue } from "@uidotdev/usehooks";

// Custom hook for log message queue management
export const useBattleActionLog = (isIntroScreenVisible: boolean) => {
  const [logVisibility, setLogVisibility] = useState<boolean>(false);
  const [logMessage, setLogMessage] = useState<ReactNode | undefined>();

  const { add, remove, first, size } = useQueue<ReactNode>([]);

  useEffect(() => {
    if (first && !isIntroScreenVisible && !logMessage) {
      setLogMessage(first);
      setLogVisibility(true);

      setTimeout(() => {
        setLogVisibility(false);

        setTimeout(() => {
          setLogMessage(undefined);
          remove();
        }, 500);
      }, 3000);
    }
  }, [isIntroScreenVisible, logMessage, first, remove]);

  // Expose the methods and properties that the component will use
  return {
    addLog: add,
    logVisibility,
    logMessage,
    logQueueSize: size,
  };
};
