import { useContract } from "../helpers";
import { ContractNames } from "../contracts.ts";
import { useContractRead, UseContractReadConfig } from "wagmi";
import { Address, formatEther } from "viem";

type SellPriceInfo = {
  sellPriceLoading: boolean;
  sellPrice: bigint | undefined;
  sellPriceFormatted: string | undefined;
  sellPriceFormattedRounded: string | undefined;
};

export const useSellPrice = (amountToSell: bigint = 0n): SellPriceInfo => {
  const contract = useContract(ContractNames.BONDING_CURVE_MONS);

  // Using useContractRead for a single contract call
  const { data, isLoading } = useContractRead({
    ...contract,
    functionName: "getSellPriceInclFees",
    args: [amountToSell],
    enabled: amountToSell > 0n,
  } as UseContractReadConfig);

  return {
    sellPriceLoading: isLoading,
    sellPrice: (data as bigint) ?? 0n,
    sellPriceFormatted: formatEther((data as bigint) ?? 0n),
    sellPriceFormattedRounded: `${parseFloat(
      formatEther((data as bigint) ?? 0n),
    ).toFixed(5)}`,
  };
};
