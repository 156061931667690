import { MonsterElement } from "../types";

const elementMap: Record<MonsterElement, string> = {
  [MonsterElement.None]: "N/A",
  [MonsterElement.Electric]: "Electric",
  [MonsterElement.Fire]: "Fire",
  [MonsterElement.Water]: "Water",
  [MonsterElement.Mental]: "Mental",
  [MonsterElement.Nature]: "Nature",
  [MonsterElement.Toxic]: "Toxic",
};

export function getNameForElement(element: MonsterElement): string {
  return elementMap[element] || "N/A";
}
