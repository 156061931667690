import { motion, useAnimation } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";

import { calculateRemainingTime } from "../../routes";
import { CountdownProps } from "./countdownProps";

export const Countdown: React.FC<
  CountdownProps & { resetKey: number; endDate: Date }
> = ({ endDate, pause, onComplete, resetKey }) => {
  const [count, setCount] = useState(calculateRemainingTime(endDate));
  const controls = useAnimation();

  useEffect(() => {
    // Recalculate remaining time when resetKey or endDate changes
    setCount(calculateRemainingTime(endDate));
  }, [resetKey, endDate]);

  useEffect(() => {
    if (count <= 0) {
      onComplete?.();
      return;
    }

    if (pause) return;

    const scaleValue = count < 10 ? [1.5, 1] : [1.2, 1];

    controls.start({
      scale: scaleValue,
      transition: { duration: 0.5 },
    });

    const intervalId = setInterval(() => {
      setCount(calculateRemainingTime(endDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [count, pause, controls, onComplete, endDate]);

  const textColorClass = count < 10 ? "text-text-error" : "text-text-primary";

  return (
    <motion.span
      animate={controls}
      className={`font-mono font-bold text-lg text-center tabular-nums ${textColorClass}`}
    >
      {count}
    </motion.span>
  );
};
