import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLoaderData } from "react-router-dom";
import useSound from "../../sound/useSound.ts";
import { Address, zeroAddress } from "viem";

import { useCopyToClipboard, useToggle } from "@uidotdev/usehooks";

import IMAGES from "../../assets/images";
import SOUNDS from "../../assets/sounds/index.ts";
import {
  ActionBar,
  ActionCard,
  ActionSheet,
  BattleActionLog,
  BattleFieldMarkings,
  BattleRoundLog,
  Button,
  ButtonVariant,
  Countdown,
  ModalBattleIntro,
  ModalBattleLog,
  ModalBattleResult,
  ModalBattleUser,
  ModalMonsterDetail,
  MonsterBoard,
  ProfilePicture,
} from "../../components";
import { UserContext } from "../../context";
import {
  GameOverLog,
  MatchPhase,
  MonsterDefeatedLog,
  useBattleActionLog,
  useBattleEffects,
  useBattleRoundLog,
  useMatch,
  useModalMonsterDetail,
  useModalProfile,
  useProcessLogs,
} from "../../hooks";
import {
  MatchPlayers,
  Monster,
  MonsterElement,
  MonsterStatusEffect,
  StatusEffectGroup,
  StatusEffectId,
  translateFromChain,
} from "../../types";
import { getCurrentTimestamp } from "../../utility";
import {
  AppliedStatusEffect,
  BoostAbility,
  DamageMove,
  ShieldTactic,
} from "./BattleActions";
import {
  ActionButtonConfig,
  ActionVariant,
  ActionVisibilityState,
  HandleActionProps,
  StatusEffectsDict,
} from "./BattleProps.ts";
import {
  calculateRemainingTime,
  getActionBoostAbilities,
  getActionDamageMovesForElement,
  getActionShieldTactics,
  getActionStatusEffects,
  getBattleVisualsBackgroundImage,
  getBattleVisualsButtonVariant,
  getPlayerData,
} from "./utility";

const actionBoostAbilities = getActionBoostAbilities();
const actionShieldTactics = getActionShieldTactics();

type BattleProps = {
  impersonatedAccount?: Address;
  impersonatedMatchId?: bigint;
  hideBattleResult?: boolean;
};

export const Battle = ({
  impersonatedAccount,
  impersonatedMatchId,
  hideBattleResult,
}: BattleProps) => {
  const element = useLoaderData() as MonsterElement;

  let { walletAddress } = useContext(UserContext)!;
  if (impersonatedAccount) {
    walletAddress = impersonatedAccount;
  }

  const {
    error,
    logs,
    allLogs,
    markLogRead,
    match,
    getMonsterById,
    previousPhase,
    setSelectedMove,
    withdraw,
    isCommitting,
    selectedMove,
    resetError,
    isConfidential,
  } = useMatch(walletAddress, impersonatedMatchId, true);

  const [isResultClosed, setIsResultClosed] = useState(false);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [soundEnabled, toggleSoundEnabled] = useToggle(true);

  const [replay, toggleReplay] = useToggle(false);
  const [play, { stop: stopSound }] = useSound(SOUNDS.battle, {
    soundEnabled,
    interrupt: true,
    volume: 0.5,
    onend: () => {
      toggleReplay();
    },
  });

  useEffect(() => {
    if (isPageVisible) {
      play();
    }

    return () => {
      stopSound();
    };
  }, [play, stopSound, replay, isPageVisible]);

  const handleVisibilityChange = () => {
    setIsPageVisible(!document.hidden);
  };

  useEffect(() => {
    // Event listeners for page visibility
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  /**
   * Battle State
   */

  const [defeatedSelfShownWaiting, setDefeatedSelfShownWaiting] =
    useState<boolean>(false);
  const [defeatedSelfShown, setDefeatedSelfShown] = useState<boolean>(false);
  const [defeatedOpponentShownWaiting, setDefeatedOpponentShownWaiting] =
    useState<boolean>(false);
  const [defeatedOpponentShown, setDefeatedOpponentShown] =
    useState<boolean>(false);

  // Translate status effects from chain to client
  const statusEffectsDict = useMemo<Partial<StatusEffectsDict>>(() => {
    const effectsArray: AppliedStatusEffect[] = getActionStatusEffects();
    const effectsDict: Partial<StatusEffectsDict> = {}; // Marked as partial
    for (const effect of effectsArray) {
      effectsDict[effect.key as StatusEffectId] = effect;
    }
    return effectsDict;
  }, []);

  const mapStatusEffects = useCallback(
    (
      monster: Monster | undefined,
      opponentMonster: Monster | undefined
    ): MonsterStatusEffect[] => {
      if (!monster || !opponentMonster) {
        return [];
      }

      return (
        monster.statusEffects?.map((effect) => {
          const key =
            effect.group === StatusEffectGroup.DEBUFF
              ? translateFromChain(opponentMonster, effect.name) // Debuffs are applied FROM the opponent
              : translateFromChain(monster, effect.name);
          const effectData = statusEffectsDict[key as StatusEffectId];

          return {
            key: key as StatusEffectId,
            name: effectData?.title || "Unknown",
            description: effectData?.description || "Unknown Description",
            remainingTurns: effect.remainingTurns,
            imageURI: effectData?.imageURI || "",
            group: effectData?.group,
          };
        }) || []
      );
    },
    [statusEffectsDict]
  );

  // Battle State
  const round = useMemo<bigint | undefined>(() => {
    return match?.round as bigint | undefined;
  }, [match]);

  const players = useMemo<MatchPlayers | undefined>(() => {
    if (!match) return undefined;

    let {
      self,
      opponent,
      activeMonster,
      inactiveMonster,
      activeMonsterOpponent,
      inactiveMonsterOpponent,
    } = match;

    if (inactiveMonster.config.attributes.hp === 0 && !defeatedSelfShown) {
      activeMonster = inactiveMonster;
      inactiveMonster = activeMonster;
    }

    if (
      inactiveMonsterOpponent.config.attributes.hp === 0 &&
      !defeatedOpponentShown
    ) {
      activeMonsterOpponent = inactiveMonsterOpponent;
      inactiveMonsterOpponent = activeMonsterOpponent;
    }

    return {
      getMonsterById,
      self: getPlayerData({
        address: self.address.toLowerCase() as Address,
        team: [activeMonster, inactiveMonster],
        userName: self.userName || "Self",
        imageURI: IMAGES.trainer,
      }),
      opponent: getPlayerData({
        address: opponent.address.toLowerCase() as Address,
        team: [activeMonsterOpponent, inactiveMonsterOpponent],
        userName: opponent.userName || "Opponent",
        imageURI: IMAGES.trainer,
      }),
    };
  }, [defeatedOpponentShown, defeatedSelfShown, getMonsterById, match]);

  const currentMonster = useMemo<Monster | undefined>(
    () => players?.self.team[0],
    [players]
  );

  const opponentMonster = useMemo<Monster | undefined>(
    () => players?.opponent.team[0],
    [players]
  );

  const currentMonsterStatusEffects = useMemo<MonsterStatusEffect[]>(
    () => mapStatusEffects(currentMonster, opponentMonster),
    [currentMonster, opponentMonster, mapStatusEffects] // Add statusEffectsDict as a dependency
  );

  const opponentMonsterStatusEffects = useMemo<MonsterStatusEffect[]>(
    () => mapStatusEffects(opponentMonster, currentMonster),
    [opponentMonster, currentMonster, mapStatusEffects] // Add statusEffectsDict as a dependency
  );

  const backgroundImage: string = useMemo(
    () => `bg-${getBattleVisualsBackgroundImage(element)}`,
    [element]
  );

  const onCountdownComplete = useCallback(() => {}, []);

  // Actions
  const variantDamageMoves = useMemo<ButtonVariant>(() => {
    if (!currentMonster) {
      return "light";
    }

    return getBattleVisualsButtonVariant(currentMonster?.config?.element);
  }, [currentMonster]);

  const actionConfig = useMemo<ActionButtonConfig[]>(() => {
    return [
      {
        actionType: "shieldTactics",
        icon: "health_and_safety",
        variant: "light",
      },
      {
        actionType: "damageMoves",
        icon: "swords",
        variant: variantDamageMoves,
      },
      { actionType: "boostAbilities", icon: "award_star", variant: "light" },
    ];
  }, [variantDamageMoves]);

  const actionDamageMoves = useMemo<DamageMove[]>(() => {
    return currentMonster?.config.element
      ? getActionDamageMovesForElement(currentMonster.config.element)
      : [];
  }, [currentMonster?.config.element]);

  const [actionVisibility, setActionVisibility] =
    useState<ActionVisibilityState>({
      damageMoves: false,
      boostAbilities: false,
      shieldTactics: false,
    });

  const handleToggleActionVisibility = useCallback(
    (key: keyof ActionVisibilityState) => {
      setActionVisibility((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    },
    []
  );

  const handleAction = useCallback(
    ({ from, contractName }: HandleActionProps) => {
      setActionVisibility((prevState) => ({
        ...prevState,
        [from]: false,
      }));

      if (contractName) {
        setSelectedMove(contractName);
        // setActionBarVisibility(false);
      }
    },
    [setSelectedMove]
  );

  // User Profile Modal
  const { profileState, handleProfileDetail, closeModalProfile } =
    useModalProfile();

  // Monster Detail Modal
  const { monsterDetail, detailVisibility, handleMonsterDetail, closeModal } =
    useModalMonsterDetail();

  // Battle Intro
  const [introVisibility, setIntroVisibility] = useState<boolean>(true);

  // Battle Log Modal
  const [logModalVisibility, setLogModalVisibility] = useState<boolean>(false);

  // Battle Round Log
  const { logMessage: roundLogMessage, logVisibility: roundLogVisibility } =
    useBattleRoundLog(round, introVisibility);

  // Battle Action Log
  const {
    logVisibility: actionLogVisibility,
    logMessage: actionLogMessage,
    addLog: addActionLog,
    logQueueSize,
  } = useBattleActionLog(introVisibility);

  // Battle Effects
  const {
    battleEffect: battleEffectOpponent,
    addBattleEffect: addBattleEffectOpponent,
  } = useBattleEffects();
  const {
    battleEffect: battleEffectSelf,
    addBattleEffect: addBattleEffectSelf,
  } = useBattleEffects();

  // Battle Result
  const winnerAddress = useMemo<Address | undefined>(() => {
    if (allLogs.length === 0) return undefined;

    // Get last log
    const lastLog = allLogs.flat()[allLogs.length - 1];

    if (lastLog instanceof GameOverLog) {
      return lastLog.winner;
    }
    let playerMonstersDefeated = 0;
    let opponentMonstersDefeated = 0;
    allLogs.forEach((log) => {
      if (log instanceof MonsterDefeatedLog) {
        if (
          match?.activeMonster.tokenId === log.monsterId ||
          match?.inactiveMonster.tokenId === log.monsterId
        ) {
          playerMonstersDefeated++;
        } else {
          opponentMonstersDefeated++;
        }
      }
    });
    if (opponentMonstersDefeated === 2) {
      return walletAddress;
    }
    if (playerMonstersDefeated === 2) {
      return match?.opponent.address;
    }
    if (match?.escaped && match?.escaped !== zeroAddress) {
      // if the opponent escaped, we are the winner
      // if we escaped, we won't even show the result modal
      return match?.escaped === walletAddress ? undefined : walletAddress;
    }

    return undefined;
  }, [
    allLogs,
    match?.activeMonster.tokenId,
    match?.escaped,
    match?.inactiveMonster.tokenId,
    match?.opponent.address,
    walletAddress,
  ]);

  const resultVisibility =
    !isResultClosed &&
    (winnerAddress !== undefined ||
      !match?.id ||
      match.escaped !== zeroAddress) &&
    logQueueSize === 0;

  /**
   * Action Bar
   */

  const [actionBarVisibility, setActionBarVisibility] = useState<boolean>(true);

  useEffect(() => {
    if (match === undefined) {
      setActionBarVisibility(false);
      return;
    }
  }, [match, logs, previousPhase, currentMonster]);

  useEffect(() => {
    if (
      currentMonster?.config.attributes.hp === 0 &&
      !defeatedSelfShownWaiting
    ) {
      setDefeatedSelfShownWaiting(true);
      setTimeout(() => {
        setDefeatedSelfShown(true);
      }, 10_000);
    }

    if (
      opponentMonster?.config.attributes.hp === 0 &&
      !defeatedOpponentShownWaiting
    ) {
      setDefeatedOpponentShownWaiting(true);
      setTimeout(() => {
        setDefeatedOpponentShown(true);
      }, 10_000);
    }
  }, [
    currentMonster,
    defeatedOpponentShownWaiting,
    defeatedSelfShownWaiting,
    opponentMonster,
  ]);

  // Battle Log Processing
  useProcessLogs({
    logs,
    players,
    markLogRead,
    addActionLog,
    addBattleEffectOpponent,
    addBattleEffectSelf,
  });

  const onCloseResult = useCallback(() => {
    setIsResultClosed(true);
  }, []);

  const isActionBarVisible =
    (!selectedMove &&
      !isCommitting &&
      match?.phase === MatchPhase.Commit &&
      match?.activeMonster?.isWaitingForCommit) ||
    (!selectedMove && isConfidential) ||
    !!error;

  const onOpponentProfilePictureClick = useCallback(() => {
    if (players?.opponent) {
      handleProfileDetail({
        userName: players.opponent.userName,
        imageURI: players.opponent.imageURI,
        walletAddress: players.opponent.address,
        team: players.opponent.team,
      });
    }
  }, [handleProfileDetail, players]);

  const [isErrorClosed, setIsErrorClosed] = useState(false);
  const onCloseError = useCallback(() => {
    setIsErrorClosed(true);
    resetError();
  }, [resetError]);

  useEffect(() => {
    if (error) {
      setIsErrorClosed(false);
    }
  }, [error]);

  const countdownEndDate = useMemo(() => {
    return new Date((Number(match?.timeout) || getCurrentTimestamp()) * 1000);
  }, [match?.timeout]);
  const isCountdownZero = calculateRemainingTime(countdownEndDate) <= 0;

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div
        className={`flex flex-1 flex-col px-4 py-safe-or-4 bg-no-repeat bg-cover bg-center bg-fixed overflow-hidden ${backgroundImage}`}
      >
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center space-x-2">
            <ProfilePicture
              loading={!players?.opponent?.imageURI}
              imageURI={players?.opponent?.imageURI}
              size={40}
              onClick={onOpponentProfilePictureClick}
            />
            <div className="w-8 h-8 bg-transparent" />
          </div>
          <div className="flex justify-center items-center h-10 w-24 rounded-lg bg-background-primary/80 border-background-primary/10 backdrop-blur-sm shadow-sm">
            <Countdown
              pause={match === undefined}
              endDate={countdownEndDate}
              onComplete={onCountdownComplete}
              resetKey={Number(match?.round || 0)}
            />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <Button
              variant="light"
              size="xs"
              className="w-8 h-8"
              sound="click"
              onClick={() => setLogModalVisibility(true)}
            >
              <span className="material-symbols-rounded text-text-tertiary text-base">
                stacks
              </span>
            </Button>
            <ProfilePicture
              loading={!players?.self?.imageURI}
              imageURI={players?.self.imageURI}
              size={40}
              onClick={() => {
                if (players?.self) {
                  handleProfileDetail({
                    userName: players.self.userName,
                    imageURI: players.self.imageURI,
                    walletAddress: players.self.address,
                    team: players.self.team,
                  });
                }
              }}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-col mb-20 mt-6 p-2 relative bg-background-primary/5 backdrop-blur-md rounded-3xl shadow-xl border border-background-primary/20">
          <BattleFieldMarkings />

          <div className="flex flex-1 rounded-t-2xl p-4 pb-3 justify-center items-center">
            <MonsterBoard
              monster={opponentMonster}
              statusEffects={opponentMonsterStatusEffects}
              battleEffect={battleEffectOpponent}
              onClick={() =>
                handleMonsterDetail({
                  monster: opponentMonster,
                  statusEffects: opponentMonsterStatusEffects,
                })
              }
            />
          </div>
          <div className="flex flex-1 rounded-t-2xl p-4 pb-3 justify-center items-center">
            <MonsterBoard
              monster={currentMonster}
              statusEffects={currentMonsterStatusEffects}
              battleEffect={battleEffectSelf}
              onClick={() =>
                handleMonsterDetail({
                  monster: currentMonster,
                  statusEffects: currentMonsterStatusEffects,
                })
              }
            />
          </div>

          <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center items-center px-2 pointer-events-none">
            <BattleActionLog
              message={actionLogMessage}
              isVisible={actionLogVisibility}
              animateHorizontal
            />
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 left-4 right-4">
        <div className="pb-safe-or-4 overflow-hidden">
          <ActionBar variant="floating" visible={isActionBarVisible}>
            {!error &&
              actionConfig.map(({ actionType, icon, variant }) => (
                <Button
                  key={actionType}
                  variant={variant}
                  size="xs"
                  className="w-full"
                  onClick={() => handleToggleActionVisibility(actionType)}
                  sound="click"
                >
                  <span className="material-symbols-rounded">{icon}</span>
                </Button>
              ))}
          </ActionBar>
        </div>
      </div>

      <BattleActionSheet
        title="Shield Tactics"
        actionType="shieldTactics"
        handleAction={handleAction}
        actions={actionShieldTactics}
        isVisible={actionVisibility.shieldTactics}
        toggleVisibility={handleToggleActionVisibility}
      />

      <BattleActionSheet
        title="Damage Moves"
        actionType="damageMoves"
        handleAction={handleAction}
        actions={actionDamageMoves}
        isVisible={actionVisibility.damageMoves}
        toggleVisibility={handleToggleActionVisibility}
      />

      <BattleActionSheet
        title="Boost Abilities"
        actionType="boostAbilities"
        handleAction={handleAction}
        actions={actionBoostAbilities}
        isVisible={actionVisibility.boostAbilities}
        toggleVisibility={handleToggleActionVisibility}
      />

      <BattleRoundLog
        message={roundLogMessage}
        isVisible={roundLogVisibility}
      />

      <div className="absolute bottom-0 right-0 left-0 px-4 pb-safe-or-2 pointer-events-none">
        <BattleActionLog
          message={
            isCountdownZero
              ? "Processing Moves On-Chain …"
              : "Waiting for opponent to commit…"
          }
          isVisible={!actionLogVisibility && !isActionBarVisible}
        />
      </div>

      <ModalMonsterDetail
        {...monsterDetail}
        isVisible={detailVisibility}
        onClose={closeModal}
      />

      <ModalBattleUser
        isVisible={profileState.isVisible}
        userName={profileState.detail?.userName}
        imageURI={profileState.detail?.imageURI}
        walletAddress={profileState.detail?.walletAddress}
        team={profileState.detail?.team}
        onClose={closeModalProfile}
        onForfeit={withdraw}
        onToggleSound={toggleSoundEnabled}
        soundEnabled={soundEnabled}
      />

      {!hideBattleResult && (
        <ModalBattleResult
          isVisible={resultVisibility}
          winner={winnerAddress}
          players={players}
          onFinish={withdraw}
          isEscaped={match?.escaped !== zeroAddress && match?.escaped !== winnerAddress}
          onLongPress={onCloseResult}
        />
      )}

      <ModalBattleIntro
        isVisible={introVisibility}
        onClose={() => setIntroVisibility(false)}
        userName={players?.self?.userName}
        imageURI={players?.self?.imageURI}
        opponentUserName={players?.opponent?.userName}
        opponentImageURI={players?.opponent?.imageURI}
      />

      {match?.id !== undefined && (
        <ModalBattleLog
          isVisible={logModalVisibility}
          onClose={() => setLogModalVisibility(false)}
          logs={allLogs}
          players={players}
          matchId={match?.id}
          round={match?.round}
        />
      )}

      <ErrorActionSheet
        isVisible={!!error && !isErrorClosed}
        onClose={onCloseError}
        error={error?.message}
        onLeave={withdraw}
      />
    </div>
  );
};

/**
 * Battle Action Sheet
 */

interface BattleActionSheetProps {
  title: string;
  isVisible: boolean;
  actions: DamageMove[] | BoostAbility[] | ShieldTactic[];
  actionType: ActionVariant;
  handleAction: (props: HandleActionProps) => void;
  toggleVisibility: (actionType: keyof ActionVisibilityState) => void;
}

const BattleActionSheet: React.FC<BattleActionSheetProps> = ({
  title,
  isVisible,
  actions,
  actionType,
  handleAction,
  toggleVisibility,
}) => {
  const onClose = useCallback(() => {
    toggleVisibility(actionType);
  }, [actionType, toggleVisibility]);

  return (
    <ActionSheet title={title} isVisible={isVisible} onClose={onClose}>
      {actions.map(
        ({ key, title, description, elements, imageURI, contractName }) => (
          <ActionCard
            key={key}
            title={title}
            description={description}
            elements={elements}
            imageURI={imageURI}
            sound="select"
            onClick={() =>
              handleAction({ from: actionType, key, contractName })
            }
          />
        )
      )}
    </ActionSheet>
  );
};

/**
 * Error Action Sheet
 */

interface ErrorActionSheetProps {
  isVisible: boolean;
  onClose: () => void;
  onLeave?: () => void;
  error?: string;
}

const ErrorActionSheet: React.FC<ErrorActionSheetProps> = ({
  isVisible,
  onClose,
  onLeave,
  error,
}) => {
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const handleClick = () => copyToClipboard(error || "");
  return (
    <ActionSheet title="Error" isVisible={isVisible} onClose={() => onClose()}>
      <div className="flex flex-col space-y-4">
        <div
          onClick={handleClick}
          className="flex flex-col p-1 rounded-md border-0.5 border-background-primary bg-background-primary/80 overflow-hidden"
        >
          <p className="text-text-error text-2xs">{error}</p>
        </div>
        <div className="flex flex-row space-x-2">
          <Button
            variant="light"
            size="xs"
            className="w-full"
            sound="click"
            onClick={() => onLeave && onLeave()}
          >
            Leave Battle
          </Button>
          <Button
            variant="light"
            size="xs"
            className="w-full"
            sound="click"
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        </div>
      </div>
    </ActionSheet>
  );
};
