import { Address } from "wagmi";
import {
  PLACEHOLDER_CHAIN_ID,
  polychainMonstersTestnet,
  sapphire,
  blastTestnet,
  pmon,
  sapphireTestnet,
  arbitrumNova,
} from "../../chains.ts";
import { Abi, zeroAddress } from "viem";
import {
  AppVersionsV1Abi,
  ContractApiV1Abi,
  LeaderboardV1Abi,
  MatchMakerV2Abi,
  UsernamesV1Abi,
  BondingCurveMonsAbi,
  LeaderboardManagerV1Abi,
} from "./abis";
import { arbitrum } from "viem/chains";

const PMON_CONTRACT_API = import.meta.env.VITE_PMON_CONTRACT_API;
const PMON_MATCH_MAKER = import.meta.env.VITE_PMON_MATCH_MAKER;
const PMON_LEADERBOARD_MANAGER = import.meta.env.VITE_PMON_LEADERBOARD_MANAGER;
const PMON_BOOSTER_PACKS = import.meta.env.VITE_PMON_BOOSTER_PACKS;
const PMON_USERNAMES = import.meta.env.VITE_PMON_USERNAMES;
const PMON_APP_VERSIONS = import.meta.env.VITE_PMON_APP_VERSIONS;

// Interface for the contract addresses
interface IContracts {
  contractApi: Address;
  matchMaker: Address;
  usernames: Address;
  leaderboardManager?: Address;
  appVersions: Address;
  packBondingCurve?: Address;
}

export enum ContractNames {
  MATCH_MAKER = "MATCH_MAKER",
  USERNAMES = "USERNAMES",
  LEADERBOARD = "LEADERBOARD",
  LEADERBOARD_MANAGER = "LEADERBOARD_MANAGER",
  APP_VERSIONS = "APP_VERSIONS",
  CONTRACT_API = "CONTRACT_API",
  BONDING_CURVE_MONS = "BONDING_CURVE_MONS",
}

const polychainMonstersTestNetContracts: IContracts = {
  contractApi: "0xDF39542077287D94f59f3830475D7D78059344e8",
  matchMaker: "0xC5a7d8E6cA39e166B143BEaC760b9EAD83647EA1",
  usernames: "0x00B0E9f222e6042780bea3dAe452aac352637596",
  leaderboardManager: "0x361af343CabecC71B6C0DaDEf5C5E6f102df3E46",
  appVersions: "0x9E0E92aB5A1ed77071FD5f6F91977F1c530fD71D",
  packBondingCurve: "0xffDEfc57491379a9766330B998ee5946Af69A4E4",
};

const pmonContracts: IContracts = {
  contractApi:
    PMON_CONTRACT_API || "0xd8751d5b2F3499d3d3Dc51Ef273C1fcd51188Da5",
  matchMaker: PMON_MATCH_MAKER || "0x72080c9212a58A69a47b9ED2a977dEA8f0A7d855",
  usernames: PMON_USERNAMES || "0x95F739638Ed982116bdf987B4698AAf222a3214E",
  appVersions:
    PMON_APP_VERSIONS || "0xb5D534724ddd7512874632dB32f8851668628215",
  leaderboardManager:
    PMON_LEADERBOARD_MANAGER || "0x7AFD6F998d3D2B47332db1fb1C4D8DF6CB14638c",
  packBondingCurve:
    PMON_BOOSTER_PACKS || "0x96a76C663bF7eB51250d823fe610b6d50aCA2097",
};

const sapphireTestnetContracts: IContracts = {
  contractApi: "0x2c641ba9f607DBB54150f0E35383A756CDD18e07",
  matchMaker: "0x5Ec1A8Fef85bb6d00c13F97A0a6eBc071225FC72",
  usernames: "0x8c7194E31122E7C6Df35fa0890546D21f8B92106",
  leaderboardManager: "0x9F03f4D67DdA5a6b2C79D93C5321dc9774b6976f",
  appVersions: "0x1ecF8Fcf3E49031161b1fEF371808bD495dCD36D",
  packBondingCurve: "0x0abe16fBFb184d27AcE3B4Ec5048225e28a94236",
};

const sapphireContracts: IContracts = {
  contractApi: "0x7734FeE41354b7a1107495B38E40Da0A023aC9DB",
  matchMaker: "0x9e24ff725d2Bb79859ddd9001A73CA6d96f47504",
  //usernames: "0x334d218C9AFc3e4b0B7758E081Eb9E49814b3f17",
  usernames: "0x21c10E328761c94c59c47525a85d744a928Dc581",
  leaderboardManager: "0x3cbd80458395fb6902051B351Eb0ED7d9BdC0ae0",
  appVersions: "0xd80540702E03E90Ec367f7A5ec887f183CB8Cbcb",
  packBondingCurve: "0x3dA6D4cdEDAc83aA38dA0f22656dA63EDC4EE314",
};

const blastTestnetContracts: IContracts = {
  contractApi: zeroAddress,
  matchMaker: zeroAddress,
  usernames: zeroAddress,
  appVersions: "0x9E0E92aB5A1ed77071FD5f6F91977F1c530fD71D", // not deployed yet
  packBondingCurve: "0xEC4Ac555caDA814CC362d48b0232720ccDf379F4",
};

const novaContracts: IContracts = {
  ...pmonContracts,
  usernames: "0x994D0330ff5cF2D1A85881C76F76DB877e4e77dE",
  appVersions: "0x67d7045c68D7f9F3E6f75F7FED86864B01F58797",
};

export const contracts: Record<string, IContracts> = {
  [PLACEHOLDER_CHAIN_ID]: {
    contractApi: zeroAddress,
    matchMaker: zeroAddress,
    usernames: zeroAddress,
    appVersions: zeroAddress,
    packBondingCurve: zeroAddress,
  },
  [`${polychainMonstersTestnet.id}`]: polychainMonstersTestNetContracts,
  [`eip155:${polychainMonstersTestnet.id}`]: polychainMonstersTestNetContracts,
  [`${sapphire.id}`]: sapphireContracts,
  [`eip155:${sapphire.id}`]: sapphireContracts,
  [`${sapphireTestnet.id}`]: sapphireTestnetContracts,
  [`eip155:${sapphireTestnet.id}`]: sapphireTestnetContracts,
  [`${blastTestnet.id}`]: blastTestnetContracts,
  [`eip155:${blastTestnet.id}`]: blastTestnetContracts,
  [`${pmon.id}`]: pmonContracts,
  [`eip155:${pmon.id}`]: pmonContracts,
  [`${arbitrumNova.id}`]: novaContracts,
  [`eip155:${arbitrumNova.id}`]: novaContracts,
  [`${arbitrum.id}`]: {} as any,
  [`eip155:${arbitrum.id}`]: {} as any,
};

export const getAbi = (contractName: ContractNames): Abi => {
  switch (contractName) {
    case ContractNames.MATCH_MAKER:
      return MatchMakerV2Abi as Abi;
    case ContractNames.USERNAMES:
      return UsernamesV1Abi as Abi;
    case ContractNames.LEADERBOARD:
      return LeaderboardV1Abi as Abi;
    case ContractNames.LEADERBOARD_MANAGER:
      return LeaderboardManagerV1Abi as Abi;
    case ContractNames.APP_VERSIONS:
      return AppVersionsV1Abi as Abi;
    case ContractNames.CONTRACT_API:
      return ContractApiV1Abi as Abi;
    case ContractNames.BONDING_CURVE_MONS:
      return BondingCurveMonsAbi as Abi;
    default:
      throw new Error(`Unknown contract name: ${contractName}`);
  }
};

export const getAddress = (
  contractName: ContractNames,
  chainId?: string
): Address => {
  switch (contractName) {
    case ContractNames.MATCH_MAKER:
      return contracts[chainId || PLACEHOLDER_CHAIN_ID].matchMaker;
    case ContractNames.USERNAMES:
      return contracts[chainId || PLACEHOLDER_CHAIN_ID].usernames;
    case ContractNames.LEADERBOARD_MANAGER:
      return (
        contracts[chainId || PLACEHOLDER_CHAIN_ID].leaderboardManager ||
        zeroAddress
      );
    case ContractNames.APP_VERSIONS:
      return contracts[chainId || PLACEHOLDER_CHAIN_ID].appVersions;
    case ContractNames.CONTRACT_API:
      return contracts[chainId || PLACEHOLDER_CHAIN_ID].contractApi;
    case ContractNames.BONDING_CURVE_MONS:
      return (
        contracts[chainId || PLACEHOLDER_CHAIN_ID].packBondingCurve ||
        zeroAddress
      );
    default:
      throw new Error(`Unknown contract name: ${contractName}`);
  }
};
